import {store} from "../store";

class MeddocFieldTypeManager {
    //список значений справочника типов полей меддока
    async getFieldTypes() {
        if(store.getters.meddocFieldTypes.length == 0){
        await store.dispatch('GET_MEDDOCFIELDTYPES');
        }
        return store.getters.meddocFieldTypes;
    }
    //список значений справочника ширин полей меддока
    async getFieldWidthTypes() {
        if(store.getters.meddocFieldWidthTypes.length == 0){
        await store.dispatch('GET_MEDDOCFIELDWIDTHTYPES');
        }
        return store.getters.meddocFieldWidthTypes;
    }
    //список значений справочника высот полей меддока
    async getFieldHeightTypes() {
        if(store.getters.meddocFieldHeightTypes.length == 0){
        await store.dispatch('GET_MEDDOCFIELDHEIGHTTYPES');
        }
        return store.getters.meddocFieldHeightTypes;
    }
}
export default new MeddocFieldTypeManager();