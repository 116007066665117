<template>
  <el-dialog
    class="modal-add-edit-appointment"
    :close-on-click-modal="false"
    :visible="isModalAddEditAppointmentVisible"
    @update:visible="closeModalAddEditAppointment"
    width="80%"
  >
    <!-- <div v-loading="isLoading"> -->
    <div class="modal-add-edit-appointment__title-slot" slot="title">
      <span class="modal-add-edit-appointment__title-default">{{
        `${titleDefault},`
      }}</span>
      <span class="modal-add-edit-appointment__title-addition">{{
        `${titleAddition}`
      }}</span>
    </div>
    <el-row :gutter="24">
      <el-col :lg="12" :md="24" :sm="24">
        <el-alert
          v-if="checkError"
          :title="checkError"
          type="error"
          show-icon
          :closable="false"
        >
        </el-alert>
        <el-row :gutter="24">
          <el-col :md="8">
            <label class="settings-label" for="">Дата и время</label>
            <el-date-picker
              size="small"
              v-model="currentAppointment.dateTimeFrom"
              type="datetime"
              format="dd.MM.yyyy HH:mm"
              placeholder="Выберите дату и время"
              :picker-options="pickerOptionsBeforeNow"
              @change="checkAvailabilityAppointment(1)"
            >
            </el-date-picker>
          </el-col>
          <el-col class="mt-md-10" :md="16">
            <label for="">Специалист</label>
            <el-select
              :disabled="isEditMode"
              size="small"
              v-model="currentAppointment.idDoctor"
              @change="checkAvailabilityAppointment(1)"
              placeholder="Выберите специалиста"
            >
              <el-option
                v-for="item in doctorsList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              >
              </el-option
            ></el-select>
          </el-col>
        </el-row>
        <span class="mt-10 modal-add-edit-appointment_subtitle">Пациент </span>
        <el-row class="mt-10" :gutter="24">
          <el-col :md="8">
            <label>Фамилия <span class="danger">*</span></label>
            <el-autocomplete
              size="small"
              placeholder="Введите фамилию"
              :trigger-on-focus="false"
              :fetch-suggestions="searchPatientByLastName"
              @select="handleSelectPatient"
              :highlight-first-item="true"
              :readonly="isEditMode"
              v-model="currentPatient.lastName"
              @input="handleInputLastName"
            ></el-autocomplete>
          </el-col>
          <el-col class="mt-md-10" :md="8">
            <label for="">Имя <span class="danger">*</span></label>
            <el-input
              :readonly="isEditMode"
              size="small"
              placeholder="Введите имя"
              v-model="currentPatient.firstName"
              @input="handleInputFirstName"
            ></el-input>
          </el-col>
          <el-col class="mt-md-10" :md="8">
            <label for="">Отчество</label>
            <el-input
              :readonly="isEditMode"
              size="small"
              placeholder="Введите отчество"
              v-model="currentPatient.patronymic"
              @input="handleInputPatronymic"
            ></el-input>
          </el-col>
        </el-row>
      </el-col>
      <el-col :lg="12" :md="24" :sm="24">
        <PatientInfoBlockComponent
          :selected-patient.sync="currentPatient"
        ></PatientInfoBlockComponent>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :lg="12" :md="24" :sm="24">
        <el-row class="mt-10" :gutter="24">
          <el-col :md="12">
            <label for="">Телефон <span class="danger">*</span></label>
            <el-autocomplete
              :readonly="isEditMode"
              size="small"
              v-maska
              data-maska="+7##########"
              placeholder="+7 000 000 00 00"
              v-model="currentPatient.phone"
              :trigger-on-focus="false"
              :fetch-suggestions="searchPatientByPhone"
              @select="handleSelectPatient"
              :highlight-first-item="true"
            ></el-autocomplete>
          </el-col>
          <el-col class="mt-md-10" :md="12">
            <label for="">Дата рождения</label>
            <el-date-picker
              :readonly="isEditMode"
              size="small"
              v-model="currentPatient.birthDate"
              type="date"
              format="dd.MM.yyyy"
              value-format="yyyy-MM-dd"
              placeholder="Введите дату"
              :picker-options="pickerOptionsAfterNow"
            >
            </el-date-picker>
          </el-col>
        </el-row>
        <el-row class="mt-10" :gutter="24">
          <el-col :md="8">
            <label for="">Статус</label>
            <el-select
              size="small"
              v-model="currentAppointment.idAppointmentStatus"
              placeholder="Выберите статус"
            >
              <el-option
                v-for="item in appointmentStatusList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              >
              </el-option
            ></el-select>
          </el-col>
          <el-col class="mt-md-10" :md="8">
            <label for="">Длительность, мин.</label>
            <el-select
              size="small"
              v-model="currentAppointment.duration"
              placeholder="Выберите длительность"
              filterable
              allow-create
              default-first-option
              @change="handleChangeAppointmentDuration"
            >
              <el-option
                v-for="(duration, index) in appointmentDurationList"
                :key="index"
                :label="duration"
                :value="duration"
              >
              </el-option
            ></el-select>
          </el-col>
          <el-col class="mt-md-10" :md="8">
            <label for="">Тип приема</label>
            <el-select
              size="small"
              v-model="currentAppointment.idAppointmentType"
              placeholder="Выберите тип приема"
            >
              <el-option
                v-for="item in visitTypeList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              >
              </el-option
            ></el-select>
          </el-col>
        </el-row>
        <div class="modal-add-edit-appointment__notice">
          <i class="el-icon-info-circle"></i
          ><span>{{ appointmentDurationNoticeText }}</span>
        </div>
      </el-col>
      <el-col :lg="12" :md="24" :sm="24">
        <el-row class="mt-10" :gutter="24">
          <el-col class="mt-md-10" :md="12">
            <label for="">Комментарий</label>
            <el-input
              size="small"
              type="textarea"
              rows="4"
              resize="none"
              placeholder="Введите комментарий"
              v-model="currentAppointment.note"
            ></el-input>
          </el-col>
          <el-col class="mt-md-10" :md="12">
            <label for="">Уведомления</label>
            <div class="modal-add-edit-appointment_notices">
              <el-checkbox v-model="currentAppointment.smsNotification"
                >SMS</el-checkbox
              >
              <el-checkbox v-model="currentAppointment.emailNotification"
                >Email</el-checkbox
              >
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <span class="modal-add-edit-appointment_subtitle">Страхование</span>
    <el-row :gutter="24">
      <el-col :lg="8" :md="12" :sm="24">
        <label for="">Страховой полис</label>
        <el-select
          data-is-search="true"
          :clearable="true"
          size="small"
          v-model="currentAppointment.insurancePolicyId"
          placeholder="Выберите полис"
          no-data-text="Нет действующих полисов страхования"
          @change="handleInsurancePolicySelect"
        >
          <el-option
            v-for="item in insurancePolicyList"
            :key="item.id"
            :label="`${item.insuranceCompany} ${item.number} - ${
              item.series
            } (${item.insuranceType === 0 ? 'ОМС' : 'ДМС'})`"
            :value="item.id"
          >
            <span style="float: left">{{
              `${item.insuranceCompany} ${item.series} ${item.number}`
            }}</span>
            <span style="float: right; color: #8492a6; font-size: 12px">{{
              item.insuranceType === 0 ? 'ОМС' : 'ДМС'
            }}</span>
          </el-option></el-select
        >
      </el-col>
      <el-col class="mt-md-10" :lg="8" :md="12" :sm="24">
        <label>Гарантийное письмо</label>
        <el-select
          :clearable="true"
          data-is-search="true"
          size="small"
          v-model="currentAppointment.guaranteeLetterId"
          placeholder="Выберите гарантийное письмо"
          no-data-text="Нет действующих гарантийных писем"
        >
          <el-option
            v-for="item of guaranteeLetterList"
            :key="item.id"
            :label="setGuaranteeLetterLabel(item)"
            :value="item.id"
          >
          </el-option
        ></el-select>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <span class="modal-add-edit-appointment_subtitle">Услуги</span>
    <el-row :gutter="24">
      <el-col :lg="12" :md="24">
        <label for="">Добавьте услуги</label>
        <el-select
          data-is-search="true"
          size="small"
          v-model="service"
          placeholder="Выберите наименование услуги"
          filterable
          @change="handleSelectService"
        >
          <el-option
            v-for="item in servicesList"
            :key="item.id"
            :label="item.orgName"
            :value="item.id"
          >
          </el-option
        ></el-select>
      </el-col>
    </el-row>
    <el-table
      class="mt-10"
      stripe
      ref="multipleTable"
      :data="currentAppointment.services"
      empty-text="Нет добавленных услуг"
      style="width: 100%"
    >
      <el-table-column
        min-width="380px"
        property="name"
        label="Наименование"
      ></el-table-column>
      <el-table-column
        min-width="150px"
        property="duration"
        label="Длительность, мин."
        :formatter="formatterDuration"
      ></el-table-column>
      <el-table-column width="150px" label="Количество, шт.">
        <template slot-scope="scope">
          <el-input-number
            size="mini"
            v-model="scope.row.count"
            :min="1"
            :max="10"
            :precision="0"
            @change="handleEditService(scope.row)"
          ></el-input-number>
        </template>
      </el-table-column>
      <el-table-column width="150px" label="Скидка, %">
        <template slot-scope="scope">
          <el-input-number
            size="mini"
            v-model="scope.row.discount"
            :min="0"
            :max="100"
            :step="10"
            :precision="0"
            @change="handleEditService(scope.row)"
          ></el-input-number>
        </template>
      </el-table-column>
      <el-table-column width="150px" property="cost" label="Стоимость, ₽">
        <template slot-scope="scope">
          <el-input-number
            size="mini"
            :controls="false"
            :disabled="true"
            v-model="scope.row.priceDiscount"
          >
          </el-input-number>
        </template>
      </el-table-column>
      <el-table-column width="100px" label="">
        <template slot-scope="scope">
          <el-button size="small" type="text" class="danger">
            <i
              class="el-icon-delete el-icon-left"
              @click="handleDeleteService(scope.row)"
            ></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-row class="mt-10" :gutter="24">
      <el-col :md="12" :xs="24">
        <span class="modal-add-edit-appointment_total-price"
          >ИТОГО: {{ totalSum }} ₽</span
        >
      </el-col>
      <el-col class="text-right" :md="12" :xs="24">
        <el-button v-if="false" type="text" size="small"
          >Сформировать счет <i class="el-icon-arrow-right"></i
        ></el-button>
      </el-col>
    </el-row>
    <div class="mt-16 modal-add-edit-appointment-footer">
      <el-button
        :disabled="checkError ? true : false"
        type="primary"
        size="small"
        @click="saveAppointmentAsync"
        >Сохранить</el-button
      >
      <el-button size="small" @click="closeModalAddEditAppointment"
        >Отменить</el-button
      >
      <el-button
        v-if="!isEditMode"
        type="danger"
        plain
        size="small"
        @click="clearModalAddEditAppointment"
        >Сбросить</el-button
      >
    </div>
    <!-- </div> -->
  </el-dialog>
</template>

<script>
import appointmentManager from '@/services/appointmentManager';
import PatientInfoBlockComponent from './PatientInfoBlockComponent.vue';
import {
  getDateString,
  setFirstLetterToUpperCase,
} from '@/services/commonService';
import { getTimeOffset } from '@/services/commonService';
import dateTimeService from '@/services/dateTimeService';
import Money from '@/services/moneyService';

export default {
  name: 'ModalAddEditAppointment',
  components: {
    PatientInfoBlockComponent,
  },
  props: {
    isModalAddEditAppointmentVisible: {
      type: Boolean,
      default: false,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    isCopyingMode: {
      type: Boolean,
      default: false,
    },
    isMovingMode: {
      type: Boolean,
      default: false,
    },
    appointmentData: {
      type: Object,
    },
    patientData: {
      type: Object,
    },
    totalPrice: {},
  },
  data() {
    return {
      checkError: null,
      manualChangeDuration: false,
      service: null,
      error: null,
      pickerOptionsAfterNow: {
        firstDayOfWeek: 1,
        disabledDate: function (time) {
          return time.getTime() > Date.now();
        },
      },
      pickerOptionsBeforeNow: {
        firstDayOfWeek: 1,
      },
    };
  },
  computed: {
    doctorsList() {
      return this.$store.getters.doctorsList;
    },
    appointmentStatusList() {
      return this.$store.getters.appointmentStatusList;
    },
    visitTypeList() {
      return this.$store.getters.appointmentTypeList;
    },
    appointmentDurationList() {
      return this.$store.getters.appointmentDurationList;
    },
    servicesList() {
      return this.$store.getters.servicesList;
    },
    currentAppointment: {
      get() {
        return this.appointmentData;
      },
      set(value) {
        this.$emit('update:appointmentData', value);
      },
    },
    currentPatient: {
      get() {
        return this.patientData;
      },
      set(value) {
        this.$emit('update:patientData', value);
      },
    },
    totalSum: {
      get() {
        return this.totalPrice;
      },
      set(value) {
        this.$emit('update:totalPrice', value);
      },
    },
    titleDefault() {
      let title = 'Добавление записи на прием';
      if (this.isEditMode) {
        title = 'Редактирование записи на прием';
      }
      if (this.isEditMode && this.isCopyingMode) {
        title = 'Копирование записи на прием';
      }
      if (this.isEditMode && this.isMovingMode) {
        title = 'Перемещение записи на прием';
      }
      return title;
    },
    titleAddition() {
      // забираем дату начала записи и выводим в зависимости от текущей даты
      let date;
      if (
        new Date(this.currentAppointment.dateTimeFrom).getDate() ===
        new Date().getDate()
      ) {
        date = 'cегодня';
      } else {
        date = new Date(
          this.currentAppointment.dateTimeFrom
        ).toLocaleDateString('ru-RU');
      }
      // Получаем день недели
      let weekDay = dateTimeService
        .getWeekdayFromDate(this.currentAppointment.dateTimeFrom, 'short')
        .toUpperCase();
      // забираем время начала приема
      let time = new Date(
        this.currentAppointment.dateTimeFrom
      ).toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });
      // получаем врача и его должность из списка
      let doctor = this.doctorsList.find(
        (d) => d.id === this.currentAppointment.idDoctor
      );
      // проверяем все ли переменные расчитались, чтобы не получать ошибки в консоли при монтаже конпонента
      if (date && time && doctor) {
        return `${date} (${weekDay}), ${time}, врач: ${doctor.value}`;
      } else {
        return '';
      }
    },
    useDoctorAppointmentDuration() {
      return this.$store.getters.useDoctorAppointmentDuration;
    },
    appointmentDurationNoticeText() {
      return this.useDoctorAppointmentDuration
        ? 'Длительность приема расчитана исходя из базовой длительности приема врача'
        : 'Длительность приема расчитана исходя из базовой длительности добавленных услуг';
    },
    insuranceOrganizationList() {
      return this.$store.getters.contractorOrganizations;
    },
    guaranteeLetterList() {
      let guaranteeLetterList =
        this.$store.getters.guaranteeLetterListByPolicyId(
          this.currentAppointment.insurancePolicyId
        );
      return guaranteeLetterList.filter(
        (x) => new Date(x.validUntil) >= new Date()
      );
    },
    insurancePolicyList() {
      let policyList = this.$store.getters.insurancePolicyListByPatientId(
        this.currentPatient.id
      );
      let activePolicyList = policyList.filter(
        (p) => new Date(p.validUntil) >= new Date()
      );
      return activePolicyList;
    },
  },
  mounted() {},
  watch: {
    currentPatient() {
      this.currentAppointment.idPatient = this.currentPatient.id;
      if (this.currentAppointment.idPatient) {
        this.$store.dispatch('getGuaranteeLetterList', {
          patientId: this.currentAppointment.idPatient,
        });
      }
      this.checkAvailabilityAppointment(0);
    },
    currentAppointment: {
      handler(value) {
        // следим за глубоким изменением объекта currentAppointment и вызываем метод пересчета длительности записи на прием
        this.calculationAppointmentDuration();
        return value;
      },
      deep: true,
    },
  },
  methods: {
    checkAvailabilityAppointment(type) {
      if (!this.isEditMode) {
        let dateTimeFrom = new Date(this.currentAppointment.dateTimeFrom);
        let dateTimeTo = new Date(
          new Date(this.currentAppointment.dateTimeFrom).getTime() +
            this.currentAppointment.duration * 60000
        );
        if (type === 0) {
          // проверка через patientId
          this.checkError = appointmentManager.checkAppointmentByPatient(
            dateTimeFrom,
            dateTimeTo,
            this.currentAppointment.idPatient
          );
        }
        if (type === 1) {
          // проверка через appointmentId
          this.checkError = appointmentManager.checkAppointmentByDoctor(
            dateTimeFrom,
            dateTimeTo,
            this.currentAppointment.idDoctor,
            this.currentAppointment.id
          );
        }
      }
    },
    setGuaranteeLetterLabel(item) {
      return `№${item.number} - действ. до ${getDateString(item.validUntil)}`;
    },
    handleInsurancePolicySelect() {
      this.currentAppointment.guaranteeLetterId = '';
    },
    closeModalAddEditAppointment() {
      this.$emit('update:isModalAddEditAppointmentVisible');
      this.$emit('update:isMovingMode');
      this.$emit('update:isCopyingMode');
      this.$emit('update:isEditMode');
      this.clearModalAddEditAppointment();
    },
    clearModalAddEditAppointment() {
      this.$emit('clearModalAddEditAppointment');
      this.checkError = null;
      this.totalSum = 0;
    },
    searchPatientByLastName(search, cb) {
      this.$store
        .dispatch('getPatients', {
          patientLastName: search,
          pagesize: 10,
          pagenumber: 1,
        })
        .then(() => {
          let results = [];
          results = this.$store.getters.patients.map((x) => {
            return {
              id: x.id,
              value: `${x.lastName} ${x.firstName} ${x.patronymic}, ${
                x.birthDate ? getDateString(x.birthDate) : ''
              }, ${x.phone}
              `,
            };
          });
          cb(results);
        });
    },
    searchPatientByPhone(search, cb) {
      this.$store
        .dispatch('getPatients', {
          patientPhone: search,
          pagesize: 10,
          pagenumber: 1,
        })
        .then(() => {
          let results = [];
          results = this.$store.getters.patients.map((x) => {
            return {
              id: x.id,
              value: `${x.lastName} ${x.firstName} ${x.patronymic}, ${
                x.birthDate ? getDateString(x.birthDate) : ''
              }, ${x.phone}
              `,
            };
          });
          cb(results);
        });
    },
    handleInputLastName(value) {
      this.currentPatient.lastName = setFirstLetterToUpperCase(value);
    },
    handleInputFirstName(value) {
      this.currentPatient.firstName = setFirstLetterToUpperCase(value);
    },
    handleInputPatronymic(value) {
      this.currentPatient.patronymic = setFirstLetterToUpperCase(value);
    },
    handleSelectPatient(item) {
      let searchPatientList = this.$store.getters.patients;
      let selectedPatient = searchPatientList.find((p) => p.id === item.id);
      if (selectedPatient) {
        this.currentPatient = selectedPatient;
        this.currentAppointment.idPatient = selectedPatient.id;
      }
    },
    handleChangeAppointmentDuration() {
      this.manualChangeDuration = true;
      this.checkAvailabilityAppointment(1);
    },
    formatterDuration(row) {
      return dateTimeService.getDurationAsMinutes(row.duration) === 0
        ? 'Не указана'
        : dateTimeService.getDurationAsMinutes(row.duration);
    },
    calculationAppointmentDuration() {
      // let flag = this.useDoctorAppointmentDuration;
      let currentDoctor = this.doctorsList.find(
        (d) => d.id === this.appointmentData.idDoctor
      );
      // если мы используем длительность приема врача для расчета длительности записи на прием
      //и не находимся в редактировании записи на прием
      if (
        this.useDoctorAppointmentDuration &&
        !this.isEditMode &&
        !this.currentAppointment.isBlockedSetDuration
      ) {
        // если у врача указана базовая длительность приема, то присваивает ее длительности записи на прием
        //в противном случае, длительность записи на прием будет рассчитана из базовой длительности слота в расписании - 15 минут

        if (
          !this.manualChangeDuration &&
          currentDoctor.appointmentDefaultDuration
        ) {
          this.currentAppointment.duration =
            dateTimeService.getDurationAsMinutes(
              currentDoctor.appointmentDefaultDuration
            );
        }
        this.manualChangeDuration = false;
      }
      if (!this.useDoctorAppointmentDuration && !this.isEditMode) {
        // получаем все услуги добавленные к записи на прием
        let appointmentServiceList = this.currentAppointment.services;
        // если услуги есть - начинаем считать их общую длительность
        if (appointmentServiceList.length) {
          let accumDuration = 0;
          for (let item of appointmentServiceList) {
            let durationAsMinutes = dateTimeService.getDurationAsMinutes(
              item.duration
            );
            accumDuration += durationAsMinutes * item.count;
          }
          // если вручную произошло изменение длительности записи на прием
          if (!this.manualChangeDuration) {
            // если суммарная длительность по услугам вычислилась тогда присваиваем ее длительности записи на прием,
            //в противном случае, длительность записи на прием будет рассчитана из базовой длительности слота в расписании - 15 минут
            if (accumDuration) {
              this.currentAppointment.duration = accumDuration;
              this.checkAvailabilityAppointment(1);
            }
          }
          this.manualChangeDuration = false;
        } else {
          // выходим из метода если услуг к записи на прием не было добавлено
          return;
        }
      }
    },
    handleSelectService(serviceId) {
      let selectedService = this.servicesList.find((s) => s.id === serviceId);
      let addedService = this.currentAppointment.services.find(
        (s) => s.idPriceList === serviceId
      );
      if (addedService) {
        addedService.count += 1;
        this.totalSum = Money.add(this.totalSum, addedService.priceDiscount);
      } else {
        let serviceForAdd = {
          id: null,
          idAppointment: null,
          idPriceList: selectedService.id,
          orgName: selectedService.orgName,
          count: 1,
          discount: 0,
          duration: selectedService.defaultDuration,
          price: selectedService.price,
          priceDiscount: selectedService.price,
          name: selectedService.medicalServiceCurrentOrganization.name,
          code: selectedService.medicalServiceCurrentOrganization
            .medicalServiceType.code,
        };
        this.currentAppointment.services.push(serviceForAdd);
        this.totalSum = Money.add(this.totalSum, serviceForAdd.priceDiscount);
      }
      this.service = null;
    },
    handleEditService(service) {
      let serviceForEdit = this.currentAppointment.services.find(
        (s) => s.idPriceList == service.idPriceList
      );
      let priceMultipliedByCount = Money.multiply(
        serviceForEdit.price,
        serviceForEdit.count
      );
      serviceForEdit.priceDiscount = Money.getAmount(
        priceMultipliedByCount -
          Money.percentage(priceMultipliedByCount, serviceForEdit.discount)
      );
      this.totalSum = this.currentAppointment.services.reduce(
        (a, x) => Money.add(a, x.priceDiscount),
        Money.getAmount(0)
      );
    },
    handleDeleteService(row) {
      let index = this.currentAppointment.services.indexOf(row);
      if (this.currentAppointment.services.length === 0) {
        this.totalSum = 0;
      } else {
        this.totalSum = Money.substract(this.totalSum, row.priceDiscount);
      }
      this.currentAppointment.services.splice(index, 1);
    },
    createAppointmentAsync(isCopyingMode, idPatient) {
      if (isCopyingMode) {
        let appointmentForCopying = { ...this.currentAppointment, id: null };
        let servicesForCopying = appointmentForCopying.services;
        for (let service of servicesForCopying) {
          service.id = null;
          service.idAppointment = null;
        }
        this.$store
          .dispatch('createAppointment', {
            ...this.currentAppointment,
            id: null,
          })
          .then((createdAppointmentId) => {
            if (this.currentAppointment.guaranteeLetterId) {
              this.updateGuaranteeLetter(createdAppointmentId)
                .then(() => {
                  return;
                })
                .catch(() => {
                  this.$message({
                    type: 'error',
                    message:
                      'Гарантийное письмо не добавлено к записи на прием!',
                  });
                });
            }
            this.$message({
              type: 'success',
              message: `Запись на прием успешно скопирована`,
            });
            // this.$emit('update:isModalAddEditAppointmentVisible');
            this.closeModalAddEditAppointment();
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: `Не удалось скопировать запись на прием!`,
            });
          });
      } else {
        if (idPatient) {
          let services = this.currentAppointment.services;
          for (let item of services) {
            item.duration = dateTimeService.getDurationStringByMinutes(
              item.duration
            );
          }
          this.$store
            .dispatch('createAppointment', {
              ...this.currentAppointment,
              services: services,
            })
            .then((createdAppointmentId) => {
              if (this.currentAppointment.guaranteeLetterId) {
                this.updateGuaranteeLetter(createdAppointmentId)
                  .then(() => {
                    return;
                  })
                  .catch(() => {
                    this.$message({
                      type: 'error',
                      message:
                        'Гарантийное письмо не добавлено к записи на прием!',
                    });
                  });
              }
              this.$message({
                type: 'success',
                message: `Пациент успешно записан на прием!`,
              });
              this.closeModalAddEditAppointment();
            })
            .catch(() => {
              this.$message({
                type: 'error',
                message: `Не удалось добавить запись на прием!`,
              });
            });
        } else {
          this.$message({
            type: 'error',
            message: 'Выберите пациента, чтобы добавить запись на прием!',
          });
        }
      }
    },
    editAppointmentAsync(idPatient) {
      if (idPatient) {
        let services = this.currentAppointment.services;
        for (let item of services) {
          item.duration = dateTimeService.getDurationStringByMinutes(
            item.duration
          );
        }
        this.$store
          .dispatch('editAppointment', {
            ...this.currentAppointment,
            services: services,
          })
          .then(() => {
            if (this.currentAppointment.guaranteeLetterId) {
              this.updateGuaranteeLetter(this.currentAppointment.id);
            }
            this.$message({
              type: 'success',
              message: `Запись на прием успешно изменена!`,
            });
            this.closeModalAddEditAppointment();
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: `Не удалось изменить запись на прием!`,
            });
          });
      }
    },
    saveAppointmentAsync() {
      let dateTimeFrom = new Date(this.currentAppointment.dateTimeFrom);
      let dateTimeTo = new Date(
        dateTimeFrom.getTime() + this.currentAppointment.duration * 60000
      );
      this.currentAppointment.dateTimeFrom = dateTimeFrom;
      this.currentAppointment.dateTimeTo = dateTimeTo;
      this.currentAppointment.clientOffset = getTimeOffset();
      // проверяем наличие id в записи на прием и если он есть - редактируем запись
      if (this.currentAppointment.id && !this.isCopyingMode) {
        this.editAppointmentAsync(this.currentAppointment.idPatient);
        // this.closeModalAddEditAppointment();
      }
      // если id нет, то смотрим флаг о копировании записи
      else {
        // если флаг о копировании установлен, то создаем копию записи на прием
        if (this.currentAppointment.idPatient && this.isCopyingMode) {
          this.createAppointmentAsync(
            this.isCopyingMode,
            this.currentAppointment.idPatient
          );
        }
        // если флаг о копировании не установлен, то создаем новую записи на прием
        else {
          this.createAppointmentAsync(
            this.isCopyingMode,
            this.currentAppointment.idPatient
          );
        }
      }
    },
    async updateGuaranteeLetter(createdAppointmentId) {
      let guaranteeLetter = this.guaranteeLetterList.find(
        (x) => x.id === this.currentAppointment.guaranteeLetterId
      );
      guaranteeLetter.appointmentId = createdAppointmentId;
      if (this.currentAppointment.guaranteeLetterId) {
        await this.$store
          .dispatch('updateGuaranteeLetter', guaranteeLetter)
          .then(() => {
            return;
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: 'Не удалось изменить гарантийное письмо.',
            });
          });
      }
    },
  },
};
</script>
