<template>
  <div class="education-main">
    <el-row :gutter="24">
      <el-col :lg="18" class="d-flex flex-between flex-center mb-16 flex-wrap">
        <h4 class="mt-16">Помощь</h4>
        <el-button class="mt-16" size="small" @click="openModalVideo" type="primary"
          >Смотреть видео<i class="el-icon-play"></i
        ></el-button>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt-16">
      <el-col :lg="18">
        <p>
          При возникновении вопросов Вы можете написать обращение в техническую
          поддержку с помощью нажатия на кнопку «Помощь» в правом верхнем углу
          приложения, либо при нажатии на ссылку «Написать в поддержку»,
          расположенную в левом нижнем углу.
        </p>
      </el-col>
      <el-col :lg="18">
        <el-image class="mt-16" :src="src.url1" :preview-src-list="srcList">
        </el-image>
      </el-col>
      <el-col :lg="18">
        <el-divider></el-divider>
        <p>
          В открывшемся окне необходимо заполнить обязательные поля «Тема
          обращения» и «Описание проблемы». Также можно приложить не более трех
          файлов в формате jpeg или png с помощью кнопки «Загрузить».
        </p>
        <p>Для отправки обращения необходимо нажать на кнопку «Отправить».</p>
        <p>Для отмены обращения необходимо нажать на кнопку «Отменить».</p>
      </el-col>
    </el-row>
    <!-- модальное окно просмотра видео-урока -->
    <el-dialog
      title="Видео-урок"
      class="modal-play-video"
      :visible.sync="modalVideo"
      width="100%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true">
      <div v-if="!idVideo">
        <h6>К сожалению видео-урок не доступен</h6>
        <p>
          В скором времени мы добавим данный видео-урок по обучению работе в
          Documeds
        </p>
      </div>
      <div v-if="idVideo">
        <VideoPlayerComponent :idVideo="idVideo"></VideoPlayerComponent>
      </div>
      <el-button
        class="education-modal-btn"
        type="primary"
        @click="openModalVideo"
        >Продолжить обучение в текстовом формате</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
  import VideoPlayerComponent from "@/components/VideoPlayerComponent.vue";
  export default {
    components: { VideoPlayerComponent },
    data() {
      return {
        modalVideo: false,
        idVideo: "q8dsM3sWTEk",
        src: {
          url1: require("@/assets/img/education/support-1.png"),
        },
        srcList: [require("@/assets/img/education/support-1.png")],
      };
    },
    methods: {
      openModalVideo() {
        this.modalVideo = !this.modalVideo;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
