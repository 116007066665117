<template>
  <div class="welcome">
    <el-dialog 
      class="welcome-modal"
      width="100%"
      :visible.sync="dialogVisible" 
      :show-close="false">
      <div>
        <img src="../assets/logo-menu.svg" alt="Documeds" />
      </div>
      <div class="welcome-title">
        <h4>
          Добро пожаловать в медицинскую <br />
          информационную систему DOCUMEDS!
        </h4>
      </div>
      <div class="welcome-descr">
        <p>
          Просим обратить Ваше внимание, что в настоящее время сервис работает в
          тестовом режиме, возможно появление неточностей в оформлении и
          возникновение непредвиденных ошибок.
          <pre />
          Если Вы нашли неточность или получили предупреждение об ошибке, сообщите об этом в
          службу <b>технической поддержки</b> через форму обратной
          связи, как показано на скриншоте ниже:
        </p>
      </div>
      <div class="demo-image__preview welcome-screen">
        <el-tooltip
          :enterable="false"
          content="Нажмите для увеличения"
          placement="left">
          <el-image
            style="width: 200px; height: 200px"
            fit="contain"
            :src="url"
            :preview-src-list="srcList">
          </el-image>
        </el-tooltip>
        <el-tooltip
          :enterable="false"
          content="Нажмите для увеличения"
          placement="right">
          <el-image
            style="width: 200px; height: 200px"
            fit="contain"
            :src="url2"
            :preview-src-list="srcList">
          </el-image>
        </el-tooltip>
      </div>
      <div class="welcome-descr">
        <p>
          Если Вы впервые знакомитесь в системой, рекомендуем пройти
          <router-link :to="{name: 'education'}" @click.native="handleClose">обучение</router-link>
        </p>
      </div>
      <div class="welcome-check">
        <el-checkbox
          label="Показывать это окно при каждом входе в систему"
          checked
          v-model="showAgain"></el-checkbox>
      </div>
      <div class="welcome-footer">
        <el-button size="small" type="primary" @click="handleClose"
          >Приступить к работе в DOCUMEDS
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import AuthManager from "../services/authManager";
  import UserManager from "../services/userManager";
  export default {
    data() {
      return {
        showAgain: true,
        user: "",
        dialogVisible: true,
        url: require("@/assets/img/welcome/welcome-1.png"),
        srcList: [
          require("@/assets/img/welcome/welcome-1.png"),
          require("@/assets/img/welcome/welcome-2.png"),
        ],
        url2: require("@/assets/img/welcome/welcome-2.png"),
        // srcListsec: [
        // require("@/assets/img/welcome/welcome-2.png"),
        // // require("@/assets/img/welcome/welcome-2.png"),
        // ],
      };
    },
    async created() {
      this.user = AuthManager.getUser();
      this.dialogVisible = this.user.showDemo == true ? true : false;
    },
    methods: {
      async handleClose() {
        this.user.showDemo = false;
        let userStorage = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
        userStorage.showDemo = false;
        AuthManager.updateUser(userStorage);
        if (this.showAgain == false) {
          await UserManager.disableWelcomeModalForUser();
        }
        this.dialogVisible = false;
      },
      // async goToEducation() {
      //   this.dialogVisible = false;
      //   this.$router.push("/education");
      // },
    },
  };
</script>

<style lang="scss" scoped></style>
