import { store } from "../store";
import axios from "@/api/axios";
import { getTimeOffset } from "@/services/commonService";

class DoctorManager {
  filialFilter = [];
  departmentFilter = [];

  //полный список врачей
  async getDoctors() {
    if (store.getters.doctors.length == 0) {
      await store.dispatch("GET_DOCTORS");
    }
    return store.getters.doctors;
  }

  //полный список исполнений должностей
  async getDoctorPositions() {
    if (store.getters.doctors.length == 0) {
      await store.dispatch("GET_DOCTORS");
    }
    let positions = store.getters.doctors.map((x) => {
      return {
        idDoctor: x.id,
        positionName: x.positions
          .filter(
            (p) =>
              (new Date(p.dateStart).getTime() == new Date().getTime() ||
                new Date(p.dateStart).getTime() < new Date().getTime()) &&
              (!p.dateEnd ||
                new Date(p.dateEnd).getTime() > new Date().getTime() ||
                new Date(p.dateEnd).getTime() == new Date().getTime())
          )
          .flatMap((p) => p.positionType.value)
          .join(","),
        name: x.lastName + " " + x.firstName + " " + x.patronymic,
        phone: x.phone,
        login: x.login,
        status: x.positions.find(
          (p) =>
            (new Date(p.dateStart).getTime() == new Date().getTime() ||
              new Date(p.dateStart).getTime() < new Date().getTime()) &&
            (!p.dateEnd ||
              new Date(p.dateEnd).getTime() > new Date().getTime() ||
              new Date(p.dateEnd).getTime() == new Date().getTime())
        )
          ? 1
          : 0,
      };
    });
    return positions;
  }

  //список врачей для выпающего списка (doctorsOnly - признак, что все сотрудники в списке ведут прием)
  async getDoctorsForSelect(doctorsOnly) {
    if (store.getters.doctors.length == 0) {
      await store.dispatch("GET_DOCTORS");
    }
    let doctors = store.getters.doctors;
    if (doctorsOnly) {
      doctors = doctors.filter((x) =>
        x.positions.find((d) => d.positionType.hasSchedule == true)
      );
    }
    return doctors
      .filter((x) =>
        x.positions.find(
          (p) =>
            (new Date(p.dateStart).getTime() == new Date().getTime() ||
              new Date(p.dateStart).getTime() < new Date().getTime()) &&
            (!p.dateEnd ||
              new Date(p.dateEnd).getTime() > new Date().getTime() ||
              new Date(p.dateEnd).getTime() == new Date().getTime())
        )
      )
      .map((x) => {
        return {
          id: x.id,
          name:
            x.lastName +
            " " +
            x.firstName +
            " (" +
            x.positions
              .filter(
                (p) =>
                  (new Date(p.dateStart).getTime() == new Date().getTime() ||
                    new Date(p.dateStart).getTime() < new Date().getTime()) &&
                  (!p.dateEnd ||
                    new Date(p.dateEnd).getTime() > new Date().getTime() ||
                    new Date(p.dateEnd).getTime() == new Date().getTime())
              )
              .flatMap((p) => p.positionType.value)
              .join(",") +
            ")",
        };
      });
  }

  //список исполнения должностей выбранного врача
  async getSelectedDoctorPositions(idDoctor) {
    if (store.getters.doctors.length == 0) {
      await store.dispatch("GET_DOCTORS");
    }
    let currentDoctor = store.getters.doctors.find((x) => x.id == idDoctor);
    return currentDoctor.positions
      .filter(
        (p) =>
          (new Date(p.dateStart).getTime() == new Date().getTime() ||
            new Date(p.dateStart).getTime() < new Date().getTime()) &&
          (!p.dateEnd ||
            new Date(p.dateEnd).getTime() > new Date().getTime() ||
            new Date(p.dateEnd).getTime() == new Date().getTime())
      )
      .map((x) => {
        return { id: x.id, name: x.positionType.value };
      });
  }

  //список врачей для выпающего списка с фильтрацией по филиалу
  async getFilialDoctorsForSelect(value) {
    this.filialFilter = value;
    if (store.getters.doctors.length == 0) {
      await store.dispatch("GET_DOCTORS");
    }
    return this.filterDoctors(store.getters.doctors);
  }

  //список врачей для выпающего списка с фильтрацией по подразделению
  async getDepartmentDoctorsForSelect(value) {
    this.departmentFilter = value;
    if (store.getters.doctors.length == 0) {
      await store.dispatch("GET_DOCTORS");
    }
    return this.filterDoctors(store.getters.doctors);
  }

  //фильтрация списка врачей
  filterDoctors(doctors) {
    if (this.filialFilter.length > 0) {
      let filteredPositions = doctors
        .flatMap((a) => a.positions)
        .filter((e) => this.filialFilter.find((i) => i == e.idFilial))
        .map((a) => a.id);
      doctors = doctors.filter((x) =>
        x.positions.find((e) => filteredPositions.find((f) => f == e.id))
      );
    }

    doctors =
      this.departmentFilter.length > 0
        ? doctors.filter((x) =>
            x.positions.find((e) =>
              this.departmentFilter.find((i) => i == e.idDepartment)
            )
          )
        : doctors;
    return doctors
      ? doctors.map((x) => {
          return {
            id: x.id,
            name:
              x.lastName +
              " " +
              x.firstName +
              " (" +
              x.positions.flatMap((p) => p.positionType.value).join(",") +
              ")",
          };
        })
      : null;
  }

  //получение расширенной информации о враче для редактирования
  async getDoctorInfo(idDoctor) {
    let { data } = await axios.get("doctor/" + idDoctor);
    let doctorInfo = data.value;
    if (doctorInfo) {
      doctorInfo.currentMainPosition = doctorInfo.positions.find(
        (x) =>
          x.isMainFunction == 1 &&
          (new Date(x.dateStart).getTime() == new Date().getTime() ||
            new Date(x.dateStart).getTime() < new Date().getTime()) &&
          (!x.dateEnd ||
            new Date(x.dateEnd).getTime() > new Date().getTime() ||
            new Date(x.dateEnd).getTime() == new Date().getTime())
      );

      doctorInfo.currentMainPosition = doctorInfo.currentMainPosition
        ? doctorInfo.currentMainPosition
        : {
            id: null,
            idPosition: null,
            dateStart: null,
            dateEnd: null,
            idDepartment: null,
            idFilial: null,
            idCategory: null,
            isMainFunction: 1,
          };

      doctorInfo.currentExtraPosition = doctorInfo.positions.find(
        (x) =>
          x.isMainFunction == 0 &&
          (new Date(x.dateStart).getTime() == new Date().getTime() ||
            new Date(x.dateStart).getTime() < new Date().getTime()) &&
          (!x.dateEnd ||
            new Date(x.dateEnd).getTime() > new Date().getTime() ||
            new Date(x.dateEnd).getTime() == new Date().getTime())
      );

      doctorInfo.currentExtraPosition = doctorInfo.currentExtraPosition
        ? doctorInfo.currentExtraPosition
        : {
            id: null,
            idPosition: null,
            dateStart: null,
            dateEnd: null,
            idDepartment: null,
            idFilial: null,
            idCategory: null,
            isMainFunction: 0,
          };

      doctorInfo.archivePositions = doctorInfo.positions.filter(
        (x) =>
          x.id != doctorInfo.currentMainPosition.id &&
          x.id != doctorInfo.currentExtraPosition.id
      );

      doctorInfo.user = doctorInfo.user
        ? doctorInfo.user
        : { id: null, login: null, password: null, idUserRoleGroupType: null };
    } else {
      doctorInfo = {
        id: null,
        lastName: null,
        firstName: null,
        patronymic: null,
        birthDate: null,
        gender: null,
        phone: null,
        email: null,
        residenceAddress: null,
        registerAddress: null,
        passportSeries: null,
        passportNumber: null,
        passportDateFrom: null,
        passportIssuer: null,
        passportIssuerCode: null,
        snils: null,
        inn: null,
        currentMainPosition: {
          id: null,
          idPosition: null,
          dateStart: null,
          dateEnd: null,
          idDepartment: null,
          idFilial: null,
          idCategory: null,
          isMainFunction: 1,
          positionType: {
            description: null,
            hasSchedule: true,
            id: null,
            value: null,
          },
        },
        currentExtraPosition: {
          id: null,
          idPosition: null,
          dateStart: null,
          dateEnd: null,
          idDepartment: null,
          idFilial: null,
          idCategory: null,
          isMainFunction: 0,
        },
        archivePositions: [
          {
            dateEnd: new Date(),
            dateStart: new Date(),
            id: "",
            idCategory: "",
            idDepartment: "",
            idFilial: "",
            idPosition: "",
            isMainFunction: "",
            positionType: {
              value: "",
            },
          },
        ],
        user: {
          id: null,
          login: null,
          password: null,
          idUserRoleGroupType: null,
        },
      };
    }
    return doctorInfo;
  }

  //создание новой записи сотрудника
  async addDoctorInfo(doctorInfo) {
    doctorInfo.clientOffset = getTimeOffset();
    doctorInfo.positions = [];
    doctorInfo.currentMainPosition.isMainFunction = 1;
    doctorInfo.positions.push(doctorInfo.currentMainPosition);
    // delete doctorInfo.currentMainPosition;
    if (
      (doctorInfo.currentExtraPosition.idPosition &&
        doctorInfo.currentExtraPosition.dateStart) ||
      doctorInfo.currentExtraPosition.idDepartment ||
      doctorInfo.currentExtraPosition.idCategory
    ) {
      doctorInfo.currentExtraPosition.isMainFunction = 0;
      doctorInfo.positions.push(doctorInfo.currentExtraPosition);
    }
    // delete doctorInfo.currentExtraPosition;
    // delete doctorInfo.archivePositions;
    // await axios
    //   .post("doctor", doctorInfo)
    //   .then((response) => {
    //     return response;
    //   })
    //   .catch((error) => {
    //     return error;
    //   });
    try {
      await axios.post("doctor", doctorInfo);
    } catch (error) {
      if (error.response.status != 200) {
        return error.response.data;
      }
    }

    await store.dispatch("GET_DOCTORS");
    // return null;
  }

  //обновление записи сотрудника
  async updateDoctorInfo(doctorInfo) {
    doctorInfo.clientOffset = getTimeOffset();
    doctorInfo.positions = [];
    doctorInfo.positions.push(doctorInfo.currentMainPosition);
    // delete doctorInfo.currentMainPosition;
    if (
      doctorInfo.currentExtraPosition.idPosition &&
      doctorInfo.currentExtraPosition.dateStart &&
      doctorInfo.currentExtraPosition.idDepartment &&
      doctorInfo.currentExtraPosition.idCategory
    ) {
      doctorInfo.positions.push(doctorInfo.currentExtraPosition);
    }
    // delete doctorInfo.currentExtraPosition;
    // delete doctorInfo.archivePositions;

    try {
      await axios.put("doctor", doctorInfo);
      return null;
    } catch (error) {
      if (error.response.status != 200) {
        return error.response.data;
      }
    }

    await store.dispatch("GET_DOCTORS");
  }

  async deleteDoctor(idDoctor) {
    try {
      await axios.delete("doctor/" + idDoctor);
    } catch (error) {
      if (error.response.status != 200) {
        return error.response.data;
      }
    }
  }
}

export default new DoctorManager();
