<template>
  <div class="notfound-wrap w-100 h-100vh">
    <el-container class="h-100">
      <el-row :gutter="24" type="flex" justify="center" class="w-100 h-100">
        <el-col :xl="12" :lg="12" :md="16" :sm="24" class="notfound-col-flex">
          <div class="notfound-logo">
            <img src="../assets/logo-menu-blue.svg" alt="Documeds" />
          </div>
          <div class="notfound-main">
            <h2>Страница не найдена</h2>
            <p>
              Вы попали на эту страницу, потому что ввели некорректные данные в
              строке поиска или произошел технический сбой. Нажмите
              <el-button class="notfound-goback" type="text" @click="goBack"
                >вернуться назад</el-button
              >
            </p>
            <!-- <div class="notfound-search">
              <el-autocomplete
                class="notfound-search-input"
                prefix-icon="el-icon-search"
                v-model="searchModule"
                :fetch-suggestions="querySearch"
                placeholder="Поиск по Documeds..."
                @select="handleSelect">
                <template slot-scope="{ item }">
                  <div class="item">{{ item.nameRu }}</div>
                </template>
              </el-autocomplete>
            </div> -->
          </div>
          <!-- <div class="notfound-quicklink">
            <h6>Быстрый доступ к разделам</h6>
            <ul class="notfound-quicklink-list">
              <li v-for="item in listModules" :key="item.id">
                <router-link :to="item.path"
                  >{{ item.nameRu }} <i class="el-icon-arrow-right"></i
                ></router-link>
              </li>
            </ul>
          </div> -->
          <div class="notfound-support">
            <p class="notfound-support-text">
              Возникли вопросы? Обратитесь в службу
              <el-button type="text" @click="handleOpenModalAddMessageSupport"
                >технической поддержки</el-button
              >
            </p>
          </div>
        </el-col>
      </el-row>
    </el-container>
    <!-- модальное окно обращения в тех поддержку-->
    <el-dialog
      class="modal-add-request"
      title="Обращение в техническую поддержку"
      :visible.sync="modalAddMessageSupport"
      width="100%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleCloseModalAddMessageSupport">
      <el-row :gutter="24">
        <el-col :span="24" class="mt">
          <label for="" class="settings-modal-label"
            >Тема обращения <span class="danger">*</span></label
          >
          <el-input
            size="small"
            placeholder="Введите тему обращения"
            v-model="supportRequest.problemSubject">
          </el-input>
        </el-col>
        <el-col :span="24" class="mt">
          <label for="" class="settings-modal-label"
            >Описание проблемы <span class="danger">*</span></label
          >
          <el-input
            size="small"
            type="textarea"
            resize="none"
            :autosize="{ minRows: 4, maxRows: 8 }"
            :rows="4"
            placeholder="Введите описание проблемы"
            v-model="supportRequest.problemDescription">
          </el-input>
        </el-col>
        <el-col :span="24" class="mt">
          <label for="" class="settings-modal-label"
            >Вы можете добавить фалй в формате JPEG или PNG с размером не более
            5 мб</label
          >
          <el-upload
            class="upload-demo"
            :limit="3"
            :on-exceed="handleExceed"
            action=""
            :on-change="onUploadChange"
            :auto-upload="false"
            :on-remove="handleRemove"
            :file-list="fileList">
            <el-button size="small" type="primary" plain>Загрузить</el-button>
            <!-- <div slot="tip" class="el-upload__tip">
              Вы можете добавить фалй в формате jpg или png с размером не более
              500кб
            </div> -->
          </el-upload>
        </el-col>
        <el-col :span="24" class="mt d-flex">
          <el-button
            size="small"
            type="primary"
            :loading="loadingBtn"
            @click="handleSendRequest"
            >Отправить</el-button
          >
          <el-button size="small" @click="handleCloseModalAddMessageSupport"
            >Отменить</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
  import SupportRequestManager from "@/services/supportRequestManager";
  export default {
    data() {
      return {
        // searchModule: "",
        // modules: [],
        // mainRoutes: [],
        // settingsRoutes: [],
        // listModules: [],
        loadingBtn: false,
        modalAddMessageSupport: false,
        fileList: [],
        supportRequest: {
          problemSubject: "",
          problemDescription: "",
          problemFiles: [],
        },
      };
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
      // querySearch(queryString, cb) {
      //   var modules = this.modules;
      //   var results = queryString
      //     ? modules.filter(this.createFilter(queryString))
      //     : modules;
      //   // call callback function to return suggestions
      //   cb(results);
      // },
      // createFilter(queryString) {
      //   return (link) => {
      //     return (
      //       link.nameRu.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      //     );
      //   };
      // },
      // loadAll() {
      //   return this.listModules;
      // },
      // handleSelect(item) {
      //   this.$router.push(item.path);
      // },
      async handleRemove(file) {
        this.supportRequest.problemFiles.splice(file);
      },
      beforeRemove(file) {
        return this.$confirm(`Cancel the transfert of ${file.name} ?`);
      },
      async handleCloseModalAddMessageSupport() {
        this.$confirm(
          "Вы действительно хотите закрыть это окно?",
          "Подтверждение действия",
          {
            confirmButtonText: "Подтвердить",
            cancelButtonText: "Отменить",
            type: "warning",
          }
        ).then(async () => {
          this.$message({
            type: "info",
            message: "Действие отменено",
          });
          this.modalAddMessageSupport = false;
        });
      },
      handleOpenModalAddMessageSupport() {
        (this.supportRequest = {
          problemSubject: "",
          problemDescription: "",
          problemFiles: [],
        }),
          (this.fileList = []);
        this.modalAddMessageSupport = true;
      },
      onUploadChange(file) {
        const isIMAGE =
          file.raw.type === "image/jpeg" || file.raw.type === "image/png";
        const isLt1M = file.size / 5 / 1024 / 1024 < 1;

        if (!isIMAGE) {
          this.$message.error(
            "Возможна загрузка файлов только в форматах jpg/png!"
          );
          return false;
        }
        if (!isLt1M) {
          this.$message.error("Возможна загрузка файла не более 5 MB!");
          return false;
        }
        var reader = new FileReader();
        reader.readAsDataURL(file.raw);

        reader.onload = function () {
          file.raw.problemFile = reader.result;
        };
        this.fileList.push(file);
      },
      handleExceed() {
        this.$message.warning(`Невозможно приложить более 3-х файлов!`);
      },
      async handleSendRequest() {
        this.loadingBtn = true;
        if (
          this.supportRequest.problemSubject != "" &&
          this.supportRequest.problemDescription
        ) {
          this.supportRequest.problemFiles = this.fileList.map((x) => {
            return { problemFileName: x.name, problemFile: x.raw.problemFile };
          });
          let result = await SupportRequestManager.sendSupportRequest(
            this.supportRequest
          );
          if (result.error) {
            this.$message.warning(
              `Ваше обращение не было отправлено. Пожалуйста, попробуйте позже.`
            );
          } else {
            this.$message.success(
              `Ваше обращение отправлено. В ближайщее время с вами свяжется сотрудник технической поддержки`
            );
            this.modalAddMessageSupport = false;
            this.loadingBtn = false;
          }
        } else {
          this.$message.warning("Заполните обязательные поля!");
          this.loadingBtn = false;
        }
      },
    },
    created() {
      // console.log(this.$router.options);
      // this.mainRoutes = this.$router.options.routes[4].children.filter(
      //   (x) => x.nameRu != null
      // );
      // this.settingsRoutes = this.mainRoutes[3].children.filter((x) => x.nameRu);
      // this.listModules = this.mainRoutes.concat(this.settingsRoutes);
    },
    mounted() {
      // this.modules = this.loadAll();
    },
  };
</script>

<style scoped></style>
