<template>
  <div class="settings-page" v-loading="loading">
    <el-row :gutter="24">
      <el-col :span="24">
        <h6>Филиалы</h6>
        <span class="settings-description">
          Здесь вы можете посмотреть все добавленные филиалы вашей организации и
          добавить новые.
        </span></el-col
      >
    </el-row>
    <el-row :gutter="24">
      <el-col
        :xl="6"
        :lg="8"
        :md="12"
        :sm="24"
        class="mt"
        v-for="item in filials"
        :key="item.id"
      >
        <div class="settings-card">
          <el-button
            size="small"
            class="settings-contacts-list-btn"
            type="text"
            icon="el-icon-delete"
            circle
            @click="handleRemoveFilial(item)"
            v-if="editFilialShow"
          ></el-button>
          <div class="settings-card-title">{{ item.name }}</div>
          <ul class="settings-card-list">
            <li class="settings-card-item">
              <span class="settings-card-field">Адрес:</span>
              <span class="settings-card-value">{{ item.address }}</span>
            </li>
            <li class="settings-card-item">
              <span class="settings-card-field">Телефон:</span>
              <span class="settings-card-value">{{
                item.phones[0]?.phone
              }}</span>
            </li>
            <li class="settings-card-item">
              <span class="settings-card-field">Email:</span>
              <span class="settings-card-value">{{
                item.emails[0]?.email
              }}</span>
            </li>
          </ul>
          <el-button
            size="small"
            type="primary"
            plain
            @click="openAddModalEditBranche(item)"
            v-if="editFilialShow"
            >Редактировать</el-button
          >
        </div>
      </el-col>
      <el-col
        :xl="6"
        :lg="8"
        :md="12"
        :sm="24"
        class="mt mb"
        v-if="editFilialShow"
      >
        <div class="settings-card empty">
          <el-button
            size="small"
            class="settings-card-btn"
            @click="openAddModalAddBranche"
          >
            <i class="el-icon-plus el-icon-left"></i>
            Добавить
          </el-button>
        </div>
      </el-col>
    </el-row>

    <!-- модальное окно доабвления филиала -->
    <el-dialog
      class="modal-branch-add"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="filial.title"
      :visible.sync="modalAddBranche"
      width="100%"
    >
      <el-row :gutter="24">
        <el-col :span="24" class="mt">
          <label for="" class="settings-modal-label"
            >Наименование <span class="danger">*</span></label
          >
          <el-input
            size="small"
            placeholder="Введите наименование"
            v-model="filial.name"
            :class="{
              invalid: $v.filial.name.$dirty && !$v.filial.name.required,
            }"
          >
          </el-input>
          <small
            class="helper-text invalid"
            v-if="$v.filial.name.$dirty && !$v.filial.name.required"
            >Поле является обязательным</small
          >
        </el-col>
        <el-col :span="24" class="mt">
          <label for="" class="settings-modal-label"
            >Адрес <span class="danger">*</span></label
          >
          <DaDataAutoCompleteComponent
            placeholder="Введите адрес"
            :disabled="false"
            v-model="filial.address"
          ></DaDataAutoCompleteComponent>
          <small
            class="helper-text invalid"
            v-if="$v.filial.address.$dirty && !$v.filial.address.required"
            >Поле является обязательным</small
          >
        </el-col>
        <el-col
          :span="24"
          class="mt-16"
          v-for="item in filial.phones"
          :key="item.id"
        >
          <label
            for=""
            class="settings-modal-label"
            v-if="filial.phones.indexOf(item) == 0"
            >Телефон <span class="danger">*</span></label
          >
          <el-row :gutter="24">
            <el-col :span="24" :sm="10">
              <el-input
                size="small"
                placeholder="Введите телефон"
                v-model="item.phone"
                v-maska
                data-maska="+7##########"
              >
                <!-- $v.filial.$model.phones[0].phone -->
              </el-input>
            </el-col>
            <el-col :span="19" :sm="9" class="mob-mt-16">
              <el-input
                size="small"
                placeholder="Введите комментарий"
                v-model="item.note"
              >
              </el-input>
            </el-col>
            <el-col :span="5" class="mob-mt-16">
              <el-button
                size="small"
                type="primary"
                plain
                v-if="filial.phones.indexOf(item) == filial.phones.length - 1"
                @click="handleAddPhone"
              >
                <span class="hidden-xs-only">Добавить</span>
                <i class="el-icon-plus el-icon-right"></i>
              </el-button>
              <el-button
                size="small"
                class="settings-contacts-list-btn"
                type="text"
                icon="el-icon-delete"
                circle
                v-if="filial.phones.indexOf(item) != filial.phones.length - 1"
                @click="handleRemovePhone(item)"
              ></el-button>
            </el-col>
          </el-row>
        </el-col>
        <el-col
          :span="24"
          class="mt-16"
          v-for="item in filial.emails"
          :key="item.id"
        >
          <label
            for=""
            class="settings-modal-label"
            v-if="filial.emails.indexOf(item) == 0"
            >Email <span class="danger">*</span></label
          >
          <el-row :gutter="24">
            <el-col :span="24" :sm="10">
              <el-input
                size="small"
                class="mr"
                placeholder="Введите email"
                v-model="item.email"
              >
              </el-input>
            </el-col>
            <el-col :span="19" :sm="9" class="mob-mt-16">
              <el-input
                size="small"
                class="mr"
                placeholder="Введите комментарий"
                v-model="item.note"
              >
              </el-input>
            </el-col>
            <el-col :span="5" class="mob-mt-16">
              <el-button
                size="small"
                type="primary"
                plain
                v-if="filial.emails.indexOf(item) == filial.emails.length - 1"
                @click="handleAddEmail"
              >
                <span class="hidden-xs-only">Добавить</span>
                <i class="el-icon-plus el-icon-right"></i>
              </el-button>
              <el-button
                size="small"
                class="settings-contacts-list-btn"
                type="text"
                icon="el-icon-delete"
                circle
                v-if="filial.emails.indexOf(item) != filial.emails.length - 1"
                @click="handleRemoveEmail(item)"
              ></el-button>
            </el-col>
          </el-row>
          <!-- <small 
            class="helper-text invalid"
            v-if="$v.filial.$model.emails[0].email.required"
          >Поле является обязательным</small> -->
        </el-col>
        <el-col :span="24" class="mt">
          <el-row :gutter="24" align="center" class="mt-20">
            <el-col :span="12" :sm="4">
              <el-button
                class="w-100"
                size="small"
                type="primary"
                @click="handleFilialSave"
                :loading="loadingModal"
                >Сохранить</el-button
              >
            </el-col>
            <el-col :span="12" :sm="4">
              <el-button
                class="w-100"
                size="small"
                @click="modalAddBranche = false"
                >Отменить</el-button
              >
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import FilialManager from '@/services/filialManager.js';
import AuthManager from '@/services/authManager';
import DaDataAutoCompleteComponent from '@/components/DaDataAutoCompleteComponent.vue';

export default {
  components: {
    DaDataAutoCompleteComponent,
  },
  data() {
    return {
      filials: [],
      filial: {
        id: null,
        title: 'Добавление филиала',
        name: '',
        address: '',
        phones: [
          { phone: '123', note: '!' },
          { phone: '456', note: 'ABC' },
        ],
        emails: [
          { email: '123fs', note: '!' },
          { email: '45d b6', note: 'ABC' },
        ],
      },
      modalAddBranche: false,
      editFilialShow: false,
      loading: false,
      loadingModal: false,
    };
  },
  async created() {
    this.loading = true;
    this.filials = await FilialManager.getFilials();
    let currentUser = AuthManager.getUser();
    this.editFilialShow = currentUser.roles.find((x) => x == 'edit_branche');
    this.loading = false;
  },
  validations: {
    filial: {
      name: { required },
      address: { required },
    },
  },
  methods: {
    openAddModalAddBranche() {
      this.filial = {
        id: null,
        title: 'Добавление филиала',
        name: '',
        address: '',
        phones: [{ phone: '', note: '' }],
        emails: [{ email: '', note: '' }],
      };
      this.modalAddBranche = true;
    },
    openAddModalEditBranche(item) {
      this.filial = {
        id: item.id,
        title: 'Редактирование филиала',
        name: item.name,
        address: item.address,
        phones:
          item.phones.length > 0 ? item.phones : [{ phone: '', note: '' }],
        emails:
          item.emails.length > 0 ? item.emails : [{ email: '', note: '' }],
      };
      this.modalAddBranche = true;
    },
    handleAddPhone() {
      this.filial.phones.push({ phone: '', note: '' });
    },
    handleAddEmail() {
      this.filial.emails.push({ email: '', note: '' });
    },
    handleRemovePhone(item) {
      let index = this.filial.phones.indexOf(item);
      this.filial.phones.splice(index, 1);
    },
    handleRemoveEmail(item) {
      let index = this.filial.emails.indexOf(item);
      this.filial.emails.splice(index, 1);
    },
    async handleFilialSave() {
      this.loadingModal = true;

      if (this.$v.$invalid) {
        this.$v.$touch();
        this.loadingModal = false;
        return;
      } else {
        if (this.filial.id) {
          this.filials = await FilialManager.updateFilial(this.filial);
        } else {
          this.filials = await FilialManager.addFilial(this.filial);
        }
        this.modalAddBranche = false;
        this.loadingModal = false;
      }
    },
    async handleRemoveFilial(item) {
      this.$confirm(
        'Вы действительно хотите удалить выбранный филиал?',
        'Удаление филиала',
        {
          confirmButtonText: 'Подтвердить',
          cancelButtonText: 'Отменить',
          type: 'warning',
        }
      )
        .then(async () => {
          this.$message({
            type: 'success',
            message: 'Удаление прошло успешно',
          });
          this.filials = await FilialManager.deleteFilial(item.id);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Удаление отменено',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
