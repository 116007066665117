import axios from "@/api/axios";
import { base64ToArrayBuffer } from "../services/commonService";
import { getTimeOffset } from "@/services/commonService";

class PdfPrintManager {
  //печать документов:
  // - согласие на медицинское вмешательство (medicalInterventioncConsent)
  // - согласие на обработку персональных данных (personalDataConsent)
  // - форма 025/у - Амбулаторная медицинская карта (medicalCardForm)
  // - договор на оказание платных медицинских услуг (medicalContract)
  async getPdfFile(checkedDocuments, idAppointment, idPatient) {
    let ids = checkedDocuments
      .map((x) => {
        return x.idClinicDocumentType;
      })
      .reduce((a, x) => a + "," + x, "");
    let pdfFile = await axios.get("documentprint", {
      params: {
        ids: ids,
        timezoneOffset: getTimeOffset(),
        idAppointment,
        idPatient,
      },
    });
    let bytes = base64ToArrayBuffer(pdfFile.data);
    let blob = new Blob([bytes], { type: "application/pdf" });
    let link = URL.createObjectURL(blob);
    return link;
  }
}

export default new PdfPrintManager();
