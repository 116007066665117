import Axios  from "axios";
import { DemoAccessURL } from '../api/httpSettings';

class RegistrationManager {
    async getDemoAccessInfo(identifier) {
        let requestUrl = DemoAccessURL()+ 'demoaccess/' + identifier;
        try {
            let {data} = await Axios.get(requestUrl);
            return { data: data, error: null};
        }
        catch (error) {
            if (error.response.status != 200) {
                return { data: null, error: 1};
            }
        }
    }

    async setPassword(model) {
        let requestUrl = DemoAccessURL()+ 'setpassword';
        model.clientOffset = new Date().getTimezoneOffset() / 60;
        try {
            let {data} = await Axios.post(requestUrl, model);
            return { data: data, error: null};
        }
        catch (error) {
            if (error.response.status == 400) {
                return { data: null, error: 1};
            }
        }
    }
}
export default new RegistrationManager();
