import myOrganizationApi from '@/api/myOrganization';
import dateTimeService from '@/services/dateTimeService';
const myOrganizationModule = {
  state: {
    businessProcesses: {
      patients: {
        title: 'Пациенты',
        col: 12,
        rules: {
          requiredFields: {
            label: 'Обязательные поля для создания пациента',
            type: 'checkbox',
            isAvailable: false,
            description:
              'Отмеченные поля будут обязательными для создания пациента',
            fields: [
              {
                id: 1,
                name: 'Фамилия',
                value: true,
              },
              {
                id: 2,
                name: 'Имя',
                value: true,
              },
              {
                id: 3,
                name: 'Дата рождения',
                value: false,
              },
              {
                id: 4,
                name: 'Телефон',
                value: true,
              },
            ],
          },
          numberGeneration: {
            label: 'Генерация номер медицинской карты',
            type: 'select',
            isAvailable: false,
            description:
              'При выбранных опциях номер медкарты будет иметь вид: ',
            fields: [
              {
                id: 1,
                name: 'pre',
                placeholder: 'Префикс',
                value: '',

                options: [
                  { id: 1, value: 'АМК' },
                  {
                    id: 2,
                    value: 'Нет',
                  },
                ],
              },
              {
                id: 2,
                name: 'number',
                placeholder: 'Номер',
                value: '',
                options: [
                  { id: 1, value: '6 цифр' },
                  {
                    id: 2,
                    value: 'Нет',
                  },
                ],
              },
              {
                id: 3,
                name: 'post',
                placeholder: 'Постфикс',
                value: '',
                options: [
                  { id: 1, value: 'Дата (01-01-2023)' },
                  {
                    id: 2,
                    value: 'Нет',
                  },
                ],
              },
            ],
          },
        },
      },
      invoices: {
        title: 'Счета',
        col: 12,
        rules: {
          schema: {
            label: 'Схема выставления счетов',
            value: '',
            type: 'select',
            isAvailable: false,
            description: '',
            fields: [
              {
                id: 1,
                placeholder: 'Укажите опцию',
                value: '',
                options: [
                  {
                    id: 1,
                    value: 'Оплата после завершения приема',
                    description:
                      'При выбранной опции счет будет формироваться автоматически после завершении приема врачом. Услуги указанные при оформлении приема будут добавлены в счет.',
                  },
                  {
                    id: 2,
                    value: 'Оплата до начала приема',
                    description:
                      'При выбранной опции счет необходимо будет формировать вручную до проведения прием',
                  },
                ],
              },
            ],
          },
          numberGeneration: {
            label: 'Генерация номер счета',
            value: '',
            type: 'select',
            isAvailable: false,
            description: 'При выбранных опциях номер счета будет иметь вид: ',
            fields: [
              {
                id: 1,
                name: 'pre',
                placeholder: 'Префикс',
                value: '',

                options: [
                  { id: 1, value: 'СЧ' },
                  {
                    id: 2,
                    value: 'Нет',
                  },
                ],
              },
              {
                id: 2,
                name: 'number',
                placeholder: 'Номер',
                value: '',
                options: [
                  { id: 1, value: '6 цифр' },
                  {
                    id: 2,
                    value: 'Нет',
                  },
                ],
              },
              {
                id: 3,
                name: 'post',
                placeholder: 'Постфикс',
                value: '',
                options: [
                  { id: 1, value: 'Дата (01-01-2023)' },
                  {
                    id: 2,
                    value: 'Нет',
                  },
                ],
              },
            ],
          },
        },
      },
      appointments: {
        title: 'Запись на прием',
        col: 12,
        rules: {
          countDuration: {
            type: 'select',
            isAvailable: true,
            label: 'Расчет длительности записи на прием',
            value: '',
            description: '',
            fields: [
              {
                id: 1,
                options: [
                  {
                    id: 1,
                    value: 'На основании добавленных услуг',
                    description:
                      'При выбранной опции длительность записи на прием будет рассчитываться исходя из суммы базовой длительности добавленных услуг',
                  },
                  {
                    id: 2,
                    value: 'На основании длительности приема врача',
                    description:
                      'При выбранной опции длительность записи на прием будет рассчитываться исходя из длительности приема врача, указанной при добавлении сотрудника',
                  },
                ],
              },
            ],
          },
        },
      },
    },
    myOrganization: {},
    filials: [],
  },
  getters: {
    businessProcesses: (state) => {
      return state.businessProcesses;
    },
    useDoctorAppointmentDuration: (state) => {
      // расчет значения настройки для управления вычислением длительности приемов
      return state.businessProcesses.appointments.rules.countDuration.value
        .id === 1
        ? false
        : true;
    },
    myOrganization: (state) => {
      return state.myOrganization;
    },
    businessHours: (state) => {
      return {
        start: dateTimeService.getDurationStringByHoursAndMinutes(
          state.myOrganization.hourOpen,
          state.myOrganization.minuteOpen
        ),
        end: dateTimeService.getDurationStringByHoursAndMinutes(
          state.myOrganization.hourClose,
          state.myOrganization.minuteClose
        ),
      };
    },
    filials: (state) => {
      return state.filials;
    },
    departments: (state) => {
      return state.filials.flatMap((f) => f.departments);
    },
    cabinets: (state) => {
      return state.filials.flatMap((f) => f.cabinets);
    },
  },
  mutations: {
    SET_MY_ORGANIZATION: (state, payload) => {
      state.myOrganization = payload;
    },
    SET_BP_APPOINTMENTS_COUNT_DURATION: (state, payload) => {
      let countDuration = payload.useDoctorAppointmentDuration;
      let options =
        state.businessProcesses.appointments.rules.countDuration.fields[0]
          .options;
      if (countDuration) {
        state.businessProcesses.appointments.rules.countDuration.value =
          options[1];
      } else {
        state.businessProcesses.appointments.rules.countDuration.value =
          options[0];
      }
    },
    SET_FILIALS: (state, payload) => {
      state.filials = payload;
    },
    SET_DEPARTMENTS: (state, payload) => {
      state.departments = payload;
    },
    SET_CABINETS: (state, payload) => {
      state.cabinets = payload;
    },
  },
  actions: {
    getMyOrganization: async (context) => {
      const myOrganization = await myOrganizationApi.getMyOrganization();
      context.commit('SET_MY_ORGANIZATION', myOrganization.data.value);
      context.commit(
        'SET_BP_APPOINTMENTS_COUNT_DURATION',
        myOrganization.data.value
      );
    },
    changeMyOrganization: async (context, myOrganization) => {
      await myOrganizationApi.changeMyOrganization(myOrganization);
    },
    getFilials: async (context) => {
      const filials = await myOrganizationApi.getFilials();
      context.commit('SET_FILIALS', filials.data.value);
    },
  },
};

export default myOrganizationModule;
