// import { store } from '@/store/index';
import moment from 'moment';
import dateTimeService from './dateTimeService';

const getScheduleTableData = (scheduleList, gridStep, clinicBusinessHours) => {
  const columns = [];
  const rows = [];
  // используем метод автоматического расчета оптимального шага сетки
  let step = calcAutoGridStep(scheduleList, gridStep);
  // получаем и преобразуем в объекты Moment JS рабочие часы клиники
  const startTime = dateTimeService.getMomentForCompareTimes(
    clinicBusinessHours.start,
    0
  );
  const endTime = dateTimeService.getMomentForCompareTimes(
    clinicBusinessHours.end,
    0
  );
  // получаем список графиков работ только врачей ведущих прием
  const scheduleListOnlyForVisits = scheduleList.filter((x) => x.hasSchedule);
  // получаем список уникальных дат для шапки таблицы
  let uniqueDates = new Set();
  for (let item of scheduleListOnlyForVisits) {
    let dateString = moment(item.dateTimeFrom).format('YYYY-MM-DD');
    uniqueDates.add(dateString);
  }
  let uniqueDatesAsArray = Array.from(uniqueDates);
  // формируем окончательные столбцы таблицы с врачами
  for (let columnDate of uniqueDatesAsArray) {
    let doctorList = [];
    for (let item of scheduleListOnlyForVisits) {
      let scheduleDate = moment(item.dateTimeFrom).format('YYYY-MM-DD');
      let doctorInfo = {};
      let activeAppointmentsByDoctor = [];
      // фильтруем записи на прием, выбираем со статусом != "Запись отменена" и относящихся к отпределнному графику работы врача
      if (item.appointments.length > 0) {
        activeAppointmentsByDoctor = item.appointments.filter(
          (x) => x.idAppointmentStatus !== 8 && x.idSchedule === item.id
        );
        for (let appointment of activeAppointmentsByDoctor) {
          const appointmentStart = dateTimeService.getMomentForCompareTimes(
            appointment.dateTimeFrom,
            1
          );
          const appointmentEnd = dateTimeService.getMomentForCompareTimes(
            appointment.dateTimeTo,
            1
          );
          appointment.doctorId = item.idDoctor;
          // расчитываем высоту записи на прием в зависимости от её дилтельности
          let appointmentDurationInMinutes = appointmentEnd
            .clone()
            .subtract(appointmentStart);
          appointment.duration = dateTimeService.getDurationAsMinutes(
            moment.duration(appointmentDurationInMinutes)
          );
          appointment.height = (appointment.duration * 40) / step;
        }
      }
      if (columnDate === scheduleDate) {
        doctorInfo = {
          id: item.idDoctor,
          scheduleId: item.id,
          lastName: item.doctorLastName,
          firstName: item.doctorFirstName,
          patronymic: item.doctorPatronymic,
          fio: `${item.doctorLastName} ${item.doctorFirstName.slice(0, 1)}. ${
            item.doctorPatronymic ? item.doctorPatronymic.slice(0, 1) + '.' : ''
          }`,
          position: item.doctorPosition,
          doctorPositionId: item.idDoctorPosition,
          departmentId: item.idDepartment,
          appointments: activeAppointmentsByDoctor,
          cabinets: item.cabinets,
          businessHours: {
            start: moment(item.dateTimeFrom).format('HH:mm'),
            end: moment(item.dateTimeTo).format('HH:mm'),
          },
        };
        for (let i = 0; i < doctorInfo.appointments.length; i++) {
          const appointment = doctorInfo.appointments[i];
          const appointmentStart = dateTimeService.getMomentForCompareTimes(
            appointment.dateTimeFrom,
            1
          );
          // определяем отсуп от верхней границы сетки в зависимости от времени начала записи на прием
          let emptyDuration = appointmentStart.clone().subtract(startTime);
          let emptyDurationInMinutes = dateTimeService.getDurationAsMinutes(
            moment.duration(emptyDuration)
          );
          appointment.marginTop = (emptyDurationInMinutes * 40) / step;
          // определяем к какому кабинету относится запись на прием
          const appointmentEnd = dateTimeService.getMomentForCompareTimes(
            appointment.dateTimeTo,
            1
          );
          for (let cabinet of doctorInfo.cabinets) {
            const cabinetStart = dateTimeService.getMomentForCompareTimes(
              cabinet.dateTimeFrom,
              1
            );
            const cabinetEnd = dateTimeService.getMomentForCompareTimes(
              cabinet.dateTimeTo,
              1
            );
            if (
              !cabinetStart.isSameOrAfter(appointmentEnd) &&
              !appointmentStart.isSameOrAfter(cabinetEnd)
            ) {
              appointment.cabinetName = cabinet.cabinetName;
            }
          }
        }
        doctorList.push(doctorInfo);
      }
    }
    columns.push({
      dateString: moment(columnDate).format('DD.MM'),
      date: columnDate,
      weekDay: dateTimeService.getWeekdayFromDate(
        new Date(columnDate),
        'short'
      ),
      doctors: doctorList,
    });
  }

  const interval = moment.duration(step, 'minutes');
  const currentTime = startTime.clone();
  // формируем строки для таблицы расписания
  while (currentTime.isSameOrBefore(endTime)) {
    const formattedTime = currentTime.format('HH:mm');
    rows.push({
      start: formattedTime,
      end: currentTime.clone().add(interval).format('HH:mm'),
    });
    currentTime.add(interval);
  }

  return {
    columns: columns,
    rows: rows,
  };
};

// метод автоматического выбора шага сетки расписания в зависимости от рабочих часов врачей
const calcAutoGridStep = (scheduleList, gridStep) => {
  let step;
  if (gridStep === 0) {
    let minutes = new Set();
    let calculatedStep = 5;
    // если шаг сетки равен 0 (авто)
    for (let schedule of scheduleList) {
      let scheduleStartMinute = new Date(schedule.dateTimeFrom).getMinutes();
      let scheduleEndMinute = new Date(schedule.dateTimeTo).getMinutes();
      if (scheduleStartMinute !== 0) {
        minutes.add(scheduleStartMinute);
      }
      if (scheduleEndMinute !== 0) {
        minutes.add(scheduleEndMinute);
      }
      if (scheduleStartMinute === 0 || scheduleEndMinute === 0) {
        minutes.add(scheduleStartMinute);
        minutes.add(scheduleEndMinute);
      }
    }
    for (let minute of minutes) {
      if (minute % 15 === 0) {
        calculatedStep = 15;
      }
      if (minute % 10 === 0 && minute % 15 != 0) {
        calculatedStep = 10;
      }
      if (minute % 5 === 0 && minute % 15 != 0 && minute % 10 != 0) {
        calculatedStep = 5;
      }
      if (minute === 0) {
        calculatedStep = 15;
      }
    }
    step = calculatedStep;
    return step;
  } else {
    // если шаг сетки отличается от 0 (авто)
    step = gridStep;
    return step;
  }
};
// метод определения доступности слотов на основании часов работы врача
const setSlotAvailabilityAndShowCabinets = function (slot, doctor) {
  const slotStart = dateTimeService.getMomentForCompareTimes(slot.start, 0);
  const slotEnd = dateTimeService.getMomentForCompareTimes(slot.end, 0);
  const businessHourStart = dateTimeService.getMomentForCompareTimes(
    doctor.businessHours.start,
    0
  );
  const businessHourEnd = dateTimeService.getMomentForCompareTimes(
    doctor.businessHours.end,
    0
  );
  // Добавить обработку вывода кабинетов
  if (
    !businessHourStart.isSameOrAfter(slotEnd) &&
    !slotStart.isSameOrAfter(businessHourEnd)
  ) {
    slot.isAvailable = true;
  } else {
    slot.isAvailable = false;
  }
};

// метод провверки пересечения выбранного слота с записями на прием и рассчета потенциальной длительности приема
const checkIntersectionAppointment = function (slot, doctor) {
  // сортируем записи на прием относительно даты начала по возрастанию
  const appointments = doctor.appointments.sort(function (a, b) {
    return new Date(a.dateTimeFrom) - new Date(b.dateTimeFrom);
  });
  const slotStart = dateTimeService.getMomentForCompareTimes(slot.start, 0);
  const slotEnd = dateTimeService.getMomentForCompareTimes(slot.end, 0);
  let intersectingAppointments = [];
  for (let i = 0; i < appointments.length; i++) {
    const appointment = appointments[i];
    const appointmentStart = dateTimeService.getMomentForCompareTimes(
      appointment.dateTimeFrom,
      1
    );
    const appointmentEnd = dateTimeService.getMomentForCompareTimes(
      appointment.dateTimeTo,
      1
    );

    if (appointmentStart.isBetween(slotStart, slotEnd)) {
      intersectingAppointments.push({
        ...appointment,
        start: appointmentStart,
        end: appointmentEnd,
      });
    }
    if (appointmentEnd.isBetween(slotStart, slotEnd)) {
      if (!intersectingAppointments.find((a) => a.id === appointment.id)) {
        intersectingAppointments.push({
          ...appointment,
          start: appointmentStart,
          end: appointmentEnd,
        });
      }
    }
    // рассчитываем время если сверху слот пересекается с записью на прием и изменяем время начала записи на прием
    if (slotStart.isBetween(appointmentStart, appointmentEnd)) {
      slot.startAfterCheckIntersection = appointmentEnd.format('HH:mm');
    }
  }
  // если записи на прием, пересекающиеся со слотом найдены,
  // тогда вычисляем необходимую длительность для создания записи на прием в текущем слоте
  if (intersectingAppointments.length != 0) {
    let durationAfterCheckIntersection = null;
    // если слот пересекается только с одной записью на прием
    if (intersectingAppointments.length === 1) {
      const appointment = intersectingAppointments[0];
      if (appointment.start.isBetween(slotStart, slotEnd)) {
        durationAfterCheckIntersection = dateTimeService.getDurationAsMinutes(
          appointment.start.clone().subtract(slotStart)
        );
      }
    } else {
      // если слот пересекается с двумя записями на прием
      for (let appointment of intersectingAppointments) {
        if (appointment.end.isBetween(slotStart, slotEnd)) {
          durationAfterCheckIntersection = dateTimeService.getDurationAsMinutes(
            slotEnd.clone().subtract(appointment.end)
          );
        }
        if (appointment.start.isBetween(slotStart, slotEnd)) {
          durationAfterCheckIntersection =
            durationAfterCheckIntersection -
            dateTimeService.getDurationAsMinutes(
              slotEnd.clone().subtract(appointment.start)
            );
        }
      }
    }
    slot.durationAfterCheckIntersection = durationAfterCheckIntersection;
    console.log(slot);
  }
};

export default {
  getScheduleTableData,
  setSlotAvailabilityAndShowCabinets,
  checkIntersectionAppointment,
};
