<template>
  <div class="education-main">
    <el-row :gutter="24">
      <el-col :lg="18" class="d-flex flex-between flex-center mb-16 flex-wrap">
        <h4 class="mt-16">Раздел «Шаблоны медицинских документов»</h4>
        <el-button class="mt-16" size="small" @click="openModalVideo" type="primary"
          >Смотреть видео<i class="el-icon-play"></i
        ></el-button>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt-16">
      <el-col :lg="18">
        <p>
          Данный раздел позволяет произвести настройку шаблонов медицинских
          документов. Для того, чтобы перейти в раздел, необходимо выбрать пункт
          меню «Настройки»/«Медицинские документы».
        </p>
        <el-image
          class="mt-16 mb-16"
          fit="cover"
          :src="src.url2"
          :preview-src-list="srcList">
        </el-image>
        <p>
          Для того, чтобы добавить новый шаблон медицинского документа,
          необходимо нажать на кнопку «Добавить». В открывшемся окне можно
          задать название шаблона, выбрать специальности врачей, которым данный
          документ будет доступен для заполнения, при помощи выпадающего списка
          с множественным выбором, а также добавить требуемые поля в документ с
          помощью кнопки «Добавить поле». Для нового поля необходимо задать
          значения следующих полей:
        </p>
        <ul>
          <li>
            «Наименование» - название поля в медицинском документе, значение
            произвольное, задается пользователем
          </li>
          <li>
            «Тип поля» - тип поля в медицинском документе, значение выбирается
            из выпадающего меню. Доступны варианты: «Текстовый ввод» и «Выбор
            значения из справочника МКБ»
          </li>
          <li>
            «Ширина поля» - ширина поля в медицинском документе, значение
            выбирается из выпадающего меню. Доступны варианты: «1/2 строки» и
            «Полная строка». Это позволяет настроить удобный формат полей в
            медицинском документе
          </li>
          <li>
            «Высота поля» - высота поля в медицинском документе, значение
            выбирается из выпадающего меню. Доступен выбор высоты поля от одной
            до шести строк. Это позволяет настроить удобный формат полей в
            медицинском документе
          </li>
          <li>
            «Обязательное» - признак обязательности заполнения поля в
            медицинском документе. При установленном флаге невозможно сохранение
            документа, если данное поле не заполнено. В заполняемом документе
            данное поле будет отмечено символом «<span class="danger">*</span>»
          </li>
          <li>
            «Печать в рекомендациях» - признак печати поля в формате «Печать
            назначений». При установленном флаге данное поле будет распечатано в
            составе документа при выборе пункта «Печать назначений». Поля, не
            помеченные данным флагом, не будут распечатаны в формате «Печать
            назначений»
          </li>
        </ul>
        <p>
          Для того, чтобы удалить поле шаблона медицинского документа,
          необходимо нажать на символ удаления в строке с требуемым полем.
        </p>
        <p>
          При нажатии на кнопку «Предпросмотр документа» будет выведен на экран
          настроенный медицинский документ в том виде, в котором он будет
          доступен для заполнения специалистом.
        </p>
        <p>
          При нажатии на кнопку «Сохранить» будут применены текущие настройки
          шаблона медицинского документа.
        </p>
        <el-image
          class="mt-16 mb-16"
          fit="cover"
          :src="src.url1"
          :preview-src-list="srcList">
        </el-image>
        <p>
          Требуемый шаблон медицинского документа может быть отредактирован или
          удален. Доступ к редактированию и удалению шаблона возможен через
          выпадающее меню «...» его строки. Окно редактирования дублирует окно
          создания шаблона.
        </p>
      </el-col>
    </el-row>
    <!-- модальное окно просмотра видео-урока -->
    <el-dialog
      title="Видео-урок"
      class="modal-play-video"
      :visible.sync="modalVideo"
      width="100%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true">
      <div v-if="!idVideo">
        <h6>К сожалению видео-урок не доступен</h6>
        <p>
          В скором времени мы добавим данный видео-урок по обучению работе в
          Documeds
        </p>
      </div>
      <div v-if="idVideo">
        <VideoPlayerComponent :idVideo="idVideo"></VideoPlayerComponent>
      </div>
      <el-button
        class="education-modal-btn"
        type="primary"
        @click="openModalVideo"
        >Продолжить обучение в текстовом формате</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
  import VideoPlayerComponent from "@/components/VideoPlayerComponent.vue";
  export default {
    components: { VideoPlayerComponent },
    data() {
      return {
        modalVideo: false,
        idVideo: "d_BkVe84Fjo",
        src: {
          url1: require("@/assets/img/education/protocol-settings-1.png"),
          url2: require("@/assets/img/education/protocol-settings-2.png"),
        },
        srcList: [
          require("@/assets/img/education/add-visit-1.png"),
          require("@/assets/img/education/add-visit-2.png"),
        ],
      };
    },
    methods: {
      openModalVideo() {
        this.modalVideo = !this.modalVideo;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
