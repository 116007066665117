<template>
  <div class="education-main">
    <el-row :gutter="24">
      <el-col :lg="18" class="d-flex flex-between flex-center mb-16 flex-wrap">
        <h4 class="mt-16">Добавление филиалов и кабинетов</h4>
        <el-button class="mt-16" size="small" @click="openModalVideo" type="primary"
          >Смотреть видео<i class="el-icon-play"></i
        ></el-button>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt-16">
      <el-col :lg="18">
        <h6 class="mb-16">Добавление филиалов</h6>
        <p>
          Для просмотра и добавления филиалов в вашу организацию необходимо
          перейти в пункт меню “Настройки/Филиалы”.
        </p>
        <el-divider></el-divider>
      </el-col>
      <el-col :lg="18">
        <el-image fit="contain" :src="src.url1" :preview-src-list="srcList">
        </el-image>
      </el-col>
      <el-col :lg="18">
        <el-divider></el-divider>
        <p>
          По умолчанию любая организация имеет один рабочий филиал. Если в Вашей
          организации несколько филиалов, можно легко добавить их, нажав на
          кнопку «Добавить».
        </p>
        <el-image fit="contain" :src="src.url2" :preview-src-list="srcList">
        </el-image>
      </el-col>
      <el-col :lg="18">
        <el-divider></el-divider>
        <p>
          Заполнив обязательные поля, отмеченные символом
          <span class="danger">*</span>, нажмите на кнопку «Сохранить».
        </p>
        <p>
          Для того, чтобы отредактировать информацию, нажмите кнопку
          «Редактировать» на карточке нужного филиала.
        </p>
        <el-image fit="cover" :src="src.url3" :preview-src-list="srcList">
        </el-image>
        <p>
          В системе Documeds нет ограничений на количество добавляемых филиалов.
          Но следует помнить, что филиалом можно считать офис организации,
          расположенный отдельно (территориально), в котором осуществляется
          медицинская деятельность.
        </p>
      </el-col>
      <el-col :lg="18">
        <el-divider></el-divider>
        <h6 class="mb-16">Добавление кабинетов</h6>
        <p>
          Кабинет в информационной системе Documeds является физической
          единицей, располагающейся в конкретном филиале организации. Поэтому
          кабинеты добавляются в каждый филиал отдельно. Для этого необходимо
          перейти в пункт меню «Настройки»/«Кабинеты».
        </p>
        <el-image
          class="mt-16 mb-16"
          fit="cover"
          :src="src.url4"
          :preview-src-list="srcList">
        </el-image>
        <p>
          Выберите нужную карточку с филиалом Вашей организации и нажмите кнопку
          «Добавить».
        </p>
        <el-image
          class="mt-16"
          fit="cover"
          :src="src.url5"
          :preview-src-list="srcList">
        </el-image>
      </el-col>
      <el-col :lg="18">
        <el-divider></el-divider>
        <p>
          Наименование кабинета является обязательным к заполнению и отмечено
          символом <span class="danger">*</span>.
        </p>
        <p>
          Также Вы можете указать в описании кабинета его принадлежность,
          например, «кабинет для приема хирурга», или указать краткий перечень
          инструментария, находящегося в кабинете.
        </p>
        <p>
          Для редактирования существующего кабинета необходимо нажать кнопку
          «Редактировать» на карточке кабинета.
        </p>
        <el-image
          class="mt-16"
          fit="cover"
          :src="src.url6"
          :preview-src-list="srcList">
        </el-image>
        <p>
          Правильное добавление кабинетов необходимо для корректного
          формирования расписания приема врачей, а также для распределения
          врачей по кабинетам в нужный день приема.
        </p>
        <p>
          Об этом можно подробнее прочитать далее в разделах обучения
          <router-link to="/education/add-timetable-lesson"
            >Создание графика работ и распределение врачей по
            кабинетам</router-link
          >
          и
          <router-link to="/education/add-appointment-lesson"
            >Расписание приема врачей и запись пациента на прием</router-link
          >
        </p>
      </el-col>
    </el-row>
    <!-- модальное окно просмотра видео-урока -->
    <el-dialog
      title="Видео-урок"
      class="modal-play-video"
      :visible.sync="modalVideo"
      width="100%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true">
      <div v-if="!idVideo">
        <h6>К сожалению видео-урок не доступен</h6>
        <p>
          В скором времени мы добавим данный видео-урок по обучению работе в
          Documeds
        </p>
      </div>
      <div v-if="idVideo">
        <VideoPlayerComponent :idVideo="idVideo"></VideoPlayerComponent>
      </div>
      <el-button
        class="education-modal-btn"
        type="primary"
        @click="openModalVideo"
        >Продолжить обучение в текстовом формате</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
  import VideoPlayerComponent from "@/components/VideoPlayerComponent.vue";
  export default {
    components: { VideoPlayerComponent },
    data() {
      return {
        modalVideo: false,
        idVideo: "TjgaK6bsF78",
        src: {
          url1: require("@/assets/img/education/add-branches-cabinets-1.png"),
          url2: require("@/assets/img/education/add-branches-cabinets-2.png"),
          url3: require("@/assets/img/education/add-branches-cabinets-3.png"),
          url4: require("@/assets/img/education/add-branches-cabinets-4.png"),
          url5: require("@/assets/img/education/add-branches-cabinets-5.png"),
          url6: require("@/assets/img/education/add-branches-cabinets-6.png"),
        },
        srcList: [
          require("@/assets/img/education/add-branches-cabinets-1.png"),
          require("@/assets/img/education/add-branches-cabinets-2.png"),
          require("@/assets/img/education/add-branches-cabinets-3.png"),
          require("@/assets/img/education/add-branches-cabinets-4.png"),
          require("@/assets/img/education/add-branches-cabinets-5.png"),
          require("@/assets/img/education/add-branches-cabinets-6.png"),
        ],
      };
    },
    methods: {
      openModalVideo() {
        this.modalVideo = !this.modalVideo;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
