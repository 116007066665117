class Money {
  getAmount = (amount) => {
    return parseFloat(amount).toFixed(2);
  };

  getAmountWithCurrency = (amount, currency) => {
    let currencyList = new Map([
      ['RUB', '₽'],
      ['USD', '$'],
      ['EUR', '€'],
      ['KZT', '₸'],
      ['BYN', 'Br'],
    ]);
    if ((amount && currency) || (amount === 0 && currency)) {
      return `${this.getAmount(amount)} ${currencyList.get(currency)}`;
    } else throw new Error('One or more arguments are undefined!');
  };
  substract = (first, second) => {
    if (first && second) {
      return this.getAmount(
        (this.getAmount(first) * 100 - this.getAmount(second) * 100) / 100
      );
    } else throw new Error('One or more arguments are undefined!');
  };
  add = (first, second) => {
    if ((first && second) || first === 0 || second === 0) {
      return this.getAmount(
        (this.getAmount(first) * 100 + this.getAmount(second) * 100) / 100
      );
    } else throw new Error('One or more arguments are undefined!');
  };
  multiply = (first, second) => {
    if ((first && second) || first === 0 || second === 0) {
      return this.getAmount(this.getAmount(first) * this.getAmount(second));
    } else throw new Error('One or more arguments are undefined!');
  };
  divide = (first, second) => {
    if (first && second) {
      return this.getAmount(this.getAmount(first) / this.getAmount(second));
    } else if (second === 0) {
      throw new Error('Second argument must not equals zero!');
    } else throw new Error('One or more arguments are undefined!');
  };
  percentage = (amount, percentage) => {
    if ((amount && percentage) || amount === 0 || percentage === 0) {
      return this.getAmount(this.getAmount(amount) * (percentage / 100));
    } else throw new Error('One or more arguments are undefined!');
  };
}

export default new Money();
