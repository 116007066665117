<template>
  <div class="education-main">
    <el-row :gutter="24">
      <el-col :lg="18" class="d-flex flex-between flex-center mb-16 flex-wrap">
        <h4 class="mt-16">Добавление сотрудников и назначение их на должности</h4>
        <el-button class="mt-16" size="small" @click="openModalVideo" type="primary"
          >Смотреть видео<i class="el-icon-play"></i
        ></el-button>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt-16">
      <el-col :lg="18">
        <p>
          Для того, чтобы просмотреть список сотрудников Вашей организации или
          добавить нового сотрудника, необходимо перейти в пункт меню
          «Настройки»/«Сотрудники».
        </p>
        <p>
          Здесь отображаются сотрудники в разрезе исполняемых ими должностей.
        </p>
        <el-divider></el-divider>
      </el-col>
      <el-col :lg="18">
        <el-image fit="contain" :src="src.url1" :preview-src-list="srcList">
        </el-image>
      </el-col>
      <el-col :lg="18">
        <el-divider></el-divider>
        <p>Раздел представляет собой таблицу со следующими графами:</p>
        <ul>
          <li>
            «Статус» - отображение текущего состояния сотрудника и его
            исполнения должностей
          </li>
          <li>«ФИО» - ФИО сотрудника</li>
          <li>«Должность» - должность, занимаемая сотрудником</li>
          <li>«Основная должность» - признак основной должности сотрудника</li>
          <li>«Дата назначения» - дата назначения сотрудника на должность</li>
          <li>
            «Дата увольнения» - дата увольнения сотрудника с должности. Значение
            больше текущей даты устанавливается при работе сотрудника по
            срочному трудовому договору. Отсутствие значения в графе
            свидетельствует о том, что сотрудник исполняет должностные
            обязанности бессрочно
          </li>
          <li>
            «Действия» - содержит кнопку для открытия дополнительного меню
            действий со строкой. Здесь можно получить доступ к редактированию и
            удалению сотрудника
          </li>
        </ul>
        <p>Для добавления сотрудника необходимо нажать на кнопку «Добавить».</p>
        <el-image fit="cover" :src="src.url2" :preview-src-list="srcList">
        </el-image>
      </el-col>
      <el-col :lg="18">
        <el-divider></el-divider>
        <p>
          Данные сотрудников для удобства заполнения разделены на карточки:
          «Персональные данные», «Документы», «Исполнения должностей», «Данные
          пользователя». Обязательные поля отмечены символом
          <span class="danger">*</span>
        </p>
        .
        <p>
          В карточке «Персональные данные» заполняются личные данные сотрудника.
        </p>
        <p>
          В карточке «Документы» заполняются данные паспорта сотрудника, его
          номер СНИЛС и ИНН.
        </p>
        <p>
          В карточке «Исполнения должностей» обязательно указывается основная
          должность сотрудника, подразделение, где он будет работать и дата
          приема на работу (дата назначения на должность).
        </p>
        <p>Дополнительная должность является необязательной для заполнения.</p>
        <el-image fit="cover" :src="src.url3" :preview-src-list="srcList">
        </el-image>
        <p>
          Корректное заполнение подразделений необходимо для правильного учета
          сотрудников в дальнейшем и правильного назначения их на должность.
        </p>
        <p>
          При редактировании карточки сотрудника будет доступен раздел «Архив
          исполнения должностей», где можно посмотреть информацию о предыдущем
          опыте работы сотрудника в Вашей организации.
        </p>
      </el-col>
      <el-col :lg="18">
        <el-divider></el-divider>
        <el-image
          class="mt-16 mb-16"
          fit="cover"
          :src="src.url4"
          :preview-src-list="srcList">
        </el-image>
        <p>
          В карточке «Данные пользователя» необходимо указать имя пользователя
          сотрудника и пароль. Эти данные будут использованы для входа в систему
          Documeds.
        </p>
        <p>
          Также необходимо выбрать значение роли пользователя в системе. В
          настоящее время доступны следующие роли: администратор системы, врач,
          медсестра, регистратор.
        </p>
        <p>
          Для сохранения внесенных изменений в настройки сотрудника необходимо
          нажать на кнопку «Сохранить».
        </p>
      </el-col>
    </el-row>
    <!-- модальное окно просмотра видео-урока -->
    <el-dialog
      title="Видео-урок"
      class="modal-play-video"
      :visible.sync="modalVideo"
      width="100%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true">
      <div v-if="!idVideo">
        <h6>К сожалению видео-урок не доступен</h6>
        <p>
          В скором времени мы добавим данный видео-урок по обучению работе в
          Documeds
        </p>
      </div>
      <div v-if="idVideo">
        <VideoPlayerComponent :idVideo="idVideo"></VideoPlayerComponent>
      </div>
      <el-button
        class="education-modal-btn"
        type="primary"
        @click="openModalVideo"
        >Продолжить обучение в текстовом формате</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
  import VideoPlayerComponent from "@/components/VideoPlayerComponent.vue";
  export default {
    components: { VideoPlayerComponent },
    data() {
      return {
        modalVideo: false,
        idVideo: "Ujj_skkldo0",
        src: {
          url1: require("@/assets/img/education/add-staff-1.png"),
          url2: require("@/assets/img/education/add-staff-2.png"),
          url3: require("@/assets/img/education/add-staff-3.png"),
          url4: require("@/assets/img/education/add-staff-4.png"),
        },
        srcList: [
          require("@/assets/img/education/add-staff-1.png"),
          require("@/assets/img/education/add-staff-2.png"),
          require("@/assets/img/education/add-staff-3.png"),
          require("@/assets/img/education/add-staff-4.png"),
        ],
      };
    },
    methods: {
      openModalVideo() {
        this.modalVideo = !this.modalVideo;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
