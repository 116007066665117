<template>
  <el-menu
    :default-active="$router.currentRoute.path"
    :router="true"
    class="el-menu-list"
  >
    <el-menu-item index="/settings/myorganization" v-if="myorganizationShow">
      <i class="el-icon-bag-alt"></i>
      <span>Моя организация</span>
    </el-menu-item>
    <el-menu-item index="/settings/branches" v-if="branchesShow">
      <i class="el-icon-building"></i>
      <span>Филиалы</span>
    </el-menu-item>
    <el-menu-item index="/settings/cabinets" v-if="cabinetsShow">
      <i class="el-icon-stethoscope"></i>
      <span>Кабинеты</span>
    </el-menu-item>
    <el-menu-item index="/settings/departments" v-if="departmentsShow">
      <i class="el-icon-clinic-medical"></i>
      <span>Подразделения</span>
    </el-menu-item>
    <el-menu-item index="/settings/positions" v-if="positionsShow">
      <i class="el-icon-user-md"></i>
      <span>Должности</span>
    </el-menu-item>
    <el-menu-item index="/settings/staff" v-if="staffShow">
      <i class="el-icon-users-alt"></i>
      <span>Сотрудники</span>
    </el-menu-item>
    <el-menu-item index="/settings/directory" v-if="directoryShow">
      <i class="el-icon-books"></i>
      <span>Справочники</span>
    </el-menu-item>
    <el-menu-item index="/settings/timetable" v-if="timetableShow">
      <i class="el-icon-calender"></i>
      <span>Графики работ</span>
    </el-menu-item>
    <el-menu-item
      index="/settings/meddoc-templates-list"
      v-if="meddocConstructorShow"
    >
      <i class="el-icon-file-medical-alt"></i>
      <span>Медицинские документы</span>
    </el-menu-item>
    <el-menu-item index="/settings/print-templates" v-if="printTemplatesShow">
      <i class="el-icon-print"></i>
      <span>Шаблоны для печати</span>
    </el-menu-item>
    <el-menu-item
      index="/settings/business-processes"
      v-if="printTemplatesShow"
    >
      <i class="el-icon-process"></i>
      <span>Бизнес-процессы</span>
    </el-menu-item>
  </el-menu>
</template>

<script>
import AuthManager from '../services/authManager';

export default {
  data() {
    return {
      myorganizationShow: false,
      branchesShow: false,
      cabinetsShow: false,
      departmentsShow: false,
      positionsShow: false,
      staffShow: false,
      directoryShow: false,
      timetableShow: false,
      meddocConstructorShow: false,
      printTemplatesShow: true,
    };
  },
  async created() {
    const currentUser = AuthManager.getUser();
    this.myorganizationShow = currentUser.roles.find(
      (x) => x == 'view_my_organization'
    );
    this.branchesShow = currentUser.roles.find((x) => x == 'view_branche');
    this.cabinetsShow = currentUser.roles.find((x) => x == 'view_department');
    this.departmentsShow = currentUser.roles.find(
      (x) => x == 'view_department'
    );
    this.positionsShow = currentUser.roles.find((x) => x == 'view_position');
    this.staffShow = currentUser.roles.find((x) => x == 'view_staff');
    this.directoryShow = currentUser.roles.find((x) => x == 'view_directory');
    this.timetableShow = currentUser.roles.find((x) => x == 'edit_timetable');
    this.meddocConstructorShow = currentUser.roles.find(
      (x) => x == 'meddoc_constructor'
    );
  },
};
</script>

<style lang="scss" scoped></style>
