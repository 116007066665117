<template>
  <div class="education-main">
    <el-row :gutter="24">
      <el-col :lg="18" class="d-flex flex-between flex-center mb-16 flex-wrap">
        <h4 class="mt-16">Раздел «Аналитика»</h4>
        <el-button class="mt-16" size="small" @click="openModalVideo" type="primary"
          >Смотреть видео<i class="el-icon-play"></i
        ></el-button>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt-16">
      <el-col :lg="18">
        <p>
          Данный раздел позволяет ознакомиться со статистическими данными работы
          Вашей организации. Раздел «Аналитика» доступен только для
          пользователей с ролью «Администратор системы».
        </p>
        <p>
          В левом верхнем углу раздела доступен выпадающий список со следующими
          значениями: «Статистика посещений», «Финансы» и «Услуги». Выбор
          каждого из значений выводит на экран настраиваемую при помощи фильтров
          диаграмму со статистикой по выставленному показателю.
        </p>
        <p>
          В правом верхнем углу раздела доступна кнопка «Выгрузить в Excel»,
          позволяющая экспортировать в Excel-файл сформированную статистику.
          Состав ячеек выгрузки зависит от применных фильтров.
        </p>
        <el-divider></el-divider>
      </el-col>

      <el-col :lg="18">
        <p>
          По показателю «Статистика посещений» данные могут быть отфильтрованы.
          Фильтр «Выберите даты» является обязательным к заполнению. В
          выпадающих списках «Выберите филиал», «Выберите подразделение»,
          «Выберите должность», «Выберите сотрудника» и «Выберите статус записи
          на прием» реализована возможность как единичного, так и множественного
          выбора, выбора всех значений из списка, а также возможность удаления
          выбранных значений.
        </p>
        <p>Параметры фильтрации:</p>
        <ul>
          <li>
            «Выберите даты» - фильтр для выбора времененного диапазона
            формирования диаграммы
          </li>
          <li>
            «Выберите филиал» - фильтр для выбора филиала. Уточняем, что
            посещение относится к филиалу в том случае, если оно проведено в
            кабинете, закрепленном за данным филиалом
          </li>
          <li>
            «Выберите подразделение» - фильтр для выбора подразделения.
            Уточняем, что посещение относится к подразделению в том случае, если
            запись в расписании, в рамках которой был произведен прием, была
            создана для исполнения должности данного подразделения
          </li>
          <li>
            «Выберите должность» - фильтр для выбора должности. Уточняем, что
            должность определяется в соответсвии с услугами, оказанными в рамках
            приема (например, если оказанная в ходе приема услуга в прайс-листе
            задана как услуга, оказываемая кардиологом, то прием будет считаться
            приемом кардиолога)
          </li>
          <li>«Выберите сотрудника» - фильтр для выбора сотрудника</li>
          <li>«Выберите пол пациентов» - фильтр для выбора пола пациентов</li>
          <li>
            «Выберите возраст пациентов» - фильтр для выбора диапазона возраста
            пациентов
          </li>
          <li>
            «Выберите статус записи на прием» - фильтр для выбора диапазона
            возраста пациентов. Уточняем, что в случае если данное поле не
            заполнено, то применяется фильтр по статусу «Прием завершен»
          </li>
        </ul>
        <p>Выбор каждого из значений фильтра приводит к изменению диаграммы.</p>
        <p>
          Под фильтрами расположены кнопки выбора формата представления
          диаграммы: «Столбчатая диаграмма» и «График».
        </p>
        <p>
          Горизонтальная ось диаграммы представляет собой временную шкалу,
          соответствующую выбранным значениям фильтров. Вертикальная ось
          диаграммы представляет собой шкалу количества посещений пациентами
          Вашей организации. Статистика посещений представлена в соответсвии с
          их типами, цветовые индикаторы которых выведены над диаграммой.
        </p>
      </el-col>
      <el-col :lg="18">
        <el-image
          class="mt-16"
          :src="src.url2"
          :preview-src-list="srcList"></el-image>
      </el-col>
      <el-col :lg="18">
        <el-divider></el-divider>
        <p>
          По показателю «Финансы» данные могут быть отфильтрованы. Фильтр
          «Выберите даты» является обязательным к заполнению. В выпадающих
          списках «Выберите филиал», «Выберите подразделение», «Выберите
          должность», «Выберите сотрудника» реализована возможность как
          единичного, так и множественного выбора, выбора всех значений из
          списка, а также возможность удаления выбранных значений. В выпадающем
          списке «Выберите услуги» отсутвует возможность автоматического выбора
          всех значений.
        </p>
        <p>Параметры фильтрации:</p>
        <ul>
          <li>
            «Выберите даты» - фильтр для выбора времененного диапазона
            формирования диаграммы
          </li>
          <li>
            «Выберите филиал» - фильтр для выбора филиала. Уточняем, что
            посещение относится к филиалу в том случае, если оно проведено в
            кабинете, закрепленном за данным филиалом
          </li>
          <li>
            «Выберите подразделение» - фильтр для выбора подразделения.
            Уточняем, что посещение относится к подразделению в том случае, если
            запись в расписании, в рамках которой был произведен прием, была
            создана для исполнения должности данного подразделения
          </li>
          <li>
            «Выберите должность» - фильтр для выбора должности. Уточняем, что
            должность определяется в соответсвии с услугами, оказанными в рамках
            приема (например, если оказанная в ходе приема услуга в прайс-листе
            задана как услуга, оказываемая кардиологом, то прием будет считаться
            приемом кардиолога)
          </li>
          <li>«Выберите сотрудника» - фильтр для выбора сотрудника</li>
          <li>
            «Выберите услуги» - фильтр для выбора услуг. Уточняем, что в случае
            если данное поле не заполнено, то применяется фильтр по всем услугам
          </li>
        </ul>
        <p>Выбор каждого из значений фильтра приводит к изменению диаграммы.</p>
        <p>
          Под фильтрами расположены кнопки выбора формата представления
          диаграммы: «Столбчатая диаграмма» и «График».
        </p>
        <p>
          Горизонтальная ось диаграммы представляет собой временную шкалу,
          соответствующую выбранным значениям фильтров. Вертикальная ось
          диаграммы представляет собой шкалу суммарной стоимости услуг.
        </p>
      </el-col>
      <el-col :lg="18">
        <el-image
          class="mt-16"
          :src="src.url3"
          :preview-src-list="srcList"></el-image>
      </el-col>
      <el-col :lg="18">
        <el-divider></el-divider>
        <p>
          По показателю «Услуги» данные могут быть отфильтрованы. Фильтр
          «Выберите даты» является обязательным к заполнению. В выпадающих
          списках «Выберите филиал», «Выберите подразделение», «Выберите
          должность», «Выберите сотрудника» реализована возможность как
          единичного, так и множественного выбора, выбора всех значений из
          списка, а также возможность удаления выбранных значений. В выпадающем
          списке «Выберите услуги» отсутвует возможность автоматического выбора
          всех значений.
        </p>
        <p>Параметры фильтрации:</p>
        <ul>
          <li>
            «Выберите даты» - фильтр для выбора времененного диапазона
            формирования диаграммы
          </li>
          <li>
            «Выберите филиал» - фильтр для выбора филиала. Уточняем, что
            посещение относится к филиалу в том случае, если оно проведено в
            кабинете, закрепленном за данным филиалом
          </li>
          <li>
            «Выберите подразделение» - фильтр для выбора подразделения.
            Уточняем, что посещение относится к подразделению в том случае, если
            запись в расписании, в рамках которой был произведен прием, была
            создана для исполнения должности данного подразделения
          </li>
          <li>
            «Выберите должность» - фильтр для выбора должности. Уточняем, что
            должность определяется в соответсвии с услугами, оказанными в рамках
            приема (например, если оказанная в ходе приема услуга в прайс-листе
            задана как услуга, оказываемая кардиологом, то прием будет считаться
            приемом кардиолога)
          </li>
          <li>«Выберите сотрудника» - фильтр для выбора сотрудника</li>
          <li>
            «Выберите услуги» - фильтр для выбора услуг. Уточняем, что в случае
            если данное поле не заполнено, то применяется фильтр по всем услугам
          </li>
        </ul>
        <p>Выбор каждого из значений фильтра приводит к изменению диаграммы.</p>
        <p>
          Под фильтрами расположены кнопки выбора формата представления
          диаграммы: «Столбчатая диаграмма» и «График».
        </p>
        <p>
          Горизонтальная ось диаграммы представляет собой временную шкалу,
          соответствующую выбранным значениям фильтров. Вертикальная ось
          диаграммы представляет собой шкалу количества оказанных услуг.
        </p>
      </el-col>
    </el-row>
    <!-- модальное окно просмотра видео-урока -->
    <el-dialog
      title="Видео-урок"
      class="modal-play-video"
      :visible.sync="modalVideo"
      width="100%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true">
      <div v-if="!idVideo">
        <h6>К сожалению видео-урок не доступен</h6>
        <p>
          В скором времени мы добавим данный видео-урок по обучению работе в
          Documeds
        </p>
      </div>
      <div v-if="idVideo">
        <VideoPlayerComponent :idVideo="idVideo"></VideoPlayerComponent>
      </div>
      <el-button
        class="education-modal-btn"
        type="primary"
        @click="openModalVideo"
        >Продолжить обучение в текстовом формате</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
  import VideoPlayerComponent from "@/components/VideoPlayerComponent.vue";
  export default {
    components: { VideoPlayerComponent },
    data() {
      return {
        modalVideo: false,
        idVideo: "Q88VuB8nNtg",
        src: {
          url1: require("@/assets/img/education/analytics-1.png"),
          url2: require("@/assets/img/education/analytics-2.png"),
          url3: require("@/assets/img/education/analytics-3.png"),
        },
        srcList: [
          require("@/assets/img/education/analytics-1.png"),
          require("@/assets/img/education/analytics-2.png"),
          require("@/assets/img/education/analytics-3.png"),
        ],
      };
    },
    methods: {
      openModalVideo() {
        this.modalVideo = !this.modalVideo;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
