<template>
  <div class="settings-page" v-loading="loading">
    <el-row :gutter="24">
      <el-col :span="24">
        <h6>Должности</h6>
        <span class="settings-description">
          Здесь вы можете посмотреть и добавить должности в вашей организации
        </span></el-col
      >
    </el-row>
    <el-row :gutter="24">
      <el-col
        :xl="6"
        :lg="8"
        :md="12"
        :sm="24"
        class="mt"
        v-for="item in positionTypes"
        :key="item.id"
      >
        <div class="settings-card">
          <el-button
            size="small"
            class="settings-contacts-list-btn"
            type="text"
            icon="el-icon-delete"
            circle
            @click="handleRemovePositionType(item)"
            v-if="editPositionShow"
          ></el-button>
          <div class="settings-card-title">{{ item.value }}</div>
          <ul class="settings-card-list">
            <li class="settings-card-item">
              <span class="settings-card-field">Описание:</span>
              <span class="settings-card-value">{{ item.description }}</span>
            </li>
            <li class="settings-card-item">
              <span class="settings-card-field">Ведет прием:</span>
              <el-checkbox
                class="settings-card-value"
                v-model="item.hasSchedule"
                disabled
              ></el-checkbox>
            </li>
          </ul>
          <el-button
            size="small"
            type="primary"
            plain
            @click="openModalEditPositionType(item)"
            v-if="editPositionShow"
            >Редактировать</el-button
          >
        </div>
      </el-col>
      <el-col
        :xl="6"
        :lg="8"
        :md="12"
        :sm="24"
        class="mt mb"
        v-if="editPositionShow"
      >
        <div class="settings-card empty">
          <el-button
            size="small"
            class="settings-card-btn"
            @click="openModalAddPositionType"
          >
            <i class="el-icon-plus el-icon-left"></i>
            Добавить
          </el-button>
        </div>
      </el-col>
    </el-row>

    <!-- модальное окно доабвления должности -->
    <el-dialog
      class="modal-add-position"
      :title="modalTitle"
      :visible.sync="modalAddPositionType"
      width="100%"
    >
      <el-row :gutter="24">
        <el-col :span="24" class="mt">
          <label for="" class="settings-modal-label"
            >Наименование <span class="danger">*</span></label
          >
          <el-input
            size="small"
            placeholder="Введите наименование должности"
            v-model="positionType.value"
            :class="{
              invalid:
                $v.positionType.value.$dirty && !$v.positionType.value.required,
            }"
          >
          </el-input>
          <small
            class="helper-text invalid"
            v-if="
              $v.positionType.value.$dirty && !$v.positionType.value.required
            "
            >Поле является обязательным</small
          >
        </el-col>
        <el-col :span="24" class="mt">
          <label for="" class="settings-modal-label">Описание</label>
          <el-input
            size="small"
            type="textarea"
            resize="none"
            :rows="4"
            placeholder="Введите описание должности"
            v-model="positionType.description"
          >
          </el-input>
        </el-col>
        <el-col :span="24" class="mt">
          <el-checkbox
            class="settings-modal-label"
            v-model="positionType.hasSchedule"
            >Ведет прием</el-checkbox
          >
        </el-col>
        <el-col :span="24" class="mt">
          <el-row :gutter="24">
            <el-col :span="12">
              <el-button
                class="w-100"
                size="small"
                type="primary"
                @click="handlePositionTypeSave"
                :loading="loadingModal"
                >Сохранить</el-button
              >
            </el-col>
            <el-col :span="12">
              <el-button
                class="w-100"
                size="small"
                @click="modalAddPositionType = false"
                >Отменить</el-button
              >
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import PositionTypeManager from '@/services/positionTypeManager.js';
import AuthManager from '@/services/authManager';

export default {
  data() {
    return {
      positionTypes: [],
      modalAddPositionType: false,
      positionType: '',
      modalTitle: '',
      editPositionShow: false,
      loading: false,
      loadingModal: false,
    };
  },
  async created() {
    this.loading = true;
    this.positionTypes = await PositionTypeManager.getPositionTypes();
    let currentUser = AuthManager.getUser();
    this.editPositionShow = currentUser.roles.find((x) => x == 'edit_position');
    this.loading = false;
  },
  validations: {
    positionType: {
      value: { required },
    },
  },
  methods: {
    openModalAddPositionType() {
      this.positionType = {
        id: null,
        value: '',
        description: '',
        hasSchedule: true,
      };
      this.modalTitle = 'Добавление должности';
      this.modalAddPositionType = true;
    },
    openModalEditPositionType(item) {
      this.positionType = {
        id: item.id,
        value: item.value,
        description: item.description,
        hasSchedule: item.hasSchedule,
      };
      this.modalTitle = 'Редактирование должности';
      this.modalAddPositionType = true;
    },
    async handlePositionTypeSave() {
      this.loadingModal = true;
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.loadingModal = false;
        return;
      } else {
        if (this.positionType.id) {
          this.positionTypes = await PositionTypeManager.updatePositionType(
            this.positionType
          );
        } else {
          this.positionTypes = await PositionTypeManager.addPositionType(
            this.positionType
          );
        }
        this.modalAddPositionType = false;
        this.loadingModal = false;
      }
    },
    async handleRemovePositionType(item) {
      this.$confirm(
        'Вы действительно хотите удалить выбранную должность?',
        'Удаление должности',
        {
          confirmButtonText: 'Подтвердить',
          cancelButtonText: 'Отменить',
          type: 'warning',
        }
      )
        .then(async () => {
          this.$message({
            type: 'success',
            message: 'Удаление прошло успешно',
          });
          this.positionTypes = await PositionTypeManager.deletePositionType(
            item
          );
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Удаление отменено',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
