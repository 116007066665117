import { store } from '../store';

class PriceListManager {
  async getPriceList() {
    if (store.getters.priceList.length == 0) {
      await store.dispatch('getPriceList');
    }
    return store.getters.priceList;
  }
  async getCurrentPriceList() {
    if (store.getters.priceList.length == 0) {
      await store.dispatch('getPriceList');
    }
    return store.getters.priceList.filter(
      (x) =>
        (new Date(x.dateFrom) < new Date() ||
          new Date(x.dateFrom).getTime() == new Date().getTime()) &&
        (!x.DateTo ||
          new Date(x.DateTo) > new Date() ||
          new Date(x.DateTo).getTime() == new Date().getTime())
    );
  }
  async addPriceListItem(item) {
    try {
      await store.dispatch('SAVE_PRICELISTITEM', item);
      let data = store.getters.priceList;
      return { data: data, error: null };
    } catch (error) {
      return { data: null, error: error };
    }
  }
  async updatePriceListItem(item) {
    try {
      await store.dispatch('UPDATE_PRICELISTITEM', item);
      let data = store.getters.priceList;
      return { data: data, error: null };
    } catch (error) {
      return { data: null, error: error };
    }
  }
}

export default new PriceListManager();
