import { store } from '../store';

class TimetableManager {
  //формирование расписания для страницы Графики работ
  async getSchedule(filter) {
    //получаем данные с помощью vuex
    let schedulesStore = store.getters.scheduleList;

    //фильтруем полученный список по дате - приводило к некорректному показу графиков работы врачей
    // schedulesStore = schedulesStore.filter(
    //   (x) => new Date(x.dateTimeFrom).getMonth() == filter.date.getMonth()
    // );
    //фильтруем по врачу
    schedulesStore = schedulesStore.filter((x) => filter.doctor == x.idDoctor);

    return schedulesStore;
  }
  //проверка доступности специалистов и кабинетов при добавлении записей в расписание
  async checkTimetableAdd(schedulesToCheck, idDoctor) {
    let schedulesStore = store.getters.scheduleList;
    for (var i = 0; i <= schedulesToCheck.length - 1; i++) {
      var sheduleToCheckDate = new Date(
        schedulesToCheck[i].dateTimeFrom.getFullYear(),
        schedulesToCheck[i].dateTimeFrom.getMonth(),
        schedulesToCheck[i].dateTimeFrom.getDate()
      ).getTime();
      if (
        schedulesStore.find(
          (x) =>
            new Date(
              new Date(x.dateTimeFrom).getFullYear(),
              new Date(x.dateTimeFrom).getMonth(),
              new Date(x.dateTimeFrom).getDate()
            ).getTime() == sheduleToCheckDate && x.idDoctor == idDoctor
        )
      ) {
        return 'У специалиста уже задан график работ на указанные даты!';
      }
    }
    return null;
  }

  //проверка доступности специалистов и кабинетов при добавлении записей в расписание
  async checkTimetableEdit(scheduleToCheck) {
    let schedulesStore = store.getters.scheduleList;
    var sheduleToCheckDateFrom = scheduleToCheck.dateTimeFrom.getTime();
    var sheduleToCheckDateTo = scheduleToCheck.dateTimeTo.getTime();
    if (
      schedulesStore.find(
        (x) =>
          ((sheduleToCheckDateFrom >= new Date(x.dateTimeFrom).getTime() &&
            sheduleToCheckDateFrom < new Date(x.dateTimeTo).getTime()) ||
            (sheduleToCheckDateTo > new Date(x.dateTimeFrom).getTime() &&
              sheduleToCheckDateTo <= new Date(x.dateTimeTo).getTime()) ||
            (sheduleToCheckDateFrom < new Date(x.dateTimeFrom).getTime() &&
              sheduleToCheckDateTo > new Date(x.dateTimeTo).getTime())) &&
          x.idCabinet == scheduleToCheck.idCabinet
      )
    ) {
      return 'Данный кабинет в указанное время занят!';
    }
    return null;
  }

  //удаление записи из расписания
  async deleteSchedule(schedule) {
    await store.dispatch('DELETE_SCHEDULE', schedule);
    await store.dispatch('GET_SCHEDULES');
  }
}

export default new TimetableManager();
