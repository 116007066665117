import axios from '@/api/axios';

const getInsurancePolicies = async () => {
  return await axios.get('insurance-policies');
};

const getInsurancePolicyById = async (id) => {
  return await axios.get('insurance-policies/' + id);
};

const createInsurancePolicies = async (insurancePolicy) => {
  return await axios.post('insurance-policies', insurancePolicy);
};

const updateInsurancePolicies = async (insurancePolicy) => {
  console.log(insurancePolicy);
  return await axios.put(
    'insurance-policies/' + insurancePolicy.id,
    insurancePolicy
  );
};

const deleteInsurancePolicyById = async (id) => {
  return await axios.delete('insurance-policies/' + id);
};

export default {
  getInsurancePolicies,
  getInsurancePolicyById,
  createInsurancePolicies,
  updateInsurancePolicies,
  deleteInsurancePolicyById,
};
