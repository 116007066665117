<template>
  <div>
    <el-autocomplete
      size="small"
      :disabled="disabled"
      v-model="search"
      :fetch-suggestions="fetchSuggestions"
      :trigger-on-focus="false"
      :placeholder="placeholder"
      @select="handleSelect"></el-autocomplete>
  </div>
</template>

<script>
  import axios from "axios";

  export default {
    props: {
      value: String,
      placeholder: String,
      disabled: Boolean,
    },
    computed: {
      search: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        },
      },
    },
    methods: {
      async fetchSuggestions(search, callback) {
        let result = await axios.get("dadatarequest", { params: { searchString: search}});
        callback(result.data.suggestions);
      },
      handleSelect(value) {
        this.$emit("select", value);
      },
    },
  };
</script>
