import axios from '@/api/axios'

class MkbManager {
    async getMkb(pagenumber, pagesize, filterstring, idMkb) {
        let requestUrl = filterstring
           ? 'mkb?pagenumber=' + pagenumber + '&pagesize=' + pagesize + '&filterstring=' + filterstring
           : 'mkb?pagenumber=' + pagenumber + '&pagesize=' + pagesize;
           requestUrl = idMkb
           ?  requestUrl + '&idMkb=' + idMkb
           : requestUrl;
        let {data} = await axios.get(requestUrl);
        return data.value;
      }
}
export default new MkbManager();