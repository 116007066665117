<template>
  <el-breadcrumb class="breadcrumb" separator="/">
    <el-breadcrumb-item
      v-for="(item, index) of breadcrumbList"
      :key="index"
      :to="item.path"
      >{{ item.text }}</el-breadcrumb-item
    >
  </el-breadcrumb>
</template>

<script>
import { getDateString } from '@/services/commonService';
export default {
  name: 'BreadcrumbComponent',
  props: {
    patient: {
      type: Object,
    },
    appointment: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    patientId() {
      if (this.patient) {
        return this.patient.id;
      } else return '';
    },
    patientInfo() {
      return `${this.patient.lastName ? this.patient.lastName : ''} ${
        this.patient.firstName ? this.patient.firstName : ''
      } ${
        this.patient.patronymic ? this.patient.patronymic : ''
      }, ${getDateString(this.patient.birthDate)}`;
    },
    appointmentDateFrom() {
      return this.appointment
        ? getDateString(this.appointment.dateTimeFrom)
        : '';
    },
    breadcrumbList() {
      let breadcrumbList = [];
      let arr = this.$route.path.split('/');
      for (let i of arr) {
        switch (i) {
          case 'patient':
            breadcrumbList.push({
              path: `/patient/${this.patientId}`,
              text: this.patientInfo,
            });
            break;
          case 'guarantee-letters':
            breadcrumbList.push({
              path: `/patient/${this.patientId}/guarantee-letters`,
              text: 'Гарантийные письма',
            });
            break;
          case 'medical-cases':
            breadcrumbList.push({
              path: `/patient/${this.patientId}/medical-cases`,
              text: 'Случаи обращения',
            });
            break;
          case 'visits-list':
            breadcrumbList.push({
              path: `/patient/${this.patientId}/medical-cases/`,
              text: 'Приемы',
            });
            break;
          case 'visit':
            breadcrumbList.push({
              path: `/patient/${this.patientId}`,
              text: `Медицинская карта № ${this.patient.cardNumber}`,
            });
            breadcrumbList.push({
              path: `/patient/${this.patientId}/medical-cases`,
              text: 'Случаи обращения',
            });
            breadcrumbList.push({
              path: `/visit/`,
              text: `Прием от ${this.appointmentDateFrom}`,
            });
            break;
          default:
            break;
        }
      }
      return breadcrumbList;
    },
  },
  mounted() {},
};
</script>
