<template>
  <div class="settings-page bp" v-loading="loading">
    <el-row :gutter="24">
      <el-col :span="24">
        <h6>Настройка бизнес-процессов</h6>
        <span class="bp-warning"
          >В настоящий момент доступны настройки не для всех
          бизнес-процессов</span
        >
      </el-col>
    </el-row>
    <el-row
      class="bp-item"
      :gutter="24"
      v-for="(row, index) in this.businessProcesses"
      :key="index"
    >
      <span class="bp-item__title">{{ row.title }}</span>
      <el-col :span="row.col" v-for="(item, index) in row.rules" :key="index">
        <div v-if="item.type == 'select'">
          <label>{{ item.label }}</label>
          <div class="d-flex">
            <el-select
              class="bp-select"
              v-for="field in item.fields"
              :key="field.id"
              :disabled="!item.isAvailable"
              :placeholder="field.placeholder"
              v-model="item.value"
              @change="setSelectDescription"
              size="small"
            >
              <el-option
                v-for="option in field.options"
                :key="option.id"
                :label="option.value"
                :value="option"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div v-if="item.type == 'checkbox'">
          <label>{{ item.label }}</label>
          <div class="d-flex">
            <el-checkbox
              v-for="(field, index) in item.fields"
              :key="index"
              :disabled="!item.isAvailable"
              v-model="field.value"
              size="small"
              >{{ field.name }}</el-checkbox
            >
          </div>
        </div>
        <span class="bp-select__label-bottom">
          {{ item.value ? item.value.description : '' }}
        </span>
      </el-col>
    </el-row>
    <el-button
      type="primary"
      size="small"
      @click="saveBusinessProcessesSettingsAsync"
      >Сохранить</el-button
    >
  </div>
</template>

<script>
// import { businessProcesses } from '@/constants';
export default {
  name: 'BusinessProcessesPage',
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    businessProcesses() {
      return this.$store.getters.businessProcesses;
    },
    myOrganization() {
      return this.$store.getters.myOrganization;
    },
  },
  mounted() {
    this.$store.dispatch('getMyOrganization');
  },
  methods: {
    setSelectDescription(value) {
      this.businessProcesses.appointments.rules.countDuration.description =
        value.description;
    },
    saveBusinessProcessesSettingsAsync() {
      let useDoctorAppointmentDuration =
        this.businessProcesses.appointments.rules.countDuration.value.id === 1
          ? false
          : true;
      this.$store
        .dispatch('changeMyOrganization', {
          ...this.myOrganization,
          useDoctorAppointmentDuration: useDoctorAppointmentDuration,
        })
        .then(() => {
          this.$message({
            type: 'success',
            message: 'Настройки бизнес-процессов успешно сохранены',
          });
          this.$store.dispatch('getMyOrganization');
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: 'Не удалось сохранить настройки бизнес-процессов!',
          });
        });
    },
  },
};
</script>
