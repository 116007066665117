import { store } from "../store";
import axios from "@/api/axios";

class MedicalServiceManager {
  //получение списка услуг текущей организации (смаппленных со справочником Минздрава)
  async getMedicalServiceCurrentOrganization() {
    if (store.getters.medicalServiceCurrentOrganization.length == 0) {
      await store.dispatch("GET_MEDICALSERVICECURRENTORGANIZATION");
    }
    return store.getters.medicalServiceCurrentOrganization;
  }
  //получение списка услуг из справочника Минздрава
  async getMedicalServices(pagenumber, pagesize, filterstring, id) {
    let { data } = await axios.get("medicalservicetype", {
      params: { pagenumber, pagesize, filterstring, id },
    });
    return data.value;
  }
}
export default new MedicalServiceManager();
