<template>
  <div class="header">
    <HamburgerMenuComponent class="hidden-sm-and-up"></HamburgerMenuComponent>
    <div class="header-logo hidden-xs-only">
      <router-link :to="{ name: 'root' }"
        ><img src="@/assets/logo-menu.svg" alt="Documeds"
      /></router-link>
    </div>
    <div class="header-main">
      <div class="header-counter-demo" v-if="isDemo">
        <span>До окончания пробного периода</span>
        <span
          >осталось <b>{{ demoDays }}</b> дней!</span
        >
      </div>
      <!-- <div class="header-search">
        <el-autocomplete
          class="header-search-input"
          prefix-icon="el-icon-search"
          v-model="searchModule"
          :fetch-suggestions="querySearch"
          placeholder="Поиск по Documeds..."
          @select="handleSelect">
          <template slot-scope="{ item }">
            <div class="item">{{ item.nameRu }}</div>
          </template>
        </el-autocomplete>
      </div> -->
      <!-- <el-dropdown trigger="click" class="header-notice">
        <span class="el-dropdown-link">
          <div>
            <el-badge :value="7" class="notice">
              <i class="el-icon-bell"></i>
            </el-badge>
          </div>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <el-badge :value="7" class="item" type="success">
              <span>Записи на приём</span>
            </el-badge>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
      <el-button
        class="header-help hidden-xs-only"
        type="text"
        @click="handleOpenModalAddMessageSupport"
        ><i class="el-icon-question-circle"></i>Помощь
      </el-button>
      <div class="header-user">
        <span class="header-user-fio">{{ userFullName }}</span>
        <span class="header-user-position">{{ userPost }}</span>
      </div>
      <div class="header-avatar">
        <el-dropdown size="mini" trigger="click">
          <span class="el-dropdown-link">
            <el-avatar icon="el-icon-user" size="medium"></el-avatar
            ><i class="el-icon-angle-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="currentDoctorId">
              <router-link
                class="header-dropdown-link"
                :to="'/profile/' + currentDoctorId"
                ><i class="el-icon-user"></i>Мой профиль</router-link
              >
            </el-dropdown-item>
            <el-dropdown-item
              ><router-link
                class="header-dropdown-link"
                to="/settings/directory/"
                ><i class="el-icon-books"></i>Справочники</router-link
              >
            </el-dropdown-item>
            <el-dropdown-item
              ><router-link class="header-dropdown-link" to="/education"
                ><i class="el-icon-graduation-cap"></i>Обучение</router-link
              >
            </el-dropdown-item>
            <el-dropdown-item
              ><el-button
                size="small"
                class="header-dropdown-link hidden-sm-and-up"
                type="text"
                @click="handleOpenModalAddMessageSupport"
                ><i class="el-icon-question-circle"></i>Помощь
              </el-button>
            </el-dropdown-item>
            <el-dropdown-item divided>
              <el-button
                class="header-dropdown-link"
                size="small"
                type="text"
                @click="logOut()"
                ><i class="el-icon-exit"></i>Выйти</el-button
              >
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- модальное окно обращения в тех поддержку-->
    <el-dialog
      class="modal-add-request"
      title="Обращение в техническую поддержку"
      :visible.sync="modalAddMessageSupport"
      width="100%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleCloseModalAddMessageSupport"
    >
      <el-row :gutter="24">
        <el-col :span="24" class="mt">
          <label for="" class="settings-modal-label"
            >Тема обращения <span class="danger">*</span></label
          >
          <el-input
            size="small"
            placeholder="Введите тему обращения"
            v-model="supportRequest.problemSubject"
          >
          </el-input>
        </el-col>
        <el-col :span="24" class="mt">
          <label for="" class="settings-modal-label"
            >Описание проблемы <span class="danger">*</span></label
          >
          <el-input
            size="small"
            type="textarea"
            resize="none"
            :autosize="{ minRows: 4, maxRows: 8 }"
            :rows="4"
            placeholder="Введите описание проблемы"
            v-model="supportRequest.problemDescription"
          >
          </el-input>
        </el-col>
        <el-col :span="24" class="mt">
          <label for="" class="settings-modal-label"
            >Вы можете добавить файл в формате JPEG или PNG с размером не более
            5 мб</label
          >
          <el-upload
            class="upload-demo"
            :limit="3"
            :on-exceed="handleExceed"
            action=""
            :on-change="onUploadChange"
            :auto-upload="false"
            :on-remove="handleRemove"
            :file-list="fileList"
          >
            <el-button size="small" type="primary" plain>Загрузить</el-button>
            <!-- <div slot="tip" class="el-upload__tip">
              Вы можете добавить фалй в формате jpg или png с размером не более
              500кб
            </div> -->
          </el-upload>
        </el-col>
        <el-col :span="24" class="mt d-flex">
          <el-button
            :loading="loadingBtn"
            size="small"
            type="primary"
            @click="handleSendRequest"
            >Отправить</el-button
          >
          <el-button size="small" @click="handleCloseModalAddMessageSupport"
            >Отменить</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import AuthManager from '@/services/authManager';
import SupportRequestManager from '@/services/supportRequestManager';
import HamburgerMenuComponent from '@/components/HamburgerMenuComponent.vue';
import { actionTypes } from '@/store/modules/documentsPrintModule';
export default {
  components: {
    HamburgerMenuComponent,
  },
  data() {
    return {
      loadingBtn: false,
      search: '',
      userFullName: '',
      userPost: '',
      currentUserId: '',
      isDemo: null,
      demoDays: null,
      modalAddMessageSupport: false,
      fileList: [],
      supportRequest: {
        problemSubject: '',
        problemDescription: '',
        problemFiles: [],
      },
      // searchModule: '',
      // modules: [],
      // mainRoutes: [],
      // settingsRoutes: [],
      // listModules: [],
      worktableShow: false,
      patientsShow: false,
      organizationsShow: false,
      scheduleShow: false,
      invoicesShow: false,
      settingsShow: false,
    };
  },
  created() {
    let currentUser = AuthManager.getUser();
    this.currentDoctorId = AuthManager.getUser().idDoctor;
    this.userFullName = currentUser.fullname;
    this.userPost = currentUser.post;
    this.isDemo = currentUser.isDemo;
    this.demoDays = currentUser.demoDays;
    // this.mainRoutes = this.$router.options.routes
    //   .find((x) => x.name == `root`)
    //   .children.filter((x) => x.nameRu != null);
    // this.settingsRoutes = this.mainRoutes
    //   .find((x) => x.name == `settings`)
    //   .children.filter((x) => x.nameRu);
    // this.listModules = this.mainRoutes.concat(this.settingsRoutes);
    this.worktableShow = currentUser.roles.find((x) => x == 'view_dashboard');
    this.patientsShow = currentUser.roles.find((x) => x == 'view_patient');
    this.organizationsShow = currentUser.roles.find(
      (x) => x == 'view_organization'
    );
    this.scheduleShow = currentUser.roles.find((x) => x == 'view_schedule');
    this.invoicesShow = currentUser.roles.find((x) => x == 'view_invoice');
    this.settingsShow = currentUser.roles.find((x) => x == 'view_directory');
  },
  mounted() {
    this.$store.dispatch(actionTypes.getDocumentsForPrint);
    // this.modules = this.loadAll();

    //console.log(document.querySelector(".el-menu").style.display)
    /* let popup = document.querySelector('.el-menu--inline');
    document.addEventListener('mousedown', function(e){
        if(e.target.closest('.el-menu--inline') === null){
            popup.style.display = 'none'; 
        }
    }); */
  },
  methods: {
    async logOut() {
      await AuthManager.singOut();
      this.$router.go('/');
    },
    async handleRemove(file) {
      this.supportRequest.problemFiles.splice(file);
    },
    beforeRemove(file) {
      return this.$confirm(`Cancel the transfert of ${file.name} ?`);
    },
    async handleCloseModalAddMessageSupport() {
      this.$confirm(
        'Вы действительно хотите закрыть это окно?',
        'Подтверждение действия',
        {
          confirmButtonText: 'Подтвердить',
          cancelButtonText: 'Отменить',
          type: 'warning',
        }
      ).then(async () => {
        this.$message({
          type: 'info',
          message: 'Действие отменено',
        });
        this.modalAddMessageSupport = false;
      });
    },
    handleOpenModalAddMessageSupport() {
      (this.supportRequest = {
        problemSubject: '',
        problemDescription: '',
        problemFiles: [],
      }),
        (this.fileList = []);
      this.modalAddMessageSupport = true;
    },
    onUploadChange(file) {
      const isIMAGE =
        file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
      const isLt1M = file.size / 5 / 1024 / 1024 < 1;

      if (!isIMAGE) {
        this.$message.error(
          'Возможна загрузка файлов только в форматах jpg/png!'
        );
        return false;
      }
      if (!isLt1M) {
        this.$message.error('Возможна загрузка файла не более 5 MB!');
        return false;
      }
      var reader = new FileReader();
      reader.readAsDataURL(file.raw);

      reader.onload = function () {
        file.raw.problemFile = reader.result;
      };
      this.fileList.push(file);
    },
    handleExceed() {
      this.$message.warning(`Невозможно приложить более 3-х файлов!`);
    },
    async handleSendRequest() {
      this.loadingBtn = true;
      if (
        this.supportRequest.problemSubject != '' &&
        this.supportRequest.problemDescription
      ) {
        this.supportRequest.problemFiles = this.fileList.map((x) => {
          return { problemFileName: x.name, problemFile: x.raw.problemFile };
        });
        let result = await SupportRequestManager.sendSupportRequest(
          this.supportRequest
        );
        if (result.error) {
          this.$message.warning(
            `Ваше обращение не было отправлено. Пожалуйста, попробуйте позже.`
          );
        } else {
          this.$message.success(
            `Ваше обращение отправлено. В ближайщее время с вами свяжется сотрудник технической поддержки`
          );
          this.modalAddMessageSupport = false;
          this.loadingBtn = false;
        }
      } else {
        this.$message.warning('Заполните обязательные поля!');
        this.loadingBtn = false;
      }
    },
    // querySearch(queryString, cb) {
    //   var modules = this.modules;
    //   var results = queryString
    //     ? modules.filter(this.createFilter(queryString))
    //     : modules;
    //   // call callback function to return suggestions
    //   cb(results);
    // },
    // createFilter(queryString) {
    //   return (link) => {
    //     return (
    //       link.nameRu.toLowerCase().indexOf(queryString.toLowerCase()) === 0
    //     );
    //   };
    // },
    // loadAll() {
    //   return this.listModules;
    // },
    // handleSelect(item) {
    //   this.$router.push(item.path);
    // },
  },
};
</script>
