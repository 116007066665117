import {store} from "../store";

class PositionTypeManager {
  async getPositionTypes() {
    if(store.getters.positionTypes.length == 0){
      await store.dispatch('GET_POSITIONTYPES');
    }
    return store.getters.positionTypes;
  }
  async addPositionType(positionType) {
    await store.dispatch('SAVE_POSITIONTYPE', positionType);
    return store.getters.positionTypes;
  }
  async updatePositionType(positionType) {
    await store.dispatch('UPDATE_POSITIONTYPE', positionType);
    return store.getters.positionTypes;
  }
  async deletePositionType(positionType) {
    await store.dispatch('DELETE_POSITIONTYPE', positionType);
    return store.getters.positionTypes;
  }


}

export default new PositionTypeManager();
