<template>
  <div class="education-main">
    <el-row :gutter="24">
      <el-col :lg="18" class="d-flex flex-between flex-center mb-16 flex-wrap">
        <h4 class="mt-16">Формирование счета на оказанные услуги</h4>
        <el-button class="mt-16" size="small" @click="openModalVideo" type="primary"
          >Смотреть видео<i class="el-icon-play"></i
        ></el-button>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt-16">
      <el-col :lg="18">
        <p>
          Формирование счетов в системе Documeds происходит автоматически после
          завершения врачом приема пациента.
          Счета доступны в разделе «Счета» в основном меню и в 
          виджете «Счета клиники сегодня» на рабочем столе пользователям с ролями 
          «Регистратор» и «Администратор системы». 
          Счету возможно присвоить статус «Оплачен» следующими способами:
          <ul>
            <li>
              В виджете «Счета клиники сегодня» в разделе «Рабочий стол» при нажатии на кнопку 
              «Оплатить счет». Далее во всплывающем окне необходимо выбрать требуемый тип оплаты из 
              предложенных в перечне выпадающего списка «Тип оплаты»
            </li>
            <li>
              В разделе «Счета» при нажатии на кнопку «...» в строке с требуемым счетом. В 
              открывшемся меню необходимо выбрать действие «Оплатить». Далее во всплывающем окне 
              необходимо выбрать требуемый тип оплаты из предложенных в перечне 
              выпадающего списка «Тип оплаты»
            </li>
          </ul>
        
        </p>
        <el-image
          class="mt-16 mb-16"
          fit="contain"
          :src="src.url1"
          :preview-src-list="srcList">
        </el-image>
        <p>
          Возможны ручная коррекция и формирование нового счета:
        </p>
          <ul>
            <li>
              <p>
          В случае, если требуется ручная коррекция счета, необходимо в разделе «Счета» 
          нажать на кнопку «...» в строке с требуемым счетом. В открывшемся меню 
          необходимо выбрать действие «Редактировать». Далее во всплывающем окне 
          можно внести требуемые правки в счет. Для редактирования доступны поля: 
          «Статус оплаты», «Тип оплаты» и «Услуги».
        </p>
        <p>
          В блок «Услуги» можно как добавить 
          новую услугу, так и удалить. Для того, чтобы добавить услугу, необходимо в поле 
          «Услуги» выбрать наименование услуги из прайс-листа Вашей клиники. При вводе 
          значений в данное поле система предлагает к выбору совпадающие по маске наименования услуг. 
          Для того, чтобы удалить услугу, необходимо нажать на символ удаления в столбце «Действия» 
          в строке с требуемой услугой 
        </p>
      </li>
            <li>
        <p>
          В случае, если требуется формирование нового счета, необходимо в разделе «Счета» 
          нажать на кнопку «Добавить счет». Далее во всплывающем окне 
          можно внести требуемую информацию по формируемому счету. Для редактирования доступны поля: 
          «Дата формирования», «Статус оплаты», «Тип оплаты», «Пациент», «Филиал» и «Услуги».
        </p>
        <p>
          В поле «Пациент» можно добавить пацента, которому будет выставлен счет. При вводе 
          значения в данное поле система сопоставляет его по маске с ФИО пациентов Вашей клиники и предлагает 
          к выбору пациентов с совпавшими данными. 
        </p>
        <p>
          В поле «Филиал» можно выбрать требуемый филиал Вашей клиники, к которому будет относиться данный счет.
        </p>
        <p>
          В блок «Услуги» можно как добавить 
          новую услугу, так и удалить. Для того, чтобы добавить услугу, необходимо в поле 
          «Услуги» выбрать наименование услуги из прайс-листа Вашей клиники. При вводе 
          значений в данное поле система предлагает к выбору совпадающие по маске наименования услуг. 
          Для того, чтобы удалить услугу, необходимо нажать на символ удаления в столбце «Действия» 
          в строке с требуемой услугой 
        </p>
      </li>
      </ul>
        <el-image
          class="mt-16 mb-16"
          fit="contain"
          :src="src.url2"
          :preview-src-list="srcList">
        </el-image>
        <p>
          Применить внесенные в счет изменения можно при нажатии на кнопку «Сохранить».
        </p>
      </el-col>
    </el-row>
    <!-- модальное окно просмотра видео-урока -->
    <el-dialog
      title="Видео-урок"
      class="modal-play-video"
      :visible.sync="modalVideo"
      width="100%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true">
      <div v-if="!idVideo">
        <h6>К сожалению видео-урок не доступен</h6>
        <p>
          В скором времени мы добавим данный видео-урок по обучению работе в
          Documeds
        </p>
      </div>
      <div v-if="idVideo">
        <VideoPlayerComponent :idVideo="idVideo"></VideoPlayerComponent>
      </div>
      <el-button
        class="education-modal-btn"
        type="primary"
        @click="openModalVideo"
        >Продолжить обучение в текстовом формате</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
  import VideoPlayerComponent from "@/components/VideoPlayerComponent.vue";
  export default {
    components: { VideoPlayerComponent },
    data() {
      return {
        modalVideo: false,
        idVideo: "cWzyXCbaNJQ",
        src: {
          url1: require("@/assets/img/education/add-invoices-1.png"),
          url2: require("@/assets/img/education/add-invoices-2.png"),
        },
        srcList: [
          require("@/assets/img/education/add-invoices-1.png"),
          require("@/assets/img/education/add-invoices-2.png"),
        ],
      };
    },
    methods: {
      openModalVideo() {
        this.modalVideo = !this.modalVideo;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
