<template>
  <div id="sidemenu">
    <button
      class="sidemenu-btn"
      v-on:click="navOpen = !navOpen"
      v-bind:class="{ active: navOpen }"
    >
      <span class="top"></span>
      <span class="mid"></span>
      <span class="bottom"></span>
    </button>
    <transition name="translateX">
      <nav v-show="navOpen">
        <div class="sidemenu-wrapper">
          <ul class="sidemenu-list">
            <li class="sidemenu-item" @click="chooseTab" v-if="worktableShow">
              <router-link class="sidemenu-dropdown-link" to="/dashboard">
                <i class="el-icon-web-grid-alt"></i>
                Рабочий стол</router-link
              >
            </li>
            <li class="sidemenu-item" @click="chooseTab" v-if="patientsShow">
              <router-link class="sidemenu-dropdown-link" to="/patients">
                <i class="el-icon-user"></i>
                Пациенты</router-link
              >
            </li>
            <li
              class="sidemenu-item"
              @click="chooseTab"
              v-if="organizationsShow"
            >
              <router-link class="sidemenu-dropdown-link" to="/organizations">
                <i class="el-icon-bag-alt"></i>
                Организации</router-link
              >
            </li>
            <li class="sidemenu-item" @click="chooseTab" v-if="scheduleShow">
              <router-link class="sidemenu-dropdown-link" to="/schedule">
                <i class="el-icon-calender"></i>
                Расписание</router-link
              >
            </li>
            <li class="sidemenu-item" @click="chooseTab" v-if="invoicesShow">
              <router-link class="sidemenu-dropdown-link" to="/invoices">
                <i class="el-icon-clipboard-alt"></i>
                Счета</router-link
              >
            </li>
            <li class="sidemenu-item" @click="chooseTab" v-if="true">
              <router-link class="sidemenu-dropdown-link" to="/analytics">
                <i class="el-icon-chart-pie-alt"></i>
                Аналитика</router-link
              >
            </li>
            <li class="sidemenu-item" @click="chooseTab" v-if="settingsShow">
              <router-link class="sidemenu-dropdown-link" to="/settings">
                <i class="el-icon-setting"></i>
                Настройки</router-link
              >
            </li>
            <li class="sidemenu-item" @click="chooseTab">
              <router-link class="sidemenu-dropdown-link" to="/education">
                <i class="el-icon-graduation-cap"></i>
                Обучение</router-link
              >
            </li>
          </ul>
        </div>
      </nav>
    </transition>
  </div>
</template>

<style>
/* .activeTab {
  background: #eff0ff;
  color: #293be2;
} */
</style>

<script>
import AuthManager from '@/services/authManager';

export default {
  name: 'HamburgerMenuComponent',
  data() {
    return {
      navOpen: false,
      worktableShow: false,
      patientsShow: false,
      organizationsShow: false,
      scheduleShow: false,
      invoicesShow: false,
      settingsShow: false,
    };
  },
  async created() {
    const currentUser = AuthManager.getUser();
    this.worktableShow = currentUser.roles.find((x) => x == 'view_dashboard');
    this.patientsShow = currentUser.roles.find((x) => x == 'view_patient');
    this.organizationsShow = currentUser.roles.find(
      (x) => x == 'view_organization'
    );
    this.scheduleShow = currentUser.roles.find((x) => x == 'view_schedule');
    this.invoicesShow = currentUser.roles.find((x) => x == 'view_invoice');
    this.settingsShow = currentUser.roles.find((x) => x == 'view_directory');
  },
  methods: {
    chooseTab() {
      this.navOpen = !this.navOpen;
    },
  },
};
</script>
