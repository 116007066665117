<template>
  <div v-if="actualLink">
    <el-row class="h-100vh">
      <el-col :lg="12" class="h-100 login-right">
        <el-col :lg="12" :md="12" :span="12" class="login-form-wrap">
          <div class="login-welcome">
            <span class="login-name">
              Ваш логин: <b>{{ userLoginFromLink }}</b>
            </span>
            <h6>Придумайте пароль</h6>

            <span
              >Укажите пароль, который будет использоваться для входа в систему.
            </span>
          </div>
          <el-form
            class="login-form"
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            :hide-required-asterisk="true">
            <el-form-item prop="userPassword">
              <el-input
                prefix-icon="el-icon-lock"
                placeholder="Введите пароль"
                v-model="ruleForm.userPassword"
                show-password>
              </el-input>
              <el-tooltip effect="light" placement="right-start">
                <div slot="content">
                  <b>Параметры пароля</b>
                  <p>Ваш пароль минимум должен содержать:</p>
                  <ul>
                    <li>8 латинских букв</li>
                    <li>1 заглавную букву</li>
                    <li>1 цифру</li>
                    <li>1 спецсимвол (! № _ } | : и т.д.)</li>
                  </ul>
                </div>
                <i class="login-password-toolip el-icon-question-circle"></i>
              </el-tooltip>
            </el-form-item>
            <el-form-item prop="userPasswordConfirm">
              <el-input
                prefix-icon="el-icon-lock"
                placeholder="Введите подтверждение пароля"
                v-model="ruleForm.userPasswordConfirm"
                show-password>
              </el-input>
            </el-form-item>

            <el-form-item>
              <el-button
                class="login-btn"
                type="primary"
                :loading="loadingBtn"
                @click="submitForm('ruleForm')"
                >Продолжить</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
        <div class="login-help">
          <span
            >Проблемы со входом? Обратитесь в службу
            <a href="#">технической поддержки</a></span
          >
        </div></el-col
      >
      <el-col :lg="12" class="h-100 login-left">
        <div class="login-logo">
          <span>Медицинская информационная система</span>
          <img src="../assets/logo.svg" alt="Documeds" />
        </div>
        <span class="login-brand"
          >© Все права защищены. ООО «ЦИТ-Рязань», 2023 г.
        </span>
      </el-col>
    </el-row>
  </div>

  <div v-else>
    <!-- Блок показывается если ссылка устарела -->
    <div class="notfound-wrap w-100 h-100vh">
      <el-container class="h-100">
        <el-row :gutter="24" type="flex" justify="center" class="w-100 h-100">
          <el-col :xl="12" :lg="12" :md="16" :sm="24" class="notfound-col-flex">
            <div class="notfound-logo">
              <img src="../assets/logo-menu-blue.svg" alt="Documeds" />
            </div>
            <div class="notfound-main">
              <h2>Ссылка неактивна</h2>
              <p>
                Вы попали на эту страницу, потому что прошло
                <b>более 24 часов</b> с момента запроса вами демо-доступа в
                систему Documeds. Пожалуйста, перейдите на сайт
                <a href="https://documeds.ru"><b>Documeds</b></a>
                и повторно заполните заявку на демо-доступ.
              </p>
            </div>
            <div class="notfound-support">
              <p class="notfound-support-text">
                Возникли вопросы или нашли ошибку? Обратитесь в службу
                <a href="mailto:contacts@documeds.ru">технический поддержки</a>
                или позвоните по телефону +7-4912-95-85-52
              </p>
            </div>
          </el-col>
        </el-row>
      </el-container>
    </div>
  </div>
</template>

<script>
  import RegistrationManager from "@/services/registrationManager.js";

  export default {
    data() {
      const regularForPass =
        /(?=.*[0-9])(?=.*[!@#$%^&*()+=_/|?<>`~"',.:;№{}[\]-])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*()+=_/|?<>`~"',.:;№{}[\]-]{8,}/g;
      var validateUserPassword = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("Введите пароль"));
        } else if (regularForPass.test(value) != true) {
          callback(
            new Error(
              "Пароль должен состоять из минимум 8 латинских заглавных и строчных букв, а также содержать специальные символы: !@#$%^&*()"
            )
          );
        } else {
          if (this.ruleForm.userPasswordConfirm !== "") {
            this.$refs.ruleForm.validateField("userPasswordConfirm");
          }
          callback();
        }
      };
      var validateUserPasswordConfirm = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("Введите подтверждение пароля"));
        } else if (value !== this.ruleForm.userPassword) {
          callback(new Error("Пароли не совпадают"));
        } else {
          callback();
        }
      };
      return {
        loadingBtn: false,
        userLoginFromLink: "",
        ruleForm: {
          userPassword: "",
          userPasswordConfirm: "",
        },
        rules: {
          userPassword: [
            { validator: validateUserPassword, trigger: "validate" },
          ],
          userPasswordConfirm: [
            { validator: validateUserPasswordConfirm, trigger: "validate" },
          ],
        },
        actualLink: true,
      };
    },
    async created() {
      let currentUuid = this.$route.params.uuid;
      let demoAccessInfo = await RegistrationManager.getDemoAccessInfo(
        currentUuid
      );
      this.actualLink = demoAccessInfo.error ? false : true;
      this.userLoginFromLink = demoAccessInfo.data;
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            this.loadingBtn = true;
            try {
              await RegistrationManager.setPassword({
                identifier: this.$route.params.uuid,
                password: this.ruleForm.userPassword,
              });
              this.$router.push("/");
            } catch (error) {
              this.loadingBtn = false;
              if (error.response.status == 400) {
                this.$message({
                  type: "error",
                  message: "Ошибка!",
                });
              }
            }
          }
        });
      },
    },
  };
</script>
