<template>
  <div class="settings-page d-flex flex-column directory" v-loading="loading">
    <div class="directory-header">
      <h6>Справочник заболеваний МКБ-10</h6>
      <el-input
        class="directory-search"
        size="small"
        prefix-icon="el-icon-search"
        placeholder="Найти диагноз/заболевание"
        v-model="searchMKB"
        @input="handleRowSearch">
      </el-input>
    </div>
    <el-table
      :data="MKBData"
      style="width: 100%"
      stripe
      class="directory-table">
      <el-table-column prop="code" label="Код заболевания" width="180">
      </el-table-column>
      <el-table-column prop="name" label="Диагноз/заболевание">
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-sizes="pageSizes"
      :page-size="currentSize"
      layout="sizes, prev, pager, next"
      :total="tableTotal"
      class="mt-20">
    </el-pagination>
  </div>
</template>

<script>
  import MkbManager from "@/services/mkbManager.js";

  const itemsPerPage = [10, 20, 30];
  export default {
    loading: false,
    data() {
      return {
        searchMKB: "",
        currentSize: itemsPerPage[0],
        pageSizes: itemsPerPage,
        currentPage: 1,
        MKBData: [],
        tableTotal: "",
      };
    },
    async mounted() {
      this.loading = true;
      let MKBDataFull = await MkbManager.getMkb(1, itemsPerPage[0], null);
      this.MKBData = MKBDataFull.mkb;
      this.tableTotal = MKBDataFull.rowCount;
      this.loading = false;
    },
    methods: {
      async handleRowSearch() {
        let MKBDataFull = await MkbManager.getMkb(
          1,
          this.currentSize,
          this.searchMKB
        );
        this.MKBData = MKBDataFull.mkb;
        this.tableTotal = MKBDataFull.rowCount;
      },
      async handleSizeChange(val) {
        this.currentSize = val;
        let MKBDataFull = await MkbManager.getMkb(
          this.currentPage,
          this.currentSize,
          this.searchMKB
        );
        this.MKBData = MKBDataFull.mkb;
        this.tableTotal = MKBDataFull.rowCount;
      },
      async handleCurrentChange(val) {
        this.currentPage = val;
        let MKBDataFull = await MkbManager.getMkb(
          this.currentPage,
          this.currentSize,
          this.searchMKB
        );
        this.MKBData = MKBDataFull.mkb;
        this.tableTotal = MKBDataFull.rowCount;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
