<template>
  <div class="education-main">
    <el-row :gutter="24">
      <el-col :lg="18" class="d-flex flex-between flex-center mb-16 flex-wrap">
        <h4 class="mt-16">Раздел «Рабочий стол»</h4>
        <el-button class="mt-16" size="small" @click="openModalVideo" type="primary"
          >Смотреть видео<i class="el-icon-play"></i
        ></el-button>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt-16">
      <el-col :lg="18">
        <p>
          Для удобства отображения базовой необходимой информации сформирован
          раздел «Рабочий стол», в котором представлен набор виджетов с
          актуальными данными, информацией о текущей дате, возможностью выбора
          филиала и бегущей строкой. Разные виджеты доступны пользователям с
          различными ролями. Администратору системы и регистратору доступны все
          виджеты. Врачу доступны все виджеты за исключением виджета «Счета» и
          отстутсвует информация о счетах в бегущей строке. Медсестре доступны
          все виджеты за исключением виджетов «Счета» и отстутсвует информация о
          счетах в бегущей строке. В то же время виджет «Мое расписание сегодня»
          доступен только тем пользователям, у которых есть хотя бы одно
          действующее исполнение должности с признаком «Ведет прием».
        </p>
        <el-image
          class="mt-16 mb-16"
          fit="contain"
          :src="src.url1"
          :preview-src-list="srcList">
        </el-image>
        <p>
          Вся информация в наборе виджетов и в бегущей строке формируется в
          рамках выбранного филиала.
        </p>
        <p>
          Виджет «Сегодня ведут прием» позволяет просмотреть список врачей,
          которые сегодня ведут прием. Кнопка «Показать всех врачей» переместит
          Вас в раздел «Расписание».
        </p>
        <el-image
          class="mt-16 mb-16"
          fit="contain"
          :src="src.url2"
          :preview-src-list="srcList">
        </el-image>
        <p>
          Виджет «Мое расписание сегодня» отображает записи пациентов на текущую
          дату к текущему пользователю. Из этого виджета возможен быстрый
          переход в прием пациента, таким образом Вам не придется искать его в
          расписании.
        </p>
        <el-image
          class="mt-16 mb-16"
          fit="contain"
          :src="src.url3"
          :preview-src-list="srcList">
        </el-image>
        <p>
          Виджет «Статистика приемов» отображает суммарную информацию по приемам
          в разрезе их статусов за текущий день.
        </p>
        <el-image
          class="mt-16 mb-16"
          fit="contain"
          :src="src.url4"
          :preview-src-list="srcList">
        </el-image>
        <p>
          Виджет «Счета» отображает список неоплаченных счетов, сформированных
          за текущий день. Из виджета возможна быстрая оплата.
        </p>
        <el-image
          class="mt-16 mb-16"
          fit="contain"
          :src="src.url5"
          :preview-src-list="srcList">
        </el-image>
        <p>
          Набор виджетов рабочего стола настраиваемый, он может быть дополнен и
          скорректирован по запросу медицинской организации.
        </p>
      </el-col>
    </el-row>
    <!-- модальное окно просмотра видео-урока -->
    <el-dialog
      title="Видео-урок"
      class="modal-play-video"
      :visible.sync="modalVideo"
      width="100%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true">
      <div v-if="!idVideo">
        <h6>К сожалению видео-урок не доступен</h6>
        <p>
          В скором времени мы добавим данный видео-урок по обучению работе в
          Documeds
        </p>
      </div>
      <div v-if="idVideo">
        <VideoPlayerComponent :idVideo="idVideo"></VideoPlayerComponent>
      </div>
      <el-button
        class="education-modal-btn"
        type="primary"
        @click="openModalVideo"
        >Продолжить обучение в текстовом формате</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
  import VideoPlayerComponent from "@/components/VideoPlayerComponent.vue";
  export default {
    components: { VideoPlayerComponent },
    data() {
      return {
        modalVideo: false,
        idVideo: "jzBrWWxJ-tg",
        src: {
          url1: require("@/assets/img/education/dashboard-1.png"),
          url2: require("@/assets/img/education/dashboard-2.png"),
          url3: require("@/assets/img/education/dashboard-3.png"),
          url4: require("@/assets/img/education/dashboard-4.png"),
          url5: require("@/assets/img/education/dashboard-5.png"),
        },
        srcList: [
          require("@/assets/img/education/dashboard-1.png"),
          require("@/assets/img/education/dashboard-2.png"),
          require("@/assets/img/education/dashboard-3.png"),
          require("@/assets/img/education/dashboard-4.png"),
          require("@/assets/img/education/dashboard-5.png"),
        ],
      };
    },
    methods: {
      openModalVideo() {
        this.modalVideo = !this.modalVideo;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
