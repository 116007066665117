import servicesApi from '@/api/services';
const servicesModule = {
  state: () => ({
    priceList: [], // все услуги из прайс-листа организации
    priceListByPage: [],
    defaultPriceListItem: {
      id: null,
      idPositionType: null,
      idCategory: null,
      positionTypeName: null,
      categoryName: null,
      orgName: null,
      typeName: null,
      dateFrom: new Date(),
      dateTo: null,
      price: 0,
      defaultDuration: 5,
      clientOffset: null,
      isUsed: false,
      medicalServiceCurrentOrganization: {
        id: null,
        name: null,
        idMedicalServiceType: null,
        medicalServiceType: {
          id: null,
          code: null,
          name: '',
        },
      },
    },
    durationList: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
    medicalServiceList: [],
  }),
  getters: {
    // возвращаем все услуги из прайс-листа организации
    priceList: (state) => {
      return state.priceList;
    },
    priceListByPage: (state, rootGetters) => {
      let currentPage = rootGetters.pagination.currentPage;
      let currentSize = rootGetters.pagination.currentSize;
      return state.priceList.slice(
        currentSize * currentPage - currentSize,
        currentSize * currentPage
      );
    },
    // возвращаем все услуги из прайс-листа активные на текущую дату
    servicesList: (state) => {
      return state.priceList.filter((s) => s.dateTo === null);
    },
    // возвращаем пустую услугу по умолчанию
    defaultPriceListItem: (state) => {
      return state.defaultPriceListItem;
    },
    medicalServiceList: (state) => {
      return state.medicalServiceList;
    },
    durationList: (state) => {
      return state.durationList;
    },
  },
  mutations: {
    SET_PRICE_LIST: (state, payload) => {
      state.priceList = payload;
    },
    SET_MEDICAL_SERVICE_FROM_DICTIONARY: (state, payload) => {
      state.medicalServiceList = payload;
    },
  },
  actions: {
    getPriceList: async (context) => {
      const priceList = await servicesApi.getPriceList();
      context.commit('SET_PRICE_LIST', priceList.data);
    },
    createPriceListItem: async (context, priceListItem) => {
      return await servicesApi.createPriceListItem(priceListItem);
    },
    changePriceListItem: async (context, priceListItem) => {
      return await servicesApi.updatePriceListitem(priceListItem);
    },
    deletePriceListItem: async (context, id) => {
      return await servicesApi.deletePriceListItem(id);
    },
    getMedicalServiceFromDictionary: async (context, params) => {
      const medicalServiceList =
        await servicesApi.getMedicalServiceFromDictionary(params);
      context.commit(
        'SET_MEDICAL_SERVICE_FROM_DICTIONARY',
        medicalServiceList.data.value.value
      );
    },
  },
};

export default servicesModule;
