import { store } from '../store';
import { getAge } from '../services/commonService';
import ClientOrganizationManager from '../services/clientOrganizationManager';
// import FilialManager from '../services/filialManager.js';
import AuthManager from '../services/authManager';
import { getDateString } from '../services/commonService';
import dateTimeService from './dateTimeService';
class ScheduleManager {
  schedulesStore = [];

  getScheduleInfo(id) {
    return store.getters.schedules.find((x) => x.id == id);
  }
  async getScheduleInfoByAppointmentId(id) {
    if (store.getters.schedules.length == 0) {
      await store.dispatch('GET_SCHEDULES');
    }
    return store.getters.schedules.find((x) =>
      x.appointments.find((a) => a.id == id)
    );
  }

  async getSchedule(filter, step) {
    let scheduleColumnsLocal = [];
    let scheduleTableLocal = [];

    let formatter = new Intl.DateTimeFormat('ru-RU', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
    let formatterTime = new Intl.DateTimeFormat('ru-RU', {
      hour: 'numeric',
      minute: 'numeric',
    });
    //получаем данные с помощью vuex
    this.schedulesStore = store.getters.schedules;
    //фильтруем полученный список по признаку Ведет прием
    this.schedulesStore = this.schedulesStore.filter((x) => x.hasSchedule);

    //фильтруем полученный список по дате
    this.schedulesStore = this.schedulesStore.filter(
      (x) =>
        (new Date(x.dateTimeFrom).getTime() == filter.dateRange[0].getTime() ||
          new Date(x.dateTimeFrom).getTime() > filter.dateRange[0].getTime()) &&
        (new Date(x.dateTimeTo).getTime() ==
          filter.dateRange[1].getTime() + 1440 * 60000 ||
          new Date(x.dateTimeTo).getTime()) <
          filter.dateRange[1].getTime() + 1440 * 60000
    );
    //фильтруем по кабинету
    this.schedulesStore =
      filter.cabinet.length > 0
        ? this.schedulesStore.filter((x) =>
            x.cabinets.find((c) => filter.cabinet.find((f) => f == c.idCabinet))
          )
        : this.schedulesStore;
    //фильтруем по филиалу
    this.schedulesStore =
      filter.filial.length > 0
        ? this.schedulesStore.filter((x) =>
            x.cabinets.find((c) => filter.filial.find((f) => f == c.idFilial))
          )
        : this.schedulesStore;
    //фильтруем по подразделению
    this.schedulesStore =
      filter.department.length > 0
        ? this.schedulesStore.filter((x) =>
            filter.department.find((f) => f == x.idDepartment)
          )
        : this.schedulesStore;
    //фильтруем по врачу
    this.schedulesStore =
      filter.doctor.length > 0
        ? this.schedulesStore.filter((x) =>
            filter.doctor.find((f) => f.id == x.idDoctor)
          )
        : this.schedulesStore;

    //устанавливаем временной шаг
    let timeStep = step ? step : 5;

    //устанавливаем границы расписания
    let clientOrganizationInfo =
      await ClientOrganizationManager.getClientOrganization();
    let startTime;
    let endTime;

    if (clientOrganizationInfo.hourOpen == null) {
      clientOrganizationInfo.hourOpen = 8;
    }
    if (clientOrganizationInfo.minuteOpen == null) {
      clientOrganizationInfo.minuteOpen = 0;
    }
    if (clientOrganizationInfo.hourClose == null) {
      clientOrganizationInfo.hourClose = 20;
    }
    if (clientOrganizationInfo.minuteClose == null) {
      clientOrganizationInfo.minuteClose = 0;
    }

    startTime = new Date(
      '0000-01-01 ' +
        clientOrganizationInfo.hourOpen.toString().padStart(2, '0') +
        ':' +
        clientOrganizationInfo.minuteOpen.toString().padStart(2, '0') +
        ':00'
    );
    endTime = new Date(
      '0000-01-01 ' +
        clientOrganizationInfo.hourClose.toString().padStart(2, '0') +
        ':' +
        clientOrganizationInfo.minuteClose.toString().padStart(2, '0') +
        ':00'
    );
    //получаем список уникальных дат
    let scheduledDates = [];
    let i;
    let j;
    for (i = 0; i <= this.schedulesStore.length - 1; i++) {
      if (
        scheduledDates.indexOf(
          this.schedulesStore[i].dateTimeFrom.substring(0, 10)
        ) < 0
      ) {
        scheduledDates.push(
          this.schedulesStore[i].dateTimeFrom.substring(0, 10)
        );
      }
    }
    //формируем список заголовков таблицы расписания
    for (i = 0; i <= scheduledDates.length - 1; i++) {
      let doctorList = [];
      for (j = 0; j <= this.schedulesStore.length - 1; j++) {
        if (
          scheduledDates[i] ==
          this.schedulesStore[j].dateTimeFrom.substring(0, 10)
        ) {
          let doctorFullName =
            this.schedulesStore[j].doctorLastName +
            ' ' +
            this.schedulesStore[j].doctorFirstName.substring(0, 1) +
            '. ' +
            this.schedulesStore[j].doctorPatronymic.substring(0, 1) +
            '. ';
          let columnName = this.schedulesStore[j].id.toString();
          let columnNoteName = 'n' + this.schedulesStore[j].id.toString();
          doctorList.push({
            name: doctorFullName,
            info: [
              {
                position: this.schedulesStore[j].doctorPosition,
                prop: columnName,
                propNote: columnNoteName,
              },
            ],
          });
        }
      }
      let scheduleDate = getDateString(new Date(scheduledDates[i]));
      let scheduledWeekday = dateTimeService
        .getWeekdayFromDate(new Date(scheduledDates[i]), 'short')
        .toUpperCase();
      scheduleColumnsLocal.push({
        date: `${scheduleDate} (${scheduledWeekday})`,
        doctors: doctorList,
      });
    }
    //формируем данные для ячеек таблицы расписания
    let k = -1;
    let appointedPatient;
    let appointmentStatus;
    let appointmentId;
    let appointmenRowToSpan;
    let appointmentDateTimeFrom;
    let appointmentDateTimeTo;
    for (
      i = startTime;
      i <= endTime;
      i = new Date(i.getTime() + timeStep * 60000)
    ) {
      // let stepTimeFull = formatterTime.format(i).toString();
      // let stepTimeFull =
      //   formatterTime.format(i).toString().substring(3) === '00'
      //     ? formatterTime.format(i).toString()
      //     : formatterTime.format(i).toString().substring(2);
      scheduleTableLocal.push({
        time: {
          hours: `${i.getHours().toString().padStart(2, '0')}`,
          minutes: `${i.getMinutes().toString().padStart(2, '0')}`,
          startHour:
            i.getMinutes().toString().padStart(2, '0') === '00' ? true : false,
        },
      });
      k++;
      for (j = 0; j <= this.schedulesStore.length - 1; j++) {
        let sheduleFrom = formatter.format(
          new Date(this.schedulesStore[j].dateTimeFrom)
        );
        let sheduleFromOnlyTime = new Date(
          sheduleFrom.replace(sheduleFrom.substring(0, 12), '0000-01-01 ')
        );
        let sheduleTo = formatter.format(
          new Date(this.schedulesStore[j].dateTimeTo)
        );
        let sheduleToOnlyTime = new Date(
          sheduleTo.replace(sheduleTo.substring(0, 12), '0000-01-01 ')
        );

        let iTime = i.getTime();
        let scheduleStart = sheduleFromOnlyTime.getTime();
        let scheduleEnd = sheduleToOnlyTime.getTime();

        if (
          (iTime === scheduleStart || iTime > scheduleStart) &&
          iTime < scheduleEnd
        ) {
          let l;
          appointedPatient = null;
          appointmentStatus = null;
          appointmentId = null;
          appointmenRowToSpan = null;
          appointmentDateTimeFrom = null;
          appointmentDateTimeTo = null;
          let curAppointments = this.schedulesStore[j].appointments.filter(
            (x) => x.idAppointmentStatus != 8
          );
          for (l = 0; l <= curAppointments.length - 1; l++) {
            let appointmentFrom = formatter.format(
              new Date(curAppointments[l].dateTimeFrom)
            );
            let appointmentFromOnlyTime = new Date(
              appointmentFrom.replace(
                appointmentFrom.substring(0, 12),
                '0000-01-01 '
              )
            );
            let appointmentTo = formatter.format(
              new Date(curAppointments[l].dateTimeTo)
            );
            let appointmentToOnlyTime = new Date(
              appointmentTo.replace(
                appointmentTo.substring(0, 12),
                '0000-01-01 '
              )
            );
            if (
              appointmentFromOnlyTime.getTime() === i.getTime() ||
              (appointmentFromOnlyTime > i &&
                appointmentFromOnlyTime <
                  new Date(i.getTime() + timeStep * 60000)) ||
              (appointmentFromOnlyTime < i && appointmentToOnlyTime > i)
            ) {
              appointedPatient = curAppointments[l].patient;
              appointmentStatus = curAppointments[l].idAppointmentStatus;
              appointmentId = curAppointments[l].id;
              appointmentDateTimeFrom = curAppointments[l].dateTimeFrom;
              appointmentDateTimeTo = curAppointments[l].dateTimeTo;
              if (
                appointmentFromOnlyTime.getTime() === i.getTime() ||
                (appointmentFromOnlyTime > i &&
                  appointmentFromOnlyTime <
                    new Date(i.getTime() + timeStep * 60000))
              ) {
                appointmenRowToSpan = Math.ceil(
                  (appointmentToOnlyTime - appointmentFromOnlyTime) /
                    (60000 * timeStep)
                );
              }
            }
          }
          let key = this.schedulesStore[j].id.toString();
          let keyType = `t${key}`;
          let tablerow = scheduleTableLocal[k];
          tablerow[key] =
            appointedPatient == null
              ? '1'
              : `${
                  appointedPatient.lastName
                } ${appointedPatient.firstName[0].toUpperCase()}. ${
                  appointedPatient.patronymic
                    ? appointedPatient.patronymic[0].toUpperCase() + '.'
                    : ''
                }`;
          tablerow[keyType] =
            appointedPatient == null
              ? 0
              : appointmentStatus == 7 // Неявка пациента
              ? 1
              : appointmentStatus == 6 // Прием завершен
              ? 2
              : appointmentStatus == 4 // Пациент подошел
              ? 3
              : 4;

          // вывод кабинетов вместе с другими параметрами appointment
          let keyCabinetChange = 'c' + this.schedulesStore[j].id.toString();
          let cabinetChange = this.schedulesStore[j].cabinets.find(
            (x) => x.cabinetName != ''
          );
          tablerow[keyCabinetChange] =
            cabinetChange == null ? null : cabinetChange.cabinetName;

          if (appointedPatient) {
            let keyNote = 'n' + this.schedulesStore[j].id.toString();
            tablerow[keyNote] = {
              id: appointedPatient.id,
              idDoctor: this.schedulesStore[j].idDoctor,
              appointmentId: appointmentId,
              appointmentStatus: appointmentStatus,
              dateTimeFrom: new Date(
                appointmentDateTimeFrom
              ).toLocaleTimeString('ru-RU', {
                hour: '2-digit',
                minute: '2-digit',
              }),
              dateTimeTo: new Date(appointmentDateTimeTo).toLocaleTimeString(
                'ru-RU',
                {
                  hour: '2-digit',
                  minute: '2-digit',
                }
              ),
              fullName:
                appointedPatient.lastName +
                ' ' +
                appointedPatient.firstName +
                ' ' +
                (appointedPatient.patronymic
                  ? appointedPatient.patronymic
                  : ''),
              phone: appointedPatient.phone,
              birthDate: getDateString(appointedPatient.birthDate),
              residenceAddress: appointedPatient.residenceAddress,
              age: appointedPatient.birthDate
                ? getAge(appointedPatient.birthDate)
                : '',
              physicalPatientCardExists:
                appointedPatient.physicalPatientCardExists,
              rowToSpan: appointmenRowToSpan ? appointmenRowToSpan : null,
            };
          } else {
            let keyNote = 'r' + this.schedulesStore[j].id.toString();
            let slotDateString = this.schedulesStore[j].dateTimeFrom.substring(
              0,
              11
            );
            let sloTimeString = formatterTime.format(new Date(iTime));
            let sloDateTime = new Date(slotDateString + sloTimeString);
            tablerow[keyNote] = {
              dateTime: sloDateTime,
              idSchedule: this.schedulesStore[j].id,
              idDoctor: this.schedulesStore[j].idDoctor,
            };
          }
        } else {
          let key = this.schedulesStore[j].id.toString();
          let tablerow = scheduleTableLocal[k];
          tablerow[key] = '0';
        }
      }
    }

    return {
      scheduleColumns: scheduleColumnsLocal,
      scheduleTable: scheduleTableLocal,
    };
  }

  async getDashboardSchedule(idFilial) {
    //получаем данные с помощью vuex
    let schedules = store.getters.scheduleList;
    let departments = store.getters.departments;

    //фильтруем полученный список по дате
    let currentDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    );
    schedules = schedules.filter(
      (x) =>
        (new Date(x.dateTimeFrom).getTime() == currentDate.getTime() ||
          new Date(x.dateTimeFrom).getTime() > currentDate.getTime()) &&
        (new Date(x.dateTimeTo).getTime() ==
          currentDate.getTime() + 1440 * 60000 ||
          new Date(x.dateTimeTo).getTime()) <
          currentDate.getTime() + 1440 * 60000
    );

    // фильтруем полученный список по филиалу
    schedules = schedules.filter((x) =>
      x.cabinets.find((f) => f.idFilial == idFilial)
    );

    //сопоставляем записи расписания и отделения
    for (let i = 0; i <= departments.length - 1; i++) {
      departments[i].schedules = [];
      for (let j = 0; j <= schedules.length - 1; j++) {
        if (schedules[j].idDepartment == departments[i].id) {
          departments[i].schedules.push({
            idDoctor: schedules[j].idDoctor,
            doctorFullName: `${schedules[j].doctorLastName} ${schedules[j].doctorFirstName}`,
            appointmentsCount: schedules[j].appointments.filter(
              (x) => x.idAppointmentStatus != 8
            ).length,
            appointmentsAllCount: schedules[j].appointments.filter(
              (x) => x.idAppointmentStatus
            ).length,
            appointmentsCompliteCount: schedules[j].appointments.filter(
              (x) => x.idAppointmentStatus == 6
            ).length,
            appointmentsExpectCount:
              schedules[j].appointments.filter(
                (x) => x.idAppointmentStatus != 6
              ).length -
              schedules[j].appointments.filter(
                (x) => x.idAppointmentStatus == 7
              ).length,
            appointmentsCancelCount: schedules[j].appointments.filter(
              (x) => x.idAppointmentStatus == 8
            ).length,
          });
        }
      }
    }
    //удаляем отделения, в которых нет приема
    departments = departments.filter((x) => x.schedules.length > 0);
    return departments;
  }

  async getUserDashboardSchedule() {
    //получаем данные с помощью vuex
    let schedules = store.getters.scheduleList;
    //фильтруем полученный список по дате и текущему пользователю
    let currentUser = AuthManager.getUser();
    let doctorId = currentUser.idDoctor;
    let currentDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    );
    schedules = schedules.filter(
      (x) =>
        (new Date(x.dateTimeFrom).getTime() == currentDate.getTime() ||
          new Date(x.dateTimeFrom).getTime() > currentDate.getTime()) &&
        (new Date(x.dateTimeTo).getTime() ==
          currentDate.getTime() + 1440 * 60000 ||
          new Date(x.dateTimeTo).getTime()) <
          currentDate.getTime() + 1440 * 60000 &&
        x.idDoctor == doctorId
    );
    return schedules;
  }
}

export default new ScheduleManager();
