<template>
  <div class="education-main">
    <el-row :gutter="24">
      <el-col :lg="18" class="d-flex flex-between flex-center mb-16 flex-wrap">
        <h4 class="mt-16">Добавление и редактирование пациента</h4>
        <el-button class="mt-16" size="small" @click="openModalVideo" type="primary"
          >Смотреть видео<i class="el-icon-play"></i
        ></el-button>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt-16">
      <el-col :lg="18">
        <p>
          Для того, чтобы добавить нового пациента в систему, необходимо перейти
          в раздел «Пациенты», который представляет собой сводную таблицу по
          всем пациентам Вашего медицинского центра.
        </p>
        <el-image
          class="mt-16"
          fit="contain"
          :src="src.url1"
          :preview-src-list="srcList">
        </el-image>
        <el-divider></el-divider>
      </el-col>
      <el-col :lg="18">
        <p>
          Помимо вывода стандартных персональных данных пациентов в системе
          Documeds реализована возможность назначения пациентам специальных
          меток, «тэгов», которые позволяют указать ключевые особенности
          пациентов.
        </p>
        <p>
          Доступ к редактированию и удалению пациента возможен через выпадающее
          меню «...» его строки. Также в указанном меню можно просмотреть его
          случаи обращения и список счетов.
        </p>
        <el-image
          class="mt-16"
          fit="cover"
          :src="src.url2"
          :preview-src-list="srcList">
        </el-image>
      </el-col>
      <el-col :lg="18">
        <el-divider></el-divider>
        <p>
          Помимо этого в разделе реализован быстрый поиск пациента по ФИО и
          номеру телефона. Также реализован метод расширенной фильтрации
          пациентов с помощью всплывающего окна «Расширенные фильтры».
        </p>
        <el-image fit="cover" :src="src.url3" :preview-src-list="srcList">
        </el-image>
      </el-col>
      <el-col :lg="18">
        <el-divider></el-divider>
        <p>
          Добавление пациентов возможно двумя способами: вручную и при помощи
          импорта из файла.
        </p>
        <p>
          Для добавления пациента вручную необходимо нажать на кнопку «Добавить»
          в правом верхнем углу экрана, после чего в выпадающем списке выбрать
          пункт «Добавить вручную».
        </p>
      </el-col>
      <el-col :lg="18">
        <el-image
          class="mt-16 mb-16"
          fit="cover"
          :src="src.url4"
          :preview-src-list="srcList">
        </el-image>
        <p>
          Далее необходимо заполнить форму с данными пациента. Обязательные для
          заполнения поля отмечены символом <span class="danger">*</span>.
        </p>
        <p>
          Форма содержит поле «Комментарий», которое позволяет добавить
          произвольную информацию о пациенте.
        </p>
        <p>
          Для применения внесенных изменений необходимо нажать на кнопку
          «Сохранить».
        </p>
        <p>
          Для загрузки списка пациентов из Excel-файла необходимо нажать на
          кнопку «Добавить» в правом верхнем углу экрана, после чего в
          выпадающем списке выбрать пункт «Импорт из файла».
        </p>
        <el-image
          class="mt-16 mb-16"
          fit="cover"
          :src="src.url5"
          :preview-src-list="srcList">
        </el-image>
        <p>
          В открывшемся модальном окне «Импорт пациентов» возможно скачивание
          шаблона Excel-файла для импорта, его загрузка будет произведена при
          нажатии на кнопку «Скачать шаблон». Просим обратить внимание, что в
          шаблон уже внесена одна запись пациента, которая может быть
          использована в качестве примера заполнения файла.
        </p>
        <p>
          Для импорта заполненного файла необходимо нажать на кнопку «Загрузить
          файл с пациентами .xls». Во всплывающем окне «Открытие» нужно выбрать
          требуемое изображение и нажать на кнопку «Открыть». Загрузка файла
          будет произведена при нажатии на кнопку «Применить» в модальном окне
          «Импорт пациентов».
        </p>
      </el-col>
    </el-row>
    <!-- модальное окно просмотра видео-урока -->
    <el-dialog
      title="Видео-урок"
      class="modal-play-video"
      :visible.sync="modalVideo"
      width="100%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true">
      <div v-if="!idVideo">
        <h6>К сожалению видео-урок не доступен</h6>
        <p>
          В скором времени мы добавим данный видео-урок по обучению работе в
          Documeds
        </p>
      </div>
      <div v-if="idVideo">
        <VideoPlayerComponent :idVideo="idVideo"></VideoPlayerComponent>
      </div>
      <el-button
        class="education-modal-btn"
        type="primary"
        @click="openModalVideo"
        >Продолжить обучение в текстовом формате</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
  import VideoPlayerComponent from "@/components/VideoPlayerComponent.vue";
  export default {
    components: { VideoPlayerComponent },
    data() {
      return {
        modalVideo: false,
        idVideo: "HqDARdjFz7A",
        src: {
          url1: require("@/assets/img/education/add-patient-1.png"),
          url2: require("@/assets/img/education/add-patient-2.png"),
          url3: require("@/assets/img/education/add-patient-3.png"),
          url4: require("@/assets/img/education/add-patient-4.png"),
          url5: require("@/assets/img/education/add-patient-5.png"),
        },
        srcList: [
          require("@/assets/img/education/add-patient-1.png"),
          require("@/assets/img/education/add-patient-2.png"),
          require("@/assets/img/education/add-patient-3.png"),
          require("@/assets/img/education/add-patient-4.png"),
          require("@/assets/img/education/add-patient-5.png"),
        ],
      };
    },
    methods: {
      openModalVideo() {
        this.modalVideo = !this.modalVideo;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
