<template>
  <div class="settings-page d-flex flex-column h-100">
    <el-row :gutter="24">
      <el-col :span="20" :sm="12">
        <h6>Сотрудники</h6>
        <span class="settings-description">
          Здесь вы можете посмотреть и добавить сотрудников в свою организацию,
          назначить их на должность, а также присвоить им категорию.
        </span>
      </el-col>
      <el-col :span="4" :sm="12" class="d-flex flex-end">
        <router-link
          v-if="editStaffShow"
          class="staff-btn-link"
          :to="{
            path: '/staff-form/' + 0,
          }"
        >
          <span class="hidden-xs-only">Добавить</span>
          <i class="el-icon-plus el-icon-right hidden-xs-only"></i>
          <i class="el-icon-user-plus hidden-sm-and-up"></i>
        </router-link>
      </el-col>
    </el-row>
    <el-table
      class="mt"
      stripe
      :data="pagedTableData"
      @sort-change="sortChange"
      :default-sort="{ prop: 'name', order: 'descending' }"
      v-loading="loading"
      style="width: 100%; overflow: auto"
    >
      <el-table-column prop="status" label="Статус" width="100" align="center">
        <template slot-scope="scope">
          <i
            class="el-icon-circle-check success"
            v-if="scope.row.status == 1"
          ></i>
          <i
            class="el-icon-circle-close danger"
            v-if="scope.row.status == 0"
          ></i>
        </template>
      </el-table-column>
      <el-table-column sortable prop="name" label="ФИО"> </el-table-column>
      <el-table-column sortable prop="positionName" label="Должность">
      </el-table-column>
      <el-table-column width="120x" sortable prop="phone" label="Телефон">
      </el-table-column>
      <el-table-column sortable prop="login" label="Логин"> </el-table-column>
      <el-table-column
        sortable
        prop="appointmentDefaultDuration"
        label="Длительность приема, мин."
        :formatter="formatterDuration"
      >
      </el-table-column>
      <el-table-column width="100px" label="Действия" v-if="editStaffShow">
        <template slot-scope="scope">
          <el-dropdown trigger="click" size="mini">
            <span class="el-dropdown-link"><i class="el-icon-more"></i> </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <router-link
                  class="staff-dropdown-link"
                  :to="{ path: '/staff-form/' + scope.row.idDoctor }"
                >
                  <i class="el-icon-edit el-icon-left"></i> Редактировать
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  size="small"
                  type="text"
                  class="danger staff-dropdown-btn"
                  @click="deleteEmployeeAsync(scope.row)"
                >
                  <i class="el-icon-delete el-icon-left"></i>
                  Удалить</el-button
                ></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.currentSize"
      layout="sizes, prev, pager, next"
      :total="staffData.length"
      class="mt-20 pb"
    >
    </el-pagination>
  </div>
</template>

<script>
import AuthManager from '@/services/authManager';
import { getDateString } from '@/services/commonService';
import dateTimeService from '@/services/dateTimeService';
function sort(order) {
  if (order === 'ascending') {
    return (a, b) => {
      if (a < b) {
        return -1;
      }
      if (b < a) {
        return 1;
      }
      return 0;
    };
  }

  return (a, b) => {
    if (a > b) {
      return -1;
    }
    if (b > a) {
      return 1;
    }
    return 0;
  };
}
export default {
  name: 'StaffPage',
  data() {
    return {
      loading: true,
      editStaffShow: false,
    };
  },
  computed: {
    staffData: {
      get() {
        return this.$store.getters.employees;
      },
      set(value) {
        return value;
      },
    },
    pagination() {
      return this.$store.getters.pagination;
    },
    pagedTableData() {
      return this.staffData.slice(
        this.pagination.currentSize * this.pagination.currentPage -
          this.pagination.currentSize,
        this.pagination.currentSize * this.pagination.currentPage
      );
    },
  },
  async mounted() {
    this.$store.dispatch('getEmployees').then(() => {
      let currentUser = AuthManager.getUser();
      this.editStaffShow = currentUser.roles.find((x) => x == 'edit_staff');
      this.loading = false;
    });
  },
  methods: {
    sortChange(value) {
      var s = sort(value.order);
      this.staffData = this.staffData.sort((a, b) =>
        s(a[value.prop], b[value.prop])
      );
    },
    handleSizeChange(val) {
      this.pagination.currentSize = val;
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
    },

    formatterDateStart(row) {
      return getDateString(row.dateStart);
    },
    formatterDateEnd(row) {
      let result = row.dateEnd ? getDateString(row.dateEnd) : null;
      return result;
    },
    formatterDuration(row) {
      return dateTimeService.getDurationAsMinutes(
        row.appointmentDefaultDuration
      ) == 0
        ? 'Не указана'
        : dateTimeService.getDurationAsMinutes(row.appointmentDefaultDuration);
    },
    async deleteEmployeeAsync(row) {
      this.$confirm(
        'Вы действительно хотите удалить этого сотрудника?',
        'Warning',
        {
          confirmButtonText: 'Подтвердить',
          cancelButtonText: 'Отменить',
          type: 'warning',
        }
      )
        .then(async () => {
          this.$store.dispatch('deleteEmployee', row.idDoctor).then(() => {
            this.$store.dispatch('getEmployees');
          });
          this.$message({
            type: 'success',
            message: 'Удаление прошло успешно',
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Удаление отменено',
          });
        });
    },
  },
};
</script>
