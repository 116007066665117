import axios from '@/api/axios';

/**
 * API method - Get Array Schedules with params
 * @param {Object} params - Object contains options for request
 * @param {number} params.from - Filter Date From
 * @param {number} params.to - Filter Date To
 * @param {number} params.filialIds - Filial IDs splited ,
 * @param {string} params.departmentIds - Department IDs splited ,
 * @param {string} params.cabinetIds - Cabinet IDs splited ,
 * @param {string} params.doctorPositionIds - Positions IDs splited ,
 * @returns Promise
 */
const getScheduleList = async (params) => {
  return await axios.get('schedule', {
    params: params,
    paramsSerializer: { indexes: null },
  });
};

/**
 * API method - Create Schedules (by Array)
 * @param {Object} schedule - Object Schedule
 * @param {number} schedule.idDoctorPosition - Doctor Position ID
 * @param {number} schedule.dateTimeFrom - DateTime Start Schedule
 * @param {number} schedule.dateTimeTo - DateTime End Schedule
 * @param {number} schedule.cabinetId - Cabinet ID

 * @returns Promise
 */
const createScheduleList = async (schedule) => {
  return await axios.post('schedule', schedule);
};

/**
 * API method - Update Schedules (by Object)
 * @param {Object} schedule - Object Schedule
 * @param {number} schedule.id - ID Schedule
 * @param {number} schedule.idDoctorPosition - Doctor Position ID
 * @param {number} schedule.dateTimeFrom - DateTime Start Schedule
 * @param {number} schedule.dateTimeTo - DateTime End Schedule
 * @param {number} schedule.cabinetId - Cabinet ID

 * @returns Promise
 */
const updateSchedule = async (schedule) => {
  return await axios.put('schedule', schedule);
};

/**
 * API method - Update Schedules (by Object)
 * @param {Object} params - Object contains options for request
 * @param {number} params.id - ID Schedule

 * @returns Promise
 */
const deleteScheduleById = async (params) => {
  return await axios.put('schedule', { params: params });
};

export default {
  getScheduleList,
  createScheduleList,
  updateSchedule,
  deleteScheduleById,
};
