<template>
  <div class="settings-page timetable" v-loading="loading">
    <el-row>
      <el-col :span="12">
        <h6>Графики работ</h6>
      </el-col>
      <el-col :span="12" class="text-right timetable-z-index">
        <router-link class="timetable-link" :to="{ name: 'cabinet-workload' }"
          >Распределение кабинетов <i class="el-icon-angle-right-b"></i
        ></router-link>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt-16">
      <el-col :span="24" :sm="12" class="d-flex flex-end">
        <el-select
          data-is-search="true"
          size="small"
          v-model="filter.doctor"
          @change="handleFilter"
          :loading="loading"
          placeholder="Выберите сотрудника"
          filterable
        >
          <el-option
            v-for="item in doctorOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row>
      <el-alert v-if="showError" :title="error" type="error"> </el-alert>
    </el-row>
    <el-row class="mt-16">
      <el-col :span="24">
        <el-calendar v-model="filter.date">
          <template slot="dateCell" slot-scope="{ date }">
            <div
              class="timetable-cell"
              @click="openEditModal(date)"
              :class="[handleCellValueTime(date).status ? true : 'notWork']"
            >
              <span class="timetable-date">
                {{ handleCellValueDate(date) }}
              </span>
              <span class="timetable-time">
                {{ handleCellValueTime(date).value }}
              </span>
              <span class="timetable-cabinet">{{
                handleCellValueCabinet(date)
              }}</span>
            </div>
          </template>
        </el-calendar>
      </el-col>
    </el-row>

    <!-- модальное окно редактирования графика -->
    <el-dialog
      class="modal-timetable"
      :visible.sync="modalEditTimeTable"
      width="100%"
    >
      <h5 slot="title">Редактирование графика работы</h5>
      <el-row :gutter="24">
        <el-alert
          v-if="showErrorModalEdit"
          :title="errorModalEdit"
          type="error"
        >
        </el-alert>
        <el-col :span="24" class="mt">
          <label for="">Сотрудник</label>
          <el-select
            data-is-search="true"
            size="small"
            v-model="timetableToEdit.doctor"
            placeholder="Выберите сотрудника"
            filterable
            clearable
            disabled
          >
            <el-option
              v-for="item in doctorOptionsModal"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="24" class="mt">
          <label for="">Должность</label>
          <el-select
            data-is-search="true"
            size="small"
            v-model="timetableToEdit.doctorPosition"
            placeholder="Выберите должность сотрудника"
            filterable
            clearable
          >
            <el-option
              v-for="item in doctorPositionOptionsModal"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="24" class="mt">
          <label for="">Кабинет</label>
          <el-select
            data-is-search="true"
            size="small"
            v-model="timetableToEdit.cabinetId"
            placeholder="Выберите кабинет"
            filterable
            clearable
          >
            <el-option
              v-for="item in cabinetOptionsModal"
              :key="item.id"
              :label="setLabelForCabinetSelect(item)"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="24" class="mt">
          <label for="datePicker">Дата</label>
          <el-date-picker
            size="small"
            style="width: 100%; border-radius: 8px"
            v-model="timetableToEdit.date"
            format="dd.MM.yyyy"
            type="date"
            :picker-options="{ firstDayOfWeek: 1 }"
            placeholder="Дата с"
            disabled
          >
          </el-date-picker>
        </el-col>
        <el-col :span="24" class="mt">
          <label for="">Рабочее время</label>
          <el-row :gutter="24">
            <el-col :span="12">
              <el-time-select
                size="small"
                placeholder="Начало"
                v-model="timetableToEdit.timeFrom"
                :picker-options="timeOptions"
              >
              </el-time-select>
            </el-col>
            <el-col :span="12">
              <el-time-select
                size="small"
                placeholder="Окончание"
                v-model="timetableToEdit.timeTo"
                :picker-options="timeOptions"
              >
              </el-time-select> </el-col
          ></el-row>
        </el-col>
      </el-row>
      <el-row :gutter="24" class="mt">
        <el-col :span="8">
          <el-button
            class="w-100"
            size="small"
            type="primary"
            :loading="loadingModal"
            @click="handleSaveEditTimeTable"
            >Сохранить</el-button
          >
        </el-col>
        <el-col :span="8">
          <el-button
            class="w-100"
            size="small"
            @click="modalEditTimeTable = false"
            >Отменить</el-button
          >
        </el-col>
        <el-col :span="8">
          <el-button
            class="w-100"
            size="small"
            type="danger"
            plain
            @click="handleDelete"
            >Удалить</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>

    <!-- модальное окно добавления графика -->
    <el-dialog
      class="modal-timetable"
      :visible.sync="modalAddTimeTable"
      width="100%"
    >
      <h5 slot="title">Добавление графика работы</h5>
      <el-row :gutter="24">
        <el-alert v-if="showErrorModalAdd" :title="errorModalAdd" type="error">
        </el-alert>
        <el-col :span="24">
          <label for="">Сотрудник</label>
          <el-select
            data-is-search="true"
            size="small"
            v-model="timetableToAdd.doctor"
            placeholder="Выберите сотрудника"
            filterable
            @change="handleDoctorChangeModal(timetableToAdd.doctor)"
            clearable
          >
            <el-option
              v-for="item in doctorOptionsModal"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="24" class="mt">
          <label for="">Должность</label>
          <el-select
            data-is-search="true"
            size="small"
            v-model="timetableToAdd.doctorPosition"
            placeholder="Выберите должность сотрудника"
            filterable
            clearable
          >
            <el-option
              v-for="item in doctorPositionOptionsModal"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="24" class="mt">
          <label for="">Кабинет</label>
          <el-select
            data-is-search="true"
            size="small"
            v-model="timetableToAdd.cabinetId"
            placeholder="Выберите кабинет"
            filterable
            clearable
          >
            <el-option
              v-for="item in cabinetOptionsModal"
              :key="item.id"
              :label="setLabelForCabinetSelect(item)"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="24" class="mt">
          <label for="datePicker">Выберите даты</label>
          <el-date-picker
            size="small"
            style="width: 100%; border-radius: 8px"
            v-model="timetableToAdd.dateRange"
            :picker-options="{ firstDayOfWeek: 1 }"
            format="dd.MM.yyyy"
            type="dates"
            @change="handleSelectDates($event)"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="24" class="mt">
          <label for="">Рабочее время</label>
          <el-row :gutter="24">
            <el-col :span="12">
              <el-time-select
                size="small"
                placeholder="Начало"
                v-model="timetableToAdd.timeFrom"
                :picker-options="timeOptions"
              >
              </el-time-select>
            </el-col>
            <el-col :span="12">
              <el-time-select
                size="small"
                placeholder="Окончание"
                v-model="timetableToAdd.timeTo"
                :picker-options="timeOptions"
              >
              </el-time-select> </el-col
          ></el-row>
        </el-col>
      </el-row>
      <el-row :gutter="24" class="mt">
        <el-col :span="12">
          <el-button
            class="w-100"
            size="small"
            type="primary"
            :loading="loadingModal"
            @click="handleSaveAddTimeTable"
            >Сохранить</el-button
          >
        </el-col>
        <el-col :span="12">
          <el-button
            class="w-100"
            size="small"
            @click="modalAddTimeTable = false"
            >Отменить</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import FilialManager from '@/services/filialManager.js';
import DoctorManager from '@/services/doctorManager.js';
import TimetableManager from '@/services/timetableManager.js';
// import ClientOrganizationManager from '@/services/clientOrganizationManager';
import { getTimeOffset } from '@/services/commonService';
import axios from '@/api/axios';

export default {
  name: 'TimeTablePage',
  data() {
    return {
      dates: [new Date()],
      filter: {
        filial: '',
        department: '',
        doctor: '',
        date: new Date(),
      },
      loading: false,
      loadingModal: false,
      filialOptions: [],
      departmentOptions: [],
      doctorOptions: [],
      showError: false,
      error: '',
      schedules: [],
      modalAddTimeTable: false,
      timetableToAdd: {
        doctor: '',
        doctorPosition: '',
        dateRange: null,
        timeFrom: '',
        timeTo: '',
        cabinetId: null,
      },
      filialOptionsModal: [],
      departmentOptionsModal: [],
      doctorOptionsModal: [],
      cabinetOptionsModal: [],
      doctorPositionOptionsModal: [],
      errorModalAdd: '',
      showErrorModalAdd: false,
      modalEditTimeTable: false,
      timetableToEdit: {
        id: null,
        filial: '',
        department: '',
        doctor: '',
        doctorPosition: '',
        date: Date(),
        timeFrom: '',
        timeTo: '',
        cabinetId: null,
      },
      errorModalEdit: '',
      showErrorModalEdit: false,
    };
  },
  computed: {
    businessHours() {
      return this.$store.getters.businessHours;
    },
    timeOptions() {
      return {
        step: '00:05',
        start: this.businessHours.start.slice(0, 5),
        end: this.businessHours.end.slice(0, 5),
      };
    },
  },
  async mounted() {
    this.loading = true;
    this.filialOptions = await FilialManager.getFilials();
    this.departmentOptions = await FilialManager.getAllDepartments();
    this.doctorOptions = await DoctorManager.getDoctorsForSelect();
    this.filter.doctor = this.doctorOptions[0].id;
    this.$store.dispatch('getMyOrganization');
    await this.loadData();
    await this.$patientHub.start();
    this.$patientHub.client.on('ScheduleChange', () => {
      this.loadData();
    });
    this.loading = false;
  },
  methods: {
    // удаляем дубликаты дат в массиве при множественном выборе - баг компонента библиотеки
    handleSelectDates($event) {
      const dates = $event;
      const uniqueDates = [];
      if (dates && dates.length >= 2) {
        for (let date of dates) {
          const ISOString = date.toISOString();

          if (!uniqueDates.includes(ISOString)) {
            uniqueDates.push(ISOString);
          }
        }
        this.timetableToAdd.dateRange = uniqueDates;
      }
    },
    setLabelForCabinetSelect(item) {
      return `${item.name} ${
        item.description == '' ? '' : `(${item.description})`
      }`;
    },
    //загрузка расписания
    async loadData() {
      axios
        .get('schedule')
        .then(async (data) => {
          this.$store.commit('SET_SCHEDULES', data.data);
          await this.handleFilter();
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: `Невозможно загрузить расписание!`,
          });
        });
    },
    async hadleFilialChange(value) {
      let valuelist = [];
      valuelist.push(value);
      this.departmentOptions = await FilialManager.getFilialDepartments(
        valuelist
      );
      this.doctorOptions = await DoctorManager.getFilialDoctorsForSelect(
        valuelist
      );
    },
    async hadleDepartmentChange(value) {
      let valuelist = [];
      valuelist.push(value);
      this.doctorOptions = await DoctorManager.getDepartmentDoctorsForSelect(
        valuelist
      );
    },
    async handleFilter() {
      if (typeof this.filter.doctor == 'string') {
        this.error = 'Не выбран сотрудник!';
        this.showError = true;
        return;
      }
      this.error = '';
      this.showError = false;
      this.schedules = await TimetableManager.getSchedule(this.filter);
    },
    handleCellValueDate(date) {
      return new Intl.DateTimeFormat('ru-RU', { day: '2-digit' }).format(
        new Date(date)
      );
    },
    handleCellValueTime(date) {
      if (this.schedules.length == 0) {
        return '';
      }
      let scheduleDate = this.schedules.find(
        (x) =>
          new Date(x.dateTimeFrom).getFullYear() == date.getFullYear() &&
          new Date(x.dateTimeFrom).getMonth() == date.getMonth() &&
          new Date(x.dateTimeFrom).getDate() == date.getDate()
      );
      if (scheduleDate) {
        let timeFrom = new Intl.DateTimeFormat('ru-RU', {
          hour: 'numeric',
          minute: 'numeric',
        }).format(new Date(scheduleDate.dateTimeFrom));
        let timeTo = new Intl.DateTimeFormat('ru-RU', {
          hour: 'numeric',
          minute: 'numeric',
        }).format(new Date(scheduleDate.dateTimeTo));
        return {
          status: true,
          value: `${timeFrom} - ${timeTo}`,
        };
      }
      if (date.getMonth() != this.filter.date.getMonth()) {
        return '';
      }
      return {
        status: false,
        value: '',
      };
    },
    handleCellValueFilial(date) {
      if (this.schedules.length == 0) {
        return '';
      }
      let scheduleDate = this.schedules.find(
        (x) =>
          new Date(x.dateTimeFrom).getFullYear() == date.getFullYear() &&
          new Date(x.dateTimeFrom).getMonth() == date.getMonth() &&
          new Date(x.dateTimeFrom).getDate() == date.getDate()
      );
      if (scheduleDate) {
        let filialName = this.filialOptions.find(
          (x) => x.id == scheduleDate.idFilial
        ).name;
        return filialName;
      }
      if (date.getMonth() != this.filter.date.getMonth()) {
        return '';
      }
      return '';
    },
    handleCellValueCabinet(date) {
      if (this.schedules.length == 0) {
        return '';
      }
      let scheduleDate = this.schedules.find(
        (x) =>
          new Date(x.dateTimeFrom).getFullYear() == date.getFullYear() &&
          new Date(x.dateTimeFrom).getMonth() == date.getMonth() &&
          new Date(x.dateTimeFrom).getDate() == date.getDate()
      );
      if (scheduleDate && scheduleDate.cabinets.length > 0) {
        let cabinets = scheduleDate.cabinets.map((c) => c.cabinetName);
        return `${cabinets.toString()}`;
      }
      if (date.getMonth() != this.filter.date.getMonth()) {
        return '';
      }
      return '';
    },
    async handleAddTimeTable(date) {
      this.showErrorModalAdd = false;
      this.filialOptionsModal = await FilialManager.getFilials();
      this.departmentOptionsModal = await FilialManager.getAllDepartments();
      this.doctorOptionsModal = await DoctorManager.getDoctorsForSelect();
      this.cabinetOptionsModal = await FilialManager.getAllCabinets();
      if (this.filter.doctor) {
        this.doctorPositionOptionsModal =
          await DoctorManager.getSelectedDoctorPositions(this.filter.doctor);
      }
      (this.timetableToAdd = {
        filial: '',
        department: '',
        doctor: this.filter.doctor,
        doctorPosition: this.doctorPositionOptionsModal[0]?.id,
        dateRange: [date],
        timeFrom: '',
        timeTo: '',
        cabinetId: this.timetableToAdd.cabinetId,
      }),
        (this.errorModalAdd = '');
      this.showErrorModalAdd = false;
      this.modalAddTimeTable = true;
    },
    async hadleFilialChangeModal(value) {
      let valuelist = [];
      valuelist.push(value);
      this.cabinetOptionsModal = await FilialManager.getFilialCabinets(
        valuelist
      );
      this.departmentOptionsModal = await FilialManager.getFilialDepartments(
        valuelist
      );
      this.doctorOptionsModal = await DoctorManager.getFilialDoctorsForSelect(
        valuelist
      );
    },
    async hadleDepartmentChangeModal(value) {
      let valuelist = [];
      valuelist.push(value);
      this.doctorOptionsModal =
        await DoctorManager.getDepartmentDoctorsForSelect(valuelist);
    },
    async handleDoctorChangeModal(value) {
      this.doctorPositionOptionsModal =
        await DoctorManager.getSelectedDoctorPositions(value);
      this.timetableToAdd.doctorPosition =
        this.doctorPositionOptionsModal[0].id;
    },
    async handleSaveAddTimeTable() {
      this.showErrorModalAdd = false;
      if (typeof this.timetableToAdd.doctorPosition == 'string') {
        this.errorModalAdd = 'Не выбрана должность!';
        this.showErrorModalAdd = true;
        return;
      }
      if (this.timetableToAdd.timeFrom.length == 0) {
        this.errorModalAdd = 'Не выбрано время начала работы!';
        this.showErrorModalAdd = true;
        return;
      }
      if (this.timetableToAdd.timeTo.length == 0) {
        this.errorModalAdd = 'Не выбрано время окончания работы!';
        this.showErrorModalAdd = true;
        return;
      }
      let schedulesToSave = [];
      for (var i = 0; i <= this.timetableToAdd.dateRange.length - 1; i++) {
        let timeFrom = new Date(this.timetableToAdd.dateRange[i]).setHours(
          this.timetableToAdd.timeFrom.substring(0, 2),
          this.timetableToAdd.timeFrom.substring(3, 5)
        );
        let timeTo = new Date(this.timetableToAdd.dateRange[i]).setHours(
          this.timetableToAdd.timeTo.substring(0, 2),
          this.timetableToAdd.timeTo.substring(3, 5)
        );
        if (timeFrom > timeTo || timeFrom == timeTo) {
          this.$message({
            type: 'error',
            message: `Некорректное время работы!`,
          });
          this.loadingModal = false;
          return;
        }
        let scheduleItem = {
          id: null,
          idDoctorPosition: this.timetableToAdd.doctorPosition,
          dateTimeFrom: new Date(timeFrom),
          dateTimeTo: new Date(timeTo),
          clientOffset: getTimeOffset(),
          cabinetId: this.timetableToAdd.cabinetId,
        };
        schedulesToSave.push(scheduleItem);
      }
      let checkResult = await TimetableManager.checkTimetableAdd(
        schedulesToSave,
        this.timetableToAdd.doctor
      );
      if (!checkResult) {
        this.loadingModal = true;
        axios
          .post('schedule', schedulesToSave)
          .then(async () => {
            await this.$patientHub.client.invoke('BroadcastScheduleChange');
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: `Невозможно добавить расписание для врача!`,
            });
          });
        await this.handleFilter();
        this.modalAddTimeTable = false;
        this.loadingModal = false;
      } else {
        this.errorModalAdd = checkResult;
        this.showErrorModalAdd = true;
        this.loadingModal = false;
        return;
      }
    },
    async openEditModal(date) {
      this.showErrorModalEdit = false;
      let scheduleDate = this.schedules.find(
        (x) =>
          new Date(x.dateTimeFrom).getFullYear() == date.getFullYear() &&
          new Date(x.dateTimeFrom).getMonth() == date.getMonth() &&
          new Date(x.dateTimeFrom).getDate() == date.getDate()
      );
      if (!scheduleDate) {
        this.handleAddTimeTable(date);
        return;
      }

      this.filialOptionsModal = await FilialManager.getFilials();
      this.departmentOptionsModal = await FilialManager.getAllDepartments();
      this.doctorOptionsModal = await DoctorManager.getDoctorsForSelect();
      this.cabinetOptionsModal = await FilialManager.getAllCabinets();
      this.doctorPositionOptionsModal =
        await DoctorManager.getSelectedDoctorPositions(scheduleDate?.idDoctor);
      let timeFrom = new Intl.DateTimeFormat('ru-RU', {
        hour: 'numeric',
        minute: 'numeric',
      }).format(new Date(scheduleDate.dateTimeFrom));
      let timeTo = new Intl.DateTimeFormat('ru-RU', {
        hour: 'numeric',
        minute: 'numeric',
      }).format(new Date(scheduleDate.dateTimeTo));
      (this.timetableToEdit = {
        id: scheduleDate.id,
        filial: scheduleDate.idFilial,
        department: scheduleDate.idDepartment,
        doctor: scheduleDate?.idDoctor ?? 0,
        doctorPosition: scheduleDate.idDoctorPosition,
        date: new Date(
          new Date(scheduleDate.dateTimeFrom).getFullYear(),
          new Date(scheduleDate.dateTimeFrom).getMonth(),
          new Date(scheduleDate.dateTimeFrom).getDate()
        ),
        timeFrom: timeFrom.toString(),
        timeTo: timeTo.toString(),
        cabinetId: scheduleDate.cabinets.length
          ? scheduleDate.cabinets[0].idCabinet
          : null,
      }),
        (this.modalEditTimeTable = true);
    },
    async handleSaveEditTimeTable() {
      this.loadingModal = true;
      let timeFrom = new Date(this.timetableToEdit.date).setHours(
        this.timetableToEdit.timeFrom.substring(0, 2),
        this.timetableToEdit.timeFrom.substring(3, 5)
      );
      let timeTo = new Date(this.timetableToEdit.date).setHours(
        this.timetableToEdit.timeTo.substring(0, 2),
        this.timetableToEdit.timeTo.substring(3, 5)
      );
      if (timeFrom > timeTo || timeFrom == timeTo) {
        this.$message({
          type: 'error',
          message: `Некорректное время работы!`,
        });
        this.loadingModal = false;
        return;
      }
      let scheduleItem = {
        id: this.timetableToEdit.id,
        idDoctorPosition: this.timetableToEdit.doctorPosition,
        dateTimeFrom: new Date(timeFrom),
        dateTimeTo: new Date(timeTo),
        clientOffset: getTimeOffset(),
        cabinetId: this.timetableToEdit.cabinetId
          ? this.timetableToEdit.cabinetId
          : null,
      };
      axios
        .put('schedule', scheduleItem)
        .then(async () => {
          await this.$patientHub.client.invoke('BroadcastScheduleChange');
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: `Невозможно изменить запись!`,
          });
        });
      await this.handleFilter();
      this.modalEditTimeTable = false;
      this.loadingModal = false;
    },
    async handleDelete() {
      this.$confirm(
        'Вы действительно хотите удалить эту запись в расписании?',
        'Warning',
        {
          confirmButtonText: 'Подтвердить',
          cancelButtonText: 'Отменить',
          type: 'warning',
        }
      )
        .then(async () => {
          axios
            .delete('schedule', { params: { id: this.timetableToEdit.id } })
            .then(async () => {
              await this.$patientHub.client.invoke('BroadcastScheduleChange');
              this.$message({
                type: 'success',
                message: 'Удаление прошло успешно',
              });
            })
            .catch(() => {
              this.$message({
                type: 'error',
                message: `Невозможно удалить запись!`,
              });
            });
          this.modalEditTimeTable = false;
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Удаление отменено',
          });
        });
    },
    handleCabinetLabel(item) {
      let filial = this.filialOptions.find((x) => x.id == item.idFilial);
      return item.name + ' (' + filial.name + ')';
    },
  },
};
</script>

<style lang="scss" scoped></style>
