import insurancePoliciesApi from '@/api/insurancePolicies';

const insurancePoliciesModule = {
  state: () => ({
    insurancePolicy: null,
    insurancePolicyList: [],
    defaultInsurancePolicy: {
      id: null,
      insuranceCompanyId: null,
      insuranceType: 0,
      number: null,
      series: null,
      singlePolicyNumber: null,
      patientId: null,
    },
  }),
  getters: {
    defaultInsurancePolicy: (state) => {
      return state.defaultInsurancePolicy;
    },
    insurancePolicy: (state) => {
      return state.insurancePolicy;
    },
    insurancePolicyList: (state) => {
      return state.insurancePolicyList;
    },
    insurancePolicyListByPatientId: (state, rootState) =>
      function (patientId) {
        let insurancePolicyListByPatient = state.insurancePolicyList.filter(
          (i) => i.patientId === patientId
        );
        let insuranceCompanyList = rootState.contractorOrganizations;
        for (let p of insurancePolicyListByPatient) {
          for (let c of insuranceCompanyList) {
            if (p.insuranceCompanyId === c.id) {
              p.insuranceCompany = c.shortName;
            }
          }
        }
        return insurancePolicyListByPatient;
      },
    insurancePolicyListWithGuaranteeLetters: (state, rootState) =>
      function (patientId) {
        let insurancePolicyList = state.insurancePolicyList.filter(
          (i) => i.patientId === patientId
        );
        let guaranteeLetterList = rootState.guaranteeLetterList.values;
        if (guaranteeLetterList) {
          for (let p of insurancePolicyList) {
            p.guaranteeLetters = [];
            for (let l of guaranteeLetterList) {
              if (p.id === l.insurancePolicyId) {
                p.guaranteeLetters.push(l);
              }
            }
          }
        }
        return insurancePolicyList;
      },
  },
  mutations: {
    SET_INSURANCE_POLICIES: (state, payload) => {
      state.insurancePolicyList = payload;
    },
    ADD_INSURANCE_POLICY_TO_LIST: (state, payload) => {
      state.insurancePolicyList.push(payload);
    },
    SET_INSURANCE_POLICY: (state, payload) => {
      state.insurancePolicy = payload;
    },
  },
  actions: {
    getInsurancePolicies: async (context) => {
      let response = await insurancePoliciesApi.getInsurancePolicies();
      context.commit('SET_INSURANCE_POLICIES', response.data.values);
    },
    getInsurancePolicyById: async (context, id) => {
      let response = await insurancePoliciesApi.getInsurancePolicyById(id);
      context.commit('SET_INSURANCE_POLICY', response.data);
    },
    createInsurancePolicies: async (context, insurancePolicyList) => {
      await insurancePoliciesApi.createInsurancePolicies(insurancePolicyList);
    },
    updateInsurancePolicies: async (context, insurancePolicy) => {
      console.log(insurancePolicy);
      return await insurancePoliciesApi.updateInsurancePolicies(
        insurancePolicy
      );
    },
    deleteInsurancePolicyById: async (context, id) => {
      return await insurancePoliciesApi.deleteInsurancePolicyById(id);
    },
  },
};

export default insurancePoliciesModule;
