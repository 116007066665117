<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import PatientHubPlugin from '@/hubs/patientHubPlugin';
import Vue from 'vue';
export default {
  created() {
    Vue.use(PatientHubPlugin);
  },
};
</script>
