<template>
  <div class="page-wrap profile" v-loading="loading">
    <el-tabs v-model="profileTabActive">
      <el-tab-pane label="Мои данные" name="personalData">
        <div class="profile-tab">
          <h6>Мои данные</h6>
          <el-row :gutter="24">
            <el-col :span="12" :lg="12" :md="12" :sm="12" :xs="24">
              <label class="profile-label" for="fullName">ФИО</label>
              <el-input
                size="small"
                disabled
                placeholder="ФИО сотрудника"
                v-model="doctorFullName"
              >
              </el-input>
            </el-col>
            <el-col :span="6" :lg="6" :md="12" :sm="12" :xs="24">
              <label class="profile-label" for="birthDate">Дата рождения</label>
              <el-date-picker
                v-model="doctorInfo.birthDate"
                format="dd.MM.yyyy"
                size="small"
                type="date"
                disabled
                placeholder="Введите дату рождения"
              >
              </el-date-picker>
            </el-col>
            <el-col :span="6" :lg="6" :md="12" :sm="12" :xs="24">
              <label for="gender" class="profile-label">Пол</label>
              <div class="d-flex">
                <el-radio-group v-model="doctorInfo.gender" disabled>
                  <el-radio :label="1">Мужской</el-radio>
                  <el-radio :label="0">Женский</el-radio>
                </el-radio-group>
              </div>
            </el-col>
            <el-col :span="12" :lg="12" :md="12" :sm="12" :xs="24">
              <label for="phone" class="profile-label">Телефон</label>
              <el-input
                size="small"
                disabled
                placeholder="Телефон"
                v-model="doctorInfo.phone"
              >
              </el-input>
            </el-col>
            <el-col :span="12" :lg="12" :md="12" :sm="12" :xs="24">
              <label for="email" class="profile-label">Email</label>
              <el-input
                size="small"
                disabled
                placeholder="Email"
                v-model="doctorInfo.email"
              >
              </el-input>
            </el-col>
            <el-col :span="12" :lg="12" :md="12" :sm="12" :xs="24">
              <label for="registerAddress" class="profile-label"
                >Адрес регистрации</label
              >
              <el-input
                size="small"
                disabled
                placeholder="Адрес регистрации"
                v-model="doctorInfo.registerAddress"
              >
              </el-input>
            </el-col>
            <el-col :span="12" :lg="12" :md="12" :sm="12" :xs="24">
              <label for="residenceAddress" class="profile-label"
                >Адрес проживания</label
              >
              <el-input
                size="small"
                disabled
                placeholder="Адрес проживания"
                v-model="doctorInfo.residenceAddress"
              >
              </el-input>
            </el-col>
            <el-col :span="8" :lg="8" :md="12" :sm="12" :xs="24">
              <label for="passportSeries" class="profile-label"
                >Серия паспорт</label
              >
              <el-input
                size="small"
                disabled
                placeholder="Серия паспорта"
                v-model="doctorInfo.passportSeries"
              >
              </el-input>
            </el-col>
            <el-col :span="8" :lg="8" :md="12" :sm="12" :xs="24">
              <label for="passportNumber" class="profile-label"
                >Номер паспорта</label
              >
              <el-input
                size="small"
                disabled
                placeholder="Номер паспорта"
                v-model="doctorInfo.passportNumber"
              >
              </el-input>
            </el-col>
            <el-col :span="8" :lg="8" :md="12" :sm="12" :xs="24">
              <label class="profile-label" for="birthDate">Дата выдачи</label>
              <el-date-picker
                v-model="doctorInfo.passportDateFrom"
                size="small"
                format="dd.MM.yyyy"
                type="date"
                disabled
                placeholder="Введите дату выдачи"
              >
              </el-date-picker>
            </el-col>
            <el-col :span="16" :lg="16" :md="12" :sm="12" :xs="24">
              <label for="passportIssuer" class="profile-label"
                >Кем выдан</label
              >
              <el-input
                size="small"
                disabled
                placeholder="Кем выдан"
                v-model="doctorInfo.passportIssuer"
              >
              </el-input>
            </el-col>
            <el-col :span="8" :lg="8" :md="12" :sm="12" :xs="24">
              <label for="passportIssuerCode" class="profile-label"
                >Код подразделения</label
              >
              <el-input
                size="small"
                disabled
                placeholder="Код подразделения"
                v-model="doctorInfo.passportIssuerCode"
              >
              </el-input>
            </el-col>
            <el-col :span="8" :lg="8" :md="12" :sm="12" :xs="24">
              <label for="snils" class="profile-label">СНИЛС</label>
              <el-input
                size="small"
                disabled
                placeholder="СНИЛС"
                v-model="doctorInfo.snils"
              >
              </el-input>
            </el-col>
            <el-col :span="8" :lg="8" :md="12" :sm="12" :xs="24">
              <label for="inn" class="profile-label">ИНН</label>
              <el-input
                size="small"
                disabled
                placeholder="ИНН"
                v-model="doctorInfo.inn"
              >
              </el-input>
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Мои пациенты" name="myPatients">
        <div class="profile-tab d-flex flex-column flex-beetwen h-100">
          <div class="profile-tab-header">
            <h5>Мои пациенты</h5>
            <el-row class="w-100" :gutter="24">
              <el-col :span="24" :sm="8">
                <el-tooltip
                  class="item hidden-xs-only"
                  effect="dark"
                  content="Поиск пациента можно осуществлять по ФИО или номеру телефона"
                  placement="right"
                  :hide-after="2500"
                >
                  <el-input
                    size="small"
                    placeholder="Найти пациента..."
                    prefix-icon="el-icon-search"
                    v-model="doctorPatientsFilter.filterstring"
                    @input="handlePatientRowSearch"
                  >
                  </el-input>
                </el-tooltip>
                <el-input
                  class="hidden-sm-and-up"
                  size="small"
                  placeholder="Найти пациента..."
                  prefix-icon="el-icon-search"
                  v-model="doctorPatientsFilter.filterstring"
                  @input="handlePatientRowSearch"
                >
                </el-input>
              </el-col>
            </el-row>
          </div>
          <el-table
            class="patients-table"
            v-loading="loading"
            stripe
            :data="tableData"
            @sort-change="handleSortChange"
            style="width: 100%"
          >
            <el-table-column
              prop="cardNumber"
              label="Номер карты"
              width="140px"
              sortable
            >
            </el-table-column>
            <el-table-column
              prop="lastName"
              label="ФИО пациента"
              sortable="custom"
              @click="editPatient(scope.row)"
              :formatter="formatterName"
            >
            </el-table-column>
            <el-table-column
              prop="birthDate"
              label="Дата рождения"
              sortable
              :formatter="formatterDate"
            ></el-table-column>
            <el-table-column
              prop="lastVisitDate"
              label="Дата последнего приема"
              sortable
              :formatter="formatterVisitDate"
            ></el-table-column>
            <el-table-column prop="phone" label="Телефон" sortable>
            </el-table-column>
            <el-table-column width="100px" label="Действия">
              <template slot-scope="scope">
                <el-dropdown trigger="click">
                  <span class="el-dropdown-link"
                    ><i class="el-icon-more"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown" v-if="linkToInvoicesShow">
                    <el-dropdown-item @click="handlePatientInvoices(scope.row)">
                      <router-link
                        class="dropdown-link"
                        :to="{
                          path: '/invoices/' + scope.row.id,
                        }"
                      >
                        <i class="el-icon-clipboard-alt el-icon-left"></i>
                        Счета
                      </router-link>
                    </el-dropdown-item>
                    <el-dropdown-item v-if="viewMedicalCasesShow">
                      <router-link
                        class="dropdown-link"
                        :to="{
                          path: '/patient/' + scope.row.id + '/medical-cases/',
                        }"
                      >
                        <i class="el-icon-medkit"></i>Случаи обращения
                      </router-link>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="doctorPatientsFilter.pagenumber"
            :page-sizes="[10, 20, 30]"
            :page-size="doctorPatientsFilter.pagesize"
            layout="sizes, prev, pager, next"
            :total="tableTotal"
            class="mt-20"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="Мой график работы"
        name="myTimetable"
        class="timetable"
      >
        <div class="profile-tab">
          <h5>Мой график работы</h5>
          <el-row class="mt-16">
            <el-col :span="24">
              <el-calendar v-model="filter.date">
                <template slot="dateCell" slot-scope="{ date }">
                  <div
                    class="timetable-cell"
                    :class="[
                      handleCellValueTime(date).status ? true : 'notWork',
                    ]"
                  >
                    <span class="timetable-date">
                      {{ handleCellValueDate(date) }}
                    </span>
                    <span class="timetable-time">
                      {{ handleCellValueTime(date).value }}
                    </span>
                  </div>
                </template>
              </el-calendar>
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Доступ в систему" name="userData">
        <div class="profile-tab">
          <h5>Доступ в систему</h5>
          <el-row class="mt-16">
            <el-col :lg="6" :md="12" :sm="24">
              <label class="profile-label" for="fullName"
                >Имя пользователя</label
              >
              <el-input
                size="small"
                disabled
                placeholder="Имя пользователя"
                v-model="doctorInfo.user.login"
              >
              </el-input>
            </el-col>
          </el-row>
          <el-row>
            <el-col :lg="6" :md="12" :sm="24">
              <el-form
                class="profile-password-form"
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                :hide-required-asterisk="true"
              >
                <el-form-item prop="userPassword">
                  <label class="profile-label" for="fullName"
                    >Новый пароль</label
                  >
                  <el-input
                    size="small"
                    prefix-icon="el-icon-lock"
                    placeholder="Введите пароль"
                    v-model="ruleForm.userPassword"
                    show-password
                  >
                  </el-input>
                  <el-tooltip effect="light" placement="right-start">
                    <div slot="content">
                      <b>Параметры пароля</b>
                      <p>Ваш пароль минимум должен содержать:</p>
                      <ul>
                        <li>8 латинских букв</li>
                        <li>1 заглавную букву</li>
                        <li>1 цифру</li>
                        <li>1 спецсимвол (! № _ } | : и т.д.)</li>
                      </ul>
                    </div>
                    <i
                      class="profile-password-toolip el-icon-question-circle"
                    ></i>
                  </el-tooltip>
                </el-form-item>
                <el-form-item prop="userPasswordConfirm">
                  <label class="profile-label" for="fullName"
                    >Подтверждение пароля</label
                  >
                  <el-input
                    class="login-btn"
                    size="small"
                    prefix-icon="el-icon-lock"
                    placeholder="Введите подтверждение пароля"
                    v-model="ruleForm.userPasswordConfirm"
                    show-password
                  >
                  </el-input>
                </el-form-item>

                <el-form-item>
                  <el-button
                    size="small"
                    class="profile-btn"
                    type="primary"
                    :loading="loadingBtn"
                    @click="submitForm('ruleForm')"
                    >Изменить пароль</el-button
                  >
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import DoctorManager from '@/services/doctorManager.js';
import TimetableManager from '@/services/timetableManager.js';
import AuthManager from '@/services/authManager.js';
import axios from '@/api/axios';

export default {
  data() {
    const regularForPass =
      /(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}/g;
    var validateUserPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Введите пароль'));
      } else if (regularForPass.test(value) != true) {
        callback(
          new Error(
            'Пароль должен состоять из минимум 8 латинских заглавных и строчных букв, а также содержать специальные символы: !@#$%^&*()'
          )
        );
      } else {
        if (this.ruleForm.userPasswordConfirm !== '') {
          this.$refs.ruleForm.validateField('userPasswordConfirm');
        }
        callback();
      }
    };
    var validateUserPasswordConfirm = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Введите подтверждение пароля'));
      } else if (value !== this.ruleForm.userPassword) {
        callback(new Error('Пароли не совпадают'));
      } else {
        callback();
      }
    };
    return {
      doctorPatientsFilter: {},
      tableData: [],
      tableTotal: 0,

      filter: {
        filial: '',
        department: '',
        doctor: '',
        date: new Date(),
      },
      userName: 'user@documeds.ru',
      ruleForm: {
        userPassword: '',
        userPasswordConfirm: '',
      },
      rules: {
        userPassword: [
          { validator: validateUserPassword, trigger: 'validate' },
        ],
        userPasswordConfirm: [
          { validator: validateUserPasswordConfirm, trigger: 'validate' },
        ],
      },
      date: new Date(),

      MedicalCases: '',
      doctorInfo: {
        user: {
          login: null,
        },
      },
      doctorFullName: '',
      doctorBirthDate: '',
      profileTabActive: 'personalData',
      loading: false,
      loadingBtn: false,
      schedules: [],
      linkToInvoicesShow: false,
      viewMedicalCasesShow: false,
    };
  },
  async mounted() {
    this.loading = true;
    this.doctorInfo = await DoctorManager.getDoctorInfo(this.$route.params.id);
    this.doctorFullName = `${this.doctorInfo.lastName} ${this.doctorInfo.firstName} ${this.doctorInfo.patronymic}`;
    this.doctorPatientsFilter = this.$store.getters.doctorPatientsFilter;
    this.filter.doctor = this.$route.params.id;
    await this.loadTableData();
    await this.loadScheduleData();
    let currentUser = AuthManager.getUser();
    this.linkToInvoicesShow = currentUser.roles.find(
      (x) => x == 'view_invoice'
    );
    this.viewMedicalCasesShow = currentUser.roles.find(
      (x) => x == 'view_medical_case'
    );
    await this.$patientHub.start();
    this.$patientHub.client.on('PatientChange', () => {
      this.loadTableData();
    });
    this.$patientHub.client.on('ScheduleChange', () => {
      this.loadScheduleData();
    });
    this.loading = false;
  },
  methods: {
    //загрузка данных в таблицу по текущему фильтру
    async loadTableData() {
      axios
        .get('patient', { params: this.doctorPatientsFilter })
        .then(async (data) => {
          this.tableData = data.data.value;
          this.tableTotal = data.data.rowCount;
          await this.$store.commit(
            'SET_DOCTORPATIENTSFILTER',
            this.doctorPatientsFilter
          );
          await this.$store.commit('SET_PATIENTS', this.tableData);
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: `Невозможно загрузить список пациентов!`,
          });
        });
    },
    async loadScheduleData() {
      axios
        .get('schedule')
        .then(async (data) => {
          this.$store.commit('SET_SCHEDULES', data.data);
          this.$store.commit('SET_SCHEDULEFILTER', this.filter);
          this.schedules = await TimetableManager.getSchedule(this.filter);
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: `Невозможно загрузить расписание!`,
          });
        });
    },

    //форматирование значений в таблице пациентов
    formatterName(row) {
      return row.lastName + ' ' + row.firstName + ' ' + row.patronymic;
    },
    formatterDate(row) {
      return new Intl.DateTimeFormat('ru-RU').format(new Date(row.birthDate));
    },
    formatterVisitDate(row) {
      return row.lastVisitDate
        ? new Intl.DateTimeFormat('ru-RU').format(new Date(row.lastVisitDate))
        : '';
    },

    //пагинация таблицы пациентов
    async handleSizeChange(val) {
      this.doctorPatientsFilter.pagesize = val;
      await this.loadTableData();
    },
    async handleCurrentChange() {
      await this.loadTableData();
    },

    //сортировка таблицы
    async handleSortChange(val) {
      this.doctorPatientsFilter.sortColumn = val.prop;
      this.doctorPatientsFilter.sortOrder = val.order == 'descending' ? 1 : 0;
      await this.loadTableData();
    },

    //быстрая фильтрация
    async handlePatientRowSearch() {
      await this.loadTableData();
    },

    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.loadingBtn = true;
            this.doctorInfo.user.password = this.ruleForm.userPassword;
            await DoctorManager.updateDoctorInfo(this.doctorInfo);
            this.loadingBtn = false;
          } catch (error) {
            this.loadingBtn = false;
            if (error.response.status == 400) {
              this.$message({
                type: 'error',
                message: 'Ошибка!',
              });
            }
          }
        }
      });
    },
    handleCellValueTime(date) {
      if (this.schedules.length == 0) {
        return '';
      }
      let scheduleDate = this.schedules.find(
        (x) =>
          new Date(x.dateTimeFrom).getFullYear() == date.getFullYear() &&
          new Date(x.dateTimeFrom).getMonth() == date.getMonth() &&
          new Date(x.dateTimeFrom).getDate() == date.getDate()
      );
      if (scheduleDate) {
        let timeFrom = new Intl.DateTimeFormat('ru-RU', {
          hour: 'numeric',
          minute: 'numeric',
        }).format(new Date(scheduleDate.dateTimeFrom));
        let timeTo = new Intl.DateTimeFormat('ru-RU', {
          hour: 'numeric',
          minute: 'numeric',
        }).format(new Date(scheduleDate.dateTimeTo));
        return {
          status: true,
          value: `${timeFrom} - ${timeTo}`,
        };
      }
      if (date.getMonth() != this.filter.date.getMonth()) {
        return '';
      }
      return {
        status: false,
        value: '',
      };
    },
    handleCellValueDate(date) {
      return new Intl.DateTimeFormat('ru-RU', { day: '2-digit' }).format(
        new Date(date)
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
