import moment from 'moment';
/**
 * Method - Method for getting duration in minuties as a number by TimeSpan
 * @param {String} timeSpan - TimeSpan is the same TimeSpan in .NET (ex. "00:15:00")
 * @returns Duration in minutes (Number)
 */
const getDurationAsMinutes = (timeSpan) => {
  return moment.duration(timeSpan).asMinutes();
};
/**
 * Method - Method for getting duration in TimeSpan by minutes (Number)
 * @param {Number} minutes - Number (ex. 15)
 * @returns TimeSpan. The same as in .NET (ex. "00:15:00")
 */
const getDurationStringByMinutes = (minutes) => {
  let d = moment.duration(minutes, 'minutes');
  return `${d.hours().toString().padStart(2, '0')}:${d
    .minutes()
    .toString()
    .padStart(2, '0')}:${d.seconds().toString().padStart(2, '0')}`;
};
/**
 * Method - Method for getting duration in TimeSpan by hours (Number) and minutes (Number)
 * @param {Number} hours - Number (ex. 2)
 * @param {Number} minutes - Number (ex. 15)
 * @returns TimeSpan. The same as in .NET (ex. "02:15:00")
 */
const getDurationStringByHoursAndMinutes = (hours, minutes) => {
  let d = moment.duration({
    hours: hours,
    minutes: minutes,
  });
  return `${d.hours().toString().padStart(2, '0')}:${d
    .minutes()
    .toString()
    .padStart(2, '0')}:${d.seconds().toString().padStart(2, '0')}`;
};

/**
 * Method - Method for getting local ru-RU weekday from DateTimeString
 * @param {Number} date - DateTimeString (ex. "2024-0202T10:00:00" )
 * @param {Number} format - The same as formay in ussual JS methods (DateTimeFormatOptions.weekday, long" | "short" | "narrow")
 * @returns String, ex. "пн"
 */
const getWeekdayFromDate = (date, format) => {
  try {
    return new Date(date).toLocaleDateString('ru-RU', { weekday: format });
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * Method - Set Moment JS from string. The method is used to compare two moments
 * @param {String} string - TimeString (ex. "08:00") or DateTimeString (ex. "2024-0202T10:00:00" )
 * @param {Number} type - Method Execution type: type = 0 - accept TimeString, type = 1 - accept DateTimeString
 * @returns Object Moment
 */
const getMomentForCompareTimes = (string, type) => {
  try {
    let result = null;
    switch (type) {
      case 0:
        result = moment().set({
          hour: Number(string.slice(0, 2)),
          minute: Number(string.slice(3, 5)),
          second: 0,
          millisecond: 0,
          date: '01',
          month: '01',
          year: '0000',
        });
        break;
      case 1:
        result = moment().set({
          hour: Number(moment(string).format('HH')),
          minute: Number(moment(string).format('mm')),
          second: 0,
          millisecond: 0,
          date: '01',
          month: '01',
          year: '0000',
        });
        break;

      default:
        break;
    }
    return result;
  } catch (err) {
    console.log('Error', err);
  }
};
export default {
  getDurationAsMinutes,
  getDurationStringByMinutes,
  getDurationStringByHoursAndMinutes,
  getWeekdayFromDate,
  getMomentForCompareTimes,
};
