<template>
  <div>
    <el-input
      size="small"
      type="text"
      placeholder="Поиск..."
      v-model="search"
      @input="searchLesson"
    ></el-input>
    <el-menu
      :default-active="$router.currentRoute.path"
      :router="true"
      class="el-menu-list education-menu mt-16"
    >
      <el-menu-item
        v-for="item in articlesSearch"
        :key="item.id"
        :index="item.path"
      >
        <span>{{ item.name }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: null,
      articlesSearch: [],
      articles: [
        {
          name: 'С чего начать использование Documeds?',
          path: '/education/start-page',
        },
        {
          name: 'Как настроить свою организацию?',
          path: '/education/organization-lesson',
        },
        {
          name: 'Добавление филиалов и кабинетов',
          path: '/education/add-branches-cabinets-lesson',
        },
        {
          name: 'Добавление подразделений и должностей',
          path: '/education/add-departments-position-lesson',
        },
        {
          name: 'Добавление сотрудников и назначение их на должности',
          path: '/education/add-staff-lesson',
        },
        {
          name: 'Создание графика работ и распределение врачей по кабинетам',
          path: '/education/add-timetable-lesson',
        },
        {
          name: 'Создание справочника услуг организации (прайс-листа)',
          path: '/education/add-price-list-lesson',
        },
        {
          name: 'Добавление и редактирование пациента',
          path: '/education/add-patient-lesson',
        },
        {
          name: 'Запись пациента на прием',
          path: '/education/add-appointment-lesson',
        },
        {
          name: 'Оформление приема пациента и заполнение протокола осмотра',
          path: '/education/add-visit-lesson',
        },
        {
          name: 'Формирование счета на оказанные услуги',
          path: '/education/add-invoice-lesson',
        },
        {
          name: 'Раздел «Рабочий стол»',
          path: '/education/dashboard-lesson',
        },
        {
          name: 'Раздел «Мой профиль»',
          path: '/education/profile-lesson',
        },
        {
          name: 'Раздел «Шаблоны медицинских документов»',
          path: '/education/protocol-settings-lesson',
        },
        {
          name: 'Раздел «Настройки шаблонов для печати»',
          path: '/education/print-templates-lesson',
        },
        {
          name: 'Раздел «Аналитика»',
          path: '/education/analytics-lesson',
        },
        {
          name: 'Помощь',
          path: '/education/support-lesson',
        },
      ],
    };
  },
  created() {
    this.articlesSearch = this.articles;
  },
  methods: {
    searchLesson() {
      if (this.search) {
        this.articlesSearch = this.articles.filter((a) =>
          a.name.toUpperCase().includes(this.search.toUpperCase())
        );
      } else {
        this.articlesSearch = this.articles;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
