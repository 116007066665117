<template>
  <div class="page-wrap patient">
    <div v-loading="loading" class="patient-wrap">
      <div class="patient-header">
        <h5 class="patient-header__title">{{ title }}</h5>
      </div>
      <div class="patient-subheader">
        <el-row :gutter="24">
          <el-col :sm="24" :md="12">
            <div class="patient-info">
              <el-avatar
                class="patient-info__avatar"
                icon="el-icon-user"
                size="medium"
              ></el-avatar>
              <div class="d-flex flex-column">
                <span class="patient-info__fullname">{{
                  patientStringInfo
                }}</span>
                <p class="patient-info__last-visit">
                  {{ patientLastVisitInfo }} -
                  <router-link
                    :to="{ path: '/patient/' + patientId + '/medical-cases/' }"
                    >Перейти к случаям обращения</router-link
                  >
                </p>
              </div>
            </div>
          </el-col>
          <el-col :sm="12" :md="6">
            <label>Номер карты</label>
            <el-input
              disabled
              size="small"
              :placeholder="placeholderCardNumber"
            ></el-input>
            <el-checkbox
              style="margin-top: 4px"
              v-model="patient.physicalPatientCardExists"
              >Наличие карты в регистратуре</el-checkbox
            >
          </el-col>
        </el-row>
      </div>
      <div class="patient-content">
        <el-tabs v-model="activeTabName">
          <el-tab-pane name="common">
            <span slot="label"
              >Общие сведения<i
                v-show="isCommonTabInvalid"
                class="el-icon-info-circle danger"
              ></i
            ></span>
            <el-row :gutter="24">
              <el-col :sm="24" :md="12" :lg="6">
                <label>Фамилия <span class="danger">*</span></label>
                <el-input
                  size="small"
                  placeholder="Фамилия"
                  v-model="patient.lastName"
                  @change="
                    genderDetermination(
                      patient.lastName,
                      patient.firstName,
                      patient.patronymic
                    )
                  "
                  :class="{
                    invalid:
                      $v.patient.lastName.$dirty &&
                      !$v.patient.lastName.required,
                  }"
                >
                </el-input>
                <small
                  class="helper-text invalid"
                  v-if="
                    $v.patient.lastName.$dirty && !$v.patient.lastName.required
                  "
                  >Поле является обязательным</small
                >
              </el-col>
              <el-col :sm="24" :md="12" :lg="6">
                <label>Имя <span class="danger">*</span></label>
                <el-input
                  size="small"
                  placeholder="Имя"
                  v-model="patient.firstName"
                  @change="
                    genderDetermination(
                      patient.lastName,
                      patient.firstName,
                      patient.patronymic
                    )
                  "
                  :class="{
                    invalid:
                      $v.patient.firstName.$dirty &&
                      !$v.patient.firstName.required,
                  }"
                ></el-input>
                <small
                  class="helper-text invalid"
                  v-if="
                    $v.patient.firstName.$dirty &&
                    !$v.patient.firstName.required
                  "
                  >Поле является обязательным</small
                >
              </el-col>
              <el-col :sm="24" :md="12" :lg="6">
                <label>Отчество</label>
                <el-input
                  size="small"
                  placeholder="Отчество"
                  v-model="patient.patronymic"
                  @change="
                    genderDetermination(
                      patient.lastName,
                      patient.firstName,
                      patient.patronymic
                    )
                  "
                ></el-input>
              </el-col>
              <el-col :sm="24" :md="12" :lg="6">
                <label>Пол <span class="danger">*</span></label>
                <el-radio-group
                  class="patient-gender"
                  v-model="patient.gender"
                  size="small"
                >
                  <el-radio :label="1">Мужской</el-radio>
                  <el-radio :label="0">Женский</el-radio>
                </el-radio-group>
              </el-col>
            </el-row>
            <el-row :gutter="24" class="mt-16">
              <el-col :sm="24" :md="12" :lg="6">
                <label>Дата рождения</label>
                <el-date-picker
                  size="small"
                  format="dd.MM.yyyy"
                  value-format="yyyy-MM-dd"
                  v-model="patient.birthDate"
                  type="date"
                  :picker-options="{ firstDayOfWeek: 1 }"
                  placeholder="Введите дату"
                >
                </el-date-picker>
              </el-col>
              <el-col :sm="24" :md="12" :lg="6">
                <label>Телефон <span class="danger">*</span></label>
                <el-input
                  size="small"
                  placeholder="+7 000 000 00 00"
                  v-model="patient.phone"
                  v-maska
                  data-maska="+7##########"
                  :class="{
                    invalid:
                      ($v.patient.phone.$dirty && !$v.patient.phone.required) ||
                      ($v.patient.phone.$dirty && !$v.patient.phone.minLength),
                  }"
                ></el-input>
                <small
                  class="helper-text invalid"
                  v-if="$v.patient.phone.$dirty && !$v.patient.phone.required"
                  >Поле является обязательным</small
                >
                <small
                  class="helper-text invalid"
                  v-else-if="
                    $v.patient.phone.$dirty && !$v.patient.phone.minLength
                  "
                >
                  Введите корректный номер телефона
                </small>
              </el-col>
              <el-col :sm="24" :md="12" :lg="6">
                <label>Email</label>
                <el-input
                  size="small"
                  placeholder="Введите Email"
                  v-model="patient.email"
                ></el-input>
              </el-col>
              <el-col :sm="24" :md="12" :lg="6">
                <label>Согласие на рассылку уведмлений</label>
                <el-checkbox
                  class="patient-consent-notification"
                  v-model="patient.consentToNotification"
                  >Да</el-checkbox
                >
              </el-col>
            </el-row>
            <el-row :gutter="24" class="mt-16">
              <el-col :sm="24" :md="9">
                <label class="patient-form-label" for=""
                  >Адрес регистрации</label
                >
                <DaDataAutoCompleteComponent
                  @input="copyToResidenceAddress"
                  placeholder="Введите адрес регистрации"
                  v-model="patient.registerAddress"
                ></DaDataAutoCompleteComponent>
                <el-checkbox
                  class="mt-12"
                  v-model="matchedRegisterAddress"
                  @change="copyToResidenceAddress"
                  >Совпадает с адресом проживания
                </el-checkbox>
              </el-col>
              <el-col :sm="24" :md="9">
                <label>Адрес проживания</label>
                <DaDataAutoCompleteComponent
                  :disabled="matchedRegisterAddress"
                  placeholder="Введите адрес проживания"
                  v-model="patient.residenceAddress"
                ></DaDataAutoCompleteComponent>
              </el-col>
              <el-col :sm="24" :md="6">
                <label>Местность <span class="danger">*</span></label>
                <el-radio-group
                  class="patient-gender"
                  v-model="patient.location"
                  size="small"
                >
                  <el-radio :label="1">Городская</el-radio>
                  <el-radio :label="0">Сельская</el-radio>
                </el-radio-group>
              </el-col>
            </el-row>
            <el-row :gutter="24" class="mt-16">
              <el-col :sm="24" :md="9">
                <label>Комментарий</label>
                <el-input
                  size="small"
                  type="textarea"
                  resize="none"
                  :autosize="{ minRows: 3, maxRows: 4 }"
                  :rows="3"
                  placeholder="Введите комментарий"
                  v-model="patient.note"
                ></el-input>
              </el-col>
              <el-col :sm="24" :md="9">
                <label>Теги</label>
                <el-select
                  class="patient-tag-select"
                  size="small"
                  v-model="patient.patientTags"
                  multiple
                  placeholder="Выберите тег"
                >
                  <el-option
                    v-for="tag in tagList"
                    :key="tag.idTag"
                    :label="tag.value"
                    :value="tag"
                  >
                  </el-option>
                </el-select>
                <span class="patient-tag-select-description">
                  <i class="el-icon-info-circle"></i> Вы можете дополнительно
                  отметить пациента, установив один или несколько тегов</span
                >
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane name="documents">
            <span slot="label"
              >Документы<i
                v-show="isDocumentsTabInvalid"
                class="el-icon-info-circle danger"
              ></i
            ></span>
            <span class="patient-document-title">Общие документы</span>
            <el-row :gutter="24" class="mb-16">
              <el-col :sm="24" :md="12" :lg="4">
                <label>СНИЛС</label>
                <el-input
                  size="small"
                  placeholder="Введите СНИЛС"
                  v-maska
                  data-maska="###-###-### ##"
                  v-model="patient.snils"
                  :class="{
                    invalid:
                      $v.patient.snils.$dirty && !$v.patient.snils.minLength,
                  }"
                ></el-input>
                <small
                  class="helper-text invalid"
                  v-if="$v.patient.snils.$dirty && !$v.patient.snils.minLength"
                  >СНИЛС должен содержать 11 цифр</small
                >
              </el-col>
              <el-col :sm="24" :md="12" :lg="4">
                <label>ИНН</label>
                <el-input
                  size="small"
                  placeholder="Введите ИНН"
                  v-maska
                  data-maska="############"
                  v-model="patient.inn"
                  :class="{
                    invalid: $v.patient.inn.$dirty && !$v.patient.inn.minLength,
                  }"
                ></el-input>
                <small
                  class="helper-text invalid"
                  v-if="$v.patient.inn.$dirty && !$v.patient.inn.minLength"
                  >Введите корректный ИНН</small
                >
              </el-col>
            </el-row>
            <span class="patient-document-title">Паспорт РФ</span>
            <el-row :gutter="24" class="mb-16">
              <el-col :sm="24" :md="12" :lg="4">
                <label>Серия паспорта</label>
                <el-input
                  size="small"
                  placeholder="Серия паспорта"
                  v-maska
                  data-maska="####"
                  v-model="patient.passportSeries"
                  :class="{
                    invalid:
                      $v.patient.passportSeries.$dirty &&
                      !$v.patient.passportSeries.minLength,
                  }"
                ></el-input>
                <small
                  class="helper-text invalid"
                  v-if="
                    $v.patient.passportSeries.$dirty &&
                    !$v.patient.passportSeries.minLength
                  "
                  >Введите корректную Серию</small
                >
              </el-col>
              <el-col :sm="24" :md="12" :lg="4">
                <label>Номер</label>
                <el-input
                  size="small"
                  placeholder="Номер паспорта"
                  v-maska
                  data-maska="######"
                  v-model="patient.passportNumber"
                  :class="{
                    invalid:
                      $v.patient.passportNumber.$dirty &&
                      !$v.patient.passportNumber.minLength,
                  }"
                ></el-input>
                <small
                  class="helper-text invalid"
                  v-if="
                    $v.patient.passportNumber.$dirty &&
                    !$v.patient.passportNumber.minLength
                  "
                  >Введите корректный Номер</small
                >
              </el-col>
              <el-col :sm="24" :md="12" :lg="8">
                <label>Кем выдан</label>
                <el-autocomplete
                  size="small"
                  placeholder="Введите кем выдан паспорт"
                  v-model="patient.passportIssuer"
                  :fetch-suggestions="getPassportIssuerSuggestion"
                ></el-autocomplete>
              </el-col>
              <el-col :sm="24" :md="12" :lg="4">
                <label>Код подразделения</label>
                <el-input
                  size="small"
                  placeholder="Код подразделения"
                  v-maska
                  data-maska="###-###"
                  v-model="patient.passportIssuerCode"
                  :class="{
                    invalid:
                      $v.patient.passportIssuerCode.$dirty &&
                      !$v.patient.passportIssuerCode.minLength,
                  }"
                ></el-input>
                <small
                  class="helper-text invalid"
                  v-if="
                    $v.patient.passportIssuerCode.$dirty &&
                    !$v.patient.passportIssuerCode.minLength
                  "
                  >Введите корректный Код подразделения</small
                >
              </el-col>
              <el-col :sm="24" :md="12" :lg="4">
                <label>Дата выдачи</label>
                <el-date-picker
                  size="small"
                  format="dd.MM.yyyy"
                  value-format="yyyy-MM-dd"
                  v-model="patient.passportDateFrom"
                  type="date"
                  placeholder="Введите дату"
                ></el-date-picker>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="Страхование" name="insurance">
            <div class="patient-insurance-header">
              <el-button
                type="primary"
                size="small"
                @click="openModalAddEditInsurancePolicy(null)"
              >
                Добавить полис<i class="el-icon-plus"></i>
              </el-button>
              <router-link
                class="patient-insurance-link"
                :to="{ path: '/patient/' + patientId + '/guarantee-letters' }"
                >Гарантийные письма <i class="el-icon-arrow-right"></i
              ></router-link>
            </div>
            <el-table :data="insurancePolicyListWithCompany" stripe>
              <el-table-column width="140px" label="Тип страхования">
                <template slot-scope="scope">
                  <el-tag size="small">{{
                    scope.row.insuranceType === 0 ? 'ОМС' : 'ДМС'
                  }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                prop="singlePolicyNumber"
                label="ЕПН"
              ></el-table-column>
              <el-table-column prop="series" label="Серия"></el-table-column>
              <el-table-column prop="number" label="Номер"></el-table-column>
              <el-table-column
                prop="insuranceCompany"
                label="Страховая компания"
              >
              </el-table-column>
              <el-table-column
                prop="validFrom"
                label="Дата начала"
                :formatter="formatterDate"
              ></el-table-column>
              <el-table-column
                prop="validUntil"
                label="Дата окончания"
                :formatter="formatterDate"
              ></el-table-column>
              <el-table-column width="80px">
                <template slot-scope="scope">
                  <el-dropdown size="mini" trigger="click">
                    <span class="el-dropdown-link"
                      ><i class="el-icon-more"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item type="button">
                        <el-button
                          class="dropdown-btn"
                          size="small"
                          type="text"
                          @click="openModalAddEditInsurancePolicy(scope.row)"
                        >
                          <i class="el-icon-edit el-icon-left"></i>
                          Редактировать
                        </el-button>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <el-button
                          class="dropdown-btn danger"
                          size="small"
                          type="text"
                          @click="deleteInsurancePolicy(scope.row)"
                        >
                          <i class="el-icon-delete"></i>
                          Удалить</el-button
                        >
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="patient-buttons pb">
      <el-button type="primary" size="small" @click="savePatientAsync"
        >Сохранить</el-button
      >
      <el-button plain size="small" @click="redirectToPatientPage"
        >Отменить</el-button
      >
    </div>
    <ModalAddEditInsuracePolicyComponent
      :is-modal-add-edit-insurance-policy-visible.sync="
        isModalAddEditInsurancePolicyVisible
      "
      :insurance-organization-list="insuranceOrganizationList"
      :insurance-policy="insurancePolicy"
      :is-edit-mode="isEditMode"
      @clearAddEditModalInsurancePolicy="clearAddEditModalInsurancePolicy"
      @addInsurancePolicy="addInsurancePolicy"
      @updateInsurancePolicy="updateInsurancePolicy"
    ></ModalAddEditInsuracePolicyComponent>
  </div>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import { getGender } from 'lvovich';
import DaDataAutoCompleteComponent from '@/components/DaDataAutoCompleteComponent.vue';
import { getAge, getAgeString, getDateString } from '@/services/commonService';
import ModalAddEditInsuracePolicyComponent from '@/components/ModalAddEditInsuracePolicyComponent.vue';
export default {
  name: 'PatientAddEditPage',
  components: {
    DaDataAutoCompleteComponent,
    ModalAddEditInsuracePolicyComponent,
  },
  props: {},
  data() {
    return {
      loading: false,
      activeTabName: 'common',
      matchedRegisterAddress: true,
      isModalAddEditInsurancePolicyVisible: false,
      insurancePolicy: {
        id: null,
        insuranceCompanyId: null,
        insuranceType: 0,
        number: null,
        series: null,
        singlePolicyNumber: null,
        patientId: null,
      },
      isEditMode: false,
    };
  },
  computed: {
    patientId() {
      return parseInt(this.$route.params.id);
    },
    patient: {
      get() {
        return this.$store.getters.patient;
      },
      set(value) {
        return value;
      },
    },
    title() {
      return this.patientId ? 'Редактирование пациента' : 'Добавление пациента';
    },
    placeholderCardNumber() {
      return !this.patientId
        ? 'Присваивается автоматически'
        : this.patient.cardNumber;
    },

    patientStringInfo() {
      let infoString =
        (this.patient.lastName ? this.patient.lastName : '') +
        ' ' +
        (this.patient.firstName ? this.patient.firstName : '') +
        ' ' +
        (this.patient.patronymic ? this.patient.patronymic : '');
      if (this.patient.birthDate) {
        infoString =
          infoString + ', ' + getAgeString(getAge(this.patient.birthDate));
      }
      if (this.patient.lastName && this.patient.firstName) {
        return infoString;
      } else {
        return 'Здесь будет ФИО пациента и его возраст';
      }
    },
    patientLastVisitInfo() {
      return this.patient.lastVisitDate
        ? `Последнее посещение клиники: ${getDateString(
            this.patient.lastVisitDate
          )}`
        : 'Здесь будет указано последнее посещение клиники';
    },
    tagList: {
      get() {
        return this.$store.getters.tagList;
      },
    },
    isCommonTabInvalid() {
      let invalid =
        (this.$v.patient.lastName.$dirty &&
          !this.$v.patient.lastName.required) ||
        (this.$v.patient.firstName.$dirty &&
          !this.$v.patient.firstName.required) ||
        (this.$v.patient.phone.$dirty && !this.$v.patient.phone.minLength);
      return invalid;
    },
    isDocumentsTabInvalid() {
      let invalid =
        (this.$v.patient.snils.$dirty && !this.$v.patient.snils.minLength) ||
        (this.$v.patient.inn.$dirty && !this.$v.patient.inn.minLength) ||
        (this.$v.patient.passportSeries.$dirty &&
          !this.$v.patient.passportSeries.minLength) ||
        (this.$v.patient.passportNumber.$dirty &&
          !this.$v.patient.passportNumber.minLength) ||
        (this.$v.patient.passportIssuerCode.$dirty &&
          !this.$v.patient.passportIssuerCode.minLength);
      return invalid;
    },
    insuranceOrganizationList() {
      return this.$store.getters.contractorOrganizations;
    },
    insurancePolicyList() {
      return this.$store.getters.insurancePolicyListByPatientId(this.patientId);
    },
    insurancePolicyListWithCompany() {
      for (let p of this.insurancePolicyList) {
        for (let c of this.insuranceOrganizationList) {
          if (p.insuranceCompanyId === c.id) {
            p.insuranceCompany = c.shortName;
          }
        }
      }
      return this.insurancePolicyList;
    },
  },
  async mounted() {
    this.$store.dispatch('getTagList');
    if (this.patientId) {
      this.loading = true;
      this.$store
        .dispatch('getPatient', {
          patientId: this.patientId,
          pagesize: 10,
          pagenumber: 1,
        })
        .then(() => {
          this.patient = this.$store.getters.patient;
          this.loading = false;
        })
        .catch(() => {
          this.$router.push('/404');
        });
    }
    this.$store.dispatch('getContractorOrganizations', {
      pagesize: 10,
      pagenumber: 1,
    });
    this.$store.dispatch('getInsurancePolicies');
    await this.$patientHub.start();
  },
  destroyed() {
    console.log('Component has been destroyed!');
    this.$store.commit('SET_PATIENT', {
      ...this.$store.getters.defaultPatient,
    });
  },
  validations: {
    patient: {
      firstName: { required },
      lastName: { required },
      phone: { required, minLength: minLength(12) },
      snils: { minLength: minLength(14) },
      inn: { minLength: minLength(12) },
      email: { email },
      passportSeries: { minLength: minLength(4) },
      passportNumber: { minLength: minLength(6) },
      passportIssuerCode: { minLength: minLength(7) },
    },
  },
  methods: {
    formatterInsuranceCompany(row) {
      return `${row.insuranceCompany.shortName}`;
    },
    formatterDate(row, column, cellValue) {
      return cellValue ? getDateString(new Date(cellValue)) : 'Не указана';
    },
    openModalAddEditInsurancePolicy(insurancePolicy) {
      if (insurancePolicy) {
        this.insurancePolicy = insurancePolicy;
        this.isEditMode = true;
      }
      this.isModalAddEditInsurancePolicyVisible =
        !this.isModalAddEditInsurancePolicyVisible;
      console.log(insurancePolicy);
    },
    clearAddEditModalInsurancePolicy() {
      this.insurancePolicy = { ...this.$store.getters.defaultInsurancePolicy };
      this.isEditMode = false;
    },
    addInsurancePolicy(insurancePolicy) {
      if (this.patientId) {
        insurancePolicy.patientId = this.patientId;
        this.$store
          .dispatch('createInsurancePolicies', insurancePolicy)
          .then(() => {
            this.$message({
              type: 'success',
              message: 'Страховой полис успешно добавлен!',
            });
            this.$store.dispatch('getInsurancePolicies');
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: 'Возникла ошибка! Страховой полис не был добавлен!',
            });
          });
      } else {
        this.insurancePolicyList.push(insurancePolicy);
      }
    },
    updateInsurancePolicy(insurancePolicy) {
      if (this.patientId) {
        this.$store
          .dispatch('updateInsurancePolicies', insurancePolicy)
          .then(() => {
            this.$message({
              type: 'success',
              message: 'Страховой полис успешно изменен!',
            });
            this.$store.dispatch('getInsurancePolicies');
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: 'Возникла ошибка! Страховой полис не был изменен!',
            });
          });
      }
    },
    deleteInsurancePolicy(insurancePolicy) {
      if (insurancePolicy.id) {
        this.$confirm(
          'Вы действительно хотите удалить этот полис страхования?',
          'Предупреждение',
          {
            confirmButtonText: 'Подтвердить',
            cancelButtonText: 'Отменить',
            type: 'warning',
          }
        )
          .then(() => {
            this.$store
              .dispatch('deleteInsurancePolicyById', insurancePolicy.id)
              .then(() => {
                this.$message({
                  type: 'success',
                  message: 'Страховой полис успешно удален!',
                });
                this.$store.dispatch('getInsurancePolicies');
              })
              .catch(() => {
                this.$message({
                  type: 'error',
                  message: 'Произошла ошибка! Страховой полис не был удален!',
                });
              });
          })
          .catch(() => {
            return;
          });
      } else {
        this.$confirm(
          'Вы действительно хотите удалить этот полис страхования?',
          'Предупреждение',
          {
            confirmButtonText: 'Подтвердить',
            cancelButtonText: 'Отменить',
            type: 'warning',
          }
        )
          .then(() => {
            this.insurancePolicyList.splice(
              this.insurancePolicyList.indexOf(insurancePolicy),
              1
            );
          })
          .catch(() => {
            return;
          });
      }
    },
    saveInsurancePoliciesAsync(addedPatient) {
      for (let insurancePolicy of this.insurancePolicyList) {
        this.$store
          .dispatch('createInsurancePolicies', {
            ...insurancePolicy,
            patientId: addedPatient.id,
          })
          .then(() => {
            this.$message({
              type: 'success',
              message: `Инфомация по страхованию пациента успешно добавлена.`,
            });
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: `При добавлении страховой информации пациента возникла ошибка. Попробуйте еще раз.`,
            });
          });
      }
    },
    savePatientAsync() {
      // проверяем форму на заполненность обязательных данных
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.$message({
          type: 'error',
          message: 'Заполните обязательные поля!',
        });
        return;
        // если форма валидна
      } else {
        // если id пациента получен - изменяем пациента
        if (this.patientId) {
          this.$store
            .dispatch('updatePatient', this.patient)
            .then(() => {
              this.$message({
                type: 'success',
                message: 'Данные пациента успешно изменены!',
              });
              this.redirectToPatientPage();
              // this.$patientHub.client.invoke('BroadcastPatientChange');
            })
            .catch(() => {
              this.$message({
                type: 'error',
                message: 'Произошла ошибка! Данные пациента не изменены!',
              });
            });
          // если нет, создаем нового
        } else {
          this.$store
            .dispatch('createPatient', this.patient)
            .then(() => {
              this.$message({
                type: 'success',
                message: `Добавлен новый пациент \n${this.patient.lastName} ${this.patient.firstName}`,
              });
              const addedPatient = this.$store.getters.addedPatient;
              this.saveInsurancePoliciesAsync(addedPatient);
              this.redirectToPatientPage();
            })
            .catch(() => {
              this.$message({
                type: 'error',
                message: 'Произошла ошибка! Пациент не был добавлен!',
              });
            });
        }
      }
    },
    redirectToPatientPage() {
      this.$router.push('/patients');
    },
    //автоматическое определение пола
    genderDetermination(lastName, firstName, patronymic) {
      let gender = getGender({
        last: lastName,
        first: firstName,
        middle: patronymic,
      });
      if (gender == 'female') {
        this.patient.gender = 0;
      } else {
        this.patient.gender = 1;
      }
    },

    //автоматическое заполнение адреса проживания адресом регистрации
    copyToResidenceAddress() {
      if (this.matchedRegisterAddress == true) {
        this.patient.residenceAddress = this.patient.registerAddress;
      }
    },

    //подсказка органа, выдавшего паспорт
    async getPassportIssuerSuggestion(queryString, cb) {
      let suggestionList = await this.$store.dispatch(
        'getPassportIssuerSuggestion',
        queryString
      );
      cb(suggestionList);
    },
  },
};
</script>

<style lang="scss" scoped></style>
