<template>
  <div class="education-main">
    <el-row :gutter="24">
      <el-col :lg="18" class="d-flex flex-between flex-center mb-16 flex-wrap">
        <h4 class="mt-16">Добавление подразделений и должностей</h4>
        <el-button class="mt-16" size="small" @click="openModalVideo" type="primary"
          >Смотреть видео<i class="el-icon-play"></i
        ></el-button>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt-16">
      <el-col :lg="18">
        <h6 class="mb-16">Добавление подразделений</h6>
        <p>
          Подразделения в системе Documeds представляют собой отделы
          (отделения), в которые могут быть добавлены сотрудники для исполнения
          своих должностей. Подразделения, как и кабинеты, закреплены за
          определенным филиалом.
        </p>
        <p>
          Для просмотра и добавления подразделений в Вашу организацию необходимо
          перейти в пункт меню «Настройки»/«Подразделения».
        </p>
        <el-divider></el-divider>
      </el-col>
      <el-col :lg="18">
        <el-image fit="contain" :src="src.url1" :preview-src-list="srcList">
        </el-image>
      </el-col>
      <el-col :lg="18">
        <el-divider></el-divider>
        <p>
          Для добавления подразделений необходимо выбрать нужную карточку
          филиала и нажать кнопку «Добавить».
        </p>
        <el-image fit="cover" :src="src.url2" :preview-src-list="srcList">
        </el-image>
      </el-col>
      <el-col :lg="18">
        <el-divider></el-divider>
        <p>
          Наименование подразделения является обязательным к заполнению и
          отмечено символом <span class="danger">*</span>
        </p>
        .
        <p>
          Также Вы можете указать в описании подразделения дополнительную
          информацию.
        </p>
        <p>
          Для редактирования существующего подразделения необходимо нажать
          кнопку «Редактировать» на карточке с подразделением.
        </p>
        <el-image fit="cover" :src="src.url3" :preview-src-list="srcList">
        </el-image>
        <p>
          Корректное заполнение подразделений необходимо для правильного учета
          сотрудников в дальнейшем и правильного назначения их на должность.
        </p>
      </el-col>
      <el-col :lg="18">
        <el-divider></el-divider>
        <h6 class="mb-16">Добавление должностей</h6>
        <el-image
          class="mt-16 mb-16"
          fit="cover"
          :src="src.url4"
          :preview-src-list="srcList">
        </el-image>
        <p>
          Для просмотра и добавления должностей в Вашу организацию необходимо
          перейти в пункт меню «Настройки»/«Должности».
        </p>
        <p>
          Данный раздел представляет собой справочник должностей сотрудников
          организации. Добавление должности происходит аналогично добавлению
          подразделений за исключением того, что должности не привязаны к
          филиалу организации, а доступны во всей организации в целом.
        </p>
      </el-col>
    </el-row>
    <!-- модальное окно просмотра видео-урока -->
    <el-dialog
      title="Видео-урок"
      class="modal-play-video"
      :visible.sync="modalVideo"
      width="100%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true">
      <div v-if="!idVideo">
        <h6>К сожалению видео-урок не доступен</h6>
        <p>
          В скором времени мы добавим данный видео-урок по обучению работе в
          Documeds
        </p>
      </div>
      <div v-if="idVideo">
        <VideoPlayerComponent :idVideo="idVideo"></VideoPlayerComponent>
      </div>
      <el-button
        class="education-modal-btn"
        type="primary"
        @click="openModalVideo"
        >Продолжить обучение в текстовом формате</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
  import VideoPlayerComponent from "@/components/VideoPlayerComponent.vue";
  export default {
    components: { VideoPlayerComponent },
    data() {
      return {
        modalVideo: false,
        idVideo: "wpeAyG1Mb6w",
        src: {
          url1: require("@/assets/img/education/add-departments-position-1.png"),
          url2: require("@/assets/img/education/add-departments-position-2.png"),
          url3: require("@/assets/img/education/add-departments-position-3.png"),
          url4: require("@/assets/img/education/add-departments-position-4.png"),
        },
        srcList: [
          require("@/assets/img/education/add-departments-position-1.png"),
          require("@/assets/img/education/add-departments-position-2.png"),
          require("@/assets/img/education/add-departments-position-3.png"),
          require("@/assets/img/education/add-departments-position-4.png"),
        ],
      };
    },
    methods: {
      openModalVideo() {
        this.modalVideo = !this.modalVideo;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
