<template>
  <div>
    <el-dialog
      title="Предварительный просмотр документа"
      class="modal-view-pdf"
      :visible="pdfViewVisible"
      @update:visible="$emit('update:pdfViewVisible')"
      :fullscreen="true">
      <iframe
        id="iframe"
        class="modal-view-iframe"
        :src="source"
        frameborder="0">
      </iframe>
    </el-dialog>
  </div>
</template>

<script>
  // import PdfPrintManager from "@/services/pdfPrintManager.js";
  export default {
    name: 'PdfViewComponent',
    data() {
      return {};
    },
    props: { pdfViewVisible: Boolean, source: String },
  };
</script>

<style lang="scss"></style>
