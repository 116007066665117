import { render, staticRenderFns } from "./DirectoryMedication.vue?vue&type=template&id=54ffd990&scoped=true&"
import script from "./DirectoryMedication.vue?vue&type=script&lang=js&"
export * from "./DirectoryMedication.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54ffd990",
  null
  
)

export default component.exports