import documentsForPrintApi from '@/api/documentsForPrint';

export const mutationTypes = {
  setDocumentsForPrint: 'SET_DOCUMENTS_FOR_PRINT',
};

export const getterTypes = {
  documentsForPrintOnInvoicesPage: 'documentsPrintForInvoices',
  documentsForPrintOnOtherPages: 'documentsPrintForOtherPage',
};

export const actionTypes = {
  getDocumentsForPrint: 'getDocumentsForPrint',
};

const state = {
  documentsForPrint: [],
};

const getters = {
  [getterTypes.documentsForPrintOnInvoicesPage]: (state) => {
    return state.documentsForPrint
      .filter((x) => x.active)
      .filter((x) => x.name == 'Товарный чек');
  },
  [getterTypes.documentsForPrintOnOtherPages]: (state) => {
    return state.documentsForPrint
      .filter((x) => x.active)
      .filter((x) => x.name != 'Товарный чек');
  },
};

const mutations = {
  [mutationTypes.setDocumentsForPrint]: (state, payload) => {
    state.documentsForPrint = payload;
  },
};

const actions = {
  [actionTypes.getDocumentsForPrint]: async (context) => {
    const data = await documentsForPrintApi.getDocumentsForPrint();
    context.commit(mutationTypes.setDocumentsForPrint, data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
