import appointmentApi from '@/api/appointment';
import patientHub from '@/hubs/patientHub';
const appointmentModule = {
  state: () => ({
    appointment: null,
    defaultAppointment: {
      id: null,
      idSchedule: null,
      idAppointmentStatus: 1,
      idAppointmentType: 1,
      idPatient: null,
      idDoctor: null,
      clientOffset: null,
      dateTimeFrom: null,
      dateTimeTo: null,
      duration: 15,
      services: [],
      meddocs: [],
      files: [],
      idMedicalCase: null,
      isVisit: null,
      medicalCaseclosed: 0,
      patientNote: null,
      emailNotification: 0,
      smsNotification: 0,
      insurancePolicyId: null,
      guaranteeLetterId: null,
    },
    appointmentTypeList: [],
    appointmentStatusList: [],
  }),
  getters: {
    appointment: (state) => {
      return state.appointment;
    },
    defaultAppointment: (state) => {
      return state.defaultAppointment;
    },
    appointmentTypeList: (state) => {
      return state.appointmentTypeList;
    },
    appointmentStatusList: (state) => {
      return state.appointmentStatusList;
    },
  },
  mutations: {
    SET_APPOINTMENT: (state, payload) => {
      state.appointment = payload;
    },
    SET_APPOINTMENT_TYPE_LIST: (state, payload) => {
      state.appointmentTypeList = payload;
    },
    SET_APPOINTMENT_STATUS_LIST: (state, payload) => {
      state.appointmentStatusList = payload;
    },
  },
  actions: {
    getAppointment: async (context, params) => {
      let appointmentData = await appointmentApi.getAppointment(params);
      context.commit('SET_APPOINTMENT', appointmentData.data);
    },
    createAppointment: async (context, appointment) => {
      let createdAppointmentId;
      await appointmentApi.createAppointment(appointment).then((response) => {
        createdAppointmentId = response.data;
        patientHub.client.invoke('BroadcastScheduleChange');
      });
      return createdAppointmentId;
    },
    editAppointment: async (context, appointment) => {
      return await appointmentApi.editAppointment(appointment).then(() => {
        patientHub.client.invoke('BroadcastScheduleChange');
      });
    },
    getAppointmentTypeList: async (context) => {
      const response = await appointmentApi.getAppointmentTypeList();
      context.commit('SET_APPOINTMENT_TYPE_LIST', response.data.value);
    },
    getAppointmentStatusList: async (context) => {
      const response = await appointmentApi.getAppointmentStatusList();
      context.commit('SET_APPOINTMENT_STATUS_LIST', response.data.value);
    },
  },
};

export default appointmentModule;
