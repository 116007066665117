<template>
  <div class="education-main">
    <el-row :gutter="24">
      <el-col :lg="18" class="d-flex flex-between flex-center mb-16 flex-wrap">
        <h4 class="mt-16">Создание справочника услуг организации (прайс-листа)</h4>
        <el-button class="mt-16" size="small" @click="openModalVideo" type="primary"
          >Смотреть видео<i class="el-icon-play"></i
        ></el-button>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt-16">
      <el-col :lg="18">
        <p>
          Для тог, чтобы корректно формировать счета за оказанные услуги
          пациенту, обратившемуся в Вашу клинику, необходимо создать свой
          прайс-лист. В системе Documeds данный раздел представляет собой
          справочник услуг организации. Услуги организации хранятся в
          справочнике в сопоставленном с номенклатурой услуг Минздрава РФ виде.
        </p>
        <p>
          Чтобы добавить услугу в справочник медицинских услуг организации,
          необходимо перейти в пункт меню «Настройки»/«Справочники»/«Услуги
          организации (прайс-лист)».
        </p>
        <el-image
          class="mt-16"
          fit="contain"
          :src="src.url1"
          :preview-src-list="srcList">
        </el-image>
        <el-divider></el-divider>
      </el-col>
      <el-col :lg="18">
        <p>
          В данном разделе услуги представлены в виде таблицы со следующими
          графами:
        </p>
        <ul>
          <li>
            «Наименование услуги» - наименование, которое Вы присваиваете услуге
            внутри вашей клиники. Например, «Оформление справки в бассейн»
          </li>
          <li>
            «Наименование и код услуги из справочника» - наименование и код
            услуги из номенклатуры медицинских услуг Минздрава РФ
          </li>
          <li>
            «Специальность» - выбираете ту специальность врача, для которой
            будет доступна услуга
          </li>
          <li>
            «Категория» - категория специалиста. Она позволяет назначать
            раличную стоимость услуг. Например, прием врача-невролога со
            степенью к. м. н. может стоить в Вашей клинике дороже, чем прием
            врача без научной степени
          </li>
          <li>«Стоимость» - стоимость услуги в рублях (₽)</li>
          <li>
            «Действует» - период времени , в течение которого данная услуга
            может быть оказана
          </li>
        </ul>
        <el-divider></el-divider>
        <p>
          Для добавления новой услуги необходимо воспользоваться кнопкой
          «Добавить» в правой верхней части окна.
        </p>
        <el-image
          class="mt-16 mb-16"
          fit="cover"
          :src="src.url2"
          :preview-src-list="srcList">
        </el-image>
        <p>
          Добавление услуги рекомендуется начать с поля «Наименование услуги». В
          данное поле можно ввести произвольное наименование услуги согласно
          прайс-листу Вашей клиники. При вводе значений в данное поле система
          предлагает к выбору совпадающие по маске наименования услуг, которые
          уже были добавлены в прайс-лист. Далее необходимо произвести
          сопоставление введенного наименования услуги из прайс-листа с
          наименованием услуги из справочника МЗ РФ, воспользовавшись
          одноименным полем. При вводе значений в данное поле система предлагает
          к выбору совпадающие по маске наименования услуг из номенклатуры МЗ
          РФ. В случае, если наименование услуги прайс-листа было выбрано из
          выпадающего списка, данное поле будет заполнено автоматически.
        </p>
        <p>
          После заполнения формы добавления услуги, необходимо нажать кнопку
          «Сохранить».
        </p>
      </el-col>
    </el-row>
    <!-- модальное окно просмотра видео-урока -->
    <el-dialog
      title="Видео-урок"
      class="modal-play-video"
      :visible.sync="modalVideo"
      width="100%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true">
      <div v-if="!idVideo">
        <h6>К сожалению видео-урок не доступен</h6>
        <p>
          В скором времени мы добавим данный видео-урок по обучению работе в
          Documeds
        </p>
      </div>
      <div v-if="idVideo">
        <VideoPlayerComponent :idVideo="idVideo"></VideoPlayerComponent>
      </div>
      <el-button
        class="education-modal-btn"
        type="primary"
        @click="openModalVideo"
        >Продолжить обучение в текстовом формате</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
  import VideoPlayerComponent from "@/components/VideoPlayerComponent.vue";
  export default {
    components: { VideoPlayerComponent },
    data() {
      return {
        modalVideo: false,
        idVideo: "-1hhvOvP1FE",
        src: {
          url1: require("@/assets/img/education/add-price-list-1.png"),
          url2: require("@/assets/img/education/add-price-list-2.png"),
        },
        srcList: [
          require("@/assets/img/education/add-price-list-1.png"),
          require("@/assets/img/education/add-price-list-2.png"),
        ],
      };
    },
    methods: {
      openModalVideo() {
        this.modalVideo = !this.modalVideo;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
