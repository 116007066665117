import Vue from 'vue';
import Vuelidate from 'vuelidate';
import { vMaska } from 'maska';
import App from './App.vue';
import router from '@/router';
import { store } from './store';
import locale from 'element-ui/lib/locale';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/display.css';
import './../index.css';
import 'element-ui/lib/theme-chalk/display.css';
import '@/assets/unicons/uil.css';
import './scss/main.scss';
import ruRU from 'element-ui/lib/locale/lang/ru-RU';
import VueCookies from 'vue-cookies';

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(Vuelidate);
Vue.use(VueCookies);

Vue.directive('maska', vMaska);
locale.use(ruRU);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

//fix el-select
let fixElSelect = function () {
  document
    .querySelectorAll('.el-select[data-is-search="true"]:hover')
    .forEach(() => {
      let elInput = document.querySelector(
        '.el-select[data-is-search="true"]:hover input[readonly]'
      );
      if (elInput) {
        elInput.readOnly = false;
        elInput.blur();
        elInput.focus();
      }
    });
};
document.addEventListener('focusin', fixElSelect);
document.addEventListener('click', fixElSelect);
document.addEventListener('touchstart', fixElSelect);
