<template>
  <el-popover placement="bottom-start" trigger="click">
    <div class="event-inner" slot="reference">
      <p class="event-inner__text">{{ patientFIO }}</p>
      <div v-if="false" class="event-inner__label">ДМС</div>
    </div>
    <div class="schedule-slot-menu">
      <div class="schedule-slot-menu__header">
        <span class="schedule-slot-menu__cabinet-times">
          {{ appointmentTimeAndCabinet }}
        </span>
        <div class="schedule-slot-menu__buttons">
          <el-tooltip
            effect="dark"
            :enterable="false"
            content="Копировать запись"
            placement="right"
          >
            <el-button
              class="schedule-slot-menu__btn--copy"
              @click="copyingAppointment"
              type="text"
            >
              <i class="el-icon-copy-alt"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip
            effect="dark"
            :enterable="false"
            content="Перенести запись"
            placement="right"
          >
            <el-button
              class="schedule-slot-menu__btn--move"
              @click="movingAppointment"
              type="text"
            >
              <i class="el-icon-history-alt"></i>
            </el-button>
          </el-tooltip>
        </div>
      </div>
      <div class="schedule-slot-menu__body">
        <router-link
          target="_blank"
          class="schedule-slot-menu__link"
          :to="/patient/ + patient.id"
        >
          <i
            v-if="patient.physicalPatientCardExists"
            class="el-icon-folder-medical"
          ></i>

          {{ patientFullName }}</router-link
        >
        <ul class="schedule-slot-menu__list">
          <li class="schedule-slot-menu__item">
            {{ patientAge }}
          </li>
          <li class="schedule-slot-menu__item">
            {{ patient.residenceAddress }}
          </li>
          <li class="schedule-slot-menu__item">
            {{ patient.phone }}
          </li>
        </ul>
        <el-checkbox
          class="schedule-slot-menu__checkbox"
          v-model="isPatientInClinic"
          @change="changeAppointmentStatus"
          v-if="appointmentStatus === 1"
          >Запись подтверждена</el-checkbox
        >
        <el-checkbox
          class="schedule-slot-menu__checkbox"
          v-model="isPatientInClinic"
          @change="changeAppointmentStatus"
          v-if="appointmentStatus === 3"
          >Пациент в клинике</el-checkbox
        >
        <el-checkbox
          class="schedule-slot-menu__checkbox"
          v-model="isAppointmentDone"
          @change="changeAppointmentStatus"
          v-if="appointmentStatus === 4 || appointmentStatus === 6"
          >Прием завершен</el-checkbox
        >
      </div>
      <div class="schedule-slot-menu__footer">
        <router-link
          class="schedule-slot-menu__btn-link"
          v-if="
            appointment.doctorId == currentUser.idDoctor &&
            appointmentStatus !== 6 &&
            appointmentStatus !== 7
          "
          :to="'/visit/' + appointment.id"
          >Начать прием</router-link
        >
        <el-button
          class="schedule-slot-menu__btn"
          type="primary"
          plain
          size="small"
          @click="openModalAddEditAppointment"
          >Редактировать</el-button
        >
        <el-button
          class="schedule-slot-menu__btn"
          type="primary"
          plain
          size="small"
          @click="openModalPrintDocuments"
          >Печать документов</el-button
        >
        <el-button
          class="schedule-slot-menu__btn"
          type="danger"
          plain
          size="small"
          @click="cancelAppointment"
          >Отменить прием</el-button
        >
      </div>
    </div>
  </el-popover>
</template>

<script>
import { getAge, getAgeString, getDateString } from '@/services/commonService';
import AuthManager from '@/services/authManager';
import moment from 'moment';
export default {
  name: 'ScheduleSlotHoverComponent',
  props: {
    appointment: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    patient() {
      return this.appointment.patient;
    },
    patientAge() {
      return `${getDateString(this.patient.birthDate)} (${getAgeString(
        getAge(this.patient.birthDate)
      )})`;
    },
    patientFIO() {
      return `${this.patient.lastName} ${this.patient.firstName.slice(
        0,
        1
      )}. ${this.patient.patronymic.slice(0, 1)}.`;
    },
    patientFullName() {
      return `${this.patient.lastName} ${this.patient.firstName} ${this.patient.patronymic}`;
    },
    appointmentTimeAndCabinet() {
      return `${
        this.appointment.cabinetName ? this.appointment.cabinetName : ''
      } (${moment(this.appointment.dateTimeFrom).format('HH:mm')} - ${moment(
        this.appointment.dateTimeTo
      ).format('HH:mm')})`;
    },
    appointmentStatus: {
      get() {
        return this.appointment.idAppointmentStatus;
      },
      set(value) {
        this.$emit('update:appointmentStatus', value);
      },
    },
    isPatientInClinic: {
      get() {
        return this.appointmentStatus === 4;
      },
      set(value) {
        this.$emit('update:isPatientInClinic', value);
      },
    },
    isConfirmed: {
      get() {
        return this.appointmentStatus === 3;
      },
      set(value) {
        this.$emit('update:isConfirmed', value);
      },
    },
    isAppointmentDone: {
      get() {
        return this.appointmentStatus === 6;
      },
      set(value) {
        this.$emit('update:isAppointmentDone', value);
      },
    },
    checkboxText() {
      let checkboxText = 'Запись подтверждена';
      if (this.appointmentStatus === 4) {
        checkboxText = 'Пациент в клинике';
      }
      if (this.appointmentStatus === 3) {
        checkboxText = 'Запись подтверждена';
      }
      return checkboxText;
    },
    insuranceNotice() {
      if (
        this.appointment.insurancePolicyId &&
        this.appointment.guaranteeLetterId
      ) {
        return 'ДМС+';
      }
      if (this.appointment.insurancePolicyId) {
        return 'ДМС';
      }
      return '₽';
    },
    currentUser() {
      return AuthManager.getUser();
    },
  },
  methods: {
    openModalAddEditAppointment() {
      this.$emit('openModalAddEditAppointment');
    },
    openModalPrintDocuments() {
      this.$emit('openModalPrintDocuments');
    },
    movingAppointment() {
      this.$emit('movingAppointment');
    },
    copyingAppointment() {
      this.$emit('copyingAppointment');
    },
    cancelAppointment() {
      this.$emit('cancelAppointment');
    },
    changeAppointmentStatus() {
      let appointmentId = this.appointment.id;
      this.$emit('changeAppointmentStatus', {
        appointmentId: appointmentId,
        idAppointmentStatus: this.appointmentStatus,
      });
    },
  },
};
</script>
