import axios from '@/api/axios';

const getDocumentsForPrint = async () => {
  const response = await axios.get('clinicdocument');
  return response.data.value;
};

export default {
  getDocumentsForPrint,
};
