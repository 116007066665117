import { store } from '../store';
import DoctorManager from '../services/doctorManager.js';
import ClientOrganizationManager from '../services/clientOrganizationManager';

class CabinetWorkloadManager {
  //построение сетки таблицы на странице Загруженность кабинетов
  async getCabinetWorkload(filter) {
    let formatterTime = new Intl.DateTimeFormat('ru-RU', {
      hour: 'numeric',
      minute: 'numeric',
    });

    //получаем расписание с помощью vuex
    let schedulesStore = store.getters.scheduleList;

    //фильтруем полученный список по дате
    let currentDate = filter.date;
    schedulesStore = schedulesStore.filter(
      (x) =>
        (new Date(x.dateTimeFrom).getTime() == currentDate.getTime() ||
          new Date(x.dateTimeFrom).getTime() > currentDate.getTime()) &&
        (new Date(x.dateTimeTo).getTime() ==
          currentDate.getTime() + 1440 * 60000 ||
          new Date(x.dateTimeTo).getTime()) <
          currentDate.getTime() + 1440 * 60000
    );

    //устанавливаем границы расписания
    let clientOrganizationInfo =
      await ClientOrganizationManager.getClientOrganization();
    let startTime;
    let endTime;
    console.log(Boolean(clientOrganizationInfo.minuteClose));
    if (
      String(clientOrganizationInfo.hourOpen) &&
      String(clientOrganizationInfo.minuteOpen) &&
      String(clientOrganizationInfo.hourClose) &&
      String(clientOrganizationInfo.minuteClose)
    ) {
      startTime = new Date(
        currentDate.getTime() +
          3600000 * clientOrganizationInfo.hourOpen +
          60000 * clientOrganizationInfo.minuteOpen
      );
      endTime = new Date(
        currentDate.getTime() +
          3600000 * clientOrganizationInfo.hourClose +
          60000 * clientOrganizationInfo.minuteClose
      );
    } else {
      startTime = new Date(currentDate.getTime() + 3600000 * 8);
      endTime = new Date(currentDate.getTime() + 3600000 * 20);
    }

    //заполняем таблицу заголовков и временные шаги
    let timeColumns = []; //временные шаги
    let headerData = []; //таблица заголовков
    let headerDataItem = { doctor: 'Сотрудники' };
    let i;
    let j;
    let k = 0;
    let timeSteps = [0, 10, 20, 30, 40, 50];
    for (i = startTime; i <= endTime; i = new Date(i.getTime() + 3600000)) {
      let timeColumnItem = {
        time: formatterTime.format(i).toString(),
        timeSteps: [],
      };
      for (j = 0; j < 6; j++) {
        k++;
        //добавляем временной шаг
        timeColumnItem.timeSteps.push({
          time: new Date(i.getTime() + 60000 * timeSteps[j]),
          prop: k.toString(),
        });
        //добавляем поле в верхнюю таблицу (заголовки)
        headerDataItem[k] = formatterTime.format(
          new Date(i.getTime() + 60000 * timeSteps[j])
        );
        headerDataItem['s' + k] = j == 0 ? '6' : '0';
      }
      timeColumns.push(timeColumnItem);
    }
    headerData.push(headerDataItem);
    //получаем список врачей
    let doctors = await DoctorManager.getDoctors();
    //оставляем только тех специалистов, которые ведут прием
    doctors = doctors.filter((x) =>
      x.positions.find((d) => d.positionType.hasSchedule == true)
    );
    if (filter.doctor.length > 0) {
      doctors = doctors.filter((x) => filter.doctor.find((f) => f == x.id));
    }
    let doctorData = [];
    doctorData.push(headerDataItem);
    for (let l = 0; l <= doctors.length - 1; l++) {
      let doctorDataItem = {
        doctor:
          doctors[l].lastName +
          ' ' +
          doctors[l].firstName.substring(0, 1) +
          '. ' +
          doctors[l].patronymic.substring(0, 1) +
          '.',
      };
      let timeColumnTimeSteps = timeColumns.flatMap((x) => x.timeSteps);
      let newEmptySpace = 1;
      for (let n = 0; n <= timeColumnTimeSteps.length - 1; n++) {
        let currentSchedule = schedulesStore.find(
          (x) => x.idDoctor == doctors[l].id
        );
        if (currentSchedule) {
          let currentScheduleDateTimeFrom = new Date(
            currentSchedule.dateTimeFrom
          );
          let currentScheduleDateTimeTo = new Date(currentSchedule.dateTimeTo);
          if (
            (currentScheduleDateTimeFrom.getTime() ==
              timeColumnTimeSteps[n].time.getTime() ||
              currentScheduleDateTimeFrom.getTime() <
                timeColumnTimeSteps[n].time.getTime()) &&
            currentScheduleDateTimeTo.getTime() >
              timeColumnTimeSteps[n].time.getTime()
          ) {
            let currentStartCabinet = currentSchedule.cabinets.find(
              (x) =>
                new Date(x.dateTimeFrom).getTime() ==
                timeColumnTimeSteps[n].time.getTime()
            );
            if (currentStartCabinet) {
              doctorDataItem[timeColumnTimeSteps[n].prop] =
                currentStartCabinet.cabinetName;
              doctorDataItem['s' + timeColumnTimeSteps[n].prop] = Math.ceil(
                (new Date(currentStartCabinet.dateTimeTo).getTime() -
                  new Date(currentStartCabinet.dateTimeFrom).getTime()) /
                  600000
              );
              doctorDataItem['p' + timeColumnTimeSteps[n].prop] = {
                id: currentStartCabinet.id,
                note:
                  'с ' +
                  formatterTime.format(
                    new Date(currentStartCabinet.dateTimeFrom)
                  ) +
                  ' по ' +
                  formatterTime.format(
                    new Date(currentStartCabinet.dateTimeTo)
                  ),
              };
            } else {
              let currentContinueCabinet = currentSchedule.cabinets.find(
                (x) =>
                  new Date(x.dateTimeFrom).getTime() <
                    timeColumnTimeSteps[n].time.getTime() &&
                  new Date(x.dateTimeTo).getTime() >
                    timeColumnTimeSteps[n].time.getTime()
              );
              if (currentContinueCabinet) {
                doctorDataItem[timeColumnTimeSteps[n].prop] = '';
                doctorDataItem['s' + timeColumnTimeSteps[n].prop] = 0;
              } else {
                if (
                  new Date(
                    timeColumnTimeSteps[n].time.getTime() + 60000 * 10
                  ).getTime() == currentScheduleDateTimeTo.getTime() ||
                  currentSchedule.cabinets.find(
                    (x) =>
                      new Date(x.dateTimeFrom).getTime() ==
                      new Date(
                        timeColumnTimeSteps[n].time.getTime() + 60000 * 10
                      ).getTime()
                  )
                ) {
                  doctorDataItem[timeColumnTimeSteps[n].prop] = '';
                  doctorDataItem['s' + timeColumnTimeSteps[n].prop] =
                    newEmptySpace;
                  doctorDataItem['m' + timeColumnTimeSteps[n].prop] = {
                    idSchedule: currentSchedule.id,
                    idDoctor: currentSchedule.idDoctor,
                    idCabinet: '',
                    doctorName:
                      currentSchedule.doctorLastName +
                      ' ' +
                      currentSchedule.doctorFirstName.substring(0, 1) +
                      '. ' +
                      currentSchedule.doctorPatronymic.substring(0, 1) +
                      '.',
                    date: new Date(
                      timeColumnTimeSteps[n].time.getFullYear(),
                      timeColumnTimeSteps[n].time.getMonth(),
                      timeColumnTimeSteps[n].time.getDate()
                    ),
                    timeFrom: formatterTime.format(
                      new Date(
                        timeColumnTimeSteps[n].time.getTime() -
                          600000 * (newEmptySpace - 1)
                      )
                    ),
                    timeTo: formatterTime.format(
                      new Date(
                        timeColumnTimeSteps[n].time.getTime() + 60000 * 10
                      )
                    ),
                  };
                  newEmptySpace = 1;
                } else {
                  newEmptySpace++;
                  doctorDataItem[timeColumnTimeSteps[n].prop] = '';
                  doctorDataItem['s' + timeColumnTimeSteps[n].prop] = 0;
                }
              }
            }
          } else {
            doctorDataItem[timeColumnTimeSteps[n].prop] = '';
          }
        } else {
          doctorDataItem[timeColumnTimeSteps[n].prop] = '';
        }
      }
      doctorData.push(doctorDataItem);
    }
    return {
      timeColumns: timeColumns,
      headerData: headerData,
      doctorData: doctorData,
    };
  }

  //проверка занятости кабинета
  async verifyCabinetWorkload(parameters) {
    //загружаем загрузку кабинетов
    let schedulesStore = store.getters.scheduleList;
    let cabinetWorkload = schedulesStore.flatMap((x) => x.cabinets);

    let formatterTime = new Intl.DateTimeFormat('ru-RU', {
      hour: 'numeric',
      minute: 'numeric',
    });
    //проверка загрузки кабинета
    let cabinetLoad = cabinetWorkload.filter(
      (x) =>
        x.idCabinet == parameters.idCabinet &&
        (parameters.dateTimeFrom == new Date(x.dateTimeFrom).getTime() ||
          parameters.dateTimeTo == new Date(x.dateTimeTo).getTime() ||
          (parameters.dateTimeFrom > new Date(x.dateTimeFrom).getTime() &&
            parameters.dateTimeFrom < new Date(x.dateTimeTo).getTime()) ||
          (parameters.dateTimeTo > new Date(x.dateTimeFrom).getTime() &&
            parameters.dateTimeTo < new Date(x.dateTimeTo).getTime()) ||
          (parameters.dateTimeFrom < new Date(x.dateTimeFrom).getTime() &&
            parameters.dateTimeTo > new Date(x.dateTimeTo).getTime()))
    );
    if (cabinetLoad.length > 0) {
      let result = 'Кабинет занят в промежутке: ';
      for (let i = 0; i <= cabinetLoad.length - 1; i++) {
        result =
          result +
          'с ' +
          formatterTime.format(new Date(cabinetLoad[i].dateTimeFrom)) +
          ' по ' +
          formatterTime.format(new Date(cabinetLoad[i].dateTimeTo));
        if (i != cabinetLoad.length - 1) {
          result = result + ', ';
        }
      }
      return result;
    }
    //проверка загрузки врача
    let scheduleLoad = cabinetWorkload.filter(
      (x) =>
        x.idSchedule == parameters.idSchedule &&
        (parameters.dateTimeFrom == new Date(x.dateTimeFrom).getTime() ||
          parameters.dateTimeTo == new Date(x.dateTimeTo).getTime() ||
          (parameters.dateTimeFrom > new Date(x.dateTimeFrom).getTime() &&
            parameters.dateTimeFrom < new Date(x.dateTimeTo).getTime()) ||
          (parameters.dateTimeTo > new Date(x.dateTimeFrom).getTime() &&
            parameters.dateTimeTo < new Date(x.dateTimeTo).getTime()) ||
          (parameters.dateTimeFrom < new Date(x.dateTimeFrom).getTime() &&
            parameters.dateTimeTo > new Date(x.dateTimeTo).getTime()))
    );
    if (scheduleLoad.length > 0) {
      let result = 'Специалист уже находится в кабинете: ';
      for (let j = 0; j <= scheduleLoad.length - 1; j++) {
        result =
          result +
          'с ' +
          formatterTime.format(new Date(scheduleLoad[j].dateTimeFrom)) +
          ' по ' +
          formatterTime.format(new Date(scheduleLoad[j].dateTimeTo));
        if (j != scheduleLoad.length - 1) {
          result = result + ', ';
        }
      }
      return result;
    }

    return null;
  }
}
export default new CabinetWorkloadManager();
