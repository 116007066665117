import axios from '@/api/axios'

class MedicationManager {
    async getMkedications(pagenumber, pagesize, sorting, filterstring,  name, mnnname, form, dosage, count) {
        let requestUrl ='medication?pagenumber=' + pagenumber + '&pagesize=' + pagesize + '&sorting=' + sorting;
        if(filterstring)
        {
            requestUrl = requestUrl + '&filterstring=' + filterstring;
        }
        if(name)
        {
            requestUrl = requestUrl + '&name=' + name;
        }
        if(mnnname)
        {
            requestUrl = requestUrl + '&mnnname=' + mnnname;
        }
        if(form)
        {
            requestUrl = requestUrl + '&form=' + form;
        }
        if(dosage)
        {
            requestUrl = requestUrl + '&dosage=' + dosage;
        }
        if(count)
        {
            requestUrl = requestUrl + '&count=' + count;
        }
        let {data} = await axios.get(requestUrl);
        return data.value;
      }
}
export default new MedicationManager();


