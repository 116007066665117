<template>
  <div class="page-wrap analytics">
    <div class="page-header">
      <h4>Аналитика</h4>
      <el-button size="small" type="text" @click="handlePrintExcel()">
        Выгрузить в Excel <i class="el-icon el-icon-print"></i>
      </el-button>
    </div>
    <div class="analytics-top">
      <el-dropdown
        size="small"
        class="dashboard-header-dropdown"
        trigger="click"
        @command="handleCommand"
      >
        <h6 class="el-dropdown-link">
          {{ selectedAnalyticsOption.name }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </h6>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="option in analyticsOptions"
            :key="option.id"
            :command="option.id"
            >{{ option.name }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-radio-group style="display: flex" v-model="radio" size="small">
        <el-radio-button label="Bars">Столбчатая диаграмма</el-radio-button>
        <el-radio-button label="Lines">График</el-radio-button>
      </el-radio-group>
      <el-button
        class="hidden-sm-and-up"
        size="small"
        type="plain"
        @click="drawer = true"
      >
        <i class="el-icon-setting"></i>
      </el-button>
    </div>
    <el-drawer
      :visible.sync="drawer"
      :with-header="false"
      :direction="direction"
      size="70%"
    >
      <el-row :gutter="24">
        <el-col :xl="6" :lg="8" :md="12" :sm="12" :xs="24">
          <label for="selectBranche">Диапазон дат</label>
          <el-date-picker
            style="width: 100%; border-radius: 8px"
            v-model="filters.dateRange"
            size="small"
            format="dd.MM.yyyy"
            type="daterange"
            @change="loadData"
            :picker-options="{ firstDayOfWeek: 1 }"
            range-separator="-"
            start-placeholder="Дата с"
            end-placeholder="по"
            :clearable="false"
          >
          </el-date-picker>
        </el-col>
        <el-col :xl="6" :lg="8" :md="12" :sm="12" :xs="24">
          <label for="selectBranche">Филиал</label>
          <el-select
            data-is-search="true"
            v-model="filters.filials"
            size="small"
            collapse-tags
            filterable
            placeholder="Выберите филиал"
            clearable
            @change="handleFilterOption"
            multiple
          >
            <el-option key="0" label="Выбрать все" value="0"></el-option>
            <el-option
              v-for="item in filialOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option> </el-select
        ></el-col>
        <el-col :xl="6" :lg="8" :md="12" :sm="12" :xs="24">
          <label for="selectDepartment">Подразделение</label>
          <el-select
            data-is-search="true"
            v-model="filters.departments"
            size="small"
            collapse-tags
            filterable
            placeholder="Выберите подразделение"
            clearable
            @change="handleFilterOption"
            multiple
          >
            <el-option key="0" label="Выбрать все" value="0"></el-option>
            <el-option
              v-for="item in departmentOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :xl="6" :lg="8" :md="12" :sm="12" :xs="24">
          <label сlass="staff-form-label" for="">Специальность</label>
          <el-select
            data-is-search="true"
            v-model="filters.positionTypes"
            size="small"
            filterable
            @change="handleFilterOption"
            placeholder="Выберите специальность"
            clearable
            multiple
            collapse-tags
          >
            <el-option key="0" label="Выбрать все" value="0"></el-option>
            <el-option
              v-for="item in positionTypeOptions"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :xl="6" :lg="8" :md="12" :sm="12" :xs="24">
          <label for="selectDoctor">Врач</label>
          <el-select
            data-is-search="true"
            v-model="filters.doctors"
            size="small"
            collapse-tags
            filterable
            @change="handleFilterOption"
            placeholder="Выберите врача"
            clearable
            multiple
          >
            <el-option key="0" label="Выбрать все" value="0"></el-option>
            <el-option
              v-for="item in doctorOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
        <el-col
          :xl="6"
          :lg="8"
          :md="12"
          :sm="12"
          :xs="24"
          v-if="
            selectedAnalyticsOption.id == 2 || selectedAnalyticsOption.id == 3
          "
        >
          <label сlass="staff-form-label" for="">Услуги</label>
          <el-select
            data-is-search="true"
            v-model="filters.services"
            size="small"
            filterable
            @change="handleFilterOption"
            clearable
            collapse-tags
            multiple
            placeholder="Выберите услуги"
            :filter-method="handleServiceFilter"
          >
            <el-option
              v-for="item in priceListOptions"
              :key="item.id"
              :label="handlePriceListLabel(item)"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col
          :xl="6"
          :lg="8"
          :md="12"
          :sm="12"
          :xs="24"
          v-if="selectedAnalyticsOption.id == 1"
        >
          <label for="selectDoctor">Статус записи на прием</label>
          <el-select
            data-is-search="true"
            v-model="filters.appointmentStatusTypes"
            @change="handleFilterOption"
            size="small"
            filterable
            collapse-tags
            placeholder="Выберите статус"
            clearable
            multiple
          >
            <el-option key="0" label="Выбрать все" value="0"></el-option>
            <el-option
              v-for="item in appointmentStatusTypeOptions"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
        <el-col
          :xl="6"
          :lg="8"
          :md="12"
          :sm="12"
          :xs="24"
          v-if="selectedAnalyticsOption.id == 1"
        >
          <label for="selectDoctor">Пол пациентов</label>
          <el-checkbox-group
            style="display: flex"
            v-model="filters.patientGenders"
            size="small"
            @change="handleFilterOption"
          >
            <el-checkbox-button
              v-for="item in patientGenderOptions"
              :key="item.id"
              :label="item.id"
              >{{ item.name }}</el-checkbox-button
            >
          </el-checkbox-group>
        </el-col>
        <el-col
          :xl="6"
          :lg="8"
          :md="12"
          :sm="12"
          :xs="24"
          v-if="selectedAnalyticsOption.id == 1"
        >
          <label for="selectDoctor">Возраст пациентов, лет</label>
          <div style="padding: 0px 8px">
            <el-slider
              v-model="filters.patientAgeRange"
              range
              :max="100"
              @change="handleFilterOption"
            ></el-slider>
          </div>
          <el-row :gutter="24">
            <el-col :span="8">
              <el-input
                size="small"
                placeholder="С"
                v-model="filters.patientAgeRange[0]"
                @change="handleFilterOption"
              ></el-input>
            </el-col>
            <el-col :offset="8" :span="8">
              <el-input
                style="text-align: left"
                size="small"
                placeholder="По"
                v-model="filters.patientAgeRange[1]"
                @change="handleFilterOption"
              ></el-input>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-drawer>
    <el-row :gutter="24" class="hidden-xs-only">
      <el-col :xl="6" :lg="8" :md="12" :sm="12" :xs="24">
        <label for="selectBranche">Диапазон дат</label>
        <el-date-picker
          style="width: 100%; border-radius: 8px"
          v-model="filters.dateRange"
          size="small"
          format="dd.MM.yyyy"
          type="daterange"
          @change="loadData"
          :picker-options="{ firstDayOfWeek: 1 }"
          range-separator="-"
          start-placeholder="Дата с"
          end-placeholder="по"
          :clearable="false"
        >
        </el-date-picker>
      </el-col>
      <el-col :xl="6" :lg="8" :md="12" :sm="12" :xs="24">
        <label for="selectBranche">Филиал</label>
        <el-select
          data-is-search="true"
          v-model="filters.filials"
          size="small"
          collapse-tags
          filterable
          placeholder="Выберите филиал"
          clearable
          @change="handleFilterOption"
          multiple
        >
          <el-option key="0" label="Выбрать все" value="0"></el-option>
          <el-option
            v-for="item in filialOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option> </el-select
      ></el-col>
      <el-col :xl="6" :lg="8" :md="12" :sm="12" :xs="24">
        <label for="selectDepartment">Подразделение</label>
        <el-select
          data-is-search="true"
          v-model="filters.departments"
          size="small"
          collapse-tags
          filterable
          placeholder="Выберите подразделение"
          clearable
          @change="handleFilterOption"
          multiple
        >
          <el-option key="0" label="Выбрать все" value="0"></el-option>
          <el-option
            v-for="item in departmentOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :xl="6" :lg="8" :md="12" :sm="12" :xs="24">
        <label сlass="staff-form-label" for="">Специальность</label>
        <el-select
          data-is-search="true"
          v-model="filters.positionTypes"
          size="small"
          filterable
          @change="handleFilterOption"
          placeholder="Выберите специальность"
          clearable
          multiple
          collapse-tags
        >
          <el-option key="0" label="Выбрать все" value="0"></el-option>
          <el-option
            v-for="item in positionTypeOptions"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :xl="6" :lg="8" :md="12" :sm="12" :xs="24">
        <label for="selectDoctor">Врач</label>
        <el-select
          data-is-search="true"
          v-model="filters.doctors"
          size="small"
          collapse-tags
          filterable
          @change="handleFilterOption"
          placeholder="Выберите врача"
          clearable
          multiple
        >
          <el-option key="0" label="Выбрать все" value="0"></el-option>
          <el-option
            v-for="item in doctorOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col
        :xl="6"
        :lg="8"
        :md="12"
        :sm="12"
        :xs="24"
        v-if="
          selectedAnalyticsOption.id == 2 || selectedAnalyticsOption.id == 3
        "
      >
        <label сlass="staff-form-label" for="">Услуги</label>
        <el-select
          data-is-search="true"
          v-model="filters.services"
          size="small"
          filterable
          @change="handleFilterOption"
          clearable
          collapse-tags
          multiple
          placeholder="Выберите услуги"
          :filter-method="handleServiceFilter"
        >
          <el-option
            v-for="item in priceListOptions"
            :key="item.id"
            :label="handlePriceListLabel(item)"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col
        :xl="6"
        :lg="8"
        :md="12"
        :sm="12"
        :xs="24"
        v-if="selectedAnalyticsOption.id == 1"
      >
        <label for="selectDoctor">Статус записи на прием</label>
        <el-select
          data-is-search="true"
          v-model="filters.appointmentStatusTypes"
          @change="handleFilterOption"
          size="small"
          filterable
          collapse-tags
          placeholder="Выберите статус"
          clearable
          multiple
        >
          <el-option key="0" label="Выбрать все" value="0"></el-option>
          <el-option
            v-for="item in appointmentStatusTypeOptions"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col
        :xl="6"
        :lg="8"
        :md="12"
        :sm="12"
        :xs="24"
        v-if="selectedAnalyticsOption.id == 1"
      >
        <label for="selectDoctor">Пол пациентов</label>
        <el-checkbox-group
          style="display: flex"
          v-model="filters.patientGenders"
          size="small"
          @change="handleFilterOption"
        >
          <el-checkbox-button
            v-for="item in patientGenderOptions"
            :key="item.id"
            :label="item.id"
            >{{ item.name }}</el-checkbox-button
          >
        </el-checkbox-group>
      </el-col>
      <el-col
        :xl="6"
        :lg="8"
        :md="12"
        :sm="12"
        :xs="24"
        v-if="selectedAnalyticsOption.id == 1"
      >
        <label for="selectDoctor">Возраст пациентов, лет</label>
        <div style="padding: 0px 8px">
          <el-slider
            v-model="filters.patientAgeRange"
            range
            :max="100"
            @change="handleFilterOption"
          ></el-slider>
        </div>
        <el-row :gutter="24">
          <el-col :span="6">
            <el-input
              size="small"
              placeholder="С"
              v-model="filters.patientAgeRange[0]"
              @change="handleFilterOption"
            ></el-input>
          </el-col>
          <el-col  :span="6">
            <el-input
              size="small"
              placeholder="По"
              v-model="filters.patientAgeRange[1]"
              @change="handleFilterOption"
            ></el-input>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <div class="analytics-chart-wrap">
      <BarChart
        :height="setChartHeight"
        :chart-options="chartOptions"
        :chartData="chartData"
        v-if="radio == 'Bars'"
      />
      <LineChart
        :height="setChartHeight"
        :chart-options="chartOptions"
        :chartData="chartData"
        v-if="radio == 'Lines'"
      />
    </div>
  </div>
</template>

<script>
import LineChart from '@/components/Charts/LineChart.vue';
import BarChart from '@/components/Charts/BarChart.vue';
import FilialManager from '@/services/filialManager.js';
import DoctorManager from '@/services/doctorManager.js';
import PositionTypeManager from '@/services/positionTypeManager.js';
import PriceListManager from '@/services/priceListManager.js';
import AppointmentManager from '@/services/appointmentManager.js';
// import axios from "@/api/axios";
import { getTimeOffset } from '@/services/commonService';
import { ChartColors } from '@/constants';
import { mapGetters } from 'vuex';
import {
  getterTypes,
  actionTypes,
  mutationTypes,
} from '@/store/modules/analyticsModule';
import { downloadFile } from '@/services/commonService';
export default {
  components: {
    BarChart,
    LineChart,
  },
  data() {
    return {
      radio: 'Bars',
      selectedAnalyticsOption: {
        id: 1,
        name: 'Статистика посещений',
      },
      analyticsOptions: [
        {
          id: 1,
          name: 'Статистика посещений',
        },
        {
          id: 2,
          name: 'Финансы',
        },
        {
          id: 3,
          name: 'Услуги',
        },
      ],
      filialOptions: [],
      departmentOptions: [],
      doctorOptions: [],
      positionTypeOptions: [],
      priceList: [],
      priceListOptions: [],
      appointmentStatusTypeOptions: [],
      patientGenderOptions: [
        {
          id: 0,
          name: 'Женский',
        },
        {
          id: 1,
          name: 'Мужской',
        },
      ],
      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        maintainAspectRatio: true,
      },
      drawer: false,
      direction: 'ltr',
    };
  },
  computed: {
    ...mapGetters({
      filters: getterTypes.analyticsFilter,
    }),
    setChartHeight() {
      let height = 0;
      const width = window.innerWidth;
      if (width > 1024) {
        height = 90;
      } else if (width > 768) {
        height = 140;
      } else if (width > 481) {
        height = 200;
      } else {
        height = 470;
      }
      return height;
    },
  },
  async mounted() {
    this.filialOptions = await FilialManager.getFilials();
    this.departmentOptions = await FilialManager.getAllDepartments();
    this.doctorOptions = await DoctorManager.getDoctorsForSelect(true);
    this.positionTypeOptions = await PositionTypeManager.getPositionTypes();
    this.priceList = await PriceListManager.getCurrentPriceList();
    this.appointmentStatusTypeOptions =
      await AppointmentManager.getAppointmentStatusTypes();
    this.priceListOptions = this.priceList.filter(
      (x) => this.priceList.indexOf(x) < 10
    );
    this.loadData();
  },
  methods: {
    getParams() {
      return {
        timeOffset: getTimeOffset(),
        type: this.selectedAnalyticsOption.id,
        dateFrom: this.filters.dateRange[0],
        dateTo: this.filters.dateRange[1],
        filials:
          this.filters.filials != null
            ? this.filters.filials.reduce((a, x) => a + ',' + x, '')
            : null,
        departments:
          this.filters.departments != null
            ? this.filters.departments.reduce((a, x) => a + ',' + x, '')
            : null,
        positionTypes:
          this.filters.positionTypes != null
            ? this.filters.positionTypes.reduce((a, x) => a + ',' + x, '')
            : null,
        doctors:
          this.filters.doctors != null
            ? this.filters.doctors.reduce((a, x) => a + ',' + x, '')
            : null,
        patientGenders:
          this.filters.patientGenders != null
            ? this.filters.patientGenders.reduce((a, x) => a + ',' + x, '')
            : null,
        patientAgeFrom:
          this.filters.patientAgeRange != null
            ? this.filters.patientAgeRange[0]
            : null,
        patientAgeTo:
          this.filters.patientAgeRange != null
            ? this.filters.patientAgeRange[1]
            : null,
        services:
          this.filters.services != null
            ? this.filters.services.reduce((a, x) => a + ',' + x, '')
            : null,
        appointmentStatusTypes:
          this.filters.appointmentStatusTypes != null
            ? this.filters.appointmentStatusTypes.reduce(
                (a, x) => a + ',' + x,
                ''
              )
            : null,
      };
    },
    loadData() {
      this.$store
        .dispatch(actionTypes.getAnalyticsData, this.getParams())
        .then(() => {
          this.chartData = this.$store.state.analytics.analyticsData;
          if (this.chartData.datasets[0]) {
            this.chartData.datasets[0].backgroundColor = ChartColors.blue400;
            this.chartData.datasets[0].borderColor = ChartColors.blue400;
          }
          if (this.chartData.datasets[1]) {
            this.chartData.datasets[1].backgroundColor = ChartColors.red400;
            this.chartData.datasets[1].borderColor = ChartColors.red400;
          }
          if (this.chartData.datasets[2]) {
            this.chartData.datasets[2].backgroundColor = ChartColors.green400;
            this.chartData.datasets[2].borderColor = ChartColors.green400;
          }
          this.$store.commit(mutationTypes.setAnalyticsFilter, this.filters);
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: `Невозможно загрузить данные!`,
          });
        });
    },
    handlePriceListLabel(item) {
      return (
        item.medicalServiceCurrentOrganization.name +
        ' (' +
        item.positionTypeName +
        ', ' +
        item.categoryName +
        ')'
      );
    },
    handleServiceFilter(query) {
      if (query !== '') {
        let queryArr = query.split(' ');
        this.priceListOptions = this.priceList;
        for (let i = 0; i < queryArr.length; i++) {
          this.priceListOptions = this.priceListOptions.filter((item) => {
            return (
              item.medicalServiceCurrentOrganization.name
                .toLowerCase()
                .indexOf(queryArr[i].toLowerCase()) > -1
            );
          });
        }
      } else {
        this.priceListOptions = [];
      }
    },
    async handleCommand(command) {
      this.selectedAnalyticsOption = this.analyticsOptions.find(
        (x) => x.id == command
      );
      this.loadData();
    },
    selectAllOptions(filterValue, optionList) {
      if (filterValue.length == 1 && Number(filterValue) === 0) {
        let newFilterValue = optionList.map((x) => {
          return x.id;
        });
        return newFilterValue;
      } else {
        if (filterValue.length > 1 && filterValue.find((x) => x == 0)) {
          let newFilterValue = optionList.map((x) => {
            return x.id;
          });
          return newFilterValue;
        }
        return filterValue;
      }
    },
    async handleFilterOption() {
      this.filters.filials = this.selectAllOptions(
        this.filters.filials,
        this.filialOptions
      );
      this.filters.departments = this.selectAllOptions(
        this.filters.departments,
        this.departmentOptions
      );
      this.filters.positionTypes = this.selectAllOptions(
        this.filters.positionTypes,
        this.positionTypeOptions
      );
      this.filters.doctors = this.selectAllOptions(
        this.filters.doctors,
        this.doctorOptions
      );
      this.filters.appointmentStatusTypes = this.selectAllOptions(
        this.filters.appointmentStatusTypes,
        this.appointmentStatusTypeOptions
      );
      this.loadData();
    },
    async handlePrintExcel() {
      this.$store
        .dispatch(actionTypes.getAnalyticsFile, this.getParams())
        .then((resolve) => {
          let file = resolve;
          let type =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          let fileName = `Аналитический отчет (${this.filters.dateRange[0].toLocaleDateString(
            'ru-RU'
          )} - ${this.filters.dateRange[1].toLocaleDateString('ru-RU')}).xlsx`;
          downloadFile(file, type, fileName);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
