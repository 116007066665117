<template>
  <div class="settings-page" v-loading="loading" v-if="!previewDrawer">
    <el-row :gutter="24" class="d-flex">
      <el-col :span="24">
        <h6>Конструктор шаблонов медицинских документов</h6>
        <span class="settings-description">
          Добавьте нужные поля в шаблон медицинского документа и укажите как они
          должны располагаться на экране.
        </span>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :xl="7" :lg="7" :md="12" class="mt-16">
        <el-input
          size="small"
          type="text"
          placeholder="Название шаблона"
          v-model="meddocTemplate.name">
        </el-input>
      </el-col>
      <el-col :xl="7" :lg="7" :md="12" class="mt-16">
        <el-input
          size="small"
          type="text"
          placeholder="Название шаблона для печати"
          v-model="meddocTemplate.printName">
        </el-input>
      </el-col>
      <el-col :xl="6" :lg="6" :md="12" :span="24" class="mt-16">
        <el-select
          size="small"
          v-model="meddocTemplate.meddocTemplatePositions"
          placeholder="Выберите специальность"
          multiple
          :collapse-tags="true">
          <el-option
            v-for="item in positionTypeOptions"
            :key="item.id"
            :label="item.value"
            :value="item.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :xl="4" :lg="4" :md="12" :span="24" class="mt-16">
        <el-button size="small" type="primary" plain @click="handleAddField"
          >Добавить поле <i class="el-icon-plus el-icon-right"></i
        ></el-button>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="24">
        <el-table
          class="mt"
          stripe
          empty-text="Добавьте поля в шаблон медцинского документа"
          ref="multipleTable"
          :data="meddocTemplate.meddocTemplateFields"
          style="width: 100%">
          <el-table-column
            property="fieldNumber"
            label="N"
            width="45px"
            align="left"></el-table-column>
          <el-table-column min-width="110px" property="name" label="Наименование" align="left">
            <template slot-scope="scope">
              <el-input
                size="small"
                type="text"
                placeholder="Введите название поля"
                v-model="scope.row.name">
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            min-width="110px"
            property="idMeddocFieldType"
            label="Тип поля"
            align="left">
            <template slot-scope="scope">
              <el-select
                size="small"
                v-model="scope.row.idMeddocFieldType"
                @change="handleClearFieldDefault(scope.row)"
                placeholder="Выберите тип поля">
                <el-option
                  v-for="item in fieldTypes"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            min-width="110px"
            property="idMeddocFieldWidthType"
            label="Ширина поля"
            align="left">
            <template slot-scope="scope">
              <el-select
                size="small"
                v-model="scope.row.idMeddocFieldWidthType"
                placeholder="Выберите ширину поля">
                <el-option
                  v-for="item in fieldWidths"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            min-width="110px"
            property="idMeddocFieldHeightType"
            label="Высота поля"
            align="left">
            <template slot-scope="scope">
              <el-select
                size="small"
                v-model="scope.row.idMeddocFieldHeightType"
                placeholder="Выберите высоту поля"
                :disabled="scope.row.idMeddocFieldType != 1">
                <el-option
                  v-for="item in fieldHeights"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            property="defaultValue" 
            width="120px" 
            label="Предзаполнение" 
            type="expand">
            <template slot-scope="scope">
              <el-input
              :disabled="scope.row.idMeddocFieldType === 2"
              size="small"
              v-model="scope.row.defaultValue"
              type="textarea"
              resize="none"
              :autosize="{minRows: 4, maxRows: 8}"
              :rows="4"
              placeholder="Введите текст для предзаполнения поля"
            >
          </el-input>
            </template>
          </el-table-column>
          <el-table-column
            property="isObligatory"
            label="Обязательное"
            align="center">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.isObligatory"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            property="isRecommendation"
            label="Печать в рекомендациях"
            align="center">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.isRecommendation"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column width="80px" label="">
            <template slot-scope="scope">
              <el-button
                class="dropdown-btn danger"
                size="small"
                type="text"
                @click="handleDelete(scope.row)">
                <i class="el-icon-delete"></i>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt-16">
      <el-col :span="24" :md="8" class="mb-16 hidden-xs-only">
        <el-button class="w-100" size="small" type="primary" plain @click="openPreview"
          >Предпросмотр документа
          <i class="el-icon-eye el-icon-right"></i>
        </el-button>
      </el-col>
      <el-col :span="12" :md="4">
        <el-button
          class="w-100"
          size="small"
          type="primary"
          @click="handleSave">
          Сохранить
        </el-button>
      </el-col>
      <el-col :span="12" :md="4">
        <el-button class="w-100" size="small" plain @click="handleQuit">Отмена</el-button>
      </el-col>
    </el-row>
  </div>

  <!--Предпросмотр документа-->
  <div v-else>
    <el-row :gutter="24">
      <el-col :xl="12" :lg="12" :md="24">
        <h5>{{meddocTemplate.name}}</h5>
      </el-col>
      <el-col ::xl="12" :lg="12" :md="24" class="d-flex flex-end">
        <el-button size="small" type="primary" plain @click="previewDrawer = false"
          >Закрыть предпросмотр <i class="el-icon-eye-slash el-icon-right"></i
        ></el-button>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt">
      <el-col
        :span="field.idMeddocFieldWidthType * 12"
        class="mt"
        v-for="field in meddocTemplate.meddocTemplateFields"
        :key="field.id"
        :label="field.name"
        :name="field.id">
        <label for="" class="document-drawer-label">{{ field.name }}<span class="danger" v-if="field.isObligatory">*</span></label>
        <el-input
          v-model="field.defaultValue"
          size="small"
          v-if="field.idMeddocFieldType == 1"
          type="textarea"
          resize="none"
          :rows="field.idMeddocFieldHeightType"
          :placeholder="field.name"
          >
        </el-input>
        <el-select
        data-is-search="true"
          size="small"
          v-if="field.idMeddocFieldType == 2"
          placeholder="Выберите код и наименование по МКБ-10"
          filterable
          :filter-method="handleMkbFilter"
          :loading="loading"
          @change="handleMkbSelect">
          <el-option
            v-for="item in diagnosisCodes"
            :key="item.id"
            :label="handleMkbLabel(item)"
            :value="item.id">
          </el-option>
        </el-select>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import MkbManager from "@/services/mkbManager";
  import MeddocFieldTypeManager from "@/services/meddocFieldTypeManager";
  import MeddocTemplateManager from "@/services/meddocTemplateManager";
  import PositionTypeManager from "@/services/positionTypeManager.js";

  export default {
    loading: false,
    data() {
      return {
        loading: false,
        meddocTemplate:{
          name: "",
          printName: "",
          meddocTemplatePositions: [],
          meddocTemplateFields: [],
        },     
        positionTypeOptions: [],
        fieldTypes: [],
        fieldWidths: [],
        fieldHeights: [],
        is_obligatoryOptions: [
          {
            id: 1,
            value: "Да",
          },
          {
            id: 0,
            value: "Нет",
          },
        ],
        recommendationOptions: [
          {
            id: 1,
            value: "Да",
          },
          {
            id: 0,
            value: "Нет",
          },
        ],
        previewDrawer: false,
        diagnosisCodes: [],
      };
    },
    async mounted() {
      this.loading = true;
      let curid = this.$route.params.id;
      if(curid != 0){
        let meddocs = await MeddocTemplateManager.getTemplateInfo(curid);
        this.meddocTemplate = meddocs[0];
      }
      this.fieldTypes = await MeddocFieldTypeManager.getFieldTypes();
      this.fieldWidths = await MeddocFieldTypeManager.getFieldWidthTypes();
      this.fieldHeights = await MeddocFieldTypeManager.getFieldHeightTypes();
      this.positionTypeOptions = await PositionTypeManager.getPositionTypes();
      this.loading = false;
    },
    methods: {
      handleAddField() {
        this.meddocTemplate.meddocTemplateFields.push({
          fieldNumber: this.meddocTemplate.meddocTemplateFields.length + 1,
          name: "",
          printName: "",
          idMeddocFieldType: 1,
          idMeddocFieldWidthType: 1,
          idMeddocFieldHeightType: 1,
          isObligatory: false,
          idMeddocTemplate: this.$route.params.id,
          isRecommendation: false,
          defaultValue: "",
        });
      },
      handleDelete(row) {
        let index = this.meddocTemplate.meddocTemplateFields.indexOf(row);
        if (index > -1) {
          this.meddocTemplate.meddocTemplateFields.splice(index, 1);
        }
        for (let field of this.meddocTemplate.meddocTemplateFields) {
          if (field.fieldNumber > row.fieldNumber) {
            field.fieldNumber = field.fieldNumber - 1;
          }
        }
      },
      handleClearFieldDefault(row) {
          row.defaultValue = ""
      },
      openPreview() {
        this.previewDrawer = true;
      },
      async handleMkbFilter(query) {
        if (query !== "") {
          let mkbs = await MkbManager.getMkb(1, 7, query);
          this.diagnosisCodes = mkbs.mkb;
        } else {
          this.diagnosisCodes = [];
        }
      },
      async handleSave() {
        if(this.meddocTemplate.name.length == 0){
          this.$message({
            type: "error",
            message: `Название шаблона не может быть пустым!`,
          });
          return;
        }
        if (!this.meddocTemplate.meddocTemplateFields.find((x) => x.idMeddocFieldType == 2)) {
          this.$message({
            type: "error",
            message: `В шаблоне отсутвует поле с типом "Выбор значения из справочника МКБ"!`,
          });
          return;
        }
        if(this.meddocTemplate.meddocTemplateFields.find((x) => x.name.length == 0)){
          this.$message({
            type: "error",
            message: `Название поля не может быть пустым!`,
          });
          return;
        }
        if(this.meddocTemplate.meddocTemplateFields.find((x) => x.name.length == 0)){
          this.$message({
            type: "error",
            message: `Название поля не может быть пустым!`,
          });
          return;
        }
        let result = null;
        if(this.$route.params.id == 0){
          result = await MeddocTemplateManager.addTemplate(this.meddocTemplate);
        }
        else{
          result = await MeddocTemplateManager.updateTemplate(this.meddocTemplate);
        }
        if (result.error != null) {
          this.$message({
            type: "error",
            message: `Произошла ошибка: ${result.error} TraceId: ${result.traceId}! \n Настройки не сохранены`,
          });
        } else {
          this.$message({
            type: "success",
            message: `Настройки сохранены`,
          });
          this.$router.push("/settings/meddoc-templates-list");
        }
      },
      handleQuit(){
        this.$router.push("/settings/meddoc-templates-list");
      },
    },
  };
</script>

<style lang="scss" scoped></style>
