import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/api/axios';
import patientsModule from '@/store/modules/patientsModule';
// import listsModule from '@/store/modules/listsModule';
import contractorOrganizationModule from '@/store/modules/contractorOrganizationModule';
import schedulesModule from '@/store/modules/schedulesModule';
import invoicesModule from '@/store/modules/invoicesModule';
import analytics from '@/store/modules/analyticsModule';
import documentsPrintModule from './modules/documentsPrintModule';
import appointmentsModule from './modules/appointmentsModule';
import myOrganizationModule from './modules/myOrganizationModule';
import servicesModule from '@/store/modules/servicesModule';
import employeesModule from './modules/employeesModule';
import exportModule from '@/store/modules/exportModule';
import commonApi from '@/api/common';
import insurancePoliciesModule from './modules/insurancePoliciesModule';
import guaranteeLettersModule from './modules/guaranteeLettersModule';
Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    patients: patientsModule,
    // lists: listsModule,
    contractorOrganizations: contractorOrganizationModule,
    schedules: schedulesModule,
    invoices: invoicesModule,
    analytics,
    documentsPrint: documentsPrintModule,
    appointments: appointmentsModule,
    myOrganization: myOrganizationModule,
    services: servicesModule,
    employees: employeesModule,
    export: exportModule,
    insurancePolicies: insurancePoliciesModule,
    guaranteeLetters: guaranteeLettersModule,
  },
  state: {
    // contractors: [],
    filials: [],
    doctors: [],
    appointmentTypes: [],
    appointmentStatusTypes: [],
    appointmentDurationList: [5, 10, 15, 20, 25, 30, 40, 50, 60],
    // priceList: [],
    positionTypes: [],
    positionCategories: [],
    userRoleGroupTypes: [],
    medicalServiceCurrentOrganization: [],
    medicalServices: [],
    medicalCases: [],
    visitsList: [],
    meddocFieldTypes: [],
    meddocFieldHeightTypes: [],
    meddocFieldWidthTypes: [],
    pagination: {
      currentPage: 1,
      pageSizes: [10, 20, 30],
      currentSize: 10,
      limit: 10,
      offset: 0,
    },
    passportIssuerList: [],
  },
  getters: {
    appointmentDurationList: (state) => {
      return state.appointmentDurationList;
    },
    meddocFieldTypes: (state) => {
      return state.meddocFieldTypes;
    },
    meddocFieldHeightTypes: (state) => {
      return state.meddocFieldHeightTypes;
    },
    meddocFieldWidthTypes: (state) => {
      return state.meddocFieldWidthTypes;
    },
    // filials: (state) => {
    //   return state.filials;
    // },
    allCabinets: (state) => {
      return state.filials.flatMap((a) => a.cabinets);
    },
    allDepartments: (state) => {
      return state.filials.flatMap((a) => a.departments);
    },
    doctors: (state) => {
      return state.doctors;
    },
    doctorsList: (state) => {
      let doctors = state.doctors.filter((x) =>
        x.positions.find((d) => d.positionType.hasSchedule == true)
      );
      let doctorsOnStaff = doctors.filter((d) =>
        d.positions.find((p) => p.dateEnd == null)
      );
      let doctorsOnStaffCurrentPositions = doctorsOnStaff.map((x) => {
        return {
          id: x.id,
          value:
            x.lastName +
            ' ' +
            x.firstName +
            ' (' +
            x.positions
              .filter(
                (p) =>
                  (new Date(p.dateStart).getTime() == new Date().getTime() ||
                    new Date(p.dateStart).getTime() < new Date().getTime()) &&
                  (!p.dateEnd ||
                    new Date(p.dateEnd).getTime() > new Date().getTime() ||
                    new Date(p.dateEnd).getTime() == new Date().getTime())
              )
              .flatMap((p) => p.positionType.value)
              .join(',') +
            ')',
          appointmentDefaultDuration: x.appointmentDefaultDuration,
        };
      });
      return doctorsOnStaffCurrentPositions;
    },
    appointmentTypes: (state) => {
      return state.appointmentTypes;
    },
    appointmentStatusTypes: (state) => {
      return state.appointmentStatusTypes;
    },
    positionTypes: (state) => {
      return state.positionTypes;
    },
    positionCategories: (state) => {
      return state.positionCategories;
    },
    userRoleGroupTypes: (state) => {
      return state.userRoleGroupTypes;
    },
    medicalServiceCurrentOrganization: (state) => {
      return state.medicalServiceCurrentOrganization;
    },
    medicalServices: (state) => {
      return state.medicalServices;
    },
    medicalCases: (state) =>
      function (search) {
        if (search) {
          return state.medicalCases.filter(
            (m) =>
              m.doctorName.toUpperCase().includes(search.toUpperCase()) ||
              m.mainDiagnosisName
                .toUpperCase()
                .includes(search.toUpperCase()) ||
              m.doctorPositionName.toUpperCase().includes(search.toUpperCase())
          );
        } else {
          return state.medicalCases;
        }
      },
    pagination: (state) => {
      return state.pagination;
    },
    passportIssuerList: (state) => {
      return state.passportIssuerList;
    },
    visitsList: (state) =>
      function (search) {
        if (search) {
          return state.visitsList.filter(
            (m) =>
              m.doctorName.toUpperCase().includes(search.toUpperCase()) ||
              m.mainDiagnosisName
                .toUpperCase()
                .includes(search.toUpperCase()) ||
              m.doctorPositionName.toUpperCase().includes(search.toUpperCase())
          );
        } else {
          return state.visitsList;
        }
      },
  },
  mutations: {
    // SET_CONTRACTORS: (state, payload) => {
    //   state.contractors = payload;
    // },
    ADD_CONTRACTOR: (state, payload) => {
      state.contractors.push(payload);
    },
    UPDATE_CONTRACTOR: function (state, payload) {
      state.contractors = state.contractors.filter(
        (element) => element.id !== payload.id
      );
      state.contractors.push(payload);
    },
    DELETE_CONTRACTOR: function (state, payload) {
      state.contractors = state.contractors.filter(
        (element) => element.id !== payload
      );
    },
    SET_FILIALS: (state, payload) => {
      state.filials = payload;
    },
    SET_DOCTORS: (state, payload) => {
      state.doctors = payload;
    },
    SET_APPOINTMENTTYPES: (state, payload) => {
      state.appointmentTypes = payload;
    },
    SET_APPOINTMENTSTATUSTYPES: (state, payload) => {
      state.appointmentStatusTypes = payload;
    },

    ADD_FILIAL: (state, payload) => {
      state.filials.push(payload);
    },
    UPDATE_FILIAL: function (state, payload) {
      state.filials = state.filials.filter(
        (element) => element.id !== payload.id
      );
      state.filials.push(payload);
    },
    DELETE_FILIAL: function (state, payload) {
      state.filials = state.filials.filter((element) => element.id !== payload);
    },
    ADD_CABINET: (state, payload) => {
      state.filials
        .find((x) => x.id == payload.idFilial)
        .cabinets.push(payload);
    },
    UPDATE_CABINET: function (state, payload) {
      let filial = state.filials.find((x) => x.id == payload.idFilial);
      let index = filial.cabinets.indexOf(
        filial.cabinets.find((x) => x.id == payload.id)
      );
      filial.cabinets.splice(index, 1);
      filial.cabinets.push(payload);
    },
    DELETE_CABINET: function (state, payload) {
      let filial = state.filials.find((x) => x.id == payload.idFilial);
      let index = filial.cabinets.indexOf(
        filial.cabinets.find((x) => x.id == payload.id)
      );
      filial.cabinets.splice(index, 1);
    },
    ADD_DEPARTMENT: (state, payload) => {
      state.filials
        .find((x) => x.id == payload.idFilial)
        .departments.push(payload);
    },
    UPDATE_DEPARTMENT: function (state, payload) {
      let filial = state.filials.find((x) => x.id == payload.idFilial);
      let index = filial.departments.indexOf(
        filial.departments.find((x) => x.id == payload.id)
      );
      filial.departments.splice(index, 1);
      filial.departments.push(payload);
    },
    DELETE_DEPARTMENT: function (state, payload) {
      let filial = state.filials.find((x) => x.id == payload.idFilial);
      let index = filial.departments.indexOf(
        filial.departments.find((x) => x.id == payload.id)
      );
      filial.departments.splice(index, 1);
    },
    SET_POSITIONTYPES: (state, payload) => {
      state.positionTypes = payload;
    },
    ADD_POSITIONTYPE: (state, payload) => {
      state.positionTypes.unshift(payload);
    },
    UPDATE_POSITIONTYPE: function (state, payload) {
      state.positionTypes = state.positionTypes.filter(
        (element) => element.id !== payload.id
      );
      state.positionTypes.unshift(payload);
    },
    DELETE_POSITIONTYPE: function (state, payload) {
      state.positionTypes = state.positionTypes.filter(
        (element) => element.id !== payload.id
      );
    },
    SET_POSITIONCATEGORIES: (state, payload) => {
      state.positionCategories = payload;
    },
    SET_USERROLEGROUPTYPES: (state, payload) => {
      state.userRoleGroupTypes = payload;
    },
    SET_MEDICALSERVICECURRENTORGANIZATION: (state, payload) => {
      state.medicalServiceCurrentOrganization = payload;
    },
    SET_MEDICALSERVICES: (state, payload) => {
      state.medicalServices = payload;
    },
    SET_MEDICALCASES: (state, payload) => {
      state.medicalCases = payload;
    },
    SET_VISITS: (state, payload) => {
      state.visitsList = payload;
    },
    SET_MEDDOCFIELDTYPES: (state, payload) => {
      state.meddocFieldTypes = payload;
    },
    SET_MEDDOCFIELDHEIGHTTYPES: (state, payload) => {
      state.meddocFieldHeightTypes = payload;
    },
    SET_MEDDOCFIELDWIDTHTYPES: (state, payload) => {
      state.meddocFieldWidthTypes = payload;
    },
    SET_PASSPORT_ISSUER: (state, payload) => {
      state.passportIssuerList = payload;
    },
  },
  actions: {
    SAVE_CONTRACTOR: async (context, payload) => {
      let { data } = await axios.post('contractororganization', payload);
      context.commit('ADD_CONTRACTOR', data.value);
    },
    UPDATE_CONTRACTOR: async (context, payload) => {
      await axios.put('contractororganization', payload);
      context.commit('UPDATE_CONTRACTOR', payload);
    },
    DELETE_CONTRACTOR: async (context, payload) => {
      await axios.delete('contractororganization/' + payload);
      context.commit('DELETE_CONTRACTOR', payload);
    },
    GET_FILIALS: async (context) => {
      let { data } = await axios.get('filial');
      context.commit('SET_FILIALS', data.value);
    },
    GET_DOCTORS: async (context) => {
      let { data } = await axios.get('doctor');
      context.commit('SET_DOCTORS', data.value);
    },
    GET_APPOINTMENTTYPES: async (context) => {
      let { data } = await axios.get('appointmenttype');
      context.commit('SET_APPOINTMENTTYPES', data.value);
    },
    GET_APPOINTMENTSTATUSTYPES: async (context) => {
      let { data } = await axios.get('appointmentstatustype');
      context.commit('SET_APPOINTMENTSTATUSTYPES', data.value);
    },
    SAVE_APPOINTMENT: async (context, payload) => {
      let { data } = await axios.post('appointment', payload);
      context.commit('ADD_APPOINTMENT', data.value);
    },
    SAVE_FILIAL: async (context, payload) => {
      let { data } = await axios.post('filial', payload);
      context.commit('ADD_FILIAL', data.value);
    },
    UPDATE_FILIAL: async (context, payload) => {
      await axios.put('filial', payload);
      context.commit('UPDATE_FILIAL', payload);
    },
    DELETE_FILIAL: async (context, payload) => {
      await axios.delete('filial/' + payload);
      context.commit('DELETE_FILIAL', payload);
    },
    SAVE_CABINET: async (context, payload) => {
      let { data } = await axios.post('cabinet', payload);
      context.commit('ADD_CABINET', data.value);
    },
    UPDATE_CABINET: async (context, payload) => {
      await axios.put('cabinet', payload);
      context.commit('UPDATE_CABINET', payload);
    },
    DELETE_CABINET: async (context, payload) => {
      await axios.delete('cabinet/' + payload.id);
      context.commit('DELETE_CABINET', payload);
    },
    SAVE_DEPARTMENT: async (context, payload) => {
      let { data } = await axios.post('department', payload);
      context.commit('ADD_DEPARTMENT', data.value);
    },
    UPDATE_DEPARTMENT: async (context, payload) => {
      await axios.put('department', payload);
      context.commit('UPDATE_DEPARTMENT', payload);
    },
    DELETE_DEPARTMENT: async (context, payload) => {
      await axios.delete('department/' + payload.id);
      context.commit('DELETE_DEPARTMENT', payload);
    },
    GET_POSITIONTYPES: async (context) => {
      let { data } = await axios.get('positiontype');
      context.commit('SET_POSITIONTYPES', data.value);
    },
    SAVE_POSITIONTYPE: async (context, payload) => {
      let { data } = await axios.post('positiontype', payload);
      context.commit('ADD_POSITIONTYPE', data.value);
    },
    UPDATE_POSITIONTYPE: async (context, payload) => {
      await axios.put('positiontype', payload);
      context.commit('UPDATE_POSITIONTYPE', payload);
    },
    DELETE_POSITIONTYPE: async (context, payload) => {
      await axios.delete('positiontype/' + payload.id);
      context.commit('DELETE_POSITIONTYPE', payload);
    },
    GET_POSITIONCATEGORIES: async (context) => {
      let { data } = await axios.get('positioncategory');
      context.commit('SET_POSITIONCATEGORIES', data.value);
    },
    GET_USERROLEGROUPTYPES: async (context) => {
      let { data } = await axios.get('userrolegrouptype');
      context.commit('SET_USERROLEGROUPTYPES', data.value);
    },
    GET_MEDICALSERVICECURRENTORGANIZATION: async (context) => {
      let { data } = await axios.get('medicalservicecurrentorganization');
      context.commit('SET_MEDICALSERVICECURRENTORGANIZATION', data.value);
    },
    GET_MEDICALSERVICES: async (context) => {
      let { data } = await axios.get('medicalservicetype');
      context.commit('SET_MEDICALSERVICES', data.value);
    },
    GET_MEDICALCASES: async (context, { id }) => {
      const { data } = await axios.get('medicalcase/' + id);
      context.commit('SET_MEDICALCASES', data.value);
    },
    GET_VISITS: async (context, { id }) => {
      const { data } = await axios.get('medicalcaseappointment/' + id);
      context.commit('SET_VISITS', data.value);
    },

    GET_MEDDOCFIELDTYPES: async (context) => {
      let { data } = await axios.get('meddocfieldtype');
      context.commit('SET_MEDDOCFIELDTYPES', data.value);
    },
    GET_MEDDOCFIELDHEIGHTTYPES: async (context) => {
      let { data } = await axios.get('meddocfieldheighttype');
      context.commit('SET_MEDDOCFIELDHEIGHTTYPES', data.value);
    },
    GET_MEDDOCFIELDWIDTHTYPES: async (context) => {
      let { data } = await axios.get('meddocfieldwidthtype');
      context.commit('SET_MEDDOCFIELDWIDTHTYPES', data.value);
    },
    getPassportIssuer: async (context, params) => {
      let response = await commonApi.getPassportIssuer(params);
      context.commit('SET_PASSPORT_ISSUER', response);
    },
  },
});
