import axios from '@/api/axios';
import { getTimeOffset } from './commonService';

class MedicalCaseManager {
  async getCases(idPatient) {
    let { data } = await axios.get('medicalcase/' + idPatient);
    return data.value;
  }

  async getActualCases(idPatient) {
    let { data } = await axios.get('medicalcase/' + idPatient);
    return data.value.filter((x) => !x.dateTimeEnd);
  }

  async getAppointments(idMedicalCase) {
    let { data } = await axios.get('medicalcaseappointment/' + idMedicalCase);
    return data.value;
  }

  async closeCase(idMedicalCase) {
    try {
      let closeInfo = {
        id: idMedicalCase,
        typeChange: 1,
        clientOffset: getTimeOffset(),
      };
      await axios.put('medicalcase', closeInfo);
      return null;
    } catch (error) {
      if (error.response.status != 200) {
        return error.response.data;
      }
    }
  }
}
export default new MedicalCaseManager();
