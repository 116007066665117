import contractorOrganizationApi from '@/api/contractorOrganization';

const contractorOrganizationModule = {
  state: () => ({
    contractorOrganizations: [],
    contractorOrganizationFilter: {
      pagenumber: 1,
      pagesize: 10,
      filterstring: null,
    },
  }),
  getters: {
    contractorOrganizations: (state) => {
      return state.contractorOrganizations;
    },
    contractorOrganizationFilter: (state) => {
      return state.contractorOrganizationFilter;
    },
  },
  mutations: {
    SET_CONTRACTORORGANIZATIONS: (state, payload) => {
      state.contractorOrganizations = payload;
    },
    SET_CONTRACTORORGANIZATIONSFILTER: (state, payload) => {
      state.contractorOrganizationFilter = payload;
    },
  },
  actions: {
    getContractorOrganizations: async (context, params) => {
      const response =
        await contractorOrganizationApi.getContractorOrganizations(params);
      context.commit('SET_CONTRACTORORGANIZATIONS', response.data.value);
    },
    createContractorOrganization: async (context, organization) => {
      return await contractorOrganizationApi.createContractorOrganization(
        organization
      );
    },
    updateContractorOrganization: async (context, organization) => {
      return await contractorOrganizationApi.updateContractorOrganization(
        organization
      );
    },
    deleteContractorOrganization: async (context, id) => {
      return await contractorOrganizationApi.createContractorOrganization({
        id: id,
      });
    },
  },
};

export default contractorOrganizationModule;
