<template>
  <div class="education-main">
    <el-row :gutter="24">
      <el-col :lg="18" class="d-flex flex-between flex-center mb-16 flex-wrap">
        <h4 class="mt-16">Как настроить свою организацию?</h4>
        <el-button class="mt-16" size="small" @click="openModalVideo" type="primary"
          >Смотреть видео<i class="el-icon-play"></i
        ></el-button>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :lg="18">
        <p>
          В модуле «Настройки» в разделе «Моя организация» можно указать
          дополнительные данные Вашей организации, а также отредактировать
          имеющиеся, если данные изменились.
        </p>
        <p>
          Для того, чтобы перейти в раздел «Моя организация», Вам необходимо
          выбрать в боковом меню пункт «Настройки», по умолчанию первым
          открывается нужный раздел «Моя организация».
        </p>
        <el-divider></el-divider>
      </el-col>
      <el-col :lg="18">
        <el-image
          fit="cover"
          :src="src.url1"
          :preview-src-list="srcList"></el-image>
      </el-col>
      <el-col :lg="18">
        <el-divider></el-divider>
        <p>В данном окне Вы можете:</p>
        <ul>
          <li>Изменить полное и краткое наименования организации</li>
          <li>
            Добавить в контактную информацию телефоны и адреса электронной
            почты. Добавление нового номера телефона или электронной почты
            производится при нажатии на кнопку «Добавить». Возможно добавление
            нескольких номеров телефонов или адресов электронной почты. Также Вы
            можете указатькомментарий к введенному контакту Вашей организации
          </li>
          <li>Добавить адресную информацию Вашей организации</li>
          <li>Добавить юридические и банковские реквизиты</li>
        </ul>
        <p>
          Для того, чтобы сохранить введенные изменения, необходимо нажать на
          кнопку «Сохранить». После получения окна об успешном сохранении данных
          Вашей организации можно переходить к работе в других разделах.
        </p>
        <el-divider></el-divider>
      </el-col>
      <el-col :lg="18" class="mt-16">
        <el-image
          fit="cover"
          :src="src.url2"
          :preview-src-list="srcList"></el-image>
      </el-col>
    </el-row>
    <!-- модальное окно просмотра видео-урока -->
    <el-dialog
      title="Видео-урок"
      class="modal-play-video"
      :visible.sync="modalVideo"
      width="100%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true">
      <div v-if="!idVideo">
        <h6>К сожалению видео-урок не доступен</h6>
        <p>
          В скором времени мы добавим данный видео-урок по обучению работе в
          Documeds
        </p>
      </div>
      <div v-if="idVideo">
        <VideoPlayerComponent :idVideo="idVideo"></VideoPlayerComponent>
      </div>
      <el-button
        class="education-modal-btn"
        type="primary"
        @click="openModalVideo"
        >Продолжить обучение в текстовом формате</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
  import VideoPlayerComponent from "@/components/VideoPlayerComponent.vue";
  export default {
    components: { VideoPlayerComponent },
    data() {
      return {
        modalVideo: false,
        idVideo: "Bj5UDyFMsdk",
        src: {
          url1: require("@/assets/img/education/org-lesson-1.png"),
          url2: require("@/assets/img/education/org-lesson-2.png"),
        },
        srcList: [
          require("@/assets/img/education/org-lesson-1.png"),
          require("@/assets/img/education/org-lesson-2.png"),
        ],
      };
    },
    methods: {
      openModalVideo() {
        this.modalVideo = !this.modalVideo;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
