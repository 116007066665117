<template>
  <el-dialog
    class="modal-add-edit-service"
    :close-on-click-modal="false"
    :title="isEditMode ? 'Редактирование услуги' : 'Добавление услуги'"
    :visible="isModalAddEditServiceVisible"
    @update:visible="closeModalAddEditService"
    width="100%"
  >
    <el-row :gutter="24">
      <el-col :md="12" :sm="24">
        <label class="modal-add-edit-service__label"
          >Наименование услуги <span class="danger">*</span></label
        >
        <el-input
          placeholder="Введите наименование"
          size="small"
          v-model="currentPriceListItem.orgName"
        ></el-input>
      </el-col>
      <el-col :md="12" :sm="24">
        <label class="modal-add-edit-service__label"
          >Наименование услуги из справочника МЗ
          <span class="danger">*</span></label
        >
        <el-select
          placeholder="Введите наименование"
          size="small"
          filterable
          default-first-option
          remote
          :remote-method="searchMedicalService"
          v-model="
            currentPriceListItem.medicalServiceCurrentOrganization
              .idMedicalServiceType
          "
        >
          <el-option
            v-for="medicalService of medicalServiceList"
            :key="medicalService.id"
            :label="`${medicalService.name} - ${medicalService.code}`"
            :value="medicalService.id"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row class="mt-16" :gutter="24">
      <el-col :md="12" :sm="24">
        <label class="modal-add-edit-service__label"
          >Специальность врача <span class="danger">*</span></label
        >
        <el-select
          placeholder="Выберите специальность"
          size="small"
          filterable
          default-first-option
          v-model="currentPriceListItem.idPositionType"
        >
          <el-option
            v-for="position of positions"
            :key="position.id"
            :label="position.value"
            :value="position.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :md="12" :sm="24">
        <label class="modal-add-edit-service__label"
          >Категория врача <span class="danger">*</span></label
        >
        <el-select
          placeholder="Выберите категорию"
          size="small"
          filterable
          default-first-option
          v-model="currentPriceListItem.idCategory"
        >
          <el-option
            v-for="category of categories"
            :key="category.id"
            :label="category.value"
            :value="category.id"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt-16">
      <el-col :md="6" :sm="12" :xs="24">
        <label class="modal-add-edit-service__label"
          >Дата начала <span class="danger">*</span></label
        >
        <el-date-picker
          size="small"
          v-model="currentPriceListItem.dateFrom"
          type="date"
          format="dd.MM.yyyy"
          placeholder="Выберите дату"
          :picker-options="{ firstDayOfWeek: 1 }"
        >
        </el-date-picker>
      </el-col>
      <el-col :md="6" :sm="12" :xs="24">
        <label class="modal-add-edit-service__label">Дата окончания</label>
        <el-date-picker
          size="small"
          v-model="currentPriceListItem.dateTo"
          type="date"
          format="dd.MM.yyyy"
          placeholder="Выберите дату"
          :picker-options="{ firstDayOfWeek: 1 }"
        >
        </el-date-picker>
      </el-col>
      <el-col :md="6" :sm="12" :xs="24">
        <label class="modal-add-edit-service__label">Длительность, мин.</label>
        <el-select
          v-model="currentPriceListItem.defaultDuration"
          filterable
          allow-create
          default-first-option
          placeholder="Выберите длительность"
          size="small"
        >
          <el-option
            v-for="(item, index) of durationList"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :md="6" :sm="12" :xs="24">
        <label class="modal-add-edit-service__label"
          >Стоимость, ₽ <span class="danger">*</span></label
        >
        <el-input-number
          size="small"
          v-model="currentPriceListItem.price"
          :min="0"
          :precision="2"
        ></el-input-number
      ></el-col>
    </el-row>
    <el-row :gutter="24" class="mt">
      <el-col :span="24">
        <el-button size="small" type="primary" @click="savePriceListItemAsync"
          >Сохранить</el-button
        >
        <el-button size="small" @click="closeModalAddEditService"
          >Отменить</el-button
        >
      </el-col>
      <el-col :span="12"> </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import dateTimeService from '@/services/dateTimeService';
export default {
  name: 'ModalAddEditServiceComponent',
  props: {
    isModalAddEditServiceVisible: {
      type: Boolean,
      default: false,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    priceListItem: {
      type: Object,
    },
  },
  data() {
    return {
      defaultDuration: 5,
      isFormValid: false,
      options: [
        {
          id: 1,
          value: 5,
        },
      ],
    };
  },
  computed: {
    currentPriceListItem: {
      get() {
        return this.priceListItem;
      },
      set(value) {
        this.$emit('update:priceListItem', value);
      },
    },
    defaultPriceListItem() {
      return this.$store.getters.defaultPriceListItem;
    },
    medicalServiceList() {
      return this.$store.getters.medicalServiceList;
    },
    positions() {
      return this.$store.getters.positions;
    },
    categories() {
      return this.$store.getters.categories;
    },
    durationList() {
      return this.$store.getters.durationList;
    },
  },
  mounted() {},
  methods: {
    clearAddEditModalService() {
      this.$emit('clearAddEditModalService');
    },
    closeModalAddEditService() {
      this.clearAddEditModalService();
      this.$emit('update:isModalAddEditServiceVisible');
    },
    searchMedicalService(search) {
      if (search) {
        this.$store.dispatch('getMedicalServiceFromDictionary', {
          pagenumber: 1,
          pagesize: 10,
          filterstring: search,
        });
      }
    },
    validationForm() {
      if (
        this.currentPriceListItem.orgName &&
        this.currentPriceListItem.medicalServiceCurrentOrganization
          .idMedicalServiceType &&
        this.currentPriceListItem.idPositionType &&
        this.currentPriceListItem.idCategory &&
        this.currentPriceListItem.price != 0
      ) {
        this.isFormValid = true;
      } else {
        this.isFormValid = false;
      }
    },
    savePriceListItemAsync() {
      this.validationForm();
      if (this.isFormValid == true) {
        if (this.isEditMode) {
          this.$store
            .dispatch('changePriceListItem', {
              ...this.currentPriceListItem,
              defaultDuration: dateTimeService.getDurationStringByMinutes(
                this.currentPriceListItem.defaultDuration
              ),
            })
            .then(() => {
              this.closeModalAddEditService();
              this.$message({
                type: 'success',
                message: 'Услуга успешно изменена',
              });
            })
            .catch(() => {
              this.$message({
                type: 'error',
                message: 'Не удалось изменить услугу',
              });
            });
        } else {
          let medicalServiceType = this.medicalServiceList.find(
            (m) =>
              m.id ===
              this.currentPriceListItem.medicalServiceCurrentOrganization
                .idMedicalServiceType
          );
          let medicalServiceCurrentOrganization = {
            id: null,
            name: this.currentPriceListItem.orgName,
            idMedicalServiceType: medicalServiceType.id,
            medicalServiceType: medicalServiceType,
          };
          this.$store
            .dispatch('createPriceListItem', {
              ...this.currentPriceListItem,
              defaultDuration: dateTimeService.getDurationStringByMinutes(
                this.currentPriceListItem.defaultDuration
              ),
              medicalServiceCurrentOrganization:
                medicalServiceCurrentOrganization,
            })
            .then(() => {
              this.closeModalAddEditService();
              this.$message({
                type: 'success',
                message: 'Услуга успешно создана',
              });
            })
            .catch(() => {
              this.$message({
                type: 'error',
                message: 'Не удалось создать услугу',
              });
            });
        }
      } else {
        this.$message({
          type: 'error',
          message: 'Заполните обязательные поля!',
        });
      }
    },
  },
};
</script>
