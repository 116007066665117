import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { HubURL } from '@/api/httpSettings';
import { AuthToken} from '@/api/httpSettings';

class PatientHub {
    constructor() {
        this.client = new HubConnectionBuilder()
            .configureLogging(LogLevel.None)
            .withUrl(HubURL() + `patienthub`)
            .build();       
    }
     async start() {
        if(this.client.hubStarted){
            return;
        }
        this.client.hubStarted = true;
        this.client.connection.baseUrl = this.client.connection.baseUrl + `?authorization=${AuthToken()}`;
        await this.client.start();
     }
}

export default new PatientHub();