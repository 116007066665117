<template>
  <div class="page-wrap guarantee-letters">
    <div>
      <div class="guarantee-letters__header">
        <BreadcrumbComponent :patient="currentPatient"></BreadcrumbComponent>
        <h5>Гарантийные письма</h5>
        <el-row :gutter="24">
          <el-col :sm="24" :md="12" :lg="8">
            <el-tooltip
              class="item hidden-xs-only"
              effect="dark"
              content="Поиск гарантийного письма можно осуществлять по его номеру"
              placement="right"
              :hide-after="2500"
            >
              <el-input
                size="small"
                placeholder="Найти гарантийное письмо..."
                prefix-icon="el-icon-search"
                v-model="search"
              >
              </el-input>
            </el-tooltip>
          </el-col>
          <el-col :sm="24" :md="12" :lg="16" align="end">
            <el-button
              type="primary"
              size="small"
              @click="openModalAddEditGuaranteeLetter(null)"
              >Добавить <i class="el-icon-plus"></i
            ></el-button>
          </el-col>
        </el-row>
      </div>
      <div class="guarantee-letters__content mt-16">
        <el-table
          class="guarantee-letters__table"
          v-loading="loading"
          stripe
          :data="guaranteeLetterListWithPolicy.values"
        >
          <el-table-column label="Статус" width="100" align="center">
            <template slot-scope="scope">
              <i
                class="el-icon-circle-check success"
                v-if="scope.row.status == 1"
              ></i>
              <i
                class="el-icon-circle-close danger"
                v-if="scope.row.status == 0"
              ></i>
            </template>
          </el-table-column>
          <el-table-column
            prop="number"
            label="Номер"
            width="200px"
          ></el-table-column>
          <el-table-column
            prop="insurancePolicyString"
            label="Страховой полис"
          ></el-table-column>
          <el-table-column
            prop="validFrom"
            label="Дата начала"
            width="150px"
            :formatter="formatterDateFrom"
          ></el-table-column>
          <el-table-column
            prop="validUntil"
            label="Дата окончания"
            width="150px"
            :formatter="formatterDateUntil"
          ></el-table-column>
          <el-table-column prop="text" label="Текст"></el-table-column>
          <el-table-column
            v-if="false"
            prop="file"
            label="Файл"
          ></el-table-column>
          <el-table-column width="80px">
            <template slot-scope="scope">
              <el-dropdown size="mini" trigger="click">
                <span class="el-dropdown-link"
                  ><i class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item type="button">
                    <el-button
                      class="dropdown-btn"
                      size="small"
                      type="text"
                      @click="openModalAddEditGuaranteeLetter(scope.row)"
                    >
                      <i class="el-icon-edit el-icon-left"></i>
                      Редактировать
                    </el-button>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      class="dropdown-btn danger"
                      size="small"
                      type="text"
                      @click="deleteGuaranteeLetter(scope.row)"
                    >
                      <i class="el-icon-delete"></i>
                      Удалить</el-button
                    >
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- <el-pagination
      v-if="guaranteeLetterListWithPolicy.total > 10"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.currentSize"
      layout="sizes, prev, pager, next"
      :total="guaranteeLetterListWithPolicy.total"
      class="mt-20 pb"
    >
    </el-pagination> -->
    <ModalAddEditGuaranteeLetterComponent
      :is-modal-add-edit-guarantee-letter-visible.sync="
        isModalAddEditGuaranteeLetterVisible
      "
      :is-edit-mode="isEditMode"
      :guarantee-letter="guaranteeLetter"
      :patient="currentPatient"
      :insurance-organization-list="insuranceOrganizationList"
      :insurance-policy-list="insurancePolicyList"
      @updateGuaranteeLetter="updateGuaranteeLetter"
      @addGuaranteeLetter="addGuaranteeLetter"
      @clearAddEditModalGuaranteeLetter="clearAddEditModalGuaranteeLetter"
    ></ModalAddEditGuaranteeLetterComponent>
  </div>
</template>

<script>
import { getDateString } from '@/services/commonService';
import ModalAddEditGuaranteeLetterComponent from '@/components/ModalAddEditGuaranteeLetterComponent.vue';
import BreadcrumbComponent from '@/components/BreadcrumbComponent.vue';
export default {
  name: 'GuaranteeLettersPage',
  components: {
    ModalAddEditGuaranteeLetterComponent,
    BreadcrumbComponent,
  },
  data() {
    return {
      isModalAddEditGuaranteeLetterVisible: false,
      isEditMode: false,
      guaranteeLetter: {
        id: null,
        insurancePolicyId: null,
        number: null,
        validFrom: null,
        validUntil: null,
        text: null,
        file: null,
      },
      search: null,
      loading: false,
    };
  },
  computed: {
    patientId() {
      return parseInt(this.$route.params.id);
    },
    currentPatient() {
      return this.$store.getters.patient;
    },

    breadcrumbList() {
      let breadcrumbList = [];

      return breadcrumbList;
    },
    insuranceOrganizationList() {
      return this.$store.getters.contractorOrganizations;
    },
    insurancePolicyList() {
      let insurancePolicyList =
        this.$store.getters.insurancePolicyListByPatientId(this.patientId);
      // выводим только ДМС полисы страхования
      let insurancePolicyListOnlyDMS = insurancePolicyList.filter(
        (p) => p.insuranceType === 1
      );
      // выводим только действующие полиса страхования
      return insurancePolicyListOnlyDMS;
      // .filter(
      //   (p) => new Date(p.validUntil) >= new Date()
      // );
    },
    guaranteeLetterList() {
      let guaranteeLetterList = this.$store.getters.guaranteeLetterList;
      // костыльный поиск без API метода
      if (this.search) {
        let afterSearchArray = guaranteeLetterList.values.filter((x) =>
          x.number.toUpperCase().includes(this.search.toUpperCase())
        );
        return { ...guaranteeLetterList, values: afterSearchArray };
      } else {
        return guaranteeLetterList;
      }
    },
    guaranteeLetterListWithPolicy() {
      let insurancePolicyListOnlyDMS = this.$store.getters
        .insurancePolicyListByPatientId(this.patientId)
        .filter((x) => x.insuranceType === 1);
      for (let l of this.guaranteeLetterList.values) {
        for (let p of insurancePolicyListOnlyDMS) {
          if (l.insurancePolicyId === p.id) {
            l.insurancePolicyString = `${p.series} № ${p.number} - ${p.insuranceCompany}`;
          }
          if (
            new Date(l.validUntil).getTime() == new Date().getTime() ||
            new Date(l.validUntil).getTime() > new Date().getTime()
          ) {
            l.status = 1;
          } else {
            l.status = 0;
          }
        }
      }
      return this.guaranteeLetterList;
    },
    pagination() {
      return this.$store.getters.pagination;
    },
  },
  mounted() {
    this.$store.dispatch('getPatient', {
      patientId: this.patientId,
      pagesize: 10,
      pagenumber: 1,
    });
    this.$store.dispatch('getContractorOrganizations', {
      pagesize: 10,
      pagenumber: 1,
    });
    this.$store.dispatch('getInsurancePolicies');
    this.loadGuaranteeLetterList();
  },
  methods: {
    loadGuaranteeLetterList() {
      this.loading = true;
      this.$store.dispatch('getGuaranteeLetterList', {
        patientId: this.patientId,
      });
      this.loading = false;
    },

    formatterDateFrom(row) {
      return getDateString(row.validFrom);
    },
    formatterDateUntil(row) {
      return getDateString(row.validUntil);
    },
    openModalAddEditGuaranteeLetter(guaranteeLetter) {
      if (guaranteeLetter) {
        this.$store
          .dispatch('getGuaranteeLetterById', guaranteeLetter.id)
          .then(() => {
            this.guaranteeLetter = this.$store.getters.guaranteeLetter;
          });
        this.isEditMode = true;
      } else {
        this.guaranteeLetter = this.$store.getters.guaranteeLetter;
        this.isEditMode = false;
      }
      this.isModalAddEditGuaranteeLetterVisible =
        !this.isModalAddEditGuaranteeLetterVisible;
    },
    clearAddEditModalGuaranteeLetter() {
      this.$store.commit('SET_GUARANTEE_LETTER', {
        ...this.$store.getters.defaultGuaranteeLetter,
      });
      this.loadGuaranteeLetterList();
    },
    addGuaranteeLetter(guaranteeLetter) {
      this.$store
        .dispatch('createGuaranteeLetter', guaranteeLetter)
        .then(() => {
          this.$message({
            type: 'success',
            message: 'Гарантийное письмо успешно добавлено',
          });
          this.isModalAddEditGuaranteeLetterVisible =
            !this.isModalAddEditGuaranteeLetterVisible;
          this.clearAddEditModalGuaranteeLetter();
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: 'Произошла ошибка! Не удалось добавить гарантийное письмо',
          });
        });
    },
    updateGuaranteeLetter(guaranteeLetter) {
      this.$store
        .dispatch('updateGuaranteeLetter', guaranteeLetter)
        .then(() => {
          this.$message({
            type: 'success',
            message: 'Данные гарантийного письма успешно изменены',
          });
          this.isModalAddEditGuaranteeLetterVisible =
            !this.isModalAddEditGuaranteeLetterVisible;
          this.clearAddEditModalGuaranteeLetter();
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: 'Произошла ошибка! Данные гарантийного письма не изменены',
          });
        });
    },
    deleteGuaranteeLetter(row) {
      this.$confirm(
        'Вы действительно хотите удалить гарантийное письмо?',
        'Предупреждение',
        {
          confirmButtonText: 'Подтвердить',
          cancelButtonText: 'Отменить',
          type: 'warning',
        }
      )
        .then(() => {
          this.$store
            .dispatch('deleteGuaranteeLetter', row.id)
            .then(() => {
              this.$message({
                type: 'success',
                message: 'Удаление успешно выполнено',
              });
              this.clearAddEditModalGuaranteeLetter();
            })
            .catch(() => {
              this.$message({
                type: 'error',
                message: 'Произошла ошибка! Удаление не выполнено.',
              });
            });
        })
        .catch(() => {
          return;
        });
    },
  },
};
</script>
