<template>
  <div class="settings-page d-flex flex-column flex-between align-items-start">
    <div class="staff-form">
      <div class="staff-form-top">
        <el-avatar class="staff-form-avatar" icon="el-icon-user"></el-avatar>
        <div class="staff-form-block">
          <h4 class="staff-form-fio">
            {{ doctorFullName }}
          </h4>
          <span class="staff-form-position">
            {{ doctorPosition }}
          </span>
        </div>
      </div>
      <el-tabs class="staff-form-tabs mt" v-model="staffTabs">
        <el-tab-pane label="Персональные данные" name="personal">
          <el-row :gutter="24" class="mt">
            <el-col :xl="4" :lg="8" :md="12" :sm="24">
              <label for="" class="staff-form-label"
                >Фамилия <span class="danger">*</span></label
              >
              <el-input
                size="small"
                placeholder="Введите фамилию"
                v-model="employee.lastName"
                @change="
                  genderDetermination(
                    employee.lastName,
                    employee.firstName,
                    employee.patronymic
                  )
                "
              >
              </el-input>
            </el-col>
            <el-col :xl="4" :lg="8" :md="12" :sm="24">
              <label for="" class="staff-form-label"
                >Имя <span class="danger">*</span></label
              >
              <el-input
                size="small"
                placeholder="Введите имя"
                v-model="employee.firstName"
                @change="
                  genderDetermination(
                    employee.lastName,
                    employee.firstName,
                    employee.patronymic
                  )
                "
              >
              </el-input>
            </el-col>
            <el-col :xl="4" :lg="8" :md="12" :sm="24">
              <label for="" class="staff-form-label">Отчество</label>
              <el-input
                size="small"
                placeholder="Введите отчетсво"
                v-model="employee.patronymic"
                @change="
                  genderDetermination(
                    employee.lastName,
                    employee.firstName,
                    employee.patronymic
                  )
                "
              >
              </el-input>
            </el-col>
            <el-col :xl="6" :lg="8" :md="12" :sm="24">
              <label for="" class="staff-form-label"
                >Дата рождения <span class="danger">*</span></label
              >
              <el-date-picker
                v-model="employee.birthDate"
                size="small"
                format="dd.MM.yyyy"
                value-format="yyyy-MM-dd"
                type="date"
                :picker-options="{ firstDayOfWeek: 1 }"
                placeholder="Введите дату рождения"
              >
              </el-date-picker>
            </el-col>
            <el-col :xl="6" :lg="8" :md="12" :sm="24">
              <label for="" class="staff-form-label"
                >Пол <span class="danger">*</span></label
              >
              <div class="d-flex">
                <el-radio-group v-model="employee.gender">
                  <el-radio :label="1">Мужской</el-radio>
                  <el-radio :label="0">Женский</el-radio>
                </el-radio-group>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="mt">
            <el-col :xl="12" :lg="12" :md="24">
              <label for="" class="staff-form-label">Телефон</label>
              <el-input
                size="small"
                placeholder="Введите телефон сотрудника"
                v-maska
                data-maska="+7##########"
                v-model="employee.phone"
              >
              </el-input>
            </el-col>
            <el-col :xl="12" :lg="12" :md="24">
              <label for="" class="staff-form-label">Email</label>
              <el-input
                size="small"
                placeholder="Введите email сотрудника"
                v-model="employee.email"
              >
              </el-input>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="mt">
            <el-col :xl="12" :lg="12" :md="24">
              <label for="" class="staff-form-label">Адрес регистрации</label>
              <DaDataAutoCompleteComponent
                @input="copyToResidenceAddress"
                placeholder="Введите адрес регистрации"
                v-model="employee.registerAddress"
              ></DaDataAutoCompleteComponent>
              <el-checkbox
                v-model="matchedRegisterAddress"
                @change="copyToResidenceAddress"
                >Совпадает с адресом проживания</el-checkbox
              >
            </el-col>
            <el-col :xl="12" :lg="12" :md="24">
              <label for="" class="staff-form-label">Адрес проживания</label>
              <DaDataAutoCompleteComponent
                placeholder="Введите адрес проживания"
                :disabled="matchedRegisterAddress"
                v-model="employee.residenceAddress"
              ></DaDataAutoCompleteComponent>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Документы" name="documends">
          <el-row :gutter="24" class="mt">
            <el-col :xl="4" :lg="8" :md="12" :sm="24">
              <label сlass="staff-form-label" for="">Серия паспорта</label>
              <el-input
                size="small"
                placeholder="Введите серию"
                v-maska
                data-maska="####"
                v-model="employee.passportSeries"
              >
              </el-input>
            </el-col>
            <el-col :xl="4" :lg="8" :md="12" :sm="24">
              <label сlass="staff-form-label" for="">Номер паспорта</label>
              <el-input
                size="small"
                placeholder="Введите номер"
                v-maska
                data-maska="######"
                v-model="employee.passportNumber"
              >
              </el-input>
            </el-col>
            <el-col :xl="4" :lg="8" :md="12" :sm="24">
              <label сlass="staff-form-label" for="">Дата выдачи</label>
              <el-date-picker
                size="small"
                v-model="employee.passportDateFrom"
                format="dd.MM.yyyy"
                value-format="yyyy-MM-dd"
                type="date"
                :picker-options="{ firstDayOfWeek: 1 }"
                placeholder="Введите дату выдачи"
              >
              </el-date-picker>
            </el-col>
            <el-col :xl="8" :lg="8" :md="12" :sm="24">
              <label сlass="staff-form-label" for="">Кем выдан</label>
              <el-autocomplete
                size="small"
                placeholder="Кем выдан"
                v-model="employee.passportIssuer"
                :fetch-suggestions="passportIssuerSearch"
              ></el-autocomplete>
            </el-col>
            <el-col :xl="4" :lg="8" :md="12" :sm="24">
              <label сlass="staff-form-label" for="">Код подразделения</label>
              <el-input
                size="small"
                placeholder="Введите код подразделения"
                v-maska
                data-maska="###-###"
                v-model="employee.passportIssuerCode"
              >
              </el-input>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :xl="4" :lg="8" :md="12" :sm="24">
              <label сlass="staff-form-label" for="">СНИЛС</label>
              <el-input
                size="small"
                placeholder="Введите СНИЛС"
                v-maska
                data-maska="###-###-### ##"
                v-model="employee.snils"
              >
              </el-input>
            </el-col>
            <el-col :xl="4" :lg="8" :md="12" :sm="24">
              <label сlass="staff-form-label" for="">ИНН</label>
              <el-input
                size="small"
                placeholder="Введите ИНН"
                v-model="employee.inn"
              >
              </el-input>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Исполнение должностей" name="positions">
          <el-row :gutter="24" class="mt">
            <el-col :xl="6" :lg="8" :md="12">
              <label сlass="staff-form-label" for=""
                >Основная должность <span class="danger">*</span></label
              >
              <el-select
                data-is-search="true"
                size="small"
                filterable
                v-model="employee.currentMainPosition.idPosition"
                placeholder="Выберите должность"
              >
                <el-option
                  v-for="item in positions"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :xl="6" :lg="8" :md="12">
              <label сlass="staff-form-label" for=""
                >Подразделение <span class="danger">*</span></label
              >
              <el-select
                data-is-search="true"
                size="small"
                filterable
                v-model="employee.currentMainPosition.idDepartment"
                placeholder="Выберите подразделение"
              >
                <el-option
                  v-for="item in departments"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :xl="6" :lg="8" :md="12">
              <label сlass="staff-form-label" for=""
                >Категория <span class="danger">*</span></label
              >
              <el-select
                data-is-search="true"
                size="small"
                filterable
                v-model="employee.currentMainPosition.idCategory"
                placeholder="Выберите категорию"
              >
                <el-option
                  v-for="item in categories"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :xl="6" :lg="12" :md="12">
              <label сlass="staff-form-label" for=""
                >Дата назначения <span class="danger">*</span></label
              >
              <el-date-picker
                size="small"
                v-model="employee.currentMainPosition.dateStart"
                format="dd.MM.yyyy"
                value-format="yyyy-MM-dd"
                type="date"
                :clearable="false"
                :picker-options="{ firstDayOfWeek: 1 }"
                placeholder="Выберите дату"
              >
              </el-date-picker>
            </el-col>
            <el-col :xl="6" :lg="12" :md="12">
              <label сlass="staff-form-label" for="">Дата увольнения</label>
              <el-date-picker
                size="small"
                v-model="employee.currentMainPosition.dateEnd"
                format="dd.MM.yyyy"
                value-format="yyyy-MM-dd"
                :clearable="false"
                type="date"
                placeholder="Выберите дату"
              >
              </el-date-picker>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="mt">
            <el-col :xl="6" :lg="8" :md="12">
              <label сlass="staff-form-label" for=""
                >Дополнительная должность</label
              >
              <el-select
                data-is-search="true"
                size="small"
                filterable
                v-model="employee.currentExtraPosition.idPosition"
                placeholder="Выберите должность"
              >
                <el-option
                  v-for="item in positions"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :xl="6" :lg="8" :md="12">
              <label сlass="staff-form-label" for="">Подразделение</label>
              <el-select
                data-is-search="true"
                size="small"
                filterable
                v-model="employee.currentExtraPosition.idDepartment"
                placeholder="Выберите подразделение"
              >
                <el-option
                  v-for="item in departments"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :xl="6" :lg="8" :md="12">
              <label сlass="staff-form-label" for="">Категория</label>
              <el-select
                data-is-search="true"
                size="small"
                filterable
                v-model="employee.currentExtraPosition.idCategory"
                placeholder="Выберите категорию"
              >
                <el-option
                  v-for="item in categories"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col :xl="6" :lg="12" :md="12">
              <label сlass="staff-form-label" for="">Дата назначения</label>
              <el-date-picker
                size="small"
                v-model="employee.currentExtraPosition.dateStart"
                format="dd.MM.yyyy"
                value-format="yyyy-MM-dd"
                type="date"
                :picker-options="{ firstDayOfWeek: 1 }"
                placeholder="Выберите дату"
              >
              </el-date-picker>
            </el-col>
            <el-col :xl="6" :lg="12" :md="12">
              <label сlass="staff-form-label" for="">Дата увольнения</label>
              <el-date-picker
                size="small"
                v-model="employee.currentExtraPosition.dateEnd"
                format="dd.MM.yyyy"
                value-format="yyyy-MM-dd"
                type="date"
                :picker-options="{ firstDayOfWeek: 1 }"
                placeholder="Выберите дату"
              >
              </el-date-picker>
            </el-col>
          </el-row>
          <h6 class="mt">Записи на прием</h6>
          <el-row :gutter="24">
            <el-col :xl="6" :lg="12" :md="12">
              <label сlass="staff-form-label">Длительность, мин.</label>
              <el-select
                v-model="employee.appointmentDefaultDuration"
                filterable
                allow-create
                default-first-option
                placeholder="Выберите длительность"
                size="small"
              >
                <el-option
                  v-for="(item, index) of durationList"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
          <div v-if="showArchivePositions">
            <h6 class="mt">Архив исполнения должностей</h6>
            <el-table :data="employee.archivePositions" style="width: 100%">
              <el-table-column prop="positionType.value" label="Должность">
              </el-table-column>
              <el-table-column
                prop="dateStart"
                label="Дата назначения"
                :formatter="formatterDateStart"
              >
              </el-table-column>
              <el-table-column
                prop="dateEnd"
                label="Дата увольнения"
                :formatter="formatterDateEnd"
              >
              </el-table-column>
              <el-table-column
                prop="isMainFunction"
                label="Основная должность"
                :formatter="formatterMainFunction"
              >
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Данные пользователя" name="userSettings">
          <el-col :xl="8" :lg="8" :md="12" :sm="24">
            <el-row>
              <el-col :span="24">
                <label сlass="staff-form-label" for=""
                  >Имя пользователя <span class="danger">*</span></label
                >
                <el-input
                  size="small"
                  placeholder="Введите имя пользователя"
                  v-model="employee.user.login"
                >
                </el-input>
              </el-col>
              <el-col :span="24" class="relative">
                <label сlass="staff-form-label" for="">Пароль</label>
                <el-input
                  size="small"
                  placeholder="Введите пароль"
                  type="password"
                  autocomplete="new-password"
                  show-password
                  v-model="employee.user.password"
                >
                </el-input>
                <el-tooltip effect="light" placement="right-start">
                  <div slot="content">
                    <b>Параметры пароля</b>
                    <p>Ваш пароль минимум должен содержать:</p>
                    <ul>
                      <li>8 латинских букв</li>
                      <li>1 заглавную букву</li>
                      <li>1 цифру</li>
                      <li>1 спецсимвол (! № _ } | : и т.д.)</li>
                    </ul>
                  </div>
                  <i class="staff-password-tooltip el-icon-question-circle"></i>
                </el-tooltip>
              </el-col>
              <el-col :span="24">
                <label сlass="staff-form-label" for=""
                  >Роль пользователя <span class="danger">*</span></label
                >
                <el-select
                  data-is-search="true"
                  size="small"
                  filterable
                  v-model="employee.user.idUserRoleGroupType"
                  placeholder="Выберите категорию"
                >
                  <el-option
                    v-for="item in userRoleGroupTypes"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-col> </el-row
          ></el-col>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-button
      size="small"
      type="primary"
      class="staff-form-btn mt-20"
      @click="saveEmployeeAsync"
      >Сохранить</el-button
    >
  </div>
</template>

<script>
import { getGender } from 'lvovich';
import DaDataAutoCompleteComponent from '@/components/DaDataAutoCompleteComponent.vue';
import { mapGetters } from 'vuex';
import dateTimeService from '@/services/dateTimeService';
import { getDateString } from '@/services/commonService';
export default {
  name: 'StaffFormPage',
  components: {
    DaDataAutoCompleteComponent,
  },
  data() {
    return {
      staffTabs: 'personal',
      loadingBtn: false,
      matchedRegisterAddress: true,
      isFormValid: false,
    };
  },
  computed: {
    showArchivePositions() {
      return this.employee.archivePositions.length === 0 ? false : true;
    },
    doctorPosition() {
      let positionObj = this.positions.find(
        (p) => p.id === this.employee.currentMainPosition.idPosition
      );
      return this.employee.currentMainPosition.idPosition
        ? positionObj.value
        : 'Должность сотрудника';
    },
    doctorFullName() {
      return this.employee.lastName &&
        this.employee.firstName &&
        this.employee.patronymic
        ? `${this.employee.lastName} ${this.employee.firstName} ${this.employee.patronymic}`
        : 'ФИО сотрудника';
    },
    employee() {
      let employee = this.$store.getters.employee;
      if (typeof employee.appointmentDefaultDuration === 'string') {
        employee.appointmentDefaultDuration =
          dateTimeService.getDurationAsMinutes(
            employee.appointmentDefaultDuration
          );
      }
      return employee;
    },
    ...mapGetters([
      'filials',
      'departments',
      'positions',
      'categories',
      'userRoleGroupTypes',
      'durationList',
    ]),
  },
  mounted() {
    this.$store.dispatch('getFilials');
    this.$store.dispatch('getPositions');
    this.$store.dispatch('getCategories');
    this.$store.dispatch('GET_USERROLEGROUPTYPES');
    let employeeId = parseInt(this.$route.params.id);
    if (employeeId) {
      this.$store.dispatch('getEmployeeById', employeeId);
    }
  },
  destroyed() {
    this.$store.commit('SET_EMPLOYEE', null);
  },
  methods: {
    genderDetermination(lastName, firstName, patronymic) {
      let gender = getGender({
        last: lastName,
        first: firstName,
        middle: patronymic,
      });
      if (gender == 'female') {
        this.employee.gender = 0;
      } else {
        this.employee.gender = 1;
      }
    },
    copyToResidenceAddress() {
      if (this.matchedRegisterAddress == true) {
        this.employee.residenceAddress = this.employee.registerAddress;
      }
    },
    formatterDateStart(row) {
      return getDateString(row.dateStart);
    },
    formatterDateEnd(row) {
      return getDateString(row.dateEnd);
    },
    formatterMainFunction(row) {
      return row.isMainFunction == 1 ? 'Да' : 'Нет';
    },
    //подсказка органа, выдавшего паспорт
    async passportIssuerSearch(search, cb) {
      this.$store
        .dispatch('getPassportIssuer', {
          substring: search,
        })
        .then(() => {
          let results = [];
          results = this.$store.getters.passportIssuerList;
          cb(results);
        });
    },
    validationForm() {
      let isPersonalTabValid =
        this.employee.lastName &&
        this.employee.firstName &&
        this.employee.birthDate;
      let isPositionTabValid =
        this.employee.currentMainPosition.idPosition &&
        this.employee.currentMainPosition.idDepartment &&
        this.employee.currentMainPosition.idCategory &&
        this.employee.currentMainPosition.dateStart;
      let isUserTabValid =
        this.employee.user.login && this.employee.user.idUserRoleGroupType;
      if (!isPersonalTabValid) {
        this.$message({
          type: 'error',
          message:
            'Заполните обязательные поля на вкладке "Персональные данные"',
        });
      } else if (!isPositionTabValid) {
        this.$message({
          type: 'error',
          message:
            'Заполните обязательные поля на вкладке "Исполнение должностей"',
        });
      } else if (!isUserTabValid) {
        this.$message({
          type: 'error',
          message:
            'Заполните обязательные поля на вкладке "Данные пользователя"',
        });
      }
      if (isPersonalTabValid && isPositionTabValid && isUserTabValid) {
        this.isFormValid = true;
      } else {
        this.isFormValid = false;
      }
    },
    saveEmployeeAsync() {
      this.validationForm();
      if (!this.employee.id && this.isFormValid) {
        console.log('No Employee ID. Send Obj to creation');
        let positions = [];
        if (this.employee.currentMainPosition.idPosition) {
          positions.push(this.employee.currentMainPosition);
        }
        if (this.employee.currentExtraPosition.idPosition) {
          positions.push(this.employee.currentExtraPosition);
        }
        let employeeObj = {
          ...this.employee,
          positions: positions,
          appointmentDefaultDuration:
            dateTimeService.getDurationStringByMinutes(
              this.employee.appointmentDefaultDuration
            ),
        };
        console.log(employeeObj);
        this.$store
          .dispatch('createEmployee', employeeObj)
          .then(() => {
            this.$message({
              type: 'success',
              message: 'Сотрудник успешно добавлен',
            });
            this.$store.dispatch('getEmployees');
            this.$router.go(-1);
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: 'Не удалось добавить сотрудника!',
            });
          });
      }
      if (this.employee.id && this.isFormValid) {
        console.log('I have got Employee ID. Send Obj to edition');
        let positions = [];
        if (this.employee.currentMainPosition.idPosition) {
          positions.push(this.employee.currentMainPosition);
        }
        if (this.employee.currentExtraPosition.idPosition) {
          positions.push(this.employee.currentExtraPosition);
        }
        this.$store
          .dispatch('updateEmployee', {
            ...this.employee,
            positions: positions,
            appointmentDefaultDuration:
              dateTimeService.getDurationStringByMinutes(
                this.employee.appointmentDefaultDuration
              ),
          })
          .then(() => {
            this.$message({
              type: 'success',
              message: 'Данные сотрудника успешно изменены',
            });
            this.$store.dispatch('getEmployees');
            this.$router.go(-1);
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: 'Данные сотрудника не изменены',
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
