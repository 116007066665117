export function authHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem('user'));

  if (user && user.token) {
    return { Authorization: 'Bearer ' + user.token };
  } else {
    return {};
  }
}

export function AuthToken() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem('user'));

  if (user && user.token) {
    return user.token;
  } else {
    return null;
  }
}

export function AuthURL() {
  const AUTH_API_URL = process.env.VUE_APP_AUTH_API_URL;
  return AUTH_API_URL;
  // let modeDocumeds = documedsMode();
  // if (modeDocumeds.authURL.startsWith('$VUE_APP_')) {
  //   return 'https://auth.parusmis.ru/';
  //   // return 'https://auth.documeds.ru/';
  // }
}

export function BaseURL() {
  const BASE_API_URL = process.env.VUE_APP_BASE_API_URL;
  return BASE_API_URL;
  // let modeDocumeds = documedsMode();
  // if (modeDocumeds.baseURL.startsWith('$VUE_APP_')) {
  //   return 'https://back.parusmis.ru/';
  //   // return 'https://back.documeds.ru/';
  // }
}

export function HubURL() {
  // let modeDocumeds = documedsMode();
  // if (modeDocumeds.baseURL.startsWith('$VUE_APP_')) {
  //   return 'https://back.parusmis.ru/';
  //   // return 'https://back.documeds.ru/';
  // }
  return BaseURL();
}

export function DemoAccessURL() {
  const DEMO_ACCESS_API_URL = process.env.VUE_APP_DEMO_ACCESS_API_URL;
  return DEMO_ACCESS_API_URL;
  // let modeDocumeds = documedsMode();
  // if (modeDocumeds.demoURL.startsWith('$VUE_APP_')) {
  //   return 'https://demo.parusmis.ru/';
  // }
}

// export function documedsMode() {
//   return {
//     authURL: '$VUE_APP_AUTH_URL',
//     baseURL: '$VUE_APP_BASE_URL',
//     demoURL: '$VUE_APP_DEMO_URL',
//   };
// }

// export function authHeaderFormdata() {
//   // return authorization header with jwt token
//   let user = JSON.parse(localStorage.getItem('user'));

//   if (user && user.token) {
//     return {
//       Authorization: 'Bearer ' + user.token,
//       'Content-Type': 'multipart/form-data',
//     };
//   } else {
//     return {};
//   }
// }
