import axios from 'axios';
import { AuthURL } from '@/api/httpSettings';
import Vue from 'vue';

class AuthManager {
  get isAuthenticated() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user?.token) {
      let tokenJson = JSON.parse(
        window.atob(
          user.token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/')
        )
      );
      let expires = new Date(tokenJson.exp * 1000);
      return expires >= Date.now();
    }
    return false;
  }

  getUser() {
    let user = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : null;
    let userToReturn = null;
    if (user?.token) {
      let tokenJson = JSON.parse(
        window.atob(
          user.token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/')
        )
      );
      userToReturn = {
        id: tokenJson[
          `http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata`
        ],
        idDoctor:
          tokenJson[
            `http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier`
          ],
        fullname: user.fullname,
        notificationEmail: tokenJson[
          `http://schemas.microsoft.com/ws/2008/06/identity/claims/role`
        ].find((x) => x == 'email')
          ? true
          : false,
        notificationSms: tokenJson[
          `http://schemas.microsoft.com/ws/2008/06/identity/claims/role`
        ].find((x) => x == 'sms')
          ? true
          : false,
        post: user.post,
        roles:
          tokenJson[
            `http://schemas.microsoft.com/ws/2008/06/identity/claims/role`
          ],
        isDemo: user.isDemo,
        demoDays: user.demoDays,
        showDemo: user.showDemo,
        token: user.token,
        exp: tokenJson.exp,
      };
    }
    return userToReturn ? userToReturn : null;
  }

  updateUser(newUser) {
    localStorage.removeItem('user');
    localStorage.setItem('user', JSON.stringify(newUser));
  }

  async singIn(login, password) {
    const IPINFO_API_KEY = process.env.VUE_APP_IPINFO_API_KEY;
    let ipInfo = await axios.get('https://ipinfo.io', {
      params: { token: IPINFO_API_KEY },
    });
    let request = {
      login,
      password,
      ip: ipInfo.data.ip ?? '',
      location:
        `${ipInfo.data.country}, ${ipInfo.data.region}, ${ipInfo.data.city}` ??
        '',
    };
    let response = await axios.post(AuthURL() + 'login', request);
    let userProfile = response.data;
    if (Vue.$cookies.isKey('Rt')) {
      Vue.$cookies.remove('Rt');
    }
    Vue.$cookies.set('Rt', userProfile.rt);
    delete userProfile.rt;
    if (localStorage.getItem('user')) {
      localStorage.removeItem('user');
    }
    localStorage.setItem('user', JSON.stringify(userProfile));
    localStorage.setItem('collapseSidebar', false);
  }

  async singOut() {
    await axios.post(AuthURL() + 'logout', { rt: Vue.$cookies.get('Rt') });
    localStorage.removeItem('user');
    localStorage.removeItem('collapseSidebar');
    localStorage.removeItem('scheduleFilters');
    Vue.$cookies.remove('Rt');
  }
}

export default new AuthManager();
