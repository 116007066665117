<template>
  <el-dialog
    class="modal-add-edit-guarantee-letter"
    :close-on-click-modal="false"
    :visible="isModalAddEditGuaranteeLetterVisible"
    @update:visible="closeModalAddEditGuaranteeLetter"
    width="50%"
  >
    <h5 slot="title">
      {{
        isEditMode
          ? 'Редактирование гарантийного письма'
          : 'Добавление гарантийного письма'
      }}
    </h5>
    <div class="patient-info">
      <el-avatar
        class="patient-info__avatar"
        icon="el-icon-user"
        size="medium"
      ></el-avatar>
      <div class="d-flex flex-column">
        <span class="patient-info__fullname">{{ patientFullNameAndAge }}</span>
        <p class="patient-info__last-visit">
          {{ patientAddressAndPhone }}
        </p>
      </div>
    </div>
    <el-row :gutter="24" class="mt-16">
      <el-col :sm="24" :md="12" :lg="8">
        <label>Номер <span class="danger">*</span></label>
        <el-input
          size="small"
          placeholder="Номер"
          v-model="currentGuaranteeLetter.number"
          :class="{
            invalid: $v.currentGuaranteeLetter.number.$error,
          }"
        ></el-input>
        <small
          class="helper-text invalid"
          v-if="$v.currentGuaranteeLetter.number.$error"
          >Поле является обязательным</small
        >
      </el-col>
      <el-col :sm="24" :md="12" :lg="14">
        <label>Страховой полис <span class="danger">*</span></label>
        <el-select
          size="small"
          v-model="currentGuaranteeLetter.insurancePolicyId"
          placeholder="Страховой полис"
          no-data-text="Нет действующих полисов страхования"
          :class="{
            invalid: $v.currentGuaranteeLetter.insurancePolicyId.$error,
          }"
        >
          <el-option
            v-for="item in insurancePolicyList"
            :key="item.id"
            :label="`${item.insuranceCompany} ${item.series} №${item.number}`"
            :value="item.id"
          >
            <span style="float: left">{{ item.insuranceCompany }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{
              `${item.series} - №${item.number} (действ. до ${getDateString(
                item.validUntil
              )})`
            }}</span>
          </el-option>
        </el-select>
        <small
          class="helper-text invalid"
          v-if="$v.currentGuaranteeLetter.insurancePolicyId.$error"
          >Поле является обязательным
        </small>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt-16">
      <el-col :sm="24" :md="12" :lg="8">
        <label>Дата начала <span class="danger">*</span></label>
        <el-date-picker
          size="small"
          format="dd.MM.yyyy"
          value-format="yyyy-MM-dd"
          v-model="currentGuaranteeLetter.validFrom"
          type="date"
          placeholder="Введите дату"
          :picker-options="{ firstDayOfWeek: 1 }"
          :class="{
            invalid: $v.currentGuaranteeLetter.validFrom.$error,
          }"
        ></el-date-picker>
        <small
          class="helper-text invalid"
          v-if="$v.currentGuaranteeLetter.validFrom.$error"
          >Поле является обязательным</small
        >
      </el-col>
      <el-col :sm="24" :md="12" :lg="8">
        <label
          >Дата окончания
          <span
            v-show="currentGuaranteeLetter.insuranceType === 1"
            class="danger"
            >*</span
          ></label
        >
        <el-date-picker
          size="small"
          format="dd.MM.yyyy"
          value-format="yyyy-MM-dd"
          v-model="currentGuaranteeLetter.validUntil"
          type="date"
          placeholder="Введите дату"
          :picker-options="{ firstDayOfWeek: 1 }"
          :class="{
            invalid: $v.currentGuaranteeLetter.validUntil.$error,
          }"
        ></el-date-picker>
        <small
          class="helper-text invalid"
          v-if="$v.currentGuaranteeLetter.validUntil.$error"
          >Поле является обязательным</small
        >
      </el-col>
      <el-col v-if="false" :sm="24" :md="12" :lg="8">
        <label>Файл гарантийного письма</label>
        <el-upload
          action=""
          :multiple="false"
          :limit="1"
          :on-change="handleUploadFile"
          :show-file-list="false"
          :auto-upload="false"
        >
          <el-button type="primary" size="small"
            >Загрузить <i class="el-icon-plus"></i
          ></el-button>
        </el-upload>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt-16">
      <el-col :sm="24">
        <label>Текст гарантийного письма</label>
        <el-input
          type="textarea"
          resize="none"
          :rows="4"
          v-model="currentGuaranteeLetter.text"
        ></el-input>
      </el-col>
      <el-col :sm="24"></el-col>
    </el-row>
    <el-divider v-if="currentGuaranteeLetter.file"></el-divider>
    <el-row v-if="currentGuaranteeLetter.file" :gutter="24" class="mt-16">
      <el-col :sm="24" :md="12">
        <el-button
          type="text"
          size="small"
          @click="downloadFile"
          class="modal-add-edit-guarantee-letter__file"
          ><i class="el-icon-file-download"></i
          >{{ currentGuaranteeLetter.file.name }}</el-button
        >
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt">
      <el-col :span="24">
        <el-button size="small" type="primary" @click="saveGuaranteeLetter"
          >Сохранить</el-button
        >
        <el-button size="small" @click="closeModalAddEditGuaranteeLetter"
          >Отменить</el-button
        >
      </el-col>
      <el-col :span="12"> </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { getDateString, getAge, getAgeString } from '@/services/commonService';
export default {
  name: 'ModalAddEditGuaranteeLetterComponent',
  props: {
    isModalAddEditGuaranteeLetterVisible: {
      type: Boolean,
      default: false,
    },
    guaranteeLetter: {
      type: Object,
      default: null,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    patient: {
      type: Object,
      default: null,
    },
    insuranceOrganizationList: {
      type: Array,
    },
    insurancePolicyList: {
      type: Array,
    },
  },
  data() {
    return {
      getDateString: getDateString,
    };
  },
  computed: {
    currentGuaranteeLetter: {
      get() {
        return this.guaranteeLetter;
      },
      set(value) {
        this.$emit('update:guaranteeLetter', value);
      },
    },
    patientFullNameAndAge() {
      return `${this.patient.lastName} ${this.patient.firstName} ${
        this.patient.patronymic ? this.patient.patronymic : ''
      }, ${getDateString(this.patient.birthDate)}, ${getAgeString(
        getAge(this.patient.birthDate)
      )}`;
    },
    patientAddressAndPhone() {
      let patientAddress = this.patient.residenceAddress
        ? this.patient.residenceAddress
        : '';
      let patientPhone = this.patient.phone;
      return `${patientAddress ? patientAddress + ', ' : ''} ${patientPhone}`;
    },
  },
  validations: {
    currentGuaranteeLetter: {
      number: { required },
      insurancePolicyId: { required },
      validFrom: { required },
      validUntil: { required },
    },
  },
  methods: {
    downloadFile() {
      let blob = new Blob([this.currentGuaranteeLetter.file.raw]);
      let link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = this.currentGuaranteeLetter.file.name;
      link.click();
      window.URL.revokeObjectURL(link);
    },
    handleUploadFile(uploadedFile) {
      this.currentGuaranteeLetter.file = uploadedFile;
    },
    clearAddEditModalGuaranteeLetter() {
      this.$emit('clearAddEditModalGuaranteeLetter');
    },
    closeModalAddEditGuaranteeLetter() {
      this.clearAddEditModalGuaranteeLetter();
      this.$emit('update:isModalAddEditGuaranteeLetterVisible');
      this.$v.$reset();
    },
    saveGuaranteeLetter() {
      console.log('saveGuaranteeLetter method have been called!');
      if (!this.$v.$invalid) {
        console.log('Form passed validation');
        // если id гарантийного письма есть, отправлем запрос на редактирование
        if (this.currentGuaranteeLetter.id) {
          this.$emit('updateGuaranteeLetter', this.currentGuaranteeLetter);
        }
        // если id гарантийного письма нет, отправляем запрос на создание нового
        else {
          this.$emit('addGuaranteeLetter', this.currentGuaranteeLetter);
        }
      } else {
        this.$v.$touch();
      }
    },
  },
};
</script>
