import Vue from 'vue';
import VueRouter from 'vue-router';
import Root from '../views/RootComponent.vue';
import PatientsPage from '../views/PatientsPage.vue';
import PatientAddEditPage from '../views/PatientAddEditPage.vue';
import InvoicesPage from '../views/InvoicesPage.vue';
import DashboardPage from '../views/DashboardPage.vue';
import SettingsPage from '../views/SettingsPage.vue';
import SchedulePage from '../views/SchedulePage.vue';
import OrganizationsPage from '../views/OrganizationsPage.vue';
import LoginPage from '../views/LoginPage.vue';
import RegistrationPage from '../views/RegistrationPage.vue';
import VisitPage from '../views/VisitPage.vue';
import AuthManager from '../services/authManager';
import BranchesPage from '../views/settings/BranchesPage.vue';
import CabinetsPage from '../views/settings/CabinetsPage.vue';
import DepartmentsPage from '../views/settings/DepartmentsPage.vue';
import DirectoryPage from '../views/settings/DirectoryPage.vue';
import DirectoryMedServices from '../views/settings/directories/DirectoryMedServices.vue';
import DirectoryMKB from '../views/settings/directories/DirectoryMKB.vue';
import DirectoryPriceList from '../views/settings/directories/DirectoryPriceList.vue';
import DirectoryMedication from '../views/settings/directories/DirectoryMedication.vue';
import MyOrganizationPage from '../views/settings/MyOrganizationPage.vue';
import PositionsPage from '../views/settings/PositionsPage.vue';
import StaffPage from '../views/settings/StaffPage.vue';
import StaffFormPage from '../views/settings/StaffFormPage.vue';
import TimeTablePage from '../views/settings/TimeTablePage.vue';
import ProfilePage from '../views/ProfilePage.vue';
import MedicalCasesPage from '../views/MedicalCasesPage.vue';
import VisitsListPage from '../views/VisitsListPage.vue';
import EducationPage from '../views/EducationPage.vue';
import StartPage from '../views/education/StartPage.vue';
import OrganizationLessonPage from '../views/education/OrganizationLessonPage.vue';
import AddBranchesCabinetsLesson from '../views/education/AddBranchesCabinetsLesson.vue';
import AddDepartmentsPositionLesson from '../views/education/AddDepartmentsPositionLesson.vue';
import AddStaffLesson from '../views/education/AddStaffLesson.vue';
import AddTimetableLesson from '../views/education/AddTimetableLesson.vue';
import AddPriceList from '../views/education/AddPriceListLesson.vue';
import AddAppointmentLesson from '../views/education/AddAppointmentLesson.vue';
import AddPatientLesson from '../views/education/AddPatientLesson.vue';
import AddVisitLesson from '../views/education/AddVisitLesson.vue';
import AddInvoiceLesson from '../views/education/AddInvoiceLesson.vue';
import DashboardLesson from '../views/education/DashboardLesson.vue';
import ProfileLesson from '../views/education/ProfileLesson.vue';
import ProtocolSettingsLesson from '../views/education/ProtocolSettingsLesson.vue';
import PrintTemplatesLesson from '../views/education/PrintTemplatesLesson.vue';
import AnalyticsLesson from '../views/education/AnalyticsLesson.vue';
import SupportLesson from '../views/education/SupportLesson.vue';
import CabinetWorkloadPage from '../views/settings/CabinetWorkloadPage.vue';
import MeddocTemplatesConstructorPage from '../views/settings/MeddocTemplatesConstructorPage.vue';
import MeddocTemplatesListPage from '../views/settings/MeddocTemplatesListPage.vue';
import PrintTemplatesPage from '../views/settings/PrintTemplatesPage.vue';
import AnalyticsPage from '../views/AnalyticsPage.vue';
import NotFound from '../views/404Page.vue';
import BusinessProcessesPage from '@/views/settings/BusinessProcessesPage.vue';
import GuaranteeLettersPage from '@/views/GuaranteeLettersPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: '/404',
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
  },
  {
    path: '/registration/:uuid',
    name: 'registration',
    component: RegistrationPage,
  },
  {
    path: '/404',
    name: 'notFound',
    component: NotFound,
  },
  {
    path: '/',
    name: 'root',
    redirect: '/dashboard',
    component: Root,
    children: [
      {
        path: '/patients',
        name: 'patients',
        nameRu: 'Пациенты',
        component: PatientsPage,
        meta: { authorize: 'view_patient' },
      },
      {
        path: '/patient/:id',
        name: 'patientAddEdit',
        nameRu: 'Пациенты',
        component: PatientAddEditPage,
        meta: { authorize: 'view_patient' },
      },
      {
        path: '/patient/:id/guarantee-letters',
        name: 'guarantee-letters',
        nameRu: 'Гарантийные письма',
        component: GuaranteeLettersPage,
      },
      {
        path: 'patient/:id/medical-cases/',
        name: 'medical-cases',
        component: MedicalCasesPage,
        meta: { authorize: 'view_visits' },
      },
      {
        path: 'patient/:id/medical-cases/:mcid/visits-list/',
        name: 'visits-list',
        component: VisitsListPage,
        meta: { authorize: 'view_visits' },
      },
      {
        path: '/organizations',
        name: 'organizations',
        nameRu: 'Организации',
        component: OrganizationsPage,
        meta: { authorize: 'view_organization' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: () =>
        //   import(/* webpackChunkName: "about" */ "../views/PatientsPage.vue"),
      },
      {
        path: '/invoices',
        name: 'invoices',
        component: InvoicesPage,
        nameRu: 'Счета',
        meta: { authorize: 'view_invoice' },
      },
      {
        path: '/analytics',
        name: 'analytics',
        component: AnalyticsPage,
        nameRu: 'Аналитика',
        meta: { authorize: true },
      },
      {
        path: '/invoices/:id',
        name: 'patientInvoices',
        component: InvoicesPage,
        meta: { authorize: 'view_invoice' },
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        nameRu: 'Рабочий стол',
        component: DashboardPage,
        meta: { authorize: 'view_dashboard' },
      },
      {
        path: '/settings',
        name: 'settings',
        nameRu: 'Настройки',
        redirect: '/settings/myorganization',
        component: SettingsPage,
        children: [
          {
            path: '/settings/branches',
            name: 'branches',
            nameRu: 'Филиалы',
            component: BranchesPage,
            meta: { authorize: 'view_branche' },
          },
          {
            path: '/settings/cabinets',
            name: 'cabinets',
            nameRu: 'Кабинеты',
            component: CabinetsPage,
            meta: { authorize: 'view_branche' },
          },
          {
            path: '/settings/departments',
            name: 'departments',
            nameRu: 'Подразделения',
            component: DepartmentsPage,
            meta: { authorize: 'view_department' },
          },
          {
            path: '/settings/directory',
            name: 'directory',
            nameRu: 'Справочники',
            component: DirectoryPage,
            meta: { authorize: 'view_directory' },
          },
          {
            path: '/settings/directory/medservices',
            name: 'medservices',
            nameRu: 'Справочник услуг МЗ РФ',
            component: DirectoryMedServices,
            meta: { authorize: 'view_directory' },
          },
          {
            path: '/settings/directory/mkb',
            name: 'mkb',
            nameRu: 'Справочник заболеваний МКБ-10',
            component: DirectoryMKB,
            meta: { authorize: 'view_directory' },
          },
          {
            path: '/settings/directory/pricelist',
            name: 'pricelist',
            nameRu: 'Справочник услуг организации (прайс-лист)',
            component: DirectoryPriceList,
            meta: { authorize: 'view_price_list' },
          },
          {
            path: '/settings/directory/medication',
            name: 'medication',
            nameRu: 'Справочник лекарственных препаратов',
            component: DirectoryMedication,
            meta: { authorize: 'view_directory' },
          },
          {
            path: '/settings/myorganization',
            name: 'myorganization',
            nameRu: 'Моя организация',
            component: MyOrganizationPage,
            meta: { authorize: 'view_my_organization' },
          },
          {
            path: '/settings/positions',
            name: 'positions',
            nameRu: 'Должности',
            component: PositionsPage,
            meta: { authorize: 'view_position' },
          },
          {
            path: '/settings/staff',
            name: 'staff',
            nameRu: 'Сотрудники',
            component: StaffPage,
            meta: { authorize: 'view_staff' },
          },
          {
            path: '/settings/timetable',
            name: 'timetable',
            nameRu: 'График работ',
            component: TimeTablePage,
            meta: { authorize: 'view_timetable' },
          },
          {
            path: '/settings/cabinet-workload',
            name: 'cabinet-workload',
            nameRu: 'Загруженность кабинетов',
            component: CabinetWorkloadPage,
            meta: { authorize: 'view_timetable' },
          },
          {
            path: '/staff-form/:id',
            name: 'staff-form',
            component: StaffFormPage,
            meta: { authorize: 'view_staff' },
          },
          {
            path: '/settings/meddoc-templates-list',
            name: 'settings-meddoclist',
            nameRu: 'Шаблоны медицинских документов',
            component: MeddocTemplatesListPage,
            meta: { authorize: 'meddoc_constructor' },
          },
          {
            path: '/settings/meddoc-templates-constructor/:id',
            name: 'settings-meddoc',
            component: MeddocTemplatesConstructorPage,
            meta: { authorize: true },
          },
          {
            path: '/settings/print-templates',
            name: 'print-templates',
            nameRu: 'Шаблоны для печати',
            component: PrintTemplatesPage,
            meta: { authorize: true },
          },
          {
            path: '/settings/business-processes',
            name: 'business-processes',
            nameRu: 'Бизнес-процессы',
            component: BusinessProcessesPage,
            meta: { authorize: true },
          },
        ],
      },
      {
        path: '/schedule',
        name: 'schedule',
        nameRu: 'Расписание',
        component: SchedulePage,
        meta: { authorize: 'view_dashboard' },
      },
      {
        path: '/visit/:id',
        component: VisitPage,
        meta: { authorize: 'view_visits' },
      },
      {
        path: '/profile/:id',
        name: 'profile',
        component: ProfilePage,
        meta: { authorize: true },
      },
      {
        path: '/education',
        name: 'education',
        nameRu: 'Обучение',
        redirect: '/education/start-page',
        component: EducationPage,
        meta: { authorize: true },
        children: [
          {
            path: '/education/start-page',
            name: 'start-page',
            component: StartPage,
            meta: { authorize: true },
          },
          {
            path: '/education/organization-lesson',
            name: 'organization-lesson',
            component: OrganizationLessonPage,
            meta: { authorize: true },
          },
          {
            path: '/education/add-branches-cabinets-lesson',
            name: 'add-branches-cabinets-lesson',
            component: AddBranchesCabinetsLesson,
            meta: { authorize: true },
          },
          {
            path: '/education/add-departments-position-lesson',
            name: 'add-departments-position-lesson',
            component: AddDepartmentsPositionLesson,
            meta: { authorize: true },
          },
          {
            path: '/education/add-staff-lesson',
            name: 'add-staff-lesson',
            component: AddStaffLesson,
            meta: { authorize: true },
          },
          {
            path: '/education/add-timetable-lesson',
            name: 'add-timetable-lesson',
            component: AddTimetableLesson,
            meta: { authorize: true },
          },
          {
            path: '/education/add-price-list-lesson',
            name: 'add-price-list',
            component: AddPriceList,
            meta: { authorize: true },
          },
          {
            path: '/education/add-appointment-lesson',
            name: 'add-appointment-lesson',
            component: AddAppointmentLesson,
            meta: { authorize: true },
          },
          {
            path: '/education/add-patient-lesson',
            name: 'add-patient-lesson',
            component: AddPatientLesson,
            meta: { authorize: true },
          },
          {
            path: '/education/add-visit-lesson',
            name: 'add-visit-lesson',
            component: AddVisitLesson,
            meta: { authorize: true },
          },
          {
            path: '/education/add-invoice-lesson',
            name: 'add-invoice-lesson',
            component: AddInvoiceLesson,
            meta: { authorize: true },
          },
          {
            path: '/education/dashboard-lesson',
            name: 'dashboard-lesson',
            component: DashboardLesson,
            meta: { authorize: true },
          },
          {
            path: '/education/profile-lesson',
            name: 'profile-lesson',
            component: ProfileLesson,
            meta: { authorize: true },
          },
          {
            path: '/education/protocol-settings-lesson',
            name: 'protocol-settings-lesson',
            component: ProtocolSettingsLesson,
            meta: { authorize: true },
          },
          {
            path: '/education/print-templates-lesson',
            name: 'print-templates-lesson',
            component: PrintTemplatesLesson,
            meta: { authorize: true },
          },
          {
            path: '/education/analytics-lesson',
            name: 'analytics-lesson',
            component: AnalyticsLesson,
            meta: { authorize: true },
          },
          {
            path: '/education/support-lesson',
            name: 'support-lesson',
            component: SupportLesson,
            meta: { authorize: true },
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const { authorize } = to.meta;
  const currentUser = AuthManager.getUser();
  if (authorize && !Vue.$cookies.isKey('Rt')) {
    return next({ path: '/login' });
  }
  if (authorize == true && Vue.$cookies.isKey('Rt')) {
    return next();
  }
  if (authorize && !currentUser.roles.find((x) => x == authorize)) {
    return next({ path: '/' });
  } else next();
});

export default router;
