import axios from '@/api/axios';

const getPriceList = async () => {
  return await axios.get('pricelist');
};

const createPriceListItem = async (priceListItem) => {
  return await axios.post('pricelist', priceListItem);
};

const updatePriceListitem = async (priceListItem) => {
  return await axios.put('pricelist', priceListItem);
};
const deletePriceListItem = async (id) => {
  return await axios.delete('pricelist/' + id);
};
const getMedicalServiceFromDictionary = async (params) => {
  return await axios.get('medicalservicetype', { params: params });
};

export default {
  getPriceList,
  createPriceListItem,
  updatePriceListitem,
  deletePriceListItem,
  getMedicalServiceFromDictionary,
};
