import guaranteeLettersApi from '@/api/guaranteeLetters';

const guaranteeLettersModule = {
  state: () => ({
    guaranteeLetter: {
      id: null,
      insurancePolicyId: null,
      number: null,
      validFrom: null,
      validUntil: null,
      text: null,
      file: null,
    },
    guaranteeLetterList: {
      total: 0,
      values: [],
    },
    defaultGuaranteeLetter: {
      id: null,
      insurancePolicyId: null,
      validFrom: null,
      validUntil: null,
      file: null,
    },
  }),
  getters: {
    guaranteeLetter: (state) => {
      return state.guaranteeLetter;
    },
    defaultGuaranteeLetter: (state) => {
      return state.defaultGuaranteeLetter;
    },
    guaranteeLetterList: (state) => {
      return state.guaranteeLetterList;
    },
    guaranteeLetterListByPolicyId: (state) =>
      function (insurancePolicyId) {
        if (insurancePolicyId) {
          return state.guaranteeLetterList.values.filter(
            (x) => x.insurancePolicyId === insurancePolicyId
          );
        } else {
          return state.guaranteeLetterList.values;
        }
      },
  },
  mutations: {
    SET_GUARANTEE_LETTER: (state, payload) => {
      state.guaranteeLetter = payload;
    },
    SET_GUARANTEE_LETTER_LIST: (state, payload) => {
      state.guaranteeLetterList = payload;
    },
  },
  actions: {
    getGuaranteeLetterList: async (context, params) => {
      const response = await guaranteeLettersApi.getGuaranteeLetterList(params);
      context.commit('SET_GUARANTEE_LETTER_LIST', response.data);
    },
    getGuaranteeLetterById: async (context, id) => {
      const response = await guaranteeLettersApi.getGuaranteeLetterById(id);
      context.commit('SET_GUARANTEE_LETTER', response.data);
      return response.data;
    },
    createGuaranteeLetter: async (context, guaranteeLetter) => {
      return await guaranteeLettersApi.createGuaranteeLetter(guaranteeLetter);
    },
    updateGuaranteeLetter: async (context, guaranteeLetter) => {
      return await guaranteeLettersApi.updateGuaranteeLetter(guaranteeLetter);
    },
    deleteGuaranteeLetter: async (context, id) => {
      return await guaranteeLettersApi.deleteGuaranteeLetterById(id);
    },
  },
};

export default guaranteeLettersModule;
