<template>
  <div>
    <!-- модальное окно для печати документов по пациенту -->
    <el-dialog
      class="modal-print-document"
      title="Печать документов пациента"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible="isModalVisible"
      @update:visible="$emit('update:isModalVisible')"
      width="100%"
    >
      <h6 class="visit-heading">Выберите документы для печати</h6>
      <div class="visit-general-document">
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >Выбрать все</el-checkbox
        >
        <el-checkbox-group
          class="visit-general-document-list mt"
          v-model="checkedDocuments"
          @change="handleCheckedDocumentsChange"
        >
          <el-checkbox
            v-for="document in documentsForPrint"
            :label="document"
            :key="document.idClinicDocumentType"
          >
            <li>
              <span>{{ document.name }}</span>
              <!-- <i class="ml-12 el-icon-file"></i> -->
            </li>
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <el-row :gutter="24" align="center" class="mt-20">
        <el-col :span="12">
          <el-button
            size="small"
            class="mt w-100"
            type="primary"
            @click="
              documentsForPrint == medDocuments
                ? handlePrintMedDocument(checkedDocuments)
                : handlePrintDocument(checkedDocuments)
            "
            >Печать</el-button
          >
        </el-col>
        <el-col :span="12">
          <el-button
            size="small"
            class="mt w-100"
            type="primary"
            plain
            @click="$emit('update:isModalVisible')"
            >Закрыть</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>
    <PdfViewComponent
      :pdf-view-visible.sync="pdfViewVisible"
      :source="link"
    ></PdfViewComponent>
  </div>
</template>

<script>
import PdfPrintManager from '@/services/pdfPrintManager.js';
import PdfViewComponent from './PdfViewComponent.vue';
import { medDocuments } from '@/constants';
import MeddocManager from '@/services/meddocManager';

export default {
  components: {
    PdfViewComponent,
  },
  data() {
    return {
      checkAll: false,
      checkedDocuments: [],
      isIndeterminate: false,
      link: '',
      pdfViewVisible: false,
      medDocuments: medDocuments,
    };
  },
  props: {
    isModalVisible: Boolean,
    idAppointment: null,
    idPatient: null,
    documentsForPrint: Array,
    meddocName: String,
    meddocPrintName: String,
    meddocFields: Array,
    diagnosisCodes: Array,
  },
  methods: {
    handleCheckAllChange(val) {
      this.checkedDocuments = val ? this.documentsForPrint : [];
      this.isIndeterminate = false;
    },
    handleCheckedDocumentsChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.documentsForPrint.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.documentsForPrint.length;
    },
    async handlePrintDocument(checkedDocuments) {
      if (checkedDocuments.length) {
        this.link = await PdfPrintManager.getPdfFile(
          checkedDocuments,
          this.idAppointment,
          this.idPatient
        );
        this.pdfViewVisible = true;
      } else {
        this.$message({
          message: 'Не выбраны документы для печати!',
          type: 'error',
        });
      }
    },
    async handlePrintMedDocument(checkedDocuments) {
      let meddocToPrint = {
        idAppointment: this.idAppointment,
        printMode: checkedDocuments.map((x) => {
          return {
            type: x.id,
          };
        }),
        documentName: this.meddocPrintName
          ? this.meddocPrintName
          : this.meddocName,
        fields: this.meddocFields.map((x) => {
          return {
            name: x.name,
            value:
              typeof x.value == 'number'
                ? this.diagnosisCodes.find((d) => d.id == x.value).code +
                  ' ' +
                  this.diagnosisCodes.find((d) => d.id == x.value).name
                : x.value
                ? x.value
                : x.defaultValue,
            fieldNumber: x.fieldNumber,
            isRecommendation: x.isRecommendation,
          };
        }),
      };
      this.link = await MeddocManager.printMeddoc(meddocToPrint);
      this.pdfViewVisible = true;
    },
  },
};
</script>
