import Axios from "axios";
import { AuthURL } from "../api/httpSettings";
import { authHeader } from '../api/httpSettings';

class UserManager {
    getNotificationTypes() {
        var user = JSON.parse(localStorage.getItem("user"));
        if (user) {
          let notificationTypes = { sms: user.notificationSms, email: user.notificationEmail};
          return notificationTypes;
        }
        return null;
    }

    async disableWelcomeModalForUser(){
      await Axios.put(AuthURL() + "showmodalnotification", {type: 1}, {headers: authHeader()});
    }
}
export default new UserManager();