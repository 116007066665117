import { store } from '../store';

class AppointmentManager {
  //получение списка типов приема
  async getAppointmentTypes() {
    if (store.getters.appointmentTypes.length == 0) {
      await store.dispatch('GET_APPOINTMENTTYPES');
    }
    return store.getters.appointmentTypes;
  }
  //получение списка статусов приема
  async getAppointmentStatusTypes() {
    if (store.getters.appointmentStatusTypes.length == 0) {
      await store.dispatch('GET_APPOINTMENTSTATUSTYPES');
    }
    return store.getters.appointmentStatusTypes;
  }
  //проверка занятости врача при добавлении записи в расписание
  checkAppointmentByDoctor(dateTimeFrom, dateTimeTo, idDoctor, idAppointment) {
    // console.log(dateTimeFrom, dateTimeTo, idDoctor, idAppointment);
    let schedules = store.getters.scheduleList;
    let schedule = schedules.filter(
      (x) =>
        (new Date(x.dateTimeFrom) < dateTimeFrom ||
          new Date(x.dateTimeFrom).getTime() == dateTimeFrom.getTime()) &&
        new Date(x.dateTimeTo) > dateTimeTo &&
        x.idDoctor == idDoctor
    );
    if (schedule.length != 1) {
      return 'В указанное время у врача нет приема.';
    }
    let appointment = schedule[0].appointments.filter(
      (x) =>
        (new Date(x.dateTimeFrom).getTime() == dateTimeFrom.getTime() ||
          new Date(x.dateTimeTo).getTime() == dateTimeTo.getTime() ||
          (dateTimeFrom > new Date(x.dateTimeFrom) &&
            dateTimeFrom < new Date(x.dateTimeTo)) ||
          (dateTimeTo > new Date(x.dateTimeFrom) &&
            dateTimeTo < new Date(x.dateTimeTo)) ||
          (dateTimeFrom < new Date(x.dateTimeFrom) &&
            dateTimeTo > new Date(x.dateTimeTo))) &&
        x.id != idAppointment &&
        x.idAppointmentStatus != 8
    );
    if (appointment.length > 0) {
      return 'На указанное время у врача уже есть запись!';
    }
    return null;
  }
  //проверка занятости кабинета при добавлении записи в расписание
  async checkAppointmentByCabinet(
    dateTimeFrom,
    dateTimeTo,
    idCabinet,
    idAppointment
  ) {
    let schedules = store.getters.schedules;
    let schedule = schedules.filter(
      (x) =>
        (new Date(x.dateTimeFrom) < dateTimeFrom ||
          new Date(x.dateTimeFrom).getTime() == dateTimeFrom.getTime()) &&
        new Date(x.dateTimeTo) > dateTimeTo &&
        x.idCabinet == idCabinet
    );
    if (schedule.length != 1) {
      return 'В указанное время в кабинет нет записи.';
    }
    let appointment = schedule[0].appointments.filter(
      (x) =>
        (new Date(x.dateTimeFrom).getTime() == dateTimeFrom.getTime() ||
          new Date(x.dateTimeTo).getTime() == dateTimeTo.getTime() ||
          (dateTimeFrom > new Date(x.dateTimeFrom) &&
            dateTimeFrom < new Date(x.dateTimeTo)) ||
          (dateTimeTo > new Date(x.dateTimeFrom) &&
            dateTimeTo < new Date(x.dateTimeTo)) ||
          (dateTimeFrom < new Date(x.dateTimeFrom) &&
            dateTimeTo > new Date(x.dateTimeTo))) &&
        x.id != idAppointment &&
        x.idAppointmentStatus != 8
    );
    if (appointment.length > 0) {
      return 'На указанное время уже есть запись в указанный кабинет.';
    }
    return null;
  }
  //проверка на то, что паицент уже записан на это время
  checkAppointmentByPatient(dateTimeFrom, dateTimeTo, idPatient) {
    let schedules = store.getters.scheduleList;
    let schedule = schedules.find((x) =>
      x.appointments.find(
        (a) =>
          a.idPatient == idPatient &&
          (new Date(a.dateTimeFrom).getTime() == dateTimeFrom.getTime() ||
            new Date(a.dateTimeTo).getTime() == dateTimeTo.getTime() ||
            (dateTimeFrom > new Date(a.dateTimeFrom) &&
              dateTimeFrom < new Date(a.dateTimeTo)) ||
            (dateTimeTo > new Date(a.dateTimeFrom) &&
              dateTimeTo < new Date(a.dateTimeTo)) ||
            (dateTimeFrom < new Date(a.dateTimeFrom) &&
              dateTimeTo > new Date(a.dateTimeTo))) &&
          a.idAppointmentStatus != 8
      )
    );
    if (schedule) {
      return 'У пациента уже есть запись на указанное время!';
    }
    return null;
  }
}

export default new AppointmentManager();
