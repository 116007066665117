<template>
  <div class="settings-page" v-loading="loading">
    <el-row :gutter="24">
      <el-col :span="24">
        <h6>Подразделения</h6>
        <span class="settings-description">
          Здесь вы можете посмотреть подразделения в филиалах вашей организации
          и создать новые.
        </span>
      </el-col>
      <el-col :span="24" class="mt">
        <el-tabs v-model="filialActive">
          <el-tab-pane
            v-for="filial in filials"
            :key="filial.id"
            :label="filial.name"
            :name="filial.id.toString()"
          >
            <el-row :gutter="24">
              <el-col
                :xl="6"
                :lg="8"
                :md="12"
                :sm="24"
                class="mt"
                v-for="item in filials.find((x) => x.id == filialActive)
                  .departments"
                :key="item.id"
              >
                <div class="settings-card">
                  <el-button
                    size="small"
                    class="settings-contacts-list-btn"
                    type="text"
                    icon="el-icon-delete"
                    circle
                    @click="handleRemoveDepartment(item)"
                    v-if="editDepartmentShow"
                  ></el-button>
                  <div class="settings-card-title">
                    {{ item.name }}
                  </div>
                  <ul class="settings-card-list">
                    <li class="settings-card-item">
                      <span class="settings-card-field">Описание:</span>
                      <span class="settings-card-value">
                        {{ item.description }}
                      </span>
                    </li>
                  </ul>
                  <el-button
                    size="small"
                    type="primary"
                    plain
                    @click="openModalEditDepartment(item)"
                    v-if="editDepartmentShow"
                    >Редактировать</el-button
                  >
                </div>
              </el-col>
              <el-col
                :xl="6"
                :lg="8"
                :md="12"
                :sm="24"
                class="mt"
                v-if="editDepartmentShow"
              >
                <div class="settings-card empty">
                  <el-button
                    size="small"
                    class="settings-card-btn"
                    @click="openModalAddDepartment"
                  >
                    <i class="el-icon-plus el-icon-left"></i>
                    Добавить
                  </el-button>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>

    <!-- модальное окно доабвления подразделения -->
    <el-dialog
      class="modal-add-department"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="modalTitle"
      :visible.sync="modalAddDepartment"
      width="100%"
    >
      <el-row :gutter="24">
        <el-col :span="24" class="mt">
          <label for="" class="settings-modal-label"
            >Наименование <span class="danger">*</span></label
          >
          <el-input
            size="small"
            placeholder="Введите наименование подразделения"
            v-model="department.name"
            :class="{
              invalid:
                $v.department.name.$dirty && !$v.department.name.required,
            }"
          >
          </el-input>
          <small
            class="helper-text invalid"
            v-if="$v.department.name.$dirty && !$v.department.name.required"
            >Поле является обязательным</small
          >
        </el-col>
        <el-col :span="24" class="mt">
          <label for="" class="settings-modal-label">Описание</label>
          <el-input
            size="small"
            type="textarea"
            resize="none"
            :rows="4"
            placeholder="Введите описание подразделения"
            v-model="department.description"
          >
          </el-input>
        </el-col>
        <el-col :span="24" class="mt">
          <el-row :gutter="24">
            <el-col :span="12">
              <el-button
                class="w-100"
                size="small"
                type="primary"
                @click="handleDepartmentSave"
                :loading="loadingModal"
                >Сохранить</el-button
              >
            </el-col>
            <el-col :span="12">
              <el-button
                class="w-100"
                size="small"
                @click="modalAddDepartment = false"
                >Отменить</el-button
              >
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import FilialManager from '@/services/filialManager.js';
import AuthManager from '@/services/authManager';

export default {
  loading: false,
  data() {
    return {
      filials: [],
      filialActive: '',
      modalAddDepartment: false,
      loadingModal: false,
      modalTitle: '',
      department: '',
      editDepartmentShow: false,
    };
  },
  async created() {
    this.loading = true;
    this.filials = await FilialManager.getFilials();
    this.filialActive = this.filials[0].id.toString();
    let currentUser = AuthManager.getUser();
    this.editDepartmentShow = currentUser.roles.find(
      (x) => x == 'edit_department'
    );
    this.loading = false;
  },
  validations: {
    department: {
      name: { required },
    },
  },
  methods: {
    openModalAddDepartment() {
      this.department = {
        id: null,
        name: '',
        description: '',
        idFilial: this.filialActive,
      };
      this.modalTitle = 'Добавление подразделения';
      this.modalAddDepartment = true;
    },
    openModalEditDepartment(item) {
      this.department = {
        id: item.id,
        name: item.name,
        description: item.description,
        idFilial: item.idFilial,
      };
      this.modalTitle = 'Редактирование подразделения';
      this.modalAddDepartment = true;
    },

    async handleDepartmentSave() {
      this.loadingModal = true;
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.loadingModal = false;
        return;
      } else {
        if (this.department.id) {
          this.filials = await FilialManager.updateDepartment(this.department);
        } else {
          this.filials = await FilialManager.addDepartment(this.department);
        }
        this.modalAddDepartment = false;
        this.loadingModal = false;
      }
    },
    async handleRemoveDepartment(item) {
      this.$confirm(
        'Вы действительно хотите удалить выбранное подразделение?',
        'Удаление подразделения',
        {
          confirmButtonText: 'Подтвердить',
          cancelButtonText: 'Отменить',
          type: 'warning',
        }
      )
        .then(async () => {
          this.$message({
            type: 'success',
            message: 'Удаление прошло успешно',
          });
          this.filials = await FilialManager.deleteDepartment(item);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Удаление отменено',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
