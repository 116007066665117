import axios from 'axios';
import { BaseURL } from '@/api/httpSettings';
import AuthManager from '@/services/authManager';
import { AuthURL } from '@/api/httpSettings';
import Vue from 'vue';

axios.defaults.baseURL = BaseURL();

axios.interceptors.request.use(function (config) {
  let user = AuthManager.getUser();
  let authorizisationToken = user && user.token ? user.token : null;
  config.headers.Authorization = authorizisationToken
    ? 'Bearer ' + authorizisationToken
    : null;
  config.headers['Content-Type'] = 'application/json';
  return config;
});

axios.interceptors.response.use(
  async function (config) {
    return config;
  },
  async function (err) {
    if (err.response.status === 401) {
      // originalConfig._retry = true;
      let usr = AuthManager.getUser();
      // let ipInfo = null;
      // await fetch(`https://api.ipify.org?format=json`)
      //   .then((x) => x.json())
      //   .then(({ ip }) => {
      //     ipInfo = ip;
      //   });
      let data = await axios.post(AuthURL() + 'refreshToken', {
        token: usr.token,
        rt: Vue.$cookies.get('Rt'),
        ip: 'something IP',
      });
      if (Vue.$cookies.isKey('Rt')) {
        Vue.$cookies.remove('Rt');
      }
      Vue.$cookies.set('Rt', data.data.rt);
      let userFromLS = JSON.parse(localStorage.getItem('user'));
      userFromLS.token = data.data.token;
      localStorage.removeItem('user');
      localStorage.setItem('user', JSON.stringify(userFromLS));
      err.config._retry = true;
      return axios.request({
        ...err.config,
        headers: {
          ...err.config.headers,
          Authorization: `Bearer ${data.data.token}`,
        },
        sent: true,
      });
    }
    if (err.response.status === 508 || err.response.status === 403) {
      if (Vue.$cookies.isKey('Rt')) {
        Vue.$cookies.remove('Rt');
      }
      localStorage.removeItem('user');
      this.$router.push('/login');
    }
    return Promise.reject(err);
  }
);

export default axios;
