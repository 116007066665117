<template>
  <div class="education-main">
    <el-row :gutter="24">
      <el-col :lg="18" class="d-flex flex-between flex-center mb-16 flex-wrap">
        <h4 class="mt-16">Оформление приема пациента и заполнение протокола осмотра</h4>
        <el-button class="mt-16" size="small" @click="openModalVideo" type="primary"
          >Смотреть видео<i class="el-icon-play"></i
        ></el-button>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt-16">
      <el-col :lg="18">
        <p>
          Начать прием пациента и оформление медицинских документов можно
          несколькими способами.
        </p>
        <p>
          В разделе «Рабочий стол» нажать на кнопку со стрелкой напротив
          необходимой записи на прием в виджете «Моё расписание сегодня».
        </p>
        <el-image
          class="mt-16 mb-16"
          fit="contain"
          :src="src.url1"
          :preview-src-list="srcList">
        </el-image>
      </el-col>
      <el-col :lg="18">
        <p>
          В разделе «Расписание» навести на требуемую ячейку в сетке расписания
          и в открывшемся окне нажать на кнопку «Начать приём».
        </p>
        <el-image
          class="mt-16 mb-16"
          fit="contain"
          :src="src.url2"
          :preview-src-list="srcList">
        </el-image>
        <p>
          При нажатии на кнопку «Начать приём» открывается окно с информацией о
          пациенте и текущей записи. Навигация в данном разделе возможна по
          вкладкам:
        </p>
        <ul>
          <li>Общие сведения</li>
          <li>Услуги</li>
          <li>Документы (протоколы)</li>
          <li>Случай обращения</li>
          <li>Файлы</li>
        </ul>
        <el-divider></el-divider>
      </el-col>
      <el-col :lg="18">
        <p>
          На вкладке «Общие сведения» врач видит данные пациента, тип приема,
          который был указан при записи, и случай обращения, к которому
          относится данный прием.
        </p>
        <p>
          Если установлен тип приема «Первичный прием», будет автоматически
          открыт новый случай обращения, при этом поле «Случай обращения» будет
          заблокировано.
        </p>
        <p>
          Если установлен тип приема «Вторичный прием», поле «Случай обращения»
          будет активно, в него попадут все открытые случаи обращения данного
          пациента. Для привязки текущего приема к требуемому случаю обращения
          необходимо выбрать данный случай из выпадающего списка.
        </p>
        <p>
          Если установлен тип приема «Консультация», будет автоматически открыт
          и закрыт текущей датой новый случай обращения, при этом поле «Случай
          обращения» будет заблокировано.
        </p>
        <p>Здесь же можно распечатать документы пациента.</p>
        <el-image
          class="mt-16 mb-16"
          fit="contain"
          :src="src.url3"
          :preview-src-list="srcList">
        </el-image>
        <p>
          Учет приемов и случаев обращения организован подобным образом в связи
          с тем, что в рамках лечения одного заболевания пациент может
          обращаться в клинику несколько раз, в том числе к различным
          специалистам. К примеру, пациент, которому поставлен диагноз I11.9 -
          Гипертоническая болезнь, может наблюдаться как у терапевта, так и у
          кардиолога в рамках лечения данного заболевания.
        </p>
        <p>
          В то же время у пациента может быть единовременно открыто несколько
          случаев обращения. Например, пациент может параллельно проходить
          лечение нескольких заболеваний у различных профильных специалистов. К
          примеру, пациент может наблюдаться у терапевта с диагнозом J06.9 -
          ОРВИ и проходить лечение у кардиолога с диагнозом I11.9 -
          Гипертоническая болезнь. В текущей ситуации это два разных случая
          обращения.
        </p>
        <p>
          Если тип приема не является «Консультацией», то случай обращения
          закрывается вручную. Это можно сделать на вкладке «Случай обращения»
          при нажатии на кнопку «Завершить случай обращения».
        </p>
        <el-image
          class="mt-16 mb-16"
          fit="contain"
          :src="src.url5"
          :preview-src-list="srcList">
        </el-image>
        <p>
          Прием пациента не может быть завершен, пока не добавлены оказанные ему
          услуги. Услуги могут быть выбраны на вкладке «Услуги». Поиск требуемой
          услуги осуществляется в специальном поле для ввода в верхней части
          вкладки. При вводе значений в данное поле система предлагает к выбору
          совпадающие по маске наименования услуг из прайс-листа организации.
          Про создание справочника услуг организации (прайс-листа) можно
          прочитать в разделе обучения
          <router-link to="/education/add-price-list-lesson"
            >Создание справочника услуг организации (прайс-листа).</router-link
          >
        </p>
        <el-image
          class="mt-16 mb-16"
          fit="contain"
          :src="src.url4"
          :preview-src-list="srcList">
        </el-image>
        <el-divider></el-divider>
      </el-col>
      <el-col :lg="18">
        <p>
          Также в рамках приема необходимо заполнить медицинский документ.
          Создать новый документ можно на вкладке «Документы (протоколы)» при
          нажатии на кнопку «Добавить». После этого становится доступным выбор
          типа медицинского документа и его заполнение. Специалист может выбрать
          требуемый тип медицинского документа из доступных ему в поле в верхней
          части вкладки, при этом состав полей документа будет динамически
          изменяться в соответсвии с выбранным типом. В данный документ вносятся
          результаты осмотра пациента.
        </p>
        <el-image
          class="mt-16 mb-16"
          fit="contain"
          :src="src.url6"
          :preview-src-list="srcList">
        </el-image>
        <p>
          После заполнения документ может быть распечатан и сохранен.
          Распечатать документ можно при нажатии на кнопку «Печать», где можно
          отметить требуемый вариант печати: печать медицинского документа
          полностью и/или печать назначений. Применить внесенные в документ
          изменения можно при нажатии на кнопку «Сохранить» после заполнения
          всех полей, отмеченных как обязательные.
        </p>
        <p>
          На текущий момент печать рецептов по формам 107/у и 148/у не
          реализована. Данный функционал находится в стадии разработки.
        </p>
        <el-divider></el-divider>
      </el-col>
      <el-col :lg="18">
        <p>
          На вкладке «Файлы» могут быть загружены произвольные медициские
          документы или файлы, относящиеся к приему или заболеванию в целом.
        </p>
        <el-image
          class="mt-16 mb-16"
          fit="contain"
          :src="src.url7"
          :preview-src-list="srcList">
        </el-image>
        <p>
          Загрузка файлов производится при нажатии на кнопку «Загрузить», после
          чего открывается всплывающее окно импорта файла, в котором можно
          выбрать требуемый файл с компьютера. После импорта файл может быть
          загружен из приложения на компьютер или удален.
        </p>
        <p>
          После того, как все данные по приему пациента были внесены, необходимо
          нажать кнопку в верхнем правом углу окна «Завершить прием». Рядом с
          этой кнопкой указано время окончания приема согласно записи в
          расписании.
        </p>
        <p>
          По завершении приема будет сформирован счет на оплату услуг,
          добавленых в течение приема. Счета доступны в разделе «Счета» в
          основном меню и в виджете «Счета клиники сегодня» на рабочем столе
          пользователям с ролями «Регистратор» и «Администратор системы».
        </p>
        <p>
          Про оформление счетов на оплату вручную, а также про смену статусов
          счетов можно прочитать в разделе
          <router-link to="/education/add-invoice-lesson"
            >Формирование счета на оказанные услуги</router-link
          >
        </p>
      </el-col>
    </el-row>
    <!-- модальное окно просмотра видео-урока -->
    <el-dialog
      title="Видео-урок"
      class="modal-play-video"
      :visible.sync="modalVideo"
      width="100%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true">
      <div v-if="!idVideo">
        <h6>К сожалению видео-урок не доступен</h6>
        <p>
          В скором времени мы добавим данный видео-урок по обучению работе в
          Documeds
        </p>
      </div>
      <div v-if="idVideo">
        <VideoPlayerComponent :idVideo="idVideo"></VideoPlayerComponent>
      </div>
      <el-button
        class="education-modal-btn"
        type="primary"
        @click="openModalVideo"
        >Продолжить обучение в текстовом формате</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
  import VideoPlayerComponent from "@/components/VideoPlayerComponent.vue";
  export default {
    components: { VideoPlayerComponent },
    data() {
      return {
        modalVideo: false,
        idVideo: "DCJL7qwLPgQ",
        src: {
          url1: require("@/assets/img/education/add-visit-1.png"),
          url2: require("@/assets/img/education/add-visit-2.png"),
          url3: require("@/assets/img/education/add-visit-3.png"),
          url4: require("@/assets/img/education/add-visit-4.png"),
          url5: require("@/assets/img/education/add-visit-5.png"),
          url6: require("@/assets/img/education/add-visit-6.png"),
          url7: require("@/assets/img/education/add-visit-7.png"),
        },
        srcList: [
          require("@/assets/img/education/add-visit-1.png"),
          require("@/assets/img/education/add-visit-2.png"),
          require("@/assets/img/education/add-visit-3.png"),
          require("@/assets/img/education/add-visit-4.png"),
          require("@/assets/img/education/add-visit-5.png"),
          require("@/assets/img/education/add-visit-6.png"),
          require("@/assets/img/education/add-visit-7.png"),
        ],
      };
    },
    methods: {
      openModalVideo() {
        this.modalVideo = !this.modalVideo;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
