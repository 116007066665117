<template>
  <div class="page-wrap" v-loading="loading">
    <BreadcrumbComponent
      :patient="patient"
      :appointment="appointment"
    ></BreadcrumbComponent>
    <div class="visit-header">
      <h4 class="visit-title">
        {{ patientFullName }},
        <span>{{ patientAge }} </span>
      </h4>
      <div class="visit-time">
        <span class="visit-time-end" v-if="isAllowedCloseAppointment"
          >Окончание приёма: <b>{{ endTime }}</b></span
        >
        <el-button
          size="small"
          type="danger"
          plain
          @click="handleCloseAppointment"
          v-if="isAllowedCloseAppointment"
          >Завершить прием</el-button
        >
        <el-tag type="success" v-if="appointment?.idAppointmentStatus == 6"
          ><b>Прием завершен</b></el-tag
        >
        <el-tag type="danger" v-if="appointment?.idAppointmentStatus == 7"
          ><b>Неявка пациента</b></el-tag
        >
        <el-tag type="danger" v-if="appointment?.idAppointmentStatus == 8"
          ><b>Прием отменен</b></el-tag
        >
      </div>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="Общие сведения" name="general">
        <el-row>
          <el-col :span="24" :sm="10">
            <h6 class="visit-heading">Общие сведения</h6>
            <div class="visit-general-row">
              <span class="visit-general-field">ФИО:</span>
              <span class="visit-general-value">{{ patientFullName }}</span>
            </div>
            <div class="visit-general-row">
              <span class="visit-general-field">Возраст:</span>
              <span class="visit-general-value">{{ patientAge }}</span>
            </div>
            <div class="visit-general-row">
              <span class="visit-general-field">Телефон:</span>
              <span class="visit-general-value">{{
                patient?.phone ?? ''
              }}</span>
            </div>
            <div class="visit-general-row">
              <span class="visit-general-field">Теги:</span>
              <span class="visit-general-value">
                <el-tag
                  v-for="(tag, index) in patient?.patientTags"
                  :key="index"
                >
                  {{ tag.value }}
                </el-tag>
              </span>
            </div>
            <div class="visit-general-row">
              <span class="visit-general-field">Тип приема:</span>
              <span class="visit-general-value">
                <el-select
                  size="small"
                  v-model="appointment.idAppointmentType"
                  clearable
                  @change="hadleVisitTypeChange"
                  placeholder="Выберите тип приема"
                >
                  <el-option
                    v-for="item in visitTypes"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </span>
            </div>
            <div class="visit-general-row">
              <span class="visit-general-field">Случай обращения:</span>
              <span class="visit-general-value">
                <el-select
                  size="small"
                  v-model="appointment.idMedicalCase"
                  clearable
                  @change="handleMedicalCaseChange"
                  :disabled="disableMedicalCase"
                  placeholder="Выберите случай обращения"
                >
                  <el-option
                    v-for="item in medicalCases"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </span>
            </div>
            <div class="visit-general-row">
              <label class="visit-general-field mb-12" for="patientComment"
                >Комментарий:</label
              >
              <el-input
                size="small"
                type="textarea"
                resize="none"
                :rows="6"
                placeholder="Введите комментарий к пациенту"
                v-model="appointment.patientNote"
              >
              </el-input>
            </div>
          </el-col>
          <el-col :span="24" :sm="10" :offset="4">
            <div class="d-flex align-items-center flex-between">
              <h6 class="visit-heading mb-0">Документы для печати</h6>
              <el-button
                size="small"
                class="mr-12"
                type="text"
                @click="openModalPrintDocument()"
              >
                <i class="el-icon-print"></i>
                Печать</el-button
              >
            </div>
            <div class="visit-general-document">
              <ul class="visit-general-document-list">
                <li v-for="item in commonDocuments" :key="item.id">
                  <i class="el-icon-file success"></i>
                  <span>{{ item.name }}</span>
                </li>
              </ul>
            </div>
            <!-- <el-button class="mt-12" type="primary" plain @click="openModalPrintDocument()">
              <i class="el-icon-print"></i>
              Печать</el-button
            > -->
          </el-col>
        </el-row>
      </el-tab-pane>
      <!-- <el-tab-pane label="Счета" name="invoices" v-if="invoiceShow">
        <h6 class="visit-heading">Счета</h6>
        <div class="visit-table">
          <el-table
            stripe
            ref="multipleTable"
            :data="invoicesData"
            style="width: 100%"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column property="invoiceNumber" label="Номер счета">
            </el-table-column>
            <el-table-column property="invoiceDateIn" label="Дата формирования">
            </el-table-column>
            <el-table-column property="invoiceDatePayment" label="Дата оплаты">
            </el-table-column>
            <el-table-column property="invoiceTotal" label="Сумма">
            </el-table-column>
            <el-table-column
              property="invoiceServicesAmount"
              label="Количество услуг">
            </el-table-column>
            <el-table-column property="invoicePaymentType" label="Тип оплаты">
            </el-table-column>
            <el-table-column property="invoiceStatus" label="Статус">
            </el-table-column>
            <el-table-column width="100px" label="Действия">
              <template slot-scope="scope">
                <el-dropdown trigger="click">
                  <span class="el-dropdown-link"
                    ><i class="el-icon-more"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                      <el-button
                        size="small"
                        type="text"
                        class="danger"
                        @click="handleDelete(scope.row)">
                        <i class="el-icon-delete el-icon-left"></i>
                        Удалить
                      </el-button>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane> -->

      <el-tab-pane label="Услуги" name="service">
        <h6 class="visit-heading">Услуги</h6>
        <template>
          <el-row>
            <el-col :span="24" :sm="12">
              <el-select
                data-is-search="true"
                size="small"
                v-model="currentService"
                filterable
                placeholder="Начните вводить название услуги"
                :filter-method="handleServiceFilter"
                :loading="loading"
                @change="handleServiceSelect"
              >
                <el-option
                  v-for="item in priceListOptions"
                  :key="item.id"
                  :label="handlePriceListLabel(item)"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </template>
        <el-dialog
          class="modal-delete-service"
          :visible.sync="serviceDeleteDialogVisible"
          width="100%"
        >
          <span
            >Действие приведет к полному удалению записи об оказанной услуге.
            Продолжить?</span
          >
          <el-row :gutter="24" class="mt">
            <el-col :span="12">
              <el-button
                class="w-100"
                size="small"
                type="primary"
                @click="handleDeleteServiceRequest(true)"
                >Подтвердить</el-button
              >
            </el-col>
            <el-col :span="12">
              <el-button
                class="w-100"
                size="small"
                @click="handleDeleteServiceRequest(false)"
                >Отменить</el-button
              >
            </el-col>
          </el-row>
        </el-dialog>
        <div class="visit-table">
          <el-table
            class="mt"
            ref="multipleTable"
            :data="appointment.services"
            style="width: 100%"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              property="name"
              label="Наименование"
              width="380px"
            ></el-table-column>
            <el-table-column
              width="100px"
              property="code"
              label="Код услуги"
            ></el-table-column>
            <el-table-column min-width="150px" label="Количество, шт.">
              <template slot-scope="scope">
                <el-input-number
                  size="small"
                  v-model="scope.row.count"
                  :min="1"
                  :max="10"
                  @change="handleChangeService(scope.row.idPriceList)"
                ></el-input-number>
              </template>
            </el-table-column>
            <el-table-column min-width="150px" label="Скидка, %">
              <template slot-scope="scope">
                <el-input-number
                  size="small"
                  v-model="scope.row.discount"
                  :min="0"
                  :max="100"
                  @change="handleChangeService(scope.row.idPriceList)"
                ></el-input-number>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150px"
              property="priceDiscount"
              label="Стоимость, ₽"
            >
              <template slot-scope="scope">
                <el-input-number
                  size="small"
                  :controls="false"
                  v-model="scope.row.priceDiscount"
                >
                </el-input-number>
              </template>
            </el-table-column>
            <el-table-column
              width="100px"
              property="dateStart"
              label="Назначена"
              :formatter="formatterDateStart"
            ></el-table-column>
            <el-table-column
              width="100px"
              property="dateEnd"
              label="Исполнена"
              :formatter="formatterDateEnd"
            ></el-table-column>
            <el-table-column width="100px" label="Действия">
              <template slot-scope="scope">
                <el-button size="small" type="text" class="danger">
                  <i
                    class="el-icon-delete el-icon-left"
                    @click="handleDeleteService(scope.row)"
                  ></i>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="Документы (протоколы)"
        name="med-document"
        v-if="meddocShow"
      >
        <div v-if="!medDocumentDrawer">
          <h6 class="visit-heading">Документы (протоколы)</h6>
          <el-row :gutter="24">
            <el-col
              :span="24"
              :sm="12"
              :md="12"
              :lg="6"
              v-for="item in appointment.meddocs"
              :key="item.id"
            >
              <div class="visit-document-card">
                <!-- <span class="visit-document-card-tag">
                <el-tag type="warning">черновик</el-tag>
              </span> -->

                <div class="visit-document-card-title">
                  <p>{{ item.name }}</p>
                  <i
                    @click="openModalPrintDocument(medDocuments, item.id)"
                    class="el-icon-printer"
                  ></i>
                </div>
                <ul class="visit-document-card-list">
                  <li>
                    <span class="visit-document-card-field">Диагноз:</span
                    ><span class="visit-document-card-value">{{
                      item.diagnosis.substring(0, 50)
                    }}</span>
                  </li>
                  <li>
                    <span class="visit-document-card-field">Дата создания:</span
                    ><span class="visit-document-card-value">{{
                      new Intl.DateTimeFormat('ru-RU').format(
                        new Date(item.dateTimeCreate)
                      )
                    }}</span>
                  </li>
                  <li>
                    <span class="visit-document-card-field">Специальность:</span
                    ><span class="visit-document-card-value">{{
                      item.doctorPositionType
                    }}</span>
                  </li>
                </ul>
                <el-button
                  size="small"
                  class="visit-document-card-editbtn"
                  type="primary"
                  plain
                  @click="openEditMeddoc(item.id)"
                  >Редактировать</el-button
                >
              </div>
            </el-col>
            <el-col :span="24" :sm="12" :md="8" :lg="6">
              <div class="visit-document-card d-flex flex-center mob-mt-16">
                <el-button
                  size="small"
                  class="visit-document-card-addbtn"
                  @click="openAddMeddoc"
                >
                  <i class="el-icon-plus"></i>
                  Добавить
                </el-button>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- компонент для медицинского документа -->
        <div v-if="medDocumentDrawer">
          <h5>{{ meddocName }}</h5>
          <el-row :gutter="24">
            <el-col :xl="12" :lg="12" :md="12" :sm="24">
              <el-select
                class="mb-16"
                size="small"
                v-model="meddocType"
                placeholder="Выберите шаблон мед. документа"
                @change="handleMeddocChange"
              >
                <el-option
                  v-for="item in doctorMeddocs"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col
              :xl="12"
              :lg="12"
              :md="12"
              :sm="24"
              class="text-right"
              v-if="medDocumentDrawer"
            >
              <el-button
                size="small"
                type="primary"
                plain
                @click="openModalPrintDocument(medDocuments)"
                ><i class="el-icon-printer"></i> Печать</el-button
              >
            </el-col>
          </el-row>

          <el-row :gutter="24" class="mt">
            <el-col
              :span="field.idMeddocFieldWidthType * 12"
              class="mt"
              v-for="field in meddocFields"
              :key="field.id"
              :label="field.name"
              :name="field.id"
            >
              <label for="" class="document-drawer-label"
                >{{ field.name
                }}<span class="danger" v-if="field.isObligatory">*</span></label
              >
              <el-input
                size="small"
                v-if="field.idMeddocFieldType == 1 && !field.defaultValue"
                v-model="field.value"
                type="textarea"
                resize="none"
                :rows="field.idMeddocFieldHeightType"
                :placeholder="field.name"
              >
              </el-input>
              <el-input
                size="small"
                v-if="field.idMeddocFieldType == 1 && field.defaultValue"
                v-model="field.defaultValue"
                type="textarea"
                resize="none"
                :rows="field.idMeddocFieldHeightType"
                :placeholder="field.name"
              >
              </el-input>
              <el-select
                data-is-search="true"
                size="small"
                v-if="field.idMeddocFieldType == 2"
                placeholder="Выберите код и наименование по МКБ-10"
                v-model="field.value"
                filterable
                :filter-method="handleMkbFilter"
                :loading="loading"
                @change="handleMkbSelect"
              >
                <el-option
                  v-for="item in diagnosisCodes"
                  :key="item.id"
                  :label="handleMkbLabel(item)"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </div>
        <el-row :gutter="24" v-if="medDocumentDrawer">
          <el-col :span="24" class="mt mb d-flex align-items-center">
            <el-button
              size="small"
              style="min-width: 140px"
              type="primary"
              @click="handleSaveMeddoc"
              >Сохранить</el-button
            >
            <el-button
              size="small"
              style="min-width: 140px"
              @click="handleCloseMedDocumentDrawer"
              >Отмена</el-button
            >
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane
        label="Случай обращения"
        name="med-cases"
        v-if="!disableMedicalCase"
      >
        <div class="visit-header">
          <h6 class="visit-heading">Приемы</h6>
          <el-button
            type="primary"
            plain
            @click="handleMedicalCaseClose"
            v-if="appointment.medicalCaseClosed != 1"
            >Закрыть случай обращения</el-button
          >
        </div>
        <div class="visit-table">
          <el-table ref="multipleTable" :data="medCaseData" style="width: 100%">
            <el-table-column property="statusName" label="Статус">
              <template slot-scope="scope">
                <router-link :to="{ path: '/visit/' + scope.row.id }">
                  {{ scope.row.statusName }}
                </router-link>
              </template>
            </el-table-column>
            <el-table-column
              property="date"
              label="Дата приема"
              :formatter="formatterDateVisit"
            >
            </el-table-column>
            <el-table-column property="doctorName" label="ФИО врача">
            </el-table-column>
            <el-table-column property="doctorPosition" label="Специальность">
            </el-table-column>
            <el-table-column property="diagnosis" label="Основной диагноз">
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Файлы" name="files">
        <el-row>
          <el-col :xl="6" :md="12">
            <h6 class="visit-heading">Прикрепленные файлы</h6>
            <el-upload
              :headers="requestHeaders"
              class="upload"
              :action="requestUrl"
              :on-remove="handleRemove"
              :on-preview="handlePreview"
              :on-success="handleSuccessUpload"
              :on-error="handleErrorUpload"
              :file-list="appointment.files"
            >
              <el-button class="mb" size="small" type="primary"
                >Загрузить</el-button
              >
            </el-upload>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
    <ModalPrintDocumentComponent
      :idAppointment="$route.params.id"
      :is-modal-visible.sync="isModalVisible"
      :meddoc-fields="meddocFields"
      :meddoc-name="meddocName"
      :meddoc-print-name="meddocPrintName"
      :documents-for-print="documentsForPrint"
      :diagnosis-codes="diagnosisCodes"
    ></ModalPrintDocumentComponent>
  </div>
</template>

<script>
import AppointmentManager from '@/services/appointmentManager.js';
import PdfPrintManager from '@/services/pdfPrintManager.js';
import FileUploadManager from '@/services/fileUploadManager.js';
import PriceListManager from '@/services/priceListManager.js';
import { authHeader } from '../api/httpSettings';
import { BaseURL } from '../api/httpSettings';
import AuthManager from '@/services/authManager';
import MeddocManager from '@/services/meddocManager';
import MkbManager from '@/services/mkbManager';
import MedicalCaseManager from '@/services/medicalCaseManager';
import MeddocTemplateManager from '@/services/meddocTemplateManager';
import { medDocuments } from '../constants';
import ModalPrintDocumentComponent from '@/components/ModalPrintDocumentComponent.vue';
import { getTimeOffset, getAge, getAgeString } from '@/services/commonService';
import axios from '@/api/axios';
import { getterTypes } from '@/store/modules/documentsPrintModule';
import { mapGetters } from 'vuex';
import BreadcrumbComponent from '@/components/BreadcrumbComponent.vue';

export default {
  name: 'VisitPage',
  components: { ModalPrintDocumentComponent, BreadcrumbComponent },
  data() {
    return {
      loading: false,
      loadingBtn: false,
      isModalVisible: false,
      appointment: {
        idAppointmentType: null,
      },
      visitTypes: [],
      priceList: [],
      medicalCases: [],
      priceListOptions: [],
      currentService: '',
      documentsForPrint: [],
      medDocuments: medDocuments,
      serviceDeleteDialogVisible: false,
      serviceToDelete: '',
      activeName: 'general',
      medDocumentDrawer: false,
      diagnosisCodes: [],
      disableMedicalCase: false,
      medCaseData: [],
      requestHeaders: { Authorization: '' },
      requestUrl: '',
      meddocShow: false,
      endTime: '',
      doctorMeddocs: [],
      meddocType: null,
      meddocName: '',
      meddocPrintName: '',
      meddocFields: [],
      meddocId: null,
      diagnosisMainMkb: null,
      initialMeddocUserPost: null,
      // commonDocuments: [],
    };
  },
  // validations: {
  //   meddoc: {
  //     complaints: { required },
  //     anamnesis: { required },
  //     examinationData: { required },
  //     diagnosisMain: { required },
  //     diagnosisMainMkbId: { required },
  //   },
  // },
  computed: {
    ...mapGetters({
      commonDocuments: getterTypes.documentsForPrintOnOtherPages,
    }),
    patient() {
      return this.$store.getters.patient;
    },
    patientFullName() {
      return `${this.patient.lastName ? this.patient.lastName : ''} ${
        this.patient.firstName ? this.patient.firstName : ''
      } ${this.patient.patronymic ? this.patient.patronymic : ''}`;
    },
    patientAge() {
      return getAgeString(getAge(this.patient.birthDate));
    },
    isAllowedCloseAppointment() {
      let flag = true;
      if (
        this.appointment.idAppointmentStatus === 6 ||
        this.appointment.idAppointmentStatus === 7 ||
        this.appointment.idAppointmentStatus === 8
      ) {
        flag = false;
      }
      return flag;
    },
  },
  async mounted() {
    this.loading = true;
    let curid = this.$route.params.id;
    let currentAppointmentInfo = await axios.get('appointment', {
      params: { id: Number(curid) },
    });
    this.appointment = currentAppointmentInfo.data;
    this.$store.dispatch('getPatient', {
      pagenumber: 1,
      pagesize: 1,
      patientId: this.appointment.idPatient,
    });
    this.appointment.idMedicalCase = null;
    // console.log(this.$store.getters);
    this.visitTypes = await AppointmentManager.getAppointmentTypes();
    this.medicalCases = await MedicalCaseManager.getActualCases(
      this.appointment.idPatient
    );
    this.priceList = await PriceListManager.getCurrentPriceList();
    this.requestHeaders = authHeader();
    this.requestUrl = BaseURL() + 'appointmentfile/' + curid;
    let currentUser = AuthManager.getUser();
    this.meddocShow = currentUser.roles.find(
      (x) => x == 'view_medical_document'
    );
    this.endTime = new Intl.DateTimeFormat('ru-RU', {
      hour: 'numeric',
      minute: 'numeric',
    }).format(new Date(this.appointment.dateTimeTo));
    this.hadleVisitTypeChange();
    this.doctorMeddocs = await MeddocTemplateManager.getDoctorTemplates(
      currentUser.idDoctor
    );

    this.priceListOptions = this.priceList.filter(
      (x) => this.priceList.indexOf(x) < 10
    );
    await this.$patientHub.start();
    this.loading = false;
  },
  methods: {
    async openModalPrintDocument(documents, id) {
      /*Получение id документа и значения его полей*/
      if (id) {
        let meddoc = await MeddocManager.getMeddoc(id);
        this.meddocFields = JSON.parse(meddoc.fieldValues);
        this.meddocName = this.doctorMeddocs.find(
          (x) => x.id == meddoc.idMeddocTemplate
        ).name;
        this.meddocPrintName = this.doctorMeddocs.find(
          (x) => x.id == meddoc.idMeddocTemplate
        ).printName;
        let mkbs = await MkbManager.getMkb(
          1,
          1,
          null,
          meddoc.idDiagnosisMainMkb
        );
        this.diagnosisCodes = mkbs.mkb;
        this.meddocId = meddoc.id;
        this.meddocType = meddoc.idMeddocTemplate;
      }
      /*Валидация*/
      if (documents) {
        let mainDiagnosis = this.diagnosisCodes.find(
          (d) =>
            d.id ==
            this.meddocFields.find((x) => x.idMeddocFieldType == 2).value
        );
        if (!mainDiagnosis) {
          this.$message({
            type: 'error',
            message: 'Не заполнен код МКБ основного диагноза!',
          });
          this.loadingBtn = false;
          return;
        }
        for (let field of this.meddocFields) {
          if (field.value) {
            if (field.isObligatory == true && !field.value) {
              this.$message({
                type: 'error',
                message: 'Не заполнено обязательное поле: ' + field.name + '!',
              });
              this.loadingBtn = false;
              return;
            }
          } else if (field.isObligatory == true && !field.defaultValue) {
            this.$message({
              type: 'error',
              message: 'Не заполнено обязательное поле: ' + field.name + '!',
            });
            this.loadingBtn = false;
            return;
          }
        }
      }
      this.isModalVisible = !this.isModalVisible;
      if (documents) {
        this.documentsForPrint = documents;
      } else {
        this.documentsForPrint = this.commonDocuments;
      }
    },
    async handleMedicalInterventioncConsent(id) {
      await PdfPrintManager.printPdfFile(1, id);
    },
    async handlePersonalDataConsent(id) {
      await PdfPrintManager.printPdfFile(2, id);
    },
    async handleMedicalCard(id) {
      await PdfPrintManager.printPdfFile(3, id);
    },
    beforeRemove(file) {
      return this.$confirm(`Удалить ${file.name} ?`);
    },
    async handleRemove(file) {
      await FileUploadManager.removeFile(file);
    },
    async handlePreview(file) {
      await FileUploadManager.dowloadFile(file);
    },
    handleSuccessUpload(response) {
      this.$message({
        type: 'success',
        message: 'Файл успешно загружен!',
      });
      this.appointment.files.push(response.value);
    },
    handleErrorUpload() {
      this.$message({
        type: 'info',
        message: 'Невозможно загрузить файл!',
      });
    },
    formatterDateStart(row) {
      return new Intl.DateTimeFormat('ru-RU').format(
        new Date(row.dateTimeStart)
      );
    },
    formatterDateEnd(row) {
      return new Intl.DateTimeFormat('ru-RU').format(new Date(row.dateTimeEnd));
    },
    formatterDateVisit(row) {
      return new Intl.DateTimeFormat('ru-RU').format(new Date(row.date));
    },
    handlePriceListLabel(item) {
      return (
        item.medicalServiceCurrentOrganization.name +
        ' (' +
        item.positionTypeName +
        ', ' +
        item.categoryName +
        ')'
      );
    },
    handleServiceFilter(query) {
      if (query !== '') {
        let queryArr = query.split(' ');
        this.priceListOptions = this.priceList;
        for (let i = 0; i < queryArr.length; i++) {
          this.priceListOptions = this.priceListOptions.filter((item) => {
            return (
              item.medicalServiceCurrentOrganization.name
                .toLowerCase()
                .indexOf(queryArr[i].toLowerCase()) > -1
            );
          });
        }
      }
    },
    handleServiceSelect() {
      let currentPriceListItem = this.priceList.find(
        (x) => x.id == this.currentService
      );
      let currentAppointmentservice = {
        id: null,
        idAppointment: this.appointment.id,
        idPriceList: this.currentService,
        dateTimeStart: new Date(),
        dateTimeEnd: new Date(),
        count: 1,
        discount: 0,
        price: currentPriceListItem.price,
        priceDiscount: currentPriceListItem.price,
        name: currentPriceListItem.medicalServiceCurrentOrganization.name,
        code: currentPriceListItem.medicalServiceCurrentOrganization
          .medicalServiceType.code,
      };
      this.appointment.services.push(currentAppointmentservice);
    },
    handleChangeService(idPriceList) {
      let currentRow = this.appointment.services.find(
        (x) => x.idPriceList == idPriceList
      );
      currentRow.priceDiscount =
        Math.ceil(
          currentRow.count *
            currentRow.price *
            (1 - currentRow.discount / 100) *
            100
        ) / 100;
    },
    handleDeleteService(row) {
      let index = this.appointment.services.indexOf(row);
      if (index > -1) {
        let id = this.appointment.services[index].id;
        if (id) {
          this.serviceDeleteDialogVisible = true;
          this.serviceToDelete = index;
        } else {
          this.appointment.services.splice(index, 1);
        }
      }
    },
    async handleDeleteServiceRequest(deleteRow) {
      if (deleteRow) {
        this.appointment.services.splice(this.serviceToDelete, 1);
      }
      this.serviceDeleteDialogVisible = false;
      this.serviceToDelete = '';
    },
    async handleCloseAppointment() {
      if (this.appointment.services.length < 1) {
        this.$message({
          type: 'error',
          message: `Невозможно завершить прием: необходимо добавить услуги!`,
        });
        return;
      }
      if (this.appointment.meddocs.length < 1) {
        this.$message({
          type: 'error',
          message: `Невозможно завершить прием: не заполнен протокол осмотра!`,
        });
        return;
      }
      if (
        this.appointment.idAppointmentType == 2 &&
        !this.appointment.idMedicalCase
      ) {
        this.$message({
          type: 'error',
          message: `Невозможно завершить прием: не выбран случай обращения для повторного приема!`,
        });
        return;
      }
      this.appointment.idAppointmentStatus = 6;
      this.appointment.isVisit = 1;
      this.appointment.clientOffset = getTimeOffset();
      axios
        .put('appointment', this.appointment)
        .then(async () => {
          await this.$patientHub.client.invoke('BroadcastScheduleChange');
          this.$message({
            type: 'success',
            message: `Прием успешно завершен!`,
          });
          this.$router.go(-1);
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: `Ошибка завершения приема!`,
          });
        });
    },
    handleCloseMedDocumentDrawer() {
      this.$confirm(
        'Вы уверены, что хотите завершить заполнение документа?'
      ).then(() => {
        this.$message({
          type: 'info',
          message: 'Создание документа прервано. Данные не сохранены',
        });
        this.meddocFields.forEach(function (entry) {
          entry.value = '';
        });
        this.medDocumentDrawer = false;
      });
    },
    async openAddMeddoc() {
      this.meddocFields.forEach(function (entry) {
        entry.value = '';
      });
      this.meddocType = this.doctorMeddocs[0].id;
      this.meddocFields = this.doctorMeddocs[0].meddocTemplateFields;
      this.meddocName = this.doctorMeddocs[0].name;
      this.meddocId = null;
      let mkbs = await MkbManager.getMkb(1, 10, null, null);
      this.diagnosisCodes = mkbs.mkb;
      this.medDocumentDrawer = true;
    },
    async openEditMeddoc(id) {
      let meddoc = await MeddocManager.getMeddoc(id);
      this.meddocFields = JSON.parse(meddoc.fieldValues);
      this.meddocName = this.doctorMeddocs.find(
        (x) => x.id == meddoc.idMeddocTemplate
      ).name;
      let mkbs = await MkbManager.getMkb(1, 1, null, meddoc.idDiagnosisMainMkb);
      this.diagnosisCodes = mkbs.mkb;
      this.meddocId = meddoc.id;
      this.meddocType = meddoc.idMeddocTemplate;
      (this.initialMeddocUserPost = meddoc.doctorPositionType),
        (this.medDocumentDrawer = true);
    },
    async handleSaveMeddoc() {
      // if (this.$v.$invalid) {
      //   this.$v.$touch();
      //   this.loadingModal = false;
      //   this.loadingBtn = false;
      //   return;
      // } else {
      let mainDiagnosis = this.diagnosisCodes.find(
        (d) =>
          d.id == this.meddocFields.find((x) => x.idMeddocFieldType == 2).value
      );
      if (!mainDiagnosis) {
        this.$message({
          type: 'error',
          message: 'Не заполнен код МКБ основного диагноза!',
        });
        this.loadingBtn = false;
        return;
      }
      for (let field of this.meddocFields) {
        if (field.value) {
          if (field.isObligatory == true && !field.value) {
            this.$message({
              type: 'error',
              message: 'Не заполнено обязательное поле: ' + field.name + '!',
            });
            this.loadingBtn = false;
            return;
          }
        } else if (field.isObligatory == true && !field.defaultValue) {
          this.$message({
            type: 'error',
            message: 'Не заполнено обязательное поле: ' + field.name + '!',
          });
          this.loadingBtn = false;
          return;
        }
      }
      if (this.meddocId) {
        let meddocToSave = {
          id: this.meddocId,
          idMeddocTemplate: this.meddocType,
          idAppointment: this.appointment.id,
          doctorPositionType: this.initialMeddocUserPost,
          diagnosisMainMkb: mainDiagnosis.code + ' ' + mainDiagnosis.name,
          idDiagnosisMainMkb: mainDiagnosis.id,
          fieldValues: JSON.stringify(this.meddocFields),
        };
        let updatedAppointment = await MeddocManager.updateMeddoc(meddocToSave);
        this.appointment.meddocs = updatedAppointment.meddocs;
      } else {
        let user = AuthManager.getUser();
        let meddocToSave = {
          id: null,
          idMeddocTemplate: this.meddocType,
          idAppointment: this.appointment.id,
          doctorPositionType: user.post,
          diagnosisMainMkb: mainDiagnosis.code + ' ' + mainDiagnosis.name,
          idDiagnosisMainMkb: mainDiagnosis.id,
          fieldValues: JSON.stringify(this.meddocFields),
        };
        let updatedAppointment = await MeddocManager.addMeddoc(meddocToSave);
        this.appointment.meddocs = updatedAppointment.meddocs;
      }
      this.medDocumentDrawer = false;
    },
    handleMkbLabel(item) {
      return item.code + ' ' + item.name;
    },
    async handleMkbFilter(query) {
      if (query !== '') {
        let mkbs = await MkbManager.getMkb(1, 7, query);
        this.diagnosisCodes = mkbs.mkb;
      } else {
        this.diagnosisCodes = [];
      }
    },
    handleMkbSelect(value) {
      this.diagnosisMainMkb = this.diagnosisCodes.find(
        (x) => x.id == value
      ).code;
    },
    // async handlePrintMeddoc(mode) {
    //   let meddocToPrint = {
    //     idAppointment: this.appointment.id,
    //     printMode: mode,
    //     documentName: this.meddocName,
    //     fields: this.meddocFields.map((x) => {
    //       return {
    //         name: x.name,
    //         value:
    //           typeof x.value == "number"
    //             ? this.diagnosisCodes.find((d) => d.id == x.value).code +
    //               " " +
    //               this.diagnosisCodes.find((d) => d.id == x.value).name
    //             : x.value,
    //         fieldNumber: x.fieldNumber,
    //         isRecommendation: x.isRecommendation,
    //       };
    //     }),
    //   };
    //   await MeddocManager.printMeddoc(meddocToPrint);
    // },
    hadleVisitTypeChange() {
      if (
        this.appointment.idAppointmentType == 1 ||
        this.appointment.idAppointmentType == 3
      ) {
        this.disableMedicalCase = true;
      } else {
        this.disableMedicalCase = false;
      }
    },
    handleMedicalCaseChange() {
      this.medCaseData = this.medicalCases.find(
        (x) => x.id == this.appointment.idMedicalCase
      ).appointments;
    },
    async handleMeddocChange() {
      let currentMeddoc = this.doctorMeddocs.find(
        (x) => x.id == this.meddocType
      );
      this.meddocName = currentMeddoc.name;
      this.meddocFields = currentMeddoc.meddocTemplateFields;
    },

    handleMedicalCaseClose(done) {
      this.$confirm('Вы уверены, что хотите закрыть случай обращения?').then(
        () => {
          MedicalCaseManager.closeCase(this.appointment.idMedicalCase)
            .then((data) => {
              if (!data) {
                this.appointment.medicalCaseClosed = 1;
                this.$message({
                  type: 'success',
                  message: 'Случай обращния закрыт',
                });
                done();
              }
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: 'Невозможно закрыть случай обращения',
              });
            });
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
