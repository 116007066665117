import axios from '@/api/axios';

const getContractorOrganizations = async (params) => {
  return await axios.get('contractororganization', { params: params });
};

const createContractorOrganization = async (organization) => {
  return await axios.post('contractororganization', organization);
};

const updateContractorOrganization = async (organization) => {
  return await axios.put('contractororganization', organization);
};

const deleteContractorOrganization = async (params) => {
  return await axios.delete('contractororganization', { params: params });
};

export default {
  getContractorOrganizations,
  createContractorOrganization,
  updateContractorOrganization,
  deleteContractorOrganization,
};
