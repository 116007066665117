import scheduleApi from '@/api/schedule';
const schedulesModule = {
  state: () => ({
    scheduleList: [],
  }),
  getters: {
    scheduleList: (state) => {
      return state.scheduleList;
    },
  },
  mutations: {
    SET_SCHEDULES: (state, payload) => {
      state.scheduleList = payload;
    },
    SET_SCHEDULE_FILTERS: (state, payload) => {
      state.scheduleFilters = payload;
    },
  },
  actions: {
    getScheduleList: async (context, params) => {
      const scheduleList = await scheduleApi.getScheduleList(params);
      context.commit('SET_SCHEDULES', scheduleList.data);
    },
  },
};

export default schedulesModule;
