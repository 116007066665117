import axios from '@/api/axios'

class MeddocTemplateManager {
    //получение полного списка шаблонов текущей организации
    async getTemplates() {
        let {data} = await axios.get('meddoctemplate');
        return data.value;
    }
    //получение списка шаблонов текущей организации, доступных врачу
    async getDoctorTemplates(idDoctor) {
        let {data} = await axios.get('meddoctemplate?idDoctor=' + idDoctor);
        return data.value;
    }
    //получение информации по выбранному шаблону
    async getTemplateInfo(id) {
        let {data} = await axios.get('meddoctemplate?idTemplate=' + id);
        return data.value;
    }
     //добавление нового шаблона
     async addTemplate(template) {
        try {
            let {data} = await axios.post('meddoctemplate', template);
            return { data: data.value, error: null};
        }
        catch (error) {
            return { data: null, error: error};
        }
    }
    //редактирование существующего шаблона
    async updateTemplate(template) {
        try {
            let {data} = await axios.put('meddoctemplate', template);
            return { data: data.value, error: null};
        }
        catch (error) {
            return { data: null, error: 1};
        }
    }
    //удаление выбранного шаблона
    async deleteTemplate(id) {
        let {data} = await axios.delete('meddoctemplate/' + id);
        return data.value;
    }
}
export default new MeddocTemplateManager();