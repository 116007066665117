import axios from '@/api/axios';

const getAppointment = async (params) => {
  return axios.get('appointment', { params: params });
};

const createAppointment = async (appointment) => {
  return await axios.post('appointment', appointment);
};

const editAppointment = async (appointment) => {
  return await axios.put('appointment', appointment);
};

const getAppointmentTypeList = async () => {
  return await axios.get('appointmenttype');
};

const getAppointmentStatusList = async () => {
  return await axios.get('appointmentstatustype');
};

export default {
  getAppointment,
  createAppointment,
  editAppointment,
  getAppointmentTypeList,
  getAppointmentStatusList,
};
