<template>
  <div class="page-wrap" v-loading="loading">
    <BreadcrumbComponent :patient="patient"></BreadcrumbComponent>
    <!-- <span class="patient-info mb-12"
      >{{ this.visitsList(false)[0].patientName }},
      {{
        new Intl.DateTimeFormat('ru-RU')
          .format(new Date(this.visitsList(false)[0].patientBirthDate))
          .toString()
      }}</span
    > -->
    <div class="page-header">
      <h4 class="mb-0">Приемы</h4>
    </div>
    <div class="page-subheader">
      <el-row class="w-100" :gutter="24">
        <el-col :span="24" :sm="12" :md="8">
          <el-input
            size="small"
            placeholder="Найти приём..."
            prefix-icon="el-icon-search"
            v-model="visitSearch"
          >
          </el-input
        ></el-col>
      </el-row>
    </div>
    <el-table
      ref="multipleTable"
      :data="visitsList(visitSearch)"
      empty-text="Не найдено приемов по данному случаю обращения"
      style="width: 100%"
      class="mt-16"
    >
      <el-table-column
        property="date"
        label="Дата приема"
        sortable
        :formatter="formatDate"
      >
      </el-table-column>
      <el-table-column property="doctorName" label="Врач" sortable>
      </el-table-column>
      <el-table-column
        min-width="140"
        property="doctorPositionName"
        label="Специальность"
        sortable
      >
      </el-table-column>
      <el-table-column
        min-width="160"
        property="mainDiagnosisName"
        label="Основной диагноз"
        sortable
      >
      </el-table-column>
      <el-table-column property="serviceCount" label="Услуги" sortable>
      </el-table-column>
      <el-table-column property="fileCount" label="Файлы" sortable>
      </el-table-column>
      <el-table-column property="appointmentStatus" label="Статус" sortable>
      </el-table-column>
      <el-table-column width="100" label="">
        <template slot-scope="scope">
          <router-link
            class="table-link"
            :to="{
              path: '/visit/' + scope.row.id,
            }"
          >
            Перейти
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BreadcrumbComponent from '@/components/BreadcrumbComponent';
export default {
  name: 'VisitsListPage',
  components: {
    BreadcrumbComponent,
  },
  data() {
    return {
      visitSearch: '',
      loading: false,
    };
  },
  mounted() {
    this.loading = true;
    this.loadData()
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.$message({
          type: 'error',
          message: 'Произошла ошибка при загрузке страницы!',
        });
        this.$router.go(-1);
      });
  },
  computed: {
    ...mapGetters(['visitsList', 'patient']),
  },
  methods: {
    async loadData() {
      await this.$store.dispatch('GET_VISITS', {
        id: this.$route.params.mcid,
      });
      await this.$store.dispatch('getPatient', {
        patientId: this.$route.params.id,
        pagenumber: 1,
        pagesize: 1,
      });
    },
    formatDate(row) {
      return new Intl.DateTimeFormat('ru-RU').format(new Date(row.date));
    },
  },
};
</script>

<style lang="scss" scoped></style>
