<template>
  <div class="settings-page d-flex flex-column directory" v-loading="loading">
    <div class="directory-header">
      <h6>Справочник лекарственных средств</h6>
      <el-row class="w-100 d-flex align-items-center" :gutter="24">
        <el-col :span="20" :sm="12" :md="8">
          <el-input
            size="small"
            prefix-icon="el-icon-search"
            placeholder="Найти препарат"
            v-model="searchDrugs"
            @input="handleRowSearch"
          >
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="text" size="small" @click="handleOpenModal">
            <span class="hidden-md-and-down">Расширенные фильтры</span>
            <i class="el-icon-setting el-icon-right"></i
          ></el-button>
        </el-col>
      </el-row>
    </div>
    <el-table
      @sort-change="sortChange"
      :default-sort="{ prop: 'medServiceInPriceList', order: 'descending' }"
      class="mt directory-table"
      :data="drugs"
      style="width: 100%"
      stripe
    >
      <el-table-column
        sortable="custom"
        prop="nameCommercial"
        label="Торговое наименование"
      >
      </el-table-column>
      <el-table-column
        sortable="custom"
        prop="nameMnn"
        label="Наименование МНН"
      >
      </el-table-column>
      <el-table-column
        sortable="custom"
        width="220"
        prop="drugForm"
        label="Лекарственная форма"
      >
      </el-table-column>
      <el-table-column
        sortable="custom"
        width="140 "
        prop="drugDosage"
        label="Дозировка"
      >
      </el-table-column>
      <el-table-column
        sortable="custom"
        width="200"
        prop="amountInPackage"
        label="Количество в уп., шт."
      >
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-sizes="pageSizes"
      :page-size="currentSize"
      layout="sizes, prev, pager, next"
      :total="tableTotal"
      class="mt-20"
    >
    </el-pagination>

    <!-- модальное окно расширенного поиска -->
    <el-dialog
      class="modal-filter-medication"
      title="Расширенные фильтры"
      :visible.sync="modalFilter"
      width="100%"
    >
      <el-row :gutter="24" align="center" class="mt-16">
        <el-col :span="24">
          <label for="" class="settings-modal-label"
            >Торговое наименование</label
          >
          <el-input
            size="small"
            placeholder="Введите торговое наименование препарата"
            v-model="nameCommectial"
          >
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="24" align="center" class="mt-16">
        <el-col :span="24">
          <label for="" class="settings-modal-label">Наименование по МНН</label>
          <el-input
            size="small"
            placeholder="Введите наименование препарата по МНН"
            v-model="nameMNN"
          >
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="24" align="center" class="mt-16">
        <el-col :span="24">
          <label for="" class="settings-modal-label">Лекарственная форма</label>
          <el-input
            size="small"
            placeholder="Введите лекарственную форму"
            v-model="drugForm"
          >
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="24" align="center" class="mt-16">
        <el-col :span="12">
          <label for="" class="settings-modal-label">Дозировка</label>
          <el-input
            size="small"
            placeholder="Введите дозировку"
            v-model="drugDosage"
          >
          </el-input>
        </el-col>
        <el-col :span="12">
          <label for="" class="settings-modal-label"
            >Количество в уп., шт.</label
          >
          <el-input
            size="small"
            placeholder="Введите количество в уп."
            v-model="drugAmountInPack"
          >
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="24" align="center" class="mt">
        <el-col :span="12">
          <el-button
            class="w-100"
            size="small"
            type="primary"
            :loading="loadingModal"
            @click="handleFilter"
            >Применить</el-button
          >
        </el-col>
        <el-col :span="12">
          <el-button size="small" class="w-100" @click="handleCloseModal"
            >Отменить</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import MedicationManager from '@/services/medicationManager.js';

const itemsPerPage = [10, 20, 30];
export default {
  data() {
    return {
      loading: false,
      loadingModal: false,
      modalFilter: false,

      nameCommectial: '',
      nameMNN: '',
      drugForm: '',
      drugDosage: '',
      drugAmountInPack: '',

      drugs: [],
      currentSize: itemsPerPage[0],
      pageSizes: itemsPerPage,
      currentPage: 1,
      sorting: 1,
      searchDrugs: '',
    };
  },
  async created() {
    this.loading = true;
    let drugsFull = await MedicationManager.getMkedications(
      1,
      itemsPerPage[0],
      1
    );
    this.drugs = drugsFull.medicationList;
    this.tableTotal = drugsFull.rowCount;
    this.loading = false;
  },
  methods: {
    async sortChange(value) {
      if (value.prop == 'nameCommercial' && value.order == 'ascending') {
        this.sorting = 1;
      }
      if (value.prop == 'nameCommercial' && value.order == 'descending') {
        this.sorting = 2;
      }
      if (value.prop == 'nameMnn' && value.order == 'ascending') {
        this.sorting = 3;
      }
      if (value.prop == 'nameMnn' && value.order == 'descending') {
        this.sorting = 4;
      }
      if (value.prop == 'drugForm' && value.order == 'ascending') {
        this.sorting = 5;
      }
      if (value.prop == 'drugForm' && value.order == 'descending') {
        this.sorting = 6;
      }
      if (value.prop == 'drugDosage' && value.order == 'ascending') {
        this.sorting = 7;
      }
      if (value.prop == 'drugDosage' && value.order == 'descending') {
        this.sorting = 8;
      }
      if (value.prop == 'amountInPackage' && value.order == 'ascending') {
        this.sorting = 9;
      }
      if (value.prop == 'amountInPackage' && value.order == 'descending') {
        this.sorting = 10;
      }
      await this.sendForSort();
    },
    async sendForSort() {
      let drugsFull = await MedicationManager.getMkedications(
        this.currentPage,
        this.currentSize,
        this.sorting,
        this.searchDrugs,
        this.nameCommectial,
        this.nameMNN,
        this.drugForm,
        this.drugDosage,
        this.drugAmountInPack
      );
      this.drugs = drugsFull.medicationList;
      this.tableTotal = drugsFull.rowCount;
    },
    async handleSizeChange(val) {
      this.currentSize = val;
      await this.sendForSort();
    },
    async handleCurrentChange(val) {
      this.currentPage = val;
      await this.sendForSort();
    },
    async handleRowSearch() {
      await this.sendForSort();
    },
    handleOpenModal() {
      this.modalFilter = true;
    },
    handleCloseModal() {
      this.nameCommectial = '';
      this.nameMNN = '';
      this.drugForm = '';
      this.drugDosage = '';
      this.drugAmountInPack = '';
      this.modalFilter = false;
    },
    async handleFilter() {
      await this.sendForSort();
      this.modalFilter = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
