<template>
  <div v-loading="isGridLoading" class="page-wrap">
    <div class="schedule-header">
      <h4>Расписание</h4>
      <div
        v-show="isMovingMode || isCopyingMode"
        class="schedule-header-moveblock"
      >
        <p>
          Выберите ячейку для
          <span v-if="isMovingMode">переноса</span>
          <span v-if="isCopyingMode">копирования</span>
          записи пациента или нажмите
          <el-button type="text" size="small" @click="cancelMovingOrCopyingMode"
            >отменить</el-button
          >
        </p>
      </div>
      <div>
        <el-button
          type="primary"
          plain
          size="small"
          class="schedule-filter-btn hidden-xs-only"
          @click="handleResetFilter"
          >Сбросить фильтры
          <i class="el-icon-filter-slash"></i>
        </el-button>
        <el-button
          type="primary"
          size="small"
          class="schedule-filter-btn hidden-xs-only"
          @click="getReportAsync"
          >Выгрузить в Excel
          <i class="el-icon-print"></i>
        </el-button>
        <el-button
          class="hidden-sm-and-up"
          size="small"
          type="plain"
          @click="drawer = true"
        >
          <i class="el-icon-setting"></i>
        </el-button>
      </div>
    </div>
    <el-drawer
      :visible.sync="drawer"
      :with-header="false"
      :direction="direction"
      size="70%"
    >
      <div class="schedule-filter">
        <el-row :gutter="24">
          <el-col :xs="24">
            <el-button
              size="small"
              class="schedule-filter-btn"
              type="text"
              @click="handleResetFilter"
              >Сбросить фильтры
              <i class="el-icon-filter-slash"></i>
            </el-button>
          </el-col>
          <el-col :xs="24">
            <label for="datePicker">Выберите даты</label>
            <el-date-picker
              size="small"
              style="width: 100%; border-radius: 8px"
              v-model="scheduleFilters.dates"
              format="dd.MM.yyyy"
              type="daterange"
              :picker-options="{ firstDayOfWeek: 1 }"
              range-separator="-"
              start-placeholder="Дата с"
              end-placeholder="по"
              :clearable="false"
              @change="loadScheduleGridData"
            >
            </el-date-picker
          ></el-col>
          <el-col :xs="24">
            <label for="selectBranche">Выберите филиал</label>
            <el-select
              data-is-search="true"
              size="small"
              collapse-tags
              filterable
              v-model="scheduleFilters.filial"
              placeholder="Выберите филиал"
              @change="hadleFilialChange(scheduleFilters.filial)"
              clearable
              multiple
            >
              <el-option
                v-for="item in filials"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option> </el-select
          ></el-col>
          <el-col :xs="24">
            <label for="selectDepartment">Выберите подразделение</label>
            <el-select
              data-is-search="true"
              size="small"
              collapse-tags
              filterable
              v-model="scheduleFilters.department"
              placeholder="Выберите подразделение"
              @change="hadleDepartmentChange(scheduleFilters.department)"
              clearable
              multiple
            >
              <el-option
                v-for="item in departments"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :xs="24">
            <label for="selectCabinet">Выберите кабинет</label>
            <el-select
              data-is-search="true"
              size="small"
              collapse-tags
              filterable
              v-model="scheduleFilters.cabinet"
              placeholder="Выберите кабинет"
              @change="loadScheduleGridData"
              clearable
              multiple
            >
              <el-option
                v-for="item in cabinets"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :xs="24">
            <label for="selectDoctor">Выберите сотрудника</label>
            <el-select
              data-is-search="true"
              size="small"
              collapse-tags
              filterable
              v-model="scheduleFilters.doctor"
              placeholder="Выберите сотрудника"
              clearable
              @change="handleSelectDoctor"
              multiple
            >
              <el-option
                v-for="item in doctors"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
    </el-drawer>
    <div class="schedule-filter hidden-xs-only">
      <el-row :gutter="24">
        <el-col :xl="4" :lg="8" :md="12" :sm="12">
          <label for="datePicker">Выберите даты</label>
          <el-date-picker
            size="small"
            style="width: 100%; border-radius: 8px"
            v-model="scheduleFilters.dates"
            format="dd.MM.yyyy"
            type="daterange"
            range-separator="-"
            start-placeholder="Дата с"
            end-placeholder="по"
            :clearable="false"
            :picker-options="pickerOptions"
            @change="loadScheduleGridData"
          >
          </el-date-picker
        ></el-col>
        <el-col :xl="5" :lg="8" :md="12" :sm="12">
          <label for="selectBranche">Выберите филиал</label>
          <el-select
            data-is-search="true"
            size="small"
            collapse-tags
            filterable
            v-model="scheduleFilters.filial"
            placeholder="Выберите филиал"
            @change="hadleFilialChange(scheduleFilters.filial)"
            clearable
            multiple
          >
            <el-option
              v-for="item in filials"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option> </el-select
        ></el-col>
        <el-col :xl="5" :lg="8" :md="12" :sm="12">
          <label for="selectDepartment">Выберите подразделение</label>
          <el-select
            data-is-search="true"
            size="small"
            collapse-tags
            filterable
            v-model="scheduleFilters.department"
            placeholder="Выберите подразделение"
            @change="hadleDepartmentChange(scheduleFilters.department)"
            clearable
            multiple
          >
            <el-option
              v-for="item in departments"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :xl="5" :lg="8" :md="12" :sm="12">
          <label for="selectCabinet">Выберите кабинет</label>
          <el-select
            data-is-search="true"
            size="small"
            collapse-tags
            filterable
            v-model="scheduleFilters.cabinet"
            placeholder="Выберите кабинет"
            @change="loadScheduleGridData"
            clearable
            multiple
          >
            <el-option
              v-for="item in cabinets"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :xl="5" :lg="8" :md="12" :sm="24">
          <label for="selectDoctor">Выберите сотрудника</label>
          <el-select
            data-is-search="true"
            size="small"
            collapse-tags
            filterable
            v-model="scheduleFilters.doctor"
            placeholder="Выберите сотрудника"
            clearable
            @change="handleSelectDoctor"
            multiple
          >
            <el-option
              v-for="item in doctors"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
    </div>
    <div v-if="scheduleGridData.rows" class="grid">
      <div v-if="scheduleGridData.columns.length === 0" class="grid--empty">
        <div class="description">
          <p>Не найдено данных о работе врачей по выбранным фильтрам.</p>
          <p>При необходимости добавьте графики работы в настройках</p>
        </div>
        <router-link
          class="schedule-slot-menu__btn-link"
          to="settings/timetable"
          >Перейти в настройки</router-link
        >
      </div>
      <div v-else class="grid-wrapper">
        <div class="grid-time">
          <div class="grid-time__header">
            <label class="grid-step__select-label">Время</label>
            <el-select
              class="grid-step__select"
              size="small"
              v-model="scheduleFilters.gridStep"
              @change="loadScheduleGridData()"
            >
              <el-option
                v-for="(item, index) of scheduleFilters.gridOptions"
                :key="index"
                :value="item"
                :label="`: ${item ? item : 'авто'}`"
              >
              </el-option>
            </el-select>
          </div>
          <div class="grid-time__column">
            <span
              v-for="(row, index) of scheduleGridData.rows"
              :key="index"
              class="grid-time__slot"
              >{{ row.start }}</span
            >
          </div>
        </div>
        <div
          class="grid-date"
          v-for="(column, index) of scheduleGridData.columns"
          :key="index"
        >
          <div class="grid-date__header" :class="setGridDateClass(column.date)">
            {{ column.dateString }} {{ column.weekDay.toUpperCase() }}
          </div>
          <div class="grid-doctor">
            <div
              class="grid-doctor__column"
              v-for="(doctor, index) of column.doctors"
              :key="index"
            >
              <div class="grid-doctor__header">
                <span class="doctor-name"> {{ doctor.fio }}</span>
                <span class="doctor-position">
                  {{ doctor.position }}
                </span>
              </div>
              <div class="grid-doctor__timeline">
                <div
                  class="slot-wrapper"
                  v-for="(row, index) of scheduleGridData.rows"
                  :key="index"
                >
                  {{ setSlotAvailabilityAndShowCabinets(row, doctor) }}
                  <span
                    class="slot"
                    :class="
                      row.isAvailable
                        ? 'slot--available'
                        : 'slot--not-available'
                    "
                    @click="addAppointment(row, column.date, doctor, $event)"
                  >
                    {{ row.start }}
                  </span>
                </div>
                <div class="grid-doctor__events">
                  <div
                    :style="{
                      top: `${appointment.marginTop}px`,
                      height: `${appointment.height}px`,
                    }"
                    class="event"
                    :class="setEventClass(appointment)"
                    v-for="(appointment, index) of doctor.appointments"
                    :key="index"
                  >
                    <ScheduleSlotMenuComponent
                      :appointment="appointment"
                      @openModalAddEditAppointment="
                        editAppointemnt(appointment, doctor)
                      "
                      @openModalPrintDocuments="
                        openModalPrintDocuments(appointment.id)
                      "
                      @movingAppointment="movingAppointment(appointment)"
                      @copyingAppointment="copyingAppointment(appointment)"
                      @cancelAppointment="
                        handleCancelAppointment(appointment.id)
                      "
                      @changeAppointmentStatus="handleChangeAppointmentStatus"
                    ></ScheduleSlotMenuComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- модальное добавления/редактирования записи в расписание -->
    <ModalAddEditAppointmentComponent
      :is-modal-add-edit-appointment-visible.sync="
        isModalAddEditAppointmentVisible
      "
      :is-loading="isModalAddEditAppointmentLoading"
      :appointment-data="appointment"
      :patient-data.sync="patient"
      :total-price.sync="totalPrice"
      :is-edit-mode="isEditMode"
      :is-copying-mode.sync="isCopyingMode"
      :is-moving-mode.sync="isMovingMode"
      @clearModalAddEditAppointment="clearModalAddEditAppointment()"
    ></ModalAddEditAppointmentComponent>
    <!-- модальное печати документов -->
    <ModalPrintDocumentComponent
      :idAppointment="appointmentId"
      :idPatient="null"
      :meddoc-name="null"
      :documents-for-print="documentsForPrint"
      :is-modal-visible.sync="isModalVisible"
    >
    </ModalPrintDocumentComponent>
  </div>
</template>

<script>
import ScheduleSlotMenuComponent from '@/components/ScheduleSlotMenuComponent.vue';
import AuthManager from '../services/authManager';
import ModalPrintDocumentComponent from '@/components/ModalPrintDocumentComponent.vue';
import ModalAddEditAppointmentComponent from '@/components/ModalAddEditAppointmentComponent.vue';
import { getterTypes } from '@/store/modules/documentsPrintModule';
import { mapGetters } from 'vuex';
import {
  downloadFileFromStream,
  getDateString,
  copyObject,
  setItem,
  getItem,
} from '@/services/commonService';
import moment from 'moment';
import scheduleService from '@/services/scheduleService';

export default {
  name: 'SchedulePage',
  components: {
    ModalPrintDocumentComponent,
    ModalAddEditAppointmentComponent,
    ScheduleSlotMenuComponent,
  },
  data() {
    return {
      scheduleGridData: [],
      scheduleFilters: {
        filial: [],
        cabinet: [],
        doctor: [],
        department: [],
        gridStep: 0,
        gridOptions: [0, 5, 10, 15, 20, 25, 30, 40, 60],
        dates: [
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate()
          ),
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() + 2
          ),
        ],
      },
      isGridLoading: false,
      isModalVisible: false,
      isModalAddEditAppointmentVisible: false,
      isEditMode: false,
      patient: {},
      appointment: {
        duration: null,
      },
      selectedAppointment: null,
      totalPrice: 0,
      modalTypeNotice: ['selected and disabled'],
      dateColumns: [],
      filialOptions: [],
      cabinetOptions: [],
      departmentOptions: [],
      pickerOptions: {
        firstDayOfWeek: 1,
        disabledDate() {
          return false;
        },
        shortcuts: [
          {
            text: 'Вчера',
            onClick(picker) {
              const start = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() - 1
              );
              const end = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() - 1
              );
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Завтра',
            onClick(picker) {
              const start = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() + 1
              );
              const end = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() + 1
              );
              // end.setTime(end.getTime() + 3600 * 1000 * 24 * 1);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Неделя',
            onClick(picker) {
              const start = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate()
              );
              const end = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() + 6
              );
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Месяц',
            onClick(picker) {
              const start = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate()
              );
              const end = new Date(
                new Date().getFullYear(),
                new Date().getMonth() + 1,
                new Date().getDate()
              );
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
      isScheduleGridLoading: false,
      isModalAddEditAppointmentLoading: false,
      notificationSmsDisable: false,
      notificationEmailDisable: false,
      currentUser: '',
      appointmentId: '',
      drawer: false,
      direction: 'ltr',
      isMovingMode: false,
      isCopyingMode: false,
    };
  },
  computed: {
    ...mapGetters({
      documentsForPrint: getterTypes.documentsForPrintOnOtherPages,
      appointmentTypeList: 'appointmentTypeList',
      appointmentStatusList: 'appointmentStatusList',
      filials: 'filials',
      departments: 'departments',
      cabinets: 'cabinets',
      doctors: 'doctorsList',
      defaultAppointment: 'defaultAppointment',
      defaultPatient: 'defaultPatient',
      businessHours: 'businessHours',
    }),
    // scheduleFilters: {
    //   get() {
    //     let filtersFromLS = getItem('scheduleFilters');
    //     let filtersFromStore = this.$store.getters.scheduleFilters;
    //     return filtersFromLS ? filtersFromLS : filtersFromStore;
    //   },
    //   set(value) {
    //     this.$store.commit('SET_SCHEDULE_FILTERS', value);
    //   },
    // },
  },
  watch: {
    scheduleFilters: {
      handler(value) {
        setItem('scheduleFilters', value);
      },
      deep: true,
    },
  },
  async mounted() {
    //подключаемся к хабу
    await this.$patientHub.start();
    this.$store.dispatch('getPriceList');
    this.$store.dispatch('getMyOrganization');
    this.$store.dispatch('GET_DOCTORS');
    this.$store.dispatch('getContractorOrganizations', {
      pagesize: 10,
      pagenumber: 1,
    });
    this.$store.dispatch('getInsurancePolicies');
    this.$store.dispatch('getFilials');
    this.$store.dispatch('getAppointmentTypeList');
    this.$store.dispatch('getAppointmentStatusList');
    if (getItem('scheduleFilters')) {
      this.scheduleFilters = getItem('scheduleFilters');
    }

    this.currentUser = AuthManager.getUser();
    //подписываемся на изменение расписания
    this.$patientHub.client.on('ScheduleChange', () => {
      this.loadScheduleGridData();
    });
    this.loadScheduleGridData();
  },
  methods: {
    setGridDateClass(date) {
      let today = new Date().getDate();
      return today === new Date(date).getDate() ? 'current' : '';
    },
    setEventClass(appointment) {
      let status = appointment.idAppointmentStatus;
      let className = '';
      switch (status) {
        case 1:
          className = 'event--created';
          break;
        case 3:
          className = 'event--confirmed';
          break;
        case 4:
          className = 'event--patient-in-clinic';
          break;
        case 6:
          className = 'event--done';
          break;
        case 7:
          className = 'event--patient-absence';
          break;
        default:
          break;
      }
      return className;
    },
    setSlotAvailabilityAndShowCabinets(slot, doctor) {
      scheduleService.setSlotAvailabilityAndShowCabinets(slot, doctor);
    },
    getReportAsync() {
      this.$store
        .dispatch('getScheduleReportInExcel', {
          from: moment(this.scheduleFilters.dates[0]).format('MM-DD-YYYY'),
          to: moment(this.scheduleFilters.dates[1]).format('MM-DD-YYYY'),
        })
        .then(() => {
          let file = this.$store.getters.scheduleReportInExcel;
          let reportDates = `${getDateString(
            this.scheduleFilters.dates[0]
          )} - ${getDateString(this.scheduleFilters.dates[1])}`;
          let fileName = `Загруженность врачей (${reportDates})`;
          downloadFileFromStream(file, fileName)
            .then(() => {
              this.$message({
                type: 'success',
                message: 'Отчет успешно получен. Проверьте папку "Загрузки"',
              });
            })
            .catch((error) => {
              console.log(error);
              this.$message({
                type: 'error',
                message: 'Произошла ошибка при получении файла!',
              });
            });
        })
        .catch((error) => {
          console.log(error);
          this.$message({
            type: 'error',
            message: 'Произошла ошибка при получении файла!',
          });
        });
    },
    //метод изменения статуса записи на прием
    async setAppointmentStatus(appointmentId, idAppointmentStatus) {
      this.$store
        .dispatch('getAppointment', {
          id: appointmentId,
        })
        .then(() => {
          let appointment = this.$store.getters.appointment;
          this.$store.dispatch('editAppointment', {
            ...appointment,
            idAppointmentStatus: idAppointmentStatus,
          });
        });
    },
    // обработчик изменения статуса записи на прием
    handleChangeAppointmentStatus({ appointmentId, idAppointmentStatus }) {
      let idAppointmentStatusToSet;
      switch (idAppointmentStatus) {
        case 1:
          idAppointmentStatusToSet = 3; // устанавливаем статус "Запись подтверждена"
          break;
        case 3:
          idAppointmentStatusToSet = 4; // устанавливаем статус "Пациент в клинике"
          break;
        case 4:
          idAppointmentStatusToSet = 6; // устанавливаем статус "Прием завершен"
          break;
        case 6:
          idAppointmentStatusToSet = 4; // устанавливаем статус "Пациент в клинике" после отмены завершения приема
          break;
        default:
          break;
      }
      this.setAppointmentStatus(appointmentId, idAppointmentStatusToSet);
    },
    cancelMovingOrCopyingMode() {
      this.isCopyingMode = false;
      this.isMovingMode = false;
      this.isEditMode = false;
    },
    openAddEditModalAppointment() {
      this.isModalAddEditAppointmentVisible =
        !this.isModalAddEditAppointmentVisible;
    },
    //Перенос пациента
    movingAppointment(appointment) {
      this.selectedAppointment = appointment;
      this.isEditMode = true;
      this.isMovingMode = true;
    },
    copyingAppointment(appointment) {
      this.selectedAppointment = appointment;
      this.isEditMode = true;
      this.isCopyingMode = true;
    },
    checkIntersectionAppointment(slot, doctor) {
      scheduleService.checkIntersectionAppointment(slot, doctor);
    },
    async addAppointment(slot, date, doctor, $event) {
      const isAvailableSlot =
        $event.target.classList.contains('slot--available');
      this.checkIntersectionAppointment(slot, doctor);
      // console.log(slot);
      // проверяем доступен ли слот для записи и требуется ли копирование или перемещение записи на прием
      if (isAvailableSlot && !this.isCopyingMode && !this.isMovingMode) {
        // добавляем новую записи на прием
        this.appointment = copyObject(this.defaultAppointment); // получаем дефолтное состояние новой записи на прием
        this.appointment.dateTimeFrom = new Date(
          `${date}T${
            slot.startAfterCheckIntersection
              ? slot.startAfterCheckIntersection
              : slot.start
          }:00`
        ); // забираем дату начала из слота или смотрим на время установленной после проверки на пересечение
        this.appointment.idSchedule = doctor.scheduleId; // забираем id графика рабоыт врача
        this.appointment.idDoctor = doctor.id; // забюираем id врача
        this.appointment.duration = slot.durationAfterCheckIntersection
          ? slot.durationAfterCheckIntersection
          : this.scheduleFilters.gridStep
          ? this.scheduleFilters.gridStep
          : 15;
        this.appointment.isBlockedSetDuration =
          slot.durationAfterCheckIntersection ? true : false;
        this.openAddEditModalAppointment();
      }
      if (isAvailableSlot && this.isCopyingMode) {
        // копируем запись на прием
        let appointmentData = await this.getAppointmentWithExtendData(
          this.selectedAppointment
        );
        this.appointment = copyObject(appointmentData);
        this.patient = appointmentData.patient;
        this.appointment.dateTimeFrom = new Date(
          `${date}T${
            slot.startAfterCheckIntersection
              ? slot.startAfterCheckIntersection
              : slot.start
          }:00`
        ); // забираем дату начала из слота или смотрим на время установленной после проверки на пересечение
        this.appointment.services = []; // сбрасываем услигу при копировании
        this.appointment.idSchedule = doctor.scheduleId; // забираем id графика работы из данных о враче
        this.appointment.idDoctor = doctor.id; // забираем id врача
        this.appointment.idAppointmentStatus = 1; // сбрасываем статус записи на прием до "Запись создана"
        this.appointment.isVisit = 0; // сбрасываем отметку о законченном приеме
        this.openAddEditModalAppointment();
      }
      if (isAvailableSlot && this.isMovingMode) {
        // перемещаем запись на прием
        let appointmentData = await this.getAppointmentWithExtendData(
          this.selectedAppointment
        );
        this.appointment = copyObject(appointmentData);
        this.patient = appointmentData.patient;
        let totalPrice = 0;
        if (appointmentData.services.length !== 0) {
          for (let i of appointmentData.services) {
            totalPrice += i.priceDiscount;
          }
        }
        this.totalPrice = totalPrice ? totalPrice : 0;
        this.appointment.dateTimeFrom = new Date(
          `${date}T${
            slot.startAfterCheckIntersection
              ? slot.startAfterCheckIntersection
              : slot.start
          }:00`
        ); // забираем дату начала из слота или смотрим на время установленной после проверки на пересечение
        this.appointment.idSchedule = doctor.scheduleId; // забираем id графика работы из данных о враче
        this.appointment.idDoctor = doctor.id; // забираем id врача
        this.openAddEditModalAppointment();
      }
    },
    async editAppointemnt(appointment, doctor) {
      this.isEditMode = true;
      let appointmentData = await this.getAppointmentWithExtendData(
        appointment
      );
      this.appointment = copyObject(appointmentData);
      this.appointment.idDoctor = doctor.id;
      this.appointment.duration = appointmentData.duration;
      this.patient = appointmentData.patient;
      let totalPrice = 0;
      if (appointmentData.services.length !== 0) {
        for (let i of appointmentData.services) {
          totalPrice += i.priceDiscount;
        }
      }
      this.totalPrice = totalPrice ? totalPrice : 0;
      this.openAddEditModalAppointment();
    },
    getAppointmentWithExtendData(appointment) {
      const getAppointment = this.$store.dispatch('getAppointment', {
        id: appointment.id,
      });
      const getGuaranteeLetter = this.$store.dispatch(
        'getGuaranteeLetterList',
        {
          patientId: appointment.idPatient,
        }
      );
      const getPatient = this.$store.dispatch('getPatients', {
        patientId: appointment.idPatient,
        pagesize: 10,
        pagenumber: 1,
      });
      // оборачиваем все запросы в один Promise
      return Promise.all([getAppointment, getGuaranteeLetter, getPatient])
        .then(() => {
          let appointmentWithExtendData = this.$store.getters.appointment;
          let timeTo = new Date(appointmentWithExtendData.dateTimeTo).getTime();
          let timeFrom = new Date(
            appointmentWithExtendData.dateTimeFrom
          ).getTime();
          let duration = (timeTo - timeFrom) / 1000 / 60;
          appointmentWithExtendData.duration = duration;
          let guaranteeLetter =
            this.$store.getters.guaranteeLetterList.values.find(
              (x) => x.appointmentId === appointment.id
            );
          appointmentWithExtendData.insurancePolicyId = guaranteeLetter
            ? guaranteeLetter.insurancePolicyId
            : null;
          appointmentWithExtendData.guaranteeLetterId = guaranteeLetter
            ? guaranteeLetter.id
            : null;
          appointmentWithExtendData.patient = this.$store.getters.patients[0];
          return appointmentWithExtendData;
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: 'Произошла ошибка при получении данных!',
          });
        });
    },
    clearModalAddEditAppointment() {
      let dateTimeFrom = this.appointment.dateTimeFrom;
      let idDoctor = this.appointment.idDoctor;
      let idSchedule = this.appointment.idSchedule;
      this.appointment = {
        ...this.defaultAppointment,
        dateTimeFrom: dateTimeFrom,
        idDoctor: idDoctor,
        idSchedule: idSchedule,
        services: [],
      };
      this.patient = { ...this.defaultPatient, lastName: '', phone: '' };
      this.isEditMode = false;
    },
    // загрузка данных и формирование сетки расписания
    async loadScheduleGridData() {
      this.isGridLoading = true;
      this.$store
        .dispatch('getScheduleList', {
          from: moment(this.scheduleFilters.dates[0]).format('MM-DD-YYYY'),
          to: moment(this.scheduleFilters.dates[1]).format('MM-DD-YYYY'),
          filialIds: this.scheduleFilters.filial,
          departmentIds: this.scheduleFilters.department,
          cabinetIds: this.scheduleFilters.cabinet,
          doctorIds: this.scheduleFilters.doctor,
        })
        .then(() => {
          let scheduleList = this.$store.getters.scheduleList;
          try {
            this.scheduleGridData = scheduleService.getScheduleTableData(
              scheduleList,
              this.scheduleFilters.gridStep,
              this.businessHours
            );
          } catch (error) {
            console.log(error);
          }
          this.isGridLoading = false;
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: `Не удалось загрузить расписание!`,
          });
          this.isGridLoading = false;
        });
    },

    //обработка выбора опций в фильтре
    hadleFilialChange() {
      this.loadScheduleGridData();
    },
    hadleDepartmentChange() {
      this.loadScheduleGridData();
    },
    handleSelectDoctor() {
      // if (value.length === 1) {
      //   let appointmentDefaultDuration = dateTimeService.getDurationAsMinutes(
      //     value[0].appointmentDefaultDuration
      //   );
      //   this.scheduleFilters.gridStep = appointmentDefaultDuration
      //     ? appointmentDefaultDuration
      //     : 15;
      // } else {
      //   this.scheduleFilters.gridStep = 15;
      // }
      this.loadScheduleGridData();
    },
    //обработка сброса фильтра
    handleResetFilter() {
      this.scheduleFilters = {
        ...this.scheduleFilters,
        filial: [],
        cabinet: [],
        doctor: [],
        department: [],
        gridStep: 0,
        dates: [
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate()
          ),
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() + 2
          ),
        ],
      };
      this.loadScheduleGridData();
    },

    //вызов компонента печати документов
    openModalPrintDocuments(id) {
      this.isModalVisible = !this.isModalVisible;
      this.appointmentId = id;
    },

    // обработчик отмены записи на прием
    handleCancelAppointment(appointmentId) {
      this.$confirm('Вы действительно хотите отменить запись?', 'Warning', {
        confirmButtonText: 'Подтвердить',
        cancelButtonText: 'Отменить',
        type: 'warning',
      }).then(async () => {
        let idAppointmentStatus = 8; // статус "Прием отменен"
        this.setAppointmentStatus(appointmentId, idAppointmentStatus)
          .then(() => {
            this.$message({
              type: 'success',
              message: 'Запись на прием успешно отменена',
            });
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: 'Не удалось отменить прием!',
            });
          });
      });
    },
  },
};
</script>
