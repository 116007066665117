<template>
  <div class="settings-page" v-loading="loading">
    <el-row :gutter="24">
      <el-col :span="20" :md="10">
        <h6>Шаблоны медицинских документов</h6>
        <span class="settings-description">
          Здесь вы можете настроить шаблоны медицинских документов вашей
          организации.
        </span>
        <el-tooltip
          class="item mt-16 hidden-xs-only"
          effect="dark"
          size="small"
          content="Поиск шаблона можно производить на названию"
          placement="right"
          :hide-after="2500">
          <el-input
            size="small"
            placeholder="Найти шаблон..."
            prefix-icon="el-icon-search"
            v-model="searchTemplate"
            @input="handleSearchTemplate">
          </el-input>
        </el-tooltip>
        <el-input
            class="mt-16 hidden-sm-and-up"
            size="small"
            placeholder="Найти шаблон..."
            prefix-icon="el-icon-search"
            v-model="searchTemplate"
            @input="handleSearchTemplate">
          </el-input>
      </el-col>
      <el-col :span="4" :md="14" class="d-flex flex-end">
        <router-link
          class="staff-btn-link hidden-sm-and-down"
          :to="{
            path: '/settings/meddoc-templates-constructor/' + 0,
          }"
          >Добавить <i class="el-icon-plus el-icon-right"></i>
        </router-link>
        <router-link
          class="staff-btn-link hidden-md-and-up"
          :to="{
            path: '/settings/meddoc-templates-constructor/' + 0,
          }"
          ><i class="el-icon-plus"></i>
        </router-link>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="24">
        <el-table
          class="mt mb"
          ref="multipleTable"
          :data="meddocTemplates"
          @sort-change="sortChange"
          stripe
          :default-sort="{ prop: 'fio', order: 'descending' }"
          style="width: 100%">
          <el-table-column
            width="140"
            sortable
            property="name"
            label="Наименование шаблона">
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column 
            min-width="180" 
            property="positionType" 
            label="Специальности врачей">
            <template slot-scope="scope">
              <!-- <span
                v-for="item in scope.row.meddocTemplatePositions"
                :key="item.id"
                >{{ item.value }}</span
              > -->
              <el-select
                size="small"
                v-model="scope.row.meddocTemplatePositions"
                placeholder="Выберите специальность"
                multiple
                disabled>
                <el-option
                  v-for="item in positionTypeOptions"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column width="80px" label="">
            <template slot-scope="scope">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link"
                  ><i class="el-icon-more"></i>
                </span>
                <el-dropdown-menu size="small" slot="dropdown">
                  <el-dropdown-item type="button" v-if="scope.row.id">
                    <router-link
                      class="staff-dropdown-link"
                      :to="{
                        path:
                          '/settings/meddoc-templates-constructor/' +
                          scope.row.id,
                      }">
                      <i class="el-icon-edit el-icon-left"></i> 
                      <span>Редактировать</span>
                    </router-link>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      :disabled="scope.row.inUse"
                      class="dropdown-btn danger"
                      size="small"
                      type="text"
                      @click="handleDelete(scope.row)">
                      <i class="el-icon-delete"></i>
                      Удалить</el-button
                    ></el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <!-- <el-col :span="24">
        <el-button
          type="primary"
          class="mt-16 mb-20"
          :loading="loadingBtn"
          @click="handleSave">
          Применить
        </el-button>
      </el-col> -->
    </el-row>
  </div>
</template>

<script>
  import PositionTypeManager from "@/services/positionTypeManager.js";
  import MeddocTemplateManager from "@/services/meddocTemplateManager.js";

  function sort(order) {
    if (order === "ascending") {
      return (a, b) => {
        if (a < b) {
          return -1;
        }
        if (b < a) {
          return 1;
        }
        return 0;
      };
    }

    return (a, b) => {
      if (a > b) {
        return -1;
      }
      if (b > a) {
        return 1;
      }
      return 0;
    };
  }

  export default {
    loading: false,
    data() {
      return {
        loading: false,
        loadingBtn: false,
        searchTemplate: "",
        meddocTemplates: [],
        meddocTemplatePositions: [],
        positionTypeOptions: [],
      };
    },
    async created() {
      this.loading = true;
      this.positionTypeOptions = await PositionTypeManager.getPositionTypes();
      this.meddocTemplates = await MeddocTemplateManager.getTemplates();
      this.loading = false;
    },
    methods: {
      sortChange(value) {
        var s = sort(value.order);
        this.staffData = this.staffData.sort((a, b) =>
          s(a[value.prop], b[value.prop])
        );
      },
      async handleSearchTemplate() {
        this.meddocTemplates = await MeddocTemplateManager.getTemplates();
        this.meddocTemplates = this.meddocTemplates.filter(x => x.name
                .toUpperCase()
                .includes(this.searchTemplate.toUpperCase()));
      },

      async handleDelete(row) {
        this.$confirm(
          `Вы действительно хотите удалить "${row.name}"?`,
          "Warning",
          {
            confirmButtonText: "Подтвердить",
            cancelButtonText: "Отменить",
            type: "warning",
          }
        )
          .then(async () => {
            let index = this.meddocTemplates.indexOf(row);
            if (index > -1) {
              this.meddocTemplates.splice(index, 1);
            }
            await MeddocTemplateManager.deleteTemplate(row.id);
            this.$message({
              type: "success",
              message: "Удаление прошло успешно",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "Невозможно удалить шаблон",
            });
          });
      },
    },
  };
</script>

<style lang="scss" scoped></style>
