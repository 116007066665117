import axios from '@/api/axios';

/**
 * API method - Get GuaranteeLetter by ID
 * @param {number} id - ID GuaranteeLetter
 * @returns Promise
 */
const getGuaranteeLetterById = async (id) => {
  return await axios.get('guarantee-letters/' + id);
};
/**
 * API method - Get Array GuaranteeLetters with params
 * @param {Object} params - Object of GuaranteeLetter
 * @param {number} params.patientId - ID Patient
 * @param {number} params.limit - Pagization Limit
 * @param {number} params.offset - Pagination Offset
 * @param {string} params.search - Search stirng (not implemented)
 * @returns Promise
 */
const getGuaranteeLetterList = async (params) => {
  return await axios.get('guarantee-letters', { params: params });
};
/**
 * API method - Create new GuaranteeLetter
 * @param {Object} guaranteeLetter - Object of GuaranteeLetter
 * @param {Number} guaranteeLetter.insurancePolicyId - ID Insurance Policy
 * @param {Number} guaranteeLetter.appointmentId - ID Appointment
 * @param {string} guaranteeLetter.text - Text Guarantee Letter
 * @returns Promise
 */
const createGuaranteeLetter = async (guaranteeLetter) => {
  return await axios.post('guarantee-letters', guaranteeLetter);
};

/**
 * API method - Update new GuaranteeLetter
 * @param {Object} guaranteeLetter - Object of Guarantee Letter
 * @param {Number} guaranteeLetter.id - ID Guarantee Letter
 * @param {string} guaranteeLetter.text - Text Guarantee Letter
 * @returns Promise
 */
const updateGuaranteeLetter = async (guaranteeLetter) => {
  return await axios.put(
    'guarantee-letters/' + guaranteeLetter.id,
    guaranteeLetter
  );
};

/**
 * API method - Delete GuaranteeLetter by ID
 * @param {number} id - ID GuaranteeLetter
 * @returns Promise
 */
const deleteGuaranteeLetterById = async (id) => {
  return await axios.delete('guarantee-letters/' + id);
};

export default {
  getGuaranteeLetterList,
  getGuaranteeLetterById,
  createGuaranteeLetter,
  updateGuaranteeLetter,
  deleteGuaranteeLetterById,
};
