import {store} from "../store";

class FilialManager {
    filials = [];
    allCabinets = [];
    allDepartments = [];
    filialCabinets = [];
    filialDepartments = [];

  async getFilials() {
    if(store.getters.filials.length == 0){
      await store.dispatch('GET_FILIALS');
    }
    this.filials = store.getters.filials;
    return this.filials;
  }

  async getAllCabinets() {
    if(store.getters.filials.length == 0){
      await store.dispatch('GET_FILIALS');
    }
    this.allCabinets = store.getters.allCabinets;
    return this.allCabinets;
  }

  async getFilialCabinets(value) {
    if(store.getters.filials.length == 0){
      await store.dispatch('GET_FILIALS');
    }
    this.filialCabinets = value.length > 0
        ? store.getters.allCabinets.filter(x => value.find(i => i == x.idFilial))
        : store.getters.allCabinets;
    return this.filialCabinets;
  }

  async getAllDepartments() {
    if(store.getters.filials.length == 0){
      await store.dispatch('GET_FILIALS');
    }
    this.allDepartments = store.getters.allDepartments;
    return this.allDepartments;
  }

  async getFilialDepartments(value) {
    if(store.getters.filials.length == 0){
      await store.dispatch('GET_FILIALS');
    }
    this.filialDepartments = value 
        ? store.getters.allDepartments.filter(x => value.find(i => i == x.idFilial))
        : store.getters.allDepartments;
    return this.filialDepartments;
  }

  async addFilial(filial) {
    await store.dispatch('SAVE_FILIAL', filial);
    this.filials = store.getters.filials;
    return this.filials;
  }

  async updateFilial(filial) {
    await store.dispatch('UPDATE_FILIAL', filial);
    this.filials = store.getters.filials;
    return this.filials;
  }

  async deleteFilial(idFilial) {
    await store.dispatch('DELETE_FILIAL', idFilial);
    this.filials = store.getters.filials;
    return this.filials;
  }

  async addCabinet(cabinet) {
    await store.dispatch('SAVE_CABINET', cabinet);
    this.filials = store.getters.filials;
    return this.filials;
  }

  async updateCabinet(cabinet) {
    await store.dispatch('UPDATE_CABINET', cabinet);
    this.filials = store.getters.filials;
    return this.filials;
  }

  async deleteCabinet(cabinet) {
    await store.dispatch('DELETE_CABINET', cabinet);
    this.filials = store.getters.filials;
    return this.filials;
  }

  async addDepartment(department) {
    await store.dispatch('SAVE_DEPARTMENT', department);
    this.filials = store.getters.filials;
    return this.filials;
  }

  async updateDepartment(department) {
    await store.dispatch('UPDATE_DEPARTMENT', department);
    this.filials = store.getters.filials;
    return this.filials;
  }

  async deleteDepartment(department) {
    await store.dispatch('DELETE_DEPARTMENT', department);
    this.filials = store.getters.filials;
    return this.filials;
  }
}

export default new FilialManager();