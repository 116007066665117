import analyticsApi from "@/api/analytics";

export const mutationTypes = {
  setAnalyticsFilter: "SET_ANALYTICS_FILTER",
  setAnalyticsData: "SET_ANALYTICS_DATA",
};
export const getterTypes = {
  analyticsFilter: "analyticsFilter",
  chartData: "analyticData",
};
export const actionTypes = {
  getAnalyticsData: "getAnalyticsData",
  getAnalyticsFile: "getAnalyticsFile",
};
const state = {
  analyticsData: [],
  analyticsFilter: {
    dateRange: [
      new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        ).getDate()
      ),
    ],
    filials: null,
    departments: null,
    positionTypes: null,
    doctors: null,
    patientGenders: [],
    patientAgeRange: [0, 100],
    services: null,
    appointmentStatusTypes: null,
  },
};
const getters = {
  [getterTypes.analyticsFilter]: (state) => {
    return state.analyticsFilter;
  },
  [getterTypes.chartData]: (state) => {
    return state.analyticsData;
  },
};
const mutations = {
  [mutationTypes.setAnalyticsFilter]: (state, payload) => {
    state.analyticsFilter = payload;
  },
  [mutationTypes.setAnalyticsData]: (state, payload) => {
    state.analyticsData = payload;
  },
};
const actions = {
  [actionTypes.getAnalyticsData]: async (context, params) => {
    const analyticsData = await analyticsApi.getAnalyticsData(params);
    context.commit(mutationTypes.setAnalyticsData, analyticsData);
  },
  [actionTypes.getAnalyticsFile]: async (context, params) => {
    return await analyticsApi.getAnalyticsFile(params);
  },
};
export default { state, getters, mutations, actions };
