import employeesApi from '@/api/employees';

const employeesModule = {
  state: () => ({
    employee: null,
    defaultEmployee: {
      id: 0,
      lastName: null,
      firstName: null,
      patronymic: '',
      birthDate: null,
      gender: null,
      phone: null,
      email: null,
      residenceAddress: null,
      registerAddress: null,
      passportSeries: null,
      passportNumber: null,
      passportDateFrom: null,
      passportIssuer: null,
      passportIssuerCode: null,
      snils: null,
      inn: null,
      currentMainPosition: {
        id: null,
        idPosition: null,
        dateStart: null,
        dateEnd: null,
        idDepartment: null,
        idFilial: null,
        idCategory: null,
        isMainFunction: 1,
        positionType: {
          description: null,
          hasSchedule: true,
          id: null,
          value: null,
        },
      },
      currentExtraPosition: {
        id: null,
        idPosition: null,
        dateStart: null,
        dateEnd: null,
        idDepartment: null,
        idFilial: null,
        idCategory: null,
        isMainFunction: 0,
        positionType: {
          description: null,
          hasSchedule: true,
          id: null,
          value: null,
        },
      },
      archivePositions: [],
      user: {
        id: null,
        login: null,
        password: null,
        idUserRoleGroupType: null,
      },
    },
    employees: [],
    positions: [],
    categories: [],
  }),
  getters: {
    employee: (state) => {
      if (state.employee) {
        let employeeFromDto = state.employee;
        let currentMainPosition = employeeFromDto.positions.find(
          (p) => p.isMainFunction === 1 && p.dateEnd == null
        );
        let currentExtraPosition = employeeFromDto.positions.find(
          (p) => p.isMainFunction === 0 && p.dateEnd == null
        );
        employeeFromDto.currentMainPosition = currentMainPosition
          ? currentMainPosition
          : {
              id: null,
              idPosition: null,
              dateStart: null,
              dateEnd: null,
              idDepartment: null,
              idFilial: null,
              idCategory: null,
              isMainFunction: 1,
            };
        employeeFromDto.currentExtraPosition = currentExtraPosition
          ? currentExtraPosition
          : {
              id: null,
              idPosition: null,
              dateStart: null,
              dateEnd: null,
              idDepartment: null,
              idFilial: null,
              idCategory: null,
              isMainFunction: 0,
            };
        let archivePositions = employeeFromDto.positions.filter(
          (x) =>
            x.id != employeeFromDto.currentMainPosition.id &&
            x.id != employeeFromDto.currentExtraPosition.id
        );
        employeeFromDto.archivePositions = archivePositions;
        if (!employeeFromDto.user) {
          employeeFromDto.user = {
            id: null,
            login: null,
            idUserRoleGroupType: null,
            password: null,
          };
        }
        return employeeFromDto;
      } else {
        return state.defaultEmployee;
      }
    },
    employees: (state) => {
      let employees = state.employees.map((x) => {
        return {
          idDoctor: x.id,
          positionName: x.positions
            .filter(
              (p) =>
                (new Date(p.dateStart).getTime() == new Date().getTime() ||
                  new Date(p.dateStart).getTime() < new Date().getTime()) &&
                (!p.dateEnd ||
                  new Date(p.dateEnd).getTime() > new Date().getTime() ||
                  new Date(p.dateEnd).getTime() == new Date().getTime())
            )
            .flatMap((p) => p.positionType.value)
            .join(','),
          name: `${x.firstName} ${x.lastName} ${x.patronymic}`,
          phone: x.phone,
          login: x.login,
          appointmentDefaultDuration: x.appointmentDefaultDuration,
          status: x.positions.find(
            (p) =>
              (new Date(p.dateStart).getTime() == new Date().getTime() ||
                new Date(p.dateStart).getTime() < new Date().getTime()) &&
              (!p.dateEnd ||
                new Date(p.dateEnd).getTime() > new Date().getTime() ||
                new Date(p.dateEnd).getTime() == new Date().getTime())
          )
            ? 1
            : 0,
        };
      });
      return employees;
    },
    positions: (state) => {
      return state.positions;
    },
    categories: (state) => {
      return state.categories;
    },
  },
  mutations: {
    SET_EMPLOYEE: (state, payload) => {
      state.employee = payload;
    },
    SET_EMPLOYEES: (state, payload) => {
      state.employees = payload;
    },
    SET_POSITIONS: (state, payload) => {
      state.positions = payload;
    },
    SET_CATEGORIES: (state, payload) => {
      state.categories = payload;
    },
  },
  actions: {
    getEmployeeById: async (context, id) => {
      const employee = await employeesApi.getEmployeeById(id);
      context.commit('SET_EMPLOYEE', employee);
    },
    getEmployees: async (context) => {
      const employees = await employeesApi.getEmployees();
      context.commit('SET_EMPLOYEES', employees);
    },
    getPositions: async (context) => {
      const positions = await employeesApi.getEmployeePositionList();
      context.commit('SET_POSITIONS', positions);
    },
    getCategories: async (context) => {
      const categories = await employeesApi.getEmployeeCategoryList();
      context.commit('SET_CATEGORIES', categories);
    },
    createEmployee: async (context, employee) => {
      await employeesApi.createEmployee(employee);
    },
    updateEmployee: async (context, employee) => {
      await employeesApi.updateEmployee(employee);
    },
    deleteEmployee: async (context, id) => {
      await employeesApi.deleteEmployee(id);
    },
  },
};

export default employeesModule;
