import axios from "@/api/axios";

const getAnalyticsData = async (params) => {
  const response = await axios.get("analytics", { params: params });
  return response.data;
};

const getAnalyticsFile = async (params) => {
  const response = await axios.get("analyticsprint", { params: params });
  return response.data;
};
export default {
  getAnalyticsData,
  getAnalyticsFile,
};
