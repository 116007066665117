<template>
  <div class="education-main">
    <el-row :gutter="24">
      <el-col :lg="18" class="d-flex flex-between flex-center mb-16 flex-wrap">
        <h4 class="mt-16">Запись пациента на прием</h4>
        <el-button class="mt-16" size="small" @click="openModalVideo" type="primary"
          >Смотреть видео<i class="el-icon-play"></i
        ></el-button>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt-16">
      <el-col :lg="18">
        <p>
          Запись пациента на прием производится в разделе системы Documeds
          «Расписание», для этого необходимо перейти в соответствующий пункт
          меню.
        </p>
      </el-col>
      <el-col :lg="18">
        <el-image fit="cover" :src="src.url1" :preview-src-list="srcList">
        </el-image>
      </el-col>
      <el-col :lg="18">
        <el-divider></el-divider>
        <p>
          Раздел «Расписание» представляет собой табличную сетку с информацией о
          приемных часах врачей на требуемые даты, а также о записях пациентов
          на прием.
        </p>
        <p>
          В верхней части раздела расположены параметры фильтрации сетки
          расписания.
        </p>
        <p>Параметры фильтрации:</p>
        <ul>
          <li>
            «Выберите даты» - фильтр для выбора времененного диапазона
            формирования сетки расписания
          </li>
          <li>
            «Выберите филиал» - фильтр для выбора филиала. После выбора филиала
            значения фильтров подразделений, кабинетов и сотрудников будут
            ограничены в соответсвии с выбранным филиалом
          </li>
          <li>
            «Выберите подразделение» - фильтр для выбора подразделения. После
            выбора подразделения значения фильтров кабинетов и сотрудников будут
            ограничены в соответсвии с выбранным подразделением
          </li>
          <li>«Выберите кабинет» - фильтр для выбора кабинета</li>
          <li>«Выберите сотрудника» - фильтр для выбора сотрудника</li>
        </ul>
      </el-col>
      <el-col :lg="18">
        <el-divider></el-divider>
        <p>
          По умолчанию при входе в раздел расписание отображается на текущую
          дату. Примененнные пользователем фильтры сохраняются до выхода из
          системы.
        </p>
        <p>
          Для добавления записи на прием к врачу необходимо выбрать свободную
          ячейку (подсвеченную белым цветом). Откроется всплывающее окно, в
          котором можно будет заполнить необходимые данные для добавления
          записи.
        </p>
        <el-divider></el-divider>
      </el-col>
      <el-col :lg="18" class="mt-16 mb-16">
        <el-row :gutter="24">
          <el-col :lg="12">
            <el-image fit="cover" :src="src.url2" :preview-src-list="srcList">
            </el-image>
          </el-col>
          <el-col :lg="12">
            <p>
              При записи пациента можно выбрать требуемый статус записи (по
              умолчанию - «Запись создана»). Планируемую длительность (10, 20,
              30 минут) и характер приема («Первичный приём», «Повторный прием»,
              «Консультация»).
            </p>
            <p>
              Дополнительно можно указать способ оповещения пациента:
              SMS-сообщение или письмо на электронную почту.
            </p>
            <p>
              Созданная запись на прием к врачу подсвечивается зеленым цветом.
              Она доступна для редактирования при нажатии на нее левой кнопкой
              мыши.
            </p>
            <p>
              В случае, если нужно отметить неявку пациента, требуется открыть
              запись для редактирования и изменить статус на «Неявка на прием».
              После этого запись на прием будет помечена в сетке расписания
              красным цветом с подписью «Неявка».
            </p>
            <p>
              В случае, если нужно отменить запись пациента, требуется открыть
              запись для редактирования и изменить статус на «Отмена записи».
              После этого запись будет удалена из сетки расписания, ячейка
              освободится и станет доступна для новой записи.
            </p>
          </el-col>
        </el-row>
      </el-col>
      <el-col :lg="18" class="mt-16 mb-16">
        <el-divider></el-divider>
        <el-row :gutter="24">
          <el-col :lg="12">
            <p>
              При наведении курсора мыши на занятую ячейку, отображается
              всплывающее меню с краткой информацией о данных пациента.
            </p>
            <p>Из данного меню можно:</p>
            <ul>
              <li>Начать запланированный прием</li>
              <li>Распечатать документы пациента</li>
              <li>Просмотреть счета пациента</li>
              <li>Отменить запись</li>
            </ul>
          </el-col>
          <el-col :lg="12">
            <el-image fit="cover" :src="src.url3" :preview-src-list="srcList">
            </el-image>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <blockquote class="danger">
          Врач может начать прием только тех пациентов, которые были добавлены в
          сетку его расписания.
        </blockquote>
        <blockquote class="danger">
          Доступ в раздел «Счета» возможен только пользователям с ролями
          «Администратор системы» и «Регистратор-администратор».
        </blockquote>
        <el-divider></el-divider>
        <p>
          Добавление приемных часов в сетку расписания врачей производится в
          пункте меню «Настройки»/«Графики работ».
        </p>
        <p>
          Про добавление графика работы врача можно прочитать в разделе
          <router-link to="/education/add-timetable-lesson"
            >Создание графика работ и распределение врачей по
            кабинетам</router-link
          >.
        </p>
      </el-col>
    </el-row>
    <!-- модальное окно просмотра видео-урока -->
    <el-dialog
      title="Видео-урок"
      class="modal-play-video"
      :visible.sync="modalVideo"
      width="100%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true">
      <div v-if="!idVideo">
        <h6>К сожалению видео-урок не доступен</h6>
        <p>
          В скором времени мы добавим данный видео-урок по обучению работе в
          Documeds
        </p>
      </div>
      <div v-if="idVideo">
        <VideoPlayerComponent :idVideo="idVideo"></VideoPlayerComponent>
      </div>
      <el-button
        class="education-modal-btn"
        type="primary"
        @click="openModalVideo"
        >Продолжить обучение в текстовом формате</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
  import VideoPlayerComponent from "@/components/VideoPlayerComponent.vue";
  export default {
    components: { VideoPlayerComponent },
    data() {
      return {
        modalVideo: false,
        idVideo: "J2i1GP9QR6s",
        src: {
          url1: require("@/assets/img/education/add-appointment-4.png"),
          url2: require("@/assets/img/education/add-appointment-2.png"),
          url3: require("@/assets/img/education/add-appointment-1.png"),
          url4: require("@/assets/img/education/add-appointment-button.png"),
        },
        srcList: [
          require("@/assets/img/education/add-appointment-4.png"),
          require("@/assets/img/education/add-appointment-2.png"),
          require("@/assets/img/education/add-appointment-1.png"),
        ],
      };
    },
    methods: {
      openModalVideo() {
        this.modalVideo = !this.modalVideo;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
