<template>
  <div class="settings-page timetable cabinet-workload">
    <el-row>
      <el-col :span="24" class="d-flex flex-between">
        <h6>Загруженность кабинетов</h6>
        <router-link class="timetable-link" :to="{ name: 'timetable' }"
          >Вернуться к графику работ<i class="el-icon-angle-right-b"></i
        ></router-link>
      </el-col>
      <el-col :span="24" class="text-right">
        <el-row :gutter="24">
          <el-col :span="12" :md="6">
            <el-date-picker
              size="small"
              v-model="filter.date"
              format="dd.MM.yyyy"
              :clearable="false"
              type="date"
              placeholder="Выберите дату"
              :picker-options="pickerOptions"
              @change="handleFilter"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="12" :md="6" class="d-flex flex-end">
            <el-select
              data-is-search="true"
              size="small"
              collapse-tags
              placeholder="Выберите сотрудника"
              v-model="filter.doctor"
              filterable
              clearable
              multiple
              @change="handleFilter"
            >
              <el-option
                v-for="item in doctorOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <!-- <el-button class="timetable-btn ml" type="primary">
              Добавить
              <i class="el-icon-plus el-icon-right"></i>
            </el-button> -->
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-table
      v-loading="loading"
      class="mt"
      :data="doctorData"
      :cell-class-name="tableCellClassName"
      :span-method="spanMethod"
      :show-header="false"
      border
      :default-sort="{ prop: 'doctor', order: 'ascending' }"
      style="width: 100%"
      max-height="654px"
      @cell-click="handleCellClick"
    >
      <el-table-column prop="doctor" label="Сотрудники" width="152px">
      </el-table-column>
      <el-table-column
        min-width="12px"
        v-for="(timeColumn, index) of timeColumns"
        :label="timeColumn.time"
        :key="index"
      >
        <el-table-column
          :prop="timeStep.prop"
          v-for="(timeStep, index) of timeColumn.timeSteps"
          :key="index"
          min-width="12px"
          align="center"
        >
          <template slot-scope="scope">
            <i
              class="el-icon-plus success"
              v-if="
                scope.row['s' + timeStep.prop] &&
                scope.row[scope.column.property] == 0
              "
            ></i>
            <div
              v-if="
                scope.row['s' + timeStep.prop] &&
                !scope.row['p' + timeStep.prop] &&
                scope.row[scope.column.property] != 0
              "
            >
              {{ scope.row[timeStep.prop] }}
            </div>
            <el-popover
              placement="bottom-start"
              trigger="hover"
              v-if="
                scope.row[scope.column.property] != 0 &&
                scope.row['p' + timeStep.prop]
              "
            >
              <div class="cabinet-workload-dropdown">
                <span class="cabinet-workload-dropdown-text"
                  >Кабинет занят</span
                >
                <span class="cabinet-workload-dropdown-text">
                  {{ scope.row['p' + scope.column.property].note }}
                </span>
                <el-button
                  :loading="loadingModal"
                  type="danger"
                  size="small"
                  plain
                  class="cabinet-workload-dropdown-btn"
                  @click="
                    handleDelete(scope.row['p' + scope.column.property].id)
                  "
                  >Отменить
                  <!-- <i class="el-icon-delete"></i> -->
                </el-button>
              </div>
              <div slot="reference">
                <p class="text">{{ scope.row[timeStep.prop] }}</p>
              </div>
            </el-popover>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>

    <!-- модальное окно добавления кабинета -->
    <el-dialog
      class="modal-add-cabinet"
      :visible.sync="modalAddCabinet"
      width="100%"
    >
      <h5 slot="title">{{ modalTitle }}</h5>
      <el-row :gutter="24">
        <el-alert v-if="showErrorModal" :title="errorModal" type="error">
        </el-alert>
        <el-col :xl="24">
          <label for="">Кабинет</label>
          <el-select
            data-is-search="true"
            size="small"
            v-model="cabinetToAdd.idCabinet"
            placeholder="Выберите кабинет"
            filterable
            @change="handleParameterChange"
            clearable
          >
            <el-option
              v-for="item in cabinetOptionsModal"
              :key="item.id"
              :label="setLabelForCabinetSelect(item)"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :xl="24" class="mt">
          <label for="datePicker">Дата</label>
          <el-date-picker
            size="small"
            style="width: 100%; border-radius: 8px"
            v-model="cabinetToAdd.date"
            format="dd.MM.yyyy"
            type="date"
            :picker-options="{ firstDayOfWeek: 1 }"
            placeholder="Дата"
            disabled
          >
          </el-date-picker>
        </el-col>
        <el-col :xl="24" class="mt">
          <el-row :gutter="24" class="d-flex align-items-end">
            <el-col :lg="8" :md="24">
              <label for="">Начало</label>
              <el-time-select
                size="small"
                placeholder="Начало"
                v-model="cabinetToAdd.timeFrom"
                :picker-options="timeOptions"
                @change="handleParameterChange"
                :disabled="modalCheckbox"
              >
              </el-time-select>
            </el-col>
            <el-col :lg="8" :md="24">
              <label for="">Окончание</label>
              <el-time-select
                size="small"
                placeholder="Окончание"
                v-model="cabinetToAdd.timeTo"
                :picker-options="timeOptions"
                @change="handleParameterChange"
                :disabled="modalCheckbox"
              >
              </el-time-select>
            </el-col>
            <el-col :lg="8" :md="24" class="mt">
              <el-checkbox v-model="modalCheckbox">Заполнить</el-checkbox>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row :gutter="24" class="mt">
        <el-col :span="12">
          <el-button
            class="w-100"
            size="small"
            type="primary"
            :loading="loadingModal"
            @click="handleSaveEditTimeTable"
            >Сохранить</el-button
          >
        </el-col>
        <el-col :span="12">
          <el-button class="w-100" size="small" @click="modalAddCabinet = false"
            >Отменить</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<style>
.notAvailable {
  background: #f8f8f9;
}

.available {
  background: #ffffff;
  cursor: pointer;
}

.busy {
  background: #e8f9ef;
  cursor: pointer;
}

.absence {
  background: #fdece9;
}
</style>

<script>
import CabinetWorkloadManager from '@/services/cabinetWorkloadManager.js';
import DoctorManager from '@/services/doctorManager.js';
import FilialManager from '@/services/filialManager';
import { getTimeOffset } from '@/services/commonService';
// import scheduleService from '@/services/scheduleService';
import axios from '@/api/axios';
function sort(order) {
  if (order === 'ascending') {
    return (a, b) => {
      if (a < b) {
        return -1;
      }
      if (b < a) {
        return 1;
      }
      return 0;
    };
  }

  return (a, b) => {
    if (a > b) {
      return -1;
    }
    if (b > a) {
      return 1;
    }
    return 0;
  };
}
export default {
  data() {
    return {
      pickerOptions: {
        firstDayOfWeek: 1,
        disabledDate() {
          return false;
        },
        shortcuts: [
          {
            text: 'Сегодня',
            onClick(picker) {
              picker.$emit(
                'pick',
                new Date(
                  new Date().getFullYear(),
                  new Date().getMonth(),
                  new Date().getDate()
                )
              );
            },
          },
          {
            text: 'Вчера',
            onClick(picker) {
              const date = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate()
              );
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            },
          },
          {
            text: 'Завтра',
            onClick(picker) {
              const date = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate()
              );
              date.setTime(date.getTime() + 3600 * 1000 * 24);
              picker.$emit('pick', date);
            },
          },
        ],
      },
      filter: {
        doctor: '',
        date: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        ),
      },
      doctorData: [],
      headerData: [],
      timeColumns: [],
      doctorOptions: [],
      modalAddCabinet: false,
      modalTitle: '',
      cabinetToAdd: {
        idSchedule: '',
        idDoctor: '',
        doctorName: '',
        idCabinet: '',
        date: new Date(),
        timeFrom: '',
        timeTo: '',
      },
      cabinetOptionsModal: [],
      timeOptions: {
        step: '00:10',
      },
      modalCheckbox: true,
      showErrorModal: false,
      errorModal: '',
      loading: false,
      loadingModal: false,
    };
  },
  async mounted() {
    this.loading = true;
    await this.loadData();
    this.doctorOptions = await DoctorManager.getDoctorsForSelect(true);
    this.cabinetOptionsModal = await FilialManager.getAllCabinets();
    await this.$patientHub.start();
    this.$patientHub.client.on('ScheduleChange', () => {
      this.loadData();
    });
    this.loading = false;
  },
  methods: {
    //загрузка расписания
    async loadData() {
      this.$store.dispatch('getScheduleList', {}).then(async () => {
        let result = await CabinetWorkloadManager.getCabinetWorkload(
          this.filter
        );
        this.timeColumns = result.timeColumns;
        this.headerData = result.headerData;
        this.doctorData = result.doctorData;
      });
    },
    setLabelForCabinetSelect(item) {
      return `${item.name} ${
        item.description == '' ? '' : `(${item.description})`
      }`;
    },
    sortChange(value) {
      var s = sort(value.order);
      this.doctorData = this.doctorData.sort((a, b) =>
        s(a[value.prop], b[value.prop])
      );
    },
    spanMethod({ row, column }) {
      if (row['s' + column.property] == undefined) {
        return {
          rowspan: 1,
          colspan: 1,
        };
      } else {
        if (row['s' + column.property] > 0) {
          return {
            rowspan: 1,
            colspan: row['s' + column.property],
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    tableCellClassName({ row, column }) {
      if (column.property != 'doctor') {
        if (row['s' + column.property]) {
          if (row[column.property] == 0) {
            return 'busy';
          } else {
            return 'available';
          }
        } else {
          return 'notAvailable';
        }
      }
    },
    async handleFilter() {
      this.test = await CabinetWorkloadManager.getCabinetWorkload(this.filter);
      this.timeColumns = this.test.timeColumns;
      this.headerData = this.test.headerData;
      this.doctorData = this.test.doctorData;
    },
    async handleDelete(id) {
      this.loadingModal = true;
      axios
        .delete('schedulecabinet', { params: { id: id } })
        .then(async () => {
          await this.$patientHub.client.invoke('BroadcastScheduleChange');
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: `Невозможно удалить загрузку кабинета!`,
          });
        });
      this.handleFilter();
      this.loadingModal = false;
    },
    handleCellClick(row, column) {
      if (!row['m' + column.property]) {
        return;
      }
      this.showErrorModal = false;
      this.cabinetToAdd = row['m' + column.property];
      this.timeOptions.start = this.cabinetToAdd.timeFrom;
      this.timeOptions.end = this.cabinetToAdd.timeTo;
      this.modalTitle = 'Добавить кабинет для ' + this.cabinetToAdd.doctorName;
      (this.modalCheckbox = true), (this.modalAddCabinet = true);
    },
    async handleParameterChange() {
      let parameters = {
        dateTimeFrom: new Date(this.cabinetToAdd.date).setHours(
          this.cabinetToAdd.timeFrom.substring(0, 2),
          this.cabinetToAdd.timeFrom.substring(3, 5)
        ),
        dateTimeTo: new Date(this.cabinetToAdd.date).setHours(
          this.cabinetToAdd.timeTo.substring(0, 2),
          this.cabinetToAdd.timeTo.substring(3, 5)
        ),
        idCabinet: this.cabinetToAdd.idCabinet,
        idSchedule: this.cabinetToAdd.idSchedule,
      };
      if (Number(parameters.dateTimeFrom) >= Number(parameters.dateTimeTo)) {
        this.showErrorModal = true;
        this.errorModal = `Дата начала не может быть больше даты окончания!`;
        return;
      }
      let result = await CabinetWorkloadManager.verifyCabinetWorkload(
        parameters
      );
      if (result) {
        this.showErrorModal = true;
        this.errorModal = result;
      } else {
        this.showErrorModal = false;
      }
    },
    async handleSaveEditTimeTable() {
      this.loadingModal = true;
      await this.handleParameterChange();
      if (this.showErrorModal != true) {
        let cabinetScheduleToAdd = {
          id: null,
          idSchedule: this.cabinetToAdd.idSchedule,
          idCabinet: this.cabinetToAdd.idCabinet,
          dateTimeFrom: new Date(
            new Date(this.cabinetToAdd.date).setHours(
              this.cabinetToAdd.timeFrom.substring(0, 2),
              this.cabinetToAdd.timeFrom.substring(3, 5)
            )
          ),
          dateTimeTo: new Date(
            new Date(this.cabinetToAdd.date).setHours(
              this.cabinetToAdd.timeTo.substring(0, 2),
              this.cabinetToAdd.timeTo.substring(3, 5)
            )
          ),
          idFilial: null,
          cabinetName: null,
          clientOffset: getTimeOffset(),
        };
        axios
          .post('schedulecabinet', cabinetScheduleToAdd)
          .then(async () => {
            await this.$patientHub.client.invoke('BroadcastScheduleChange');
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: `Невозможно добавить загрузку кабинета!`,
            });
          });
        this.modalAddCabinet = false;
        this.loadingModal = false;
        this.handleFilter();
      } else {
        this.loadingModal = false;
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
