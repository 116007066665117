//import Axios from "axios";
import AuthManager from '@/services/authManager';
import { getTimeOffset } from '@/services/commonService';
import patientApi from '@/api/patient';
import patientHub from '@/hubs/patientHub';
const patientsModule = {
  state: () => ({
    patient: {
      birthDate: null,
      cardNumber: null,
      clientOffset: null,
      dateTimeStart: null,
      email: null,
      firstName: null,
      gender: 1,
      location: 1,
      id: null,
      lastDoctorVisitDate: null,
      lastName: null,
      lastVisitDate: null,
      note: null,
      passportDateFrom: null,
      passportIssuer: null,
      passportIssuerCode: null,
      passportNumber: null,
      passportSeries: null,
      patientTags: [],
      patronymic: null,
      phone: null,
      registerAddress: null,
      residenceAddress: null,
      snils: null,
    },
    defaultPatient: {
      birthDate: null,
      cardNumber: null,
      clientOffset: null,
      dateTimeStart: null,
      email: null,
      firstName: null,
      gender: 1,
      location: 1,
      id: null,
      lastDoctorVisitDate: null,
      lastName: null,
      lastVisitDate: null,
      note: null,
      passportDateFrom: null,
      passportIssuer: null,
      passportIssuerCode: null,
      passportNumber: null,
      passportSeries: null,
      patientTags: [],
      patronymic: null,
      phone: null,
      registerAddress: null,
      residenceAddress: null,
      snils: null,
    },
    addedPatient: null,
    patients: [],
    tagList: [],
    patientFilter: {
      timeOffset: getTimeOffset(),
      pagenumber: 1,
      pagesize: 10,
      filterstring: null,
      patientLastName: null,
      patientFirstName: null,
      patientPatronymic: null,
      patientGender: null,
      patientBirthDateFrom: null,
      patientBirthDateTo: null,
      patientPhone: null,
      counter: 0,
    },
    doctorPatientsFilter: {
      timeOffset: getTimeOffset(),
      pagenumber: 1,
      pagesize: 10,
      doctorId: AuthManager.getUser() ? AuthManager.getUser().idDoctor : 0,
    },
  }),
  getters: {
    patient: (state) => {
      return state.patient;
    },
    defaultPatient: (state) => {
      return state.defaultPatient;
    },
    addedPatient: (state) => {
      return state.addedPatient;
    },
    patients: (state) => {
      return state.patients;
    },
    patientFilter: (state) => {
      return state.patientFilter;
    },
    doctorPatientsFilter: (state) => {
      return state.doctorPatientsFilter;
    },
    tagList: (state) => {
      let tagListChanged = state.tagList.map((x) => {
        return {
          idTag: x.id,
          value: x.value,
        };
      });
      return tagListChanged;
    },
  },
  mutations: {
    SET_PATIENT: (state, payload) => {
      state.patient = payload;
    },
    SET_ADDED_PATIENT: (state, payload) => {
      state.addedPatient = payload;
    },
    SET_PATIENTS: (state, payload) => {
      state.patients = payload;
    },
    SET_PATIENTFILTER: (state, payload) => {
      state.patientFilter = payload;
    },
    SET_DOCTORPATIENTSFILTER: (state, payload) => {
      state.doctorPatientsFilter = payload;
    },
    SET_TAG_LIST: (state, payload) => {
      state.tagList = payload;
    },
  },
  actions: {
    createPatient: async (context, patient) => {
      return await patientApi.createPatient(patient).then((response) => {
        patientHub.client.invoke('BroadcastPatientChange');
        context.commit('SET_ADDED_PATIENT', response.data);
      });
    },
    getPatient: async (context, params) => {
      const patient = await patientApi.getPatients(params);
      context.commit('SET_PATIENT', patient.data.value[0]);
    },
    getPatients: async (context, params) => {
      const patient = await patientApi.getPatients(params);
      context.commit('SET_PATIENTS', patient.data.value);
    },
    updatePatient: async (context, patient) => {
      return await patientApi.updatePatient(patient).then(() => {
        patientHub.client.invoke('BroadcastPatientChange');
      });
    },
    UPDATE_PATIENTS: async (context, payload) => {
      context.commit('SET_PATIENTS', payload);
    },
    UPDATE_PATIENTFILTER: async (context, payload) => {
      context.commit('SET_PATIENTFILTER', payload);
    },
    getTagList: async (context) => {
      const tagList = await patientApi.getTagList();
      context.commit('SET_TAG_LIST', tagList.data.value);
    },
    getPassportIssuerSuggestion: async (context, search) => {
      const suggestionList = await patientApi.getPassportIssuerSuggestion(
        search
      );
      return suggestionList.data.value;
    },
  },
};

export default patientsModule;
