<template>
  <div>
    <el-container class="h-100vh">
      <el-header height="72px"><HeaderComponent></HeaderComponent></el-header>
      <el-container class="h-custom">
        <el-aside width="unset">
          <AsideComponent></AsideComponent>
        </el-aside>
        <el-main class=""
          ><router-view :key="$route.fullPath"></router-view
        ></el-main>
      </el-container>
    </el-container>
    <WelcomeComponent></WelcomeComponent>
  </div>
</template>
<script>
  import AsideComponent from "@/components/AsideComponent.vue";
  import HeaderComponent from "@/components/HeaderComponent.vue";
  import WelcomeComponent from "@/components/WelcomeComponent.vue";

  export default {
    components: {
      AsideComponent,
      HeaderComponent,
      WelcomeComponent,
    },
  };
</script>
