<template>
  <div class="page-wrap education">
    <div class="education-aside hidden-sm-and-up">
      <el-button size="small" type="plain" @click="drawer = true">
        Оглавление
        <i class="el-icon-books"></i>
      </el-button>
      <el-drawer
        :visible.sync="drawer"
        :with-header="false"
        :direction="direction"
        size="70%"
      >
        <EducationAsideComponent></EducationAsideComponent>
      </el-drawer>
    </div>
    <div class="education-wrap">
      <div class="education-aside hidden-xs-only">
        <h4>Обучение</h4>
        <EducationAsideComponent></EducationAsideComponent>
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
import EducationAsideComponent from '@/components/EducationAsideComponent.vue';
export default {
  components: {
    EducationAsideComponent,
  },
  data() {
    return {
      drawer: false,
      direction: 'ltr',
    };
  },
};
</script>

<style lang="scss" scoped></style>
