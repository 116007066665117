<template>
  <div class="aside h-100">
    <div class="aside-collapse">
      <el-button
        @click="toggleSidebar"
        v-show="!isCollapse"
        type="text"
        size="small"
        class="aside-collapse-btn"
        icon="el-icon-angle-left-b"
      >
        Скрыть меню
      </el-button>
      <el-button
        @click="toggleSidebar"
        v-show="isCollapse"
        type="primary"
        size="mini"
        class="aside-collapse-btn"
        icon="el-icon-angle-right-b"
      >
      </el-button>
      <span class="divider"></span>
    </div>
    <el-menu
      :router="true"
      class="el-menu-list"
      :collapse="isCollapse"
      :default-active="$router.currentRoute.path"
    >
      <el-menu-item index="/dashboard" v-if="worktableShow">
        <i class="el-icon-web-grid-alt"></i>
        <span slot="title">Рабочий стол</span>
      </el-menu-item>
      <el-menu-item index="/patients" v-if="patientsShow">
        <i class="el-icon-user"></i>
        <span slot="title">Пациенты</span>
      </el-menu-item>
      <el-menu-item index="/organizations" v-if="organizationsShow">
        <i class="el-icon-bag-alt"></i>
        <span slot="title">Организации</span>
      </el-menu-item>
      <el-menu-item index="/schedule" v-if="scheduleShow">
        <i class="el-icon-calender"></i>
        <span slot="title">Расписание</span>
      </el-menu-item>
      <el-menu-item index="/invoices" v-if="invoicesShow">
        <i class="el-icon-clipboard-alt"></i>
        <span slot="title">Счета</span>
      </el-menu-item>
      <el-menu-item index="/analytics" v-if="true">
        <i class="el-icon-chart-pie-alt"></i>
        <span slot="title">Аналитика</span>
      </el-menu-item>
      <el-menu-item index="/settings" v-if="settingsShow">
        <i class="el-icon-setting"></i>
        <span slot="title">Настройки</span>
      </el-menu-item>
      <el-menu-item index="/education">
        <i class="el-icon-graduation-cap"></i>
        <span slot="title">Обучение</span>
      </el-menu-item>
    </el-menu>
    <div class="aside-help" v-show="!isCollapse">
      <span>Нужна помощь?</span
      ><el-button type="text" @click="handleOpenModalAddMessageSupport"
        >Написать в поддержку</el-button
      >
      <a class="aside-link" target="_blank" href="https://documeds.ru"
        >Documeds ®</a
      >
    </div>
    <!-- модальное окно обращения в тех поддержку-->
    <el-dialog
      class="modal-add-request"
      title="Обращение в техническую поддержку"
      :visible.sync="modalAddMessageSupport"
      width="100%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleCloseModalAddMessageSupport"
    >
      <el-row :gutter="24">
        <el-col :span="24" class="mt">
          <label for="" class="settings-modal-label"
            >Тема обращения <span class="danger">*</span></label
          >
          <el-input
            size="small"
            placeholder="Введите тему обращения"
            v-model="supportRequest.problemSubject"
          >
          </el-input>
        </el-col>
        <el-col :span="24" class="mt">
          <label for="" class="settings-modal-label"
            >Описание проблемы <span class="danger">*</span></label
          >
          <el-input
            size="small"
            type="textarea"
            resize="none"
            :autosize="{ minRows: 4, maxRows: 8 }"
            :rows="4"
            placeholder="Введите описание проблемы"
            v-model="supportRequest.problemDescription"
          >
          </el-input>
        </el-col>
        <el-col :span="24" class="mt">
          <label for="" class="settings-modal-label"
            >Вы можете добавить фалй в формате JPEG или PNG с размером не более
            5 мб</label
          >
          <el-upload
            class="upload-demo"
            :limit="3"
            :on-exceed="handleExceed"
            action=""
            :on-change="onUploadChange"
            :auto-upload="false"
            :on-remove="handleRemove"
            :file-list="fileList"
          >
            <el-button size="small" type="primary" plain>Загрузить</el-button>
            <!-- <div slot="tip" class="el-upload__tip">
              Вы можете добавить фалй в формате jpg или png с размером не более
              500кб
            </div> -->
          </el-upload>
        </el-col>
        <el-col :span="24" class="mt d-flex">
          <el-button
            size="small"
            type="primary"
            :loading="loadingBtn"
            @click="handleSendRequest"
            >Отправить</el-button
          >
          <el-button size="small" @click="handleCloseModalAddMessageSupport"
            >Отменить</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<style></style>

<script>
import AuthManager from '../services/authManager';
import SupportRequestManager from '@/services/supportRequestManager';
import { getItem, setItem } from '@/services/commonService';

export default {
  data() {
    return {
      loadingBtn: false,
      modalAddMessageSupport: false,
      fileList: [],
      supportRequest: {
        problemSubject: '',
        problemDescription: '',
        problemFiles: [],
      },
      activeLink: null,
      isCollapse: false,
      worktableShow: false,
      patientsShow: false,
      organizationsShow: false,
      scheduleShow: false,
      invoicesShow: false,
      settingsShow: false,
    };
  },
  async created() {
    this.isCollapse = getItem('collapseSidebar');
    const currentUser = AuthManager.getUser();
    this.worktableShow = currentUser.roles.find((x) => x == 'view_dashboard');
    this.patientsShow = currentUser.roles.find((x) => x == 'view_patient');
    this.organizationsShow = currentUser.roles.find(
      (x) => x == 'view_organization'
    );
    this.scheduleShow = currentUser.roles.find((x) => x == 'view_schedule');
    this.invoicesShow = currentUser.roles.find((x) => x == 'view_invoice');
    this.settingsShow = currentUser.roles.find((x) => x == 'view_directory');
  },
  methods: {
    toggleSidebar() {
      this.isCollapse = !this.isCollapse;
      setItem('collapseSidebar', this.isCollapse);
    },
    async handleRemove(file) {
      this.supportRequest.problemFiles.splice(file);
    },
    beforeRemove(file) {
      return this.$confirm(`Cancel the transfert of ${file.name} ?`);
    },
    async handleCloseModalAddMessageSupport() {
      this.$confirm(
        'Вы действительно хотите закрыть это окно?',
        'Подтверждение действия',
        {
          confirmButtonText: 'Подтвердить',
          cancelButtonText: 'Отменить',
          type: 'warning',
        }
      ).then(async () => {
        this.$message({
          type: 'info',
          message: 'Действие отменено',
        });
        this.modalAddMessageSupport = false;
      });
    },
    handleOpenModalAddMessageSupport() {
      (this.supportRequest = {
        problemSubject: '',
        problemDescription: '',
        problemFiles: [],
      }),
        (this.fileList = []);
      this.modalAddMessageSupport = true;
    },
    onUploadChange(file) {
      const isIMAGE =
        file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
      const isLt1M = file.size / 5 / 1024 / 1024 < 1;

      if (!isIMAGE) {
        this.$message.error(
          'Возможна загрузка файлов только в форматах jpg/png!'
        );
        return false;
      }
      if (!isLt1M) {
        this.$message.error('Возможна загрузка файла не более 5 MB!');
        return false;
      }
      var reader = new FileReader();
      reader.readAsDataURL(file.raw);

      reader.onload = function () {
        file.raw.problemFile = reader.result;
      };
      this.fileList.push(file);
    },
    handleExceed() {
      this.$message.warning(`Невозможно приложить более 3-х файлов!`);
    },
    async handleSendRequest() {
      this.loadingBtn = true;
      if (
        this.supportRequest.problemSubject != '' &&
        this.supportRequest.problemDescription
      ) {
        this.supportRequest.problemFiles = this.fileList.map((x) => {
          return { problemFileName: x.name, problemFile: x.raw.problemFile };
        });
        let result = await SupportRequestManager.sendSupportRequest(
          this.supportRequest
        );
        if (result.error) {
          this.$message.warning(
            `Ваше обращение не было отправлено. Пожалуйста, попробуйте позже.`
          );
        } else {
          this.$message.success(
            `Ваше обращение отправлено. В ближайщее время с вами свяжется сотрудник технической поддержки`
          );
          this.modalAddMessageSupport = false;
          this.loadingBtn = false;
        }
      } else {
        this.$message.warning('Заполните обязательные поля!');
        this.loadingBtn = false;
      }
    },
  },
};
</script>
