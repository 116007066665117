import axios from '@/api/axios';

const getPassportIssuer = async (params) => {
  let response = await axios.get('passportissuer', { params: params });
  return response.data.value;
};

export default {
  getPassportIssuer,
};
