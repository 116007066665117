<template>
  <div class="education-main">
    <el-row :gutter="24">
      <el-col :lg="18" class="d-flex flex-between flex-center mb-16 flex-wrap">
        <h4 class="mt-16">С чего начать использование Documeds?</h4>
        <el-button class="mt-16" size="small" @click="openModalVideo" type="primary"
          >Смотреть видео<i class="el-icon-play"></i
        ></el-button>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :lg="18">
        <p>Добро пожаловать в информационную медицинскую систему Documeds!</p>
        <p>
          В данном разделе вы можете изучить как пользоваться основными
          функциями системы и сделать свою работу еще удобнее.
        </p>
        <p>
          В верхнем меню приложения расположен блок с информацией о
          пользователе: его ФИО и должность, занимаемая в клинике, а также
          пиктограмма, при нажатию на которую, открывается дополнительное меню
          со следующими разделами:
        </p>
        <el-divider></el-divider>
      </el-col>
      <el-col :lg="12" :xs="24">
        <ul>
          <li>
            <b>Мой профиль</b> - раздел, посвященный пользователю системы. Здесь
            вы можете посмотреть ваши персональные данные, своих пациентов, а
            также изменить пароль на удобный вам. Рекомендованная частота смена
            пароля один раз в три месяца. Подробнее о смене пароля для входа в
            систему можно прочитать в специализированном разделе Мой
            профиль/Смена пароля
          </li>
          <li>
            <b>Справочники</b> - быстрый доступ к справочникам организации
            (Справочник МКБ-10, Справочник медицинских услуг и т. д.)
          </li>
          <li>
            <b>Обучение</b> - раздел, где можно ознакомиться с руководством
            пользователя системы
          </li>
          <li><b>Выйти</b> - кнопка выхода из системы</li>
        </ul>
      </el-col>
      <el-col :lg="6" :xs="24">
        <el-image
          fit="cover"
          :src="src.url1"
          :preview-src-list="srcList"></el-image>
      </el-col>
      <el-col :lg="18">
        <el-divider></el-divider>
        <p>
          В левой части основного окна приложения расположено боковое меню с
          главными разделами системы.
        </p>
        <p>
          При нажатии на кнопку “Скрыть меню“, боковое меню сворачивается до
          размера иконок разделов, расширяя общее рабочее поле.
        </p>
      </el-col>
      <el-col :lg="18">
        <el-image class="mt-16" :src="src.url2" :preview-src-list="srcList">
        </el-image>
      </el-col>
      <el-col :lg="18">
        <el-divider></el-divider>
        <p>
          Навигация по разделам осуществляется при помощи нажатия левой кнопкой
          мыши по нужному разделу в боковом меню, при это активный пункт меню
          подсвечивается светло-синим цветом.
        </p>
        <p>
          В настоящее время доступны разделы “Рабочий стол“, “Пациенты“,
          “Организации“, “Счета“, “Настройки“, “Аналитика“ и “Помощь“.
          Доступность разделов зависит от ролей пользователей, присвоенных
          администратором системы в вашей организации и тарифного плана.
        </p>
      </el-col>
    </el-row>
    <!-- модальное окно просмотра видео-урока -->
    <el-dialog
      title="Видео-урок"
      class="modal-play-video"
      :visible.sync="modalVideo"
      width="100%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true">
      <div v-if="!idVideo">
        <h6>К сожалению видео-урок не доступен</h6>
        <p>
          В скором времени мы добавим данный видео-урок по обучению работе в
          Documeds
        </p>
      </div>
      <div v-if="idVideo">
        <VideoPlayerComponent :idVideo="idVideo"></VideoPlayerComponent>
      </div>
      <el-button
        class="education-modal-btn"
        type="primary"
        @click="openModalVideo"
        >Продолжить обучение в текстовом формате</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
  import VideoPlayerComponent from "@/components/VideoPlayerComponent.vue";
  export default {
    components: { VideoPlayerComponent },
    data() {
      return {
        modalVideo: false,
        idVideo: "tKcU-uw0FtE",
        src: {
          url1: require("@/assets/img/education/start-page-1.png"),
          url2: require("@/assets/img/education/start-page-3.png"),
        },
        srcList: [
          require("@/assets/img/education/start-page-1.png"),
          require("@/assets/img/education/start-page-3.png"),
        ],
      };
    },
    methods: {
      openModalVideo() {
        this.modalVideo = !this.modalVideo;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
