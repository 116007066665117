<template>
  <div class="h-100 w-100 d-flex">
    <div v-if="!patient.id" class="patient-info-block">
      <div class="patient-info-block_header">
        <span class="patient-info-block_title">Пациент не найден</span>
        <span class="patient-info-block_label"
          ><i class="el-icon-info-circle"></i> Предварительная запись</span
        >
      </div>
      <p class="patient-info-block_text">
        Нажмите добавить, чтобы оформить нового пациента и добавить его в базу
        клиники. Предварительно заполните все обязательные поля:
        <b>Фамилию пациента, его имя и телефон.</b>
      </p>
      <el-button
        class="patient-info-block-btn"
        type="primary"
        size="small"
        @click="createPatientAsync"
        >Добавить <i class="el-icon-plus"></i
      ></el-button>
    </div>
    <div v-else class="patient-info-block">
      <div class="patient-info-block_content">
        <el-row class="w-100" :gutter="24">
          <el-col :lg="12" :md="12" :sm="24">
            <span class="patient-info-block_title">
              <router-link :to="{ path: '/patient/' + patient.id }"
                >{{ patientFullName }}, {{ patientAge }}</router-link
              >
            </span>

            <p><b>Телефон:</b> {{ patientPhone }}</p>
            <p><b>Email:</b> {{ patientEmail }}</p>
            <p><b>Адрес:</b> {{ patientAddress }}</p>
            <div class="patient-info-block_tags">
              <el-tag
                v-for="tag in patient.patientTags"
                :key="tag.id"
                :value="tag.value"
                size="mini"
                >{{ tag.value }}</el-tag
              >
            </div>
          </el-col>
          <el-col :lg="12" :md="12" :sm="24">
            <span class="patient-info-block_title">Данные о посещениях</span>
            <p><b>Мед. карта:</b> № {{ patientCardNumber }}</p>
            <!-- <p><b>Визитов:</b> 23</p> -->
            <p><b>Последний визит:</b> {{ patientLasVisit }}</p>
            <el-checkbox
              class="patient-info-block_checkbox"
              v-model="patient.physicalPatientCardExists"
              @change="updatePatientAsync"
              >Наличие карты в регистратуре</el-checkbox
            >
          </el-col>
        </el-row>
      </div>
      <el-row class="d-flex align-items-center" :gutter="24">
        <el-col :md="12"> </el-col>
        <el-col :md="12"> </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getAgeString, getAge, getDateString } from '@/services/commonService';
import { getGender } from 'lvovich';

export default {
  name: 'PatientInfoBlockComponent',
  props: {
    selectedPatient: {
      type: Object,
    },
  },
  computed: {
    patient: {
      get() {
        return this.selectedPatient;
      },
      set() {
        this.$emit('update:selectedPatient');
      },
    },
    patientFullName() {
      if (this.selectedPatient.id) {
        return `${this.selectedPatient.lastName} ${
          this.selectedPatient.firstName
        } ${
          this.selectedPatient.patronymic ? this.selectedPatient.patronymic : ''
        }`;
      } else {
        return '';
      }
    },
    patientAge() {
      if (this.selectedPatient.id) {
        return this.selectedPatient.birthDate
          ? `${getAgeString(getAge(this.selectedPatient.birthDate))}`
          : '';
      } else return '';
    },
    patientPhone() {
      if (this.selectedPatient.phone) {
        return this.selectedPatient.phone;
      } else return 'Не указан';
    },
    patientEmail() {
      if (this.selectedPatient.email) {
        return this.selectedPatient.email;
      } else return 'Не указан';
    },
    patientAddress() {
      if (this.selectedPatient.residenceAddress) {
        return this.selectedPatient.residenceAddress;
      } else return 'Не указан';
    },
    patientCardNumber() {
      if (this.selectedPatient.cardNumber) {
        return this.selectedPatient.cardNumber;
      } else return 'Не указан';
    },
    patientLasVisit() {
      if (this.selectedPatient.lastVisitDate) {
        return getDateString(this.selectedPatient.lastVisitDate);
      } else return 'Не указан';
    },
  },
  methods: {
    createPatientAsync() {
      if (!this.selectedPatient.id) {
        if (
          this.selectedPatient.lastName &&
          this.selectedPatient.firstName &&
          this.selectedPatient.phone
        ) {
          let patientGender = getGender({
            last: this.selectedPatient.lastName,
            first: this.selectedPatient.firstName,
            middle: this.selectedPatient.patronymic,
          });
          let patient = {
            ...this.selectedPatient,
            patientTags: [],
            gender: patientGender === 'female' ? 0 : 1,
          };
          console.log(patient);
          this.$store.dispatch('createPatient', patient).then((response) => {
            this.$message({
              type: 'success',
              message: 'Пациент успешно добавлен!',
            });
            this.getCreatedPatientAsync(response);
            // this.$emit('update:selectedPatient', patient);
          });
        } else {
          this.$message({
            type: 'error',
            message: 'Корректно заполните Фамилию, Имя и Телефон пациента!',
          });
        }
      }
    },
    updatePatientAsync() {
      this.$store
        .dispatch('updatePatient', this.patient)
        .then(() => {
          this.$message({
            type: 'success',
            message: 'Данные пациента успешно изменены',
          });
          this.patient = this.$store.getters.patients[0];
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: 'Данные пациента не изменены!',
          });
        });
    },
    getCreatedPatientAsync(id) {
      this.$store
        .dispatch('getPatients', {
          patientId: id,
          pagesize: 10,
          pagenumber: 1,
        })
        .then(() => {
          let createdPatient = this.$store.getters.patients[0];
          this.$emit('update:selectedPatient', createdPatient);
        });
    },
  },
};
</script>
