import axios from '@/api/axios';

const getScheduleReportInExcel = async (params) => {
  console.log(params);
  let response = await axios({
    method: 'GET',
    url: 'export/schedule/excel',
    params: params,
    responseType: 'arraybuffer',
  });
  return response.data;
};

export default {
  getScheduleReportInExcel,
};
