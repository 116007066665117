<template>
  <div class="settings-page organization" v-loading="loading">
    <el-row :gutter="24">
      <el-col :xl="14" :lg="14" :md="12" :sm="24">
        <h6>Моя организация</h6>
      </el-col>
      <el-col :xl="10" :lg="10" :md="12" :sm="24">
        <el-row :gutter="24">
          <el-col :span="24" :sm="6">
            <label style="width: 84px" for="">Часы работы</label>
          </el-col>
          <el-col :span="12" :sm="9">
            <el-time-select
              size="small"
              placeholder="Начало"
              v-model="officeHourStart"
              :picker-options="timeOptions">
            </el-time-select>
          </el-col>
          <el-col :span="12" :sm="9">
            <el-time-select
              size="small"
              placeholder="Окончание"
              v-model="officeHourEnd"
              :picker-options="timeOptions">
            </el-time-select>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt-16">
      <el-col :xl="16" :lg="16" :md="24">
        <label сlass="settings-label" for=""
          >Полное наименование <span class="danger">*</span></label
        >
        <el-input
          size="small"
          placeholder="Полное наименование"
          v-model="clientOrganization.fullName">
        </el-input>
      </el-col>
      <el-col :xl="8" :lg="8" :md="24">
        <label сlass="settings-label" for=""
          >Краткое наименование <span class="danger">*</span></label
        >
        <el-input
          size="small"
          placeholder="Краткое наименование"
          v-model="clientOrganization.shortName">
        </el-input>
      </el-col>
    </el-row>
    <h6>Контакты</h6>
    <el-row :gutter="24">
      <el-col :xl="12" :lg="12" :md="24">
        <ul class="settings-contacts-list">
          <li
            class="settings-contacts-list-item"
            v-for="item in clientOrganization.phones"
            :key="item.id">
            <el-row :gutter="24">
              <el-col :span="12">
                <label
                  сlass="settings-label"
                  for=""
                  v-if="clientOrganization.phones.indexOf(item) == 0"
                  >Телефон</label
                >
                <el-input
                  size="small"
                  placeholder="Телефон"
                  v-model="item.phone"
                  v-maska
                  data-maska="+7##########">
                </el-input>
              </el-col>
              <el-col :span="10">
                <label
                  сlass="settings-label"
                  for=""
                  v-if="clientOrganization.phones.indexOf(item) == 0"
                  >Комментарий</label
                >
                <el-input
                  size="small"
                  placeholder="Комментарий"
                  v-model="item.note">
                </el-input>
              </el-col>
              <el-col :span="2" class="d-flex flex-center">
                <el-button
                  size="small"
                  class="settings-contacts-list-btn"
                  type="text"
                  icon="el-icon-delete"
                  circle
                  @click="handleRemovePhone(item)"></el-button>
              </el-col>
            </el-row>
          </li>
        </ul>
        <el-button type="primary" plain size="small" @click="handleAddPhone">
          Добавить
          <i class="el-icon-plus el-icon-right"></i>
        </el-button>
      </el-col>
      <el-col :xl="12" :lg="12" :md="24">
        <ul class="settings-contacts-list">
          <li
            class="settings-contacts-list-item"
            v-for="item in clientOrganization.emails"
            :key="item.id">
            <el-row :gutter="24">
              <el-col :span="12">
                <label
                  сlass="settings-label"
                  for=""
                  v-if="clientOrganization.emails.indexOf(item) == 0"
                  >Email</label
                >
                <el-input size="small" placeholder="Email" v-model="item.email">
                </el-input>
              </el-col>
              <el-col :span="10">
                <label
                  сlass="settings-label"
                  for=""
                  v-if="clientOrganization.emails.indexOf(item) == 0"
                  >Комментарий</label
                >
                <el-input
                  size="small"
                  placeholder="Комментарий"
                  v-model="item.note">
                </el-input>
              </el-col>
              <el-col :span="2" class="d-flex flex-center">
                <el-button
                  size="small"
                  class="settings-contacts-list-btn"
                  type="text"
                  icon="el-icon-delete"
                  circle
                  @click="handleRemoveEmail(item)"></el-button>
              </el-col>
            </el-row>
          </li>
        </ul>
        <el-button size="small" type="primary" plain @click="handleAddEmail">
          Добавить
          <i class="el-icon-plus el-icon-right"></i>
        </el-button>
      </el-col>
    </el-row>
    <h6>Адреса</h6>
    <el-row :gutter="24">
      <el-col :xl="12" :lg="12" :md="24">
        <label сlass="settings-label" for=""
          >Юридический адрес <span class="danger">*</span></label
        >
        <DaDataAutoCompleteComponent
          placeholder="Юридический адрес"
          @input="copyToFactAddress"
          v-model="clientOrganization.jurAddress"></DaDataAutoCompleteComponent>
        <el-checkbox
          size="small"
          class="mt-12"
          v-model="matchedFactAddress"
          @change="copyToFactAddress"
          >Совпадает с фактическим адресом</el-checkbox
        >
      </el-col>
      <el-col :xl="12" :lg="12" :md="24">
        <label сlass="settings-label" for=""
          >Фактический адрес <span class="danger">*</span></label
        >
        <DaDataAutoCompleteComponent
          placeholder="Фактический адрес"
          :disabled="matchedFactAddress"
          v-model="
            clientOrganization.factAddress
          "></DaDataAutoCompleteComponent>
      </el-col>
    </el-row>
    <h6>Юридические реквизиты</h6>
    <el-row :gutter="24">
      <el-col :xl="8" :lg="8" :md="24">
        <label сlass="settings-label" for=""
          >ИНН <span class="danger">*</span></label
        >
        <el-input
          size="small"
          placeholder="ИНН"
          v-model="clientOrganization.inn">
        </el-input>
      </el-col>
      <el-col :xl="8" :lg="8" :md="24">
        <label сlass="settings-label" for=""
          >КПП <span class="danger">*</span></label
        >
        <el-input
          size="small"
          placeholder="КПП"
          v-model="clientOrganization.kpp">
        </el-input>
      </el-col>
      <el-col :xl="8" :lg="8" :md="24">
        <label сlass="settings-label" for="">ОГРН</label>
        <el-input
          size="small"
          placeholder="ОГРН"
          v-model="clientOrganization.ogrn">
        </el-input>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :xl="8" :lg="8" :md="24">
        <label сlass="settings-label" for="">ФИО директора</label>
        <el-input
          size="small"
          placeholder="ФИО директора/руководителя"
          v-model="clientOrganization.directorName">
        </el-input>
      </el-col>
      <el-col :xl="8" :lg="8" :md="24">
        <label сlass="settings-label" for=""
          >Основания действия (род. падеж)</label
        >
        <el-input
          size="small"
          placeholder="Например, устава"
          v-model="clientOrganization.reasonForActing">
        </el-input>
      </el-col>
    </el-row>
    <h6>Банковские реквизиты</h6>
    <el-row :gutter="24">
      <el-col :xl="8" :lg="8" :md="24">
        <label сlass="settings-label" for=""
          >Наименование банка <span class="danger">*</span></label
        >
        <el-input
          size="small"
          placeholder="Наименование банка"
          v-model="clientOrganization.bankName">
        </el-input>
      </el-col>
      <el-col :xl="8" :lg="8" :md="24">
        <label сlass="settings-label" for=""
          >Адрес банка <span class="danger">*</span></label
        >
        <el-input
          size="small"
          placeholder="Адрес банка"
          v-model="clientOrganization.bankAddress">
        </el-input>
      </el-col>
      <el-col :xl="8" :lg="8" :md="24">
        <label сlass="settings-label" for=""
          >БИК <span class="danger">*</span></label
        >
        <el-input
          size="small"
          placeholder="БИК банка"
          v-model="clientOrganization.bik">
        </el-input>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :xl="8" :lg="8" :md="24">
        <label сlass="settings-label" for=""
          >Корр. счет <span class="danger">*</span></label
        >
        <el-input
          size="small"
          placeholder="Корр. счет"
          v-model="clientOrganization.korrAccount">
        </el-input>
      </el-col>
      <el-col :xl="8" :lg="8" :md="24">
        <label сlass="settings-label" for=""
          >Рассчетный счет <span class="danger">*</span></label
        >
        <el-input
          size="small"
          placeholder="Рассчетный счет"
          v-model="clientOrganization.rsAccount">
        </el-input>
      </el-col>
    </el-row>
    <h6>Лицензия на медицинскую деятельность</h6>
    <el-row :gutter="24">
      <el-col :xl="4" :lg="4" :md="24">
        <label сlass="settings-label" for="">Серия</label>
        <el-input
          size="small"
          placeholder="Серия лицензии"
          v-model="clientOrganization.licenseSeries">
        </el-input>
      </el-col>
      <el-col :xl="4" :lg="4" :md="24">
        <label сlass="settings-label" for="">Номер</label>
        <el-input
          size="small"
          placeholder="Номер лицензии"
          v-model="clientOrganization.licenseNumber">
        </el-input>
      </el-col>
      <el-col :xl="8" :lg="8" :md="24">
        <label сlass="settings-label" for="">Кем выдана</label>
        <el-input
          size="small"
          placeholder="Кем выдана лицензия"
          v-model="clientOrganization.licenseIssuer">
        </el-input>
      </el-col>
      <el-col :xl="8" :lg="8" :md="24">
        <label сlass="settings-label" for="">Дата выдачи</label>
        <el-date-picker
          v-model="clientOrganization.licenseDate"
          size="small"
          format="dd.MM.yyyy"
          type="date"
          :picker-options="{ firstDayOfWeek: 1 }"
          placeholder="Дату выдачи лицензии">
        </el-date-picker>
      </el-col>
    </el-row>
    <el-button
      size="small"
      type="primary"
      class="mt mb-20"
      :loading="loadingBtn"
      @click="handleSave"
      v-if="editClientOrganizationShow">
      Применить
    </el-button>
  </div>
</template>

<script>
  import ClientOrganizationManager from "@/services/clientOrganizationManager.js";
  import DaDataAutoCompleteComponent from "@/components/DaDataAutoCompleteComponent.vue";
  import AuthManager from "@/services/authManager";

  export default {
    components: {
      DaDataAutoCompleteComponent,
    },
    data() {
      return {
        matchedFactAddress: true,
        loading: false,
        loadingBtn: false,
        clientOrganization: {},
        editClientOrganizationShow: false,
        officeHourStart: "",
        officeHourEnd: "",
        timeOptions: {
          step: "00:30",
          start: "07:00",
          end: "23:00",
        },
      };
    },
    async mounted() {
      this.loading = true;
      this.clientOrganization =
        await ClientOrganizationManager.getClientOrganization();
      this.officeHourStart = this.clientOrganization.hourOpen
        ? this.clientOrganization.hourOpen.toString().padStart(2, "0") +
          ":" +
          this.clientOrganization.minuteOpen.toString().padStart(2, "0")
        : "";
      this.officeHourEnd = this.clientOrganization.hourClose
        ? this.clientOrganization.hourClose.toString().padStart(2, "0") +
          ":" +
          this.clientOrganization.minuteClose.toString().padStart(2, "0")
        : "";
      let currentUser = AuthManager.getUser();
      this.editClientOrganizationShow = currentUser.roles.find(
        (x) => x == "edit_my_organization"
      );
      this.matchedFactAddress =
        this.clientOrganization.factAddress &&
        this.clientOrganization.jurAddress &&
        this.clientOrganization.factAddress ===
          this.clientOrganization.jurAddress;
      this.loading = false;
    },
    methods: {
      copyToFactAddress() {
        if (this.matchedFactAddress == true) {
          this.clientOrganization.factAddress =
            this.clientOrganization.jurAddress;
        }
      },
      handleAddPhone() {
        this.clientOrganization.phones.push({ id: null, phone: "", note: "" });
      },
      handleAddEmail() {
        this.clientOrganization.emails.push({ id: null, email: "", note: "" });
      },
      handleRemovePhone(item) {
        let index = this.clientOrganization.phones.indexOf(item);
        this.clientOrganization.phones.splice(index, 1);
      },
      handleRemoveEmail(item) {
        let index = this.clientOrganization.emails.indexOf(item);
        this.clientOrganization.emails.splice(index, 1);
      },
      async handleSave() {
        this.loadingBtn = true;
        this.clientOrganization.phones = this.clientOrganization.phones.filter(
          (x) => x.phone != "" && x.note != ""
        );
        this.clientOrganization.emails = this.clientOrganization.emails.filter(
          (x) => x.email != "" && x.note != ""
        );
        let orgToSave = {
          id: this.clientOrganization.id,
          fullName: this.clientOrganization.fullName,
          shortName: this.clientOrganization.shortName,
          inn: this.clientOrganization.inn,
          kpp: this.clientOrganization.kpp,
          ogrn: this.clientOrganization.ogrn,
          website: this.clientOrganization.website,
          jurAddress: this.clientOrganization.jurAddress,
          factAddress: this.clientOrganization.factAddress,
          rsAccount: this.clientOrganization.rsAccount,
          korrAccount: this.clientOrganization.korrAccount,
          bik: this.clientOrganization.bik,
          bankName: this.clientOrganization.bankName,
          bankAddress: this.clientOrganization.bankAddress,
          hourOpen:
            this.officeHourStart.length > 0
              ? this.officeHourStart.substring(0, 2)
              : null,
          minuteOpen:
            this.officeHourStart.length > 0
              ? this.officeHourStart.substring(3, 5)
              : null,
          hourClose:
            this.officeHourEnd.length > 0
              ? this.officeHourEnd.substring(0, 2)
              : null,
          minuteClose:
            this.officeHourEnd.length > 0
              ? this.officeHourEnd.substring(3, 5)
              : null,
          phones: this.clientOrganization.phones,
          emails: this.clientOrganization.emails,
          directorName: this.clientOrganization.directorName,
          licenseSeries: this.clientOrganization.licenseSeries,
          licenseNumber: this.clientOrganization.licenseNumber,
          licenseDate: this.clientOrganization.licenseDate,
          licenseIssuer: this.clientOrganization.licenseIssuer,
          reasonForActing: this.clientOrganization.reasonForActing,
          clientOffset: new Date().getTimezoneOffset() / 60,
        };

        if (
          typeof this.clientOrganization.fullName == "string" &&
          this.clientOrganization.fullName.length > 0 &&
          typeof this.clientOrganization.shortName == "string" &&
          this.clientOrganization.shortName.length > 0 &&
          typeof this.clientOrganization.inn == "string" &&
          this.clientOrganization.inn.length > 0 &&
          typeof this.clientOrganization.kpp == "string" &&
          this.clientOrganization.kpp.length > 0 &&
          typeof this.clientOrganization.jurAddress == "string" &&
          this.clientOrganization.jurAddress.length > 0 &&
          typeof this.clientOrganization.factAddress == "string" &&
          this.clientOrganization.factAddress.length > 0 &&
          typeof this.clientOrganization.korrAccount == "string" &&
          this.clientOrganization.korrAccount.length > 0 &&
          typeof this.clientOrganization.rsAccount == "string" &&
          this.clientOrganization.rsAccount.length > 0 &&
          typeof this.clientOrganization.bik == "string" &&
          this.clientOrganization.bik.length > 0 &&
          typeof this.clientOrganization.bankName == "string" &&
          this.clientOrganization.bankName.length > 0 &&
          typeof this.clientOrganization.bankAddress == "string" &&
          this.clientOrganization.bankAddress.length > 0
        ) {
          await ClientOrganizationManager.updateClientOrganization(orgToSave);
          this.loadingBtn = false;
          this.$message({
            message: "Данные вашей организации успешно сохранены.",
            type: "success",
          });
        } else {
          this.loadingBtn = false;
          this.$message({
            message: "Заполните пустые поля",
            type: "error",
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped></style>
