<template>
  <div class="page-wrap">
    <div class="page-header">
      <h4>Организации</h4>
      <el-button
        size="small"
        type="primary"
        @click="openModalAddOrganization"
        v-if="addOrganizationShow"
      >
        <span class="hidden-xs-only">Добавить организацию</span>
        <i class="el-icon-plus el-icon-right"></i>
      </el-button>
    </div>
    <div class="page-subheader">
      <el-row class="w-100" :gutter="24">
        <el-col :span="24" :sm="8">
          <el-tooltip
            effect="dark"
            placement="right-start"
            class="hidden-xs-only"
          >
            <div slot="content">
              <span>Начните вводить наименование организации</span>
            </div>
            <el-input
              size="small"
              placeholder="Найти организацию..."
              prefix-icon="el-icon-search"
              v-model="contractorOrganizationFilter.filterstring"
              @input="handleOrganizationRowSearch"
            >
            </el-input>
          </el-tooltip>
          <el-input
            size="small"
            class="hidden-sm-and-up"
            placeholder="Найти организацию..."
            prefix-icon="el-icon-search"
            v-model="contractorOrganizationFilter.filterstring"
            @change="handleOrganizationRowSearch"
          >
          </el-input>
        </el-col>
      </el-row>
    </div>
    <template>
      <el-table
        v-loading="loading"
        :data="tableData"
        @sort-change="handleSortChange"
        stripe
        style="width: 100%"
      >
        <el-table-column
          min-width="190px"
          prop="shortName"
          label="Краткое наименование"
          sortable="custom"
        ></el-table-column>
        <el-table-column prop="inn" label="ИНН" sortable></el-table-column>
        <el-table-column prop="kpp" label="КПП" sortable></el-table-column>
        <el-table-column
          min-width="190px"
          prop="jurAddress"
          label="Юр. адрес"
          sortable
        ></el-table-column>
        <el-table-column
          width="130px"
          prop="phone"
          label="Телефон"
          sortable
        ></el-table-column>
        <el-table-column
          width="180px"
          prop="email"
          label="Email"
          sortable
        ></el-table-column>
        <el-table-column width="80px" label="">
          <template slot-scope="scope">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link"
                ><i class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <el-button
                    class="dropdown-btn"
                    type="text"
                    size="small"
                    v-if="editOrganizationShow"
                    @click="openModalEditOrganization(scope.row)"
                  >
                    <i class="el-icon-edit el-icon-left"></i>
                    Редактировать</el-button
                  ></el-dropdown-item
                >
                <el-dropdown-item>
                  <el-button
                    size="small"
                    type="text"
                    class="dropdown-btn danger"
                    @click="handleDelete(scope.row)"
                    v-if="deleteOrganizationShow"
                  >
                    <i class="el-icon-delete el-icon-left"></i>
                    Удалить</el-button
                  ></el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="mt-20"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="contractorOrganizationFilter.pagenumber"
        :page-sizes="[10, 20, 30]"
        :page-size="contractorOrganizationFilter.pagesize"
        layout="sizes, prev, pager, next"
        :total="tableTotal"
      >
      </el-pagination>
    </template>
    <!-- модальное окно добавления организации -->
    <el-dialog
      class="modal-add-organization"
      :close-on-click-modal="false"
      :visible.sync="modalAddOrganization"
      width="100%"
    >
      <h5 slot="title">{{ modalTitle }}</h5>
      <el-row :gutter="24">
        <el-col :span="24" :sm="12">
          <label for=""
            >Полное наименование <span class="danger">*</span></label
          >
          <el-input
            size="small"
            placeholder="Введите полное наименование"
            v-model="organizationToSave.fullName"
            :class="{
              invalid: $v.organizationToSave.fullName.$error,
            }"
          ></el-input>
          <small
            class="helper-text invalid"
            v-if="$v.organizationToSave.fullName.$error"
            >Поле является обязательным</small
          >
        </el-col>
        <el-col :span="24" :sm="12" class="mob-mt-16">
          <label for=""
            >Краткое наименование <span class="danger">*</span></label
          >
          <el-input
            size="small"
            placeholder="Введите краткое наименование"
            v-model="organizationToSave.shortName"
            :class="{
              invalid: $v.organizationToSave.shortName.$error,
            }"
          ></el-input>
          <small
            class="helper-text invalid"
            v-if="$v.organizationToSave.shortName.$error"
            >Поле является обязательным</small
          >
        </el-col>
      </el-row>
      <el-row :gutter="24" align="center" class="mt">
        <el-col :span="24" :sm="8">
          <label for="">ИНН <span class="danger">*</span></label>
          <el-input
            size="small"
            placeholder="Введите ИНН"
            v-model="organizationToSave.inn"
            :class="{
              invalid: $v.organizationToSave.inn.$error,
            }"
          ></el-input>
          <small
            class="helper-text invalid"
            v-if="$v.organizationToSave.inn.$error"
            >Поле является обязательным</small
          >
        </el-col>
        <el-col :span="24" :sm="8" class="mob-mt-16">
          <label for="">КПП <span class="danger">*</span></label>
          <el-input
            size="small"
            placeholder="Введите КПП"
            v-model="organizationToSave.kpp"
            :class="{
              invalid: $v.organizationToSave.kpp.$error,
            }"
          ></el-input>
          <small
            class="helper-text invalid"
            v-if="$v.organizationToSave.kpp.$error"
            >Поле является обязательным</small
          >
        </el-col>
        <el-col :span="24" :sm="8" class="mob-mt-16">
          <label for="">ОГРН <span class="danger">*</span></label>
          <el-input
            size="small"
            placeholder="Введите ОРГН"
            v-model="organizationToSave.ogrn"
            :class="{
              invalid: $v.organizationToSave.ogrn.$error,
            }"
          ></el-input>
          <small
            class="helper-text invalid"
            v-if="$v.organizationToSave.ogrn.$error"
            >Поле является обязательным</small
          >
        </el-col>
      </el-row>
      <el-row :gutter="24" align="center" class="mt">
        <el-col :span="12" :sm="8">
          <label for="">Телефон <span class="danger">*</span></label>
          <el-input
            size="small"
            placeholder="Введите телефон"
            v-model="organizationToSave.phone"
            v-maska
            data-maska="+7##########"
            :class="{
              invalid: $v.organizationToSave.phone.$error,
            }"
          ></el-input>
          <small
            class="helper-text invalid"
            v-if="
              $v.organizationToSave.phone.$dirty &&
              !$v.organizationToSave.phone.required
            "
            >Поле является обязательным</small
          >
          <small
            class="helper-text invalid"
            v-else-if="
              $v.organizationToSave.phone.$dirty &&
              !$v.organizationToSave.phone.minLength
            "
          >
            Введите корректный номер телефона
          </small>
        </el-col>
        <el-col :span="12" :sm="8">
          <label for="">Email <span class="danger">*</span></label>
          <el-input
            size="small"
            placeholder="Введите email"
            v-model="organizationToSave.email"
            :class="{
              invalid: $v.organizationToSave.email.$error,
            }"
          ></el-input>
          <small
            class="helper-text invalid"
            v-if="
              $v.organizationToSave.email.$error ||
              !$v.organizationToSave.email.email
            "
            >Введите корректный Email</small
          >
        </el-col>
      </el-row>
      <el-row :gutter="24" align="center" class="mt">
        <el-col :span="24" :sm="12">
          <label for="">Юридический адрес <span class="danger">*</span></label>
          <DaDataAutoCompleteComponent
            @input="copyToFactAddress"
            placeholder="Введите юридический адрес"
            v-model="organizationToSave.jurAddress"
            :class="{
              invalid: $v.organizationToSave.jurAddress.$error,
            }"
          ></DaDataAutoCompleteComponent>
          <small
            class="helper-text invalid"
            v-if="$v.organizationToSave.jurAddress.$error"
            >Поле является обязательным</small
          >
          <el-checkbox
            class="mt-12"
            v-model="matchedFactAddress"
            @change="copyToFactAddress"
            >Совпадает с фактическим адресом</el-checkbox
          >
        </el-col>
        <el-col :span="24" :sm="12">
          <label for="">Фактический адрес</label>
          <DaDataAutoCompleteComponent
            :disabled="matchedFactAddress"
            placeholder="Введите фактический адрес"
            v-model="organizationToSave.factAddress"
          ></DaDataAutoCompleteComponent>
        </el-col>
      </el-row>

      <el-row :gutter="24" align="center" class="mt">
        <el-col :span="12" :sm="6">
          <el-button
            class="w-100"
            size="small"
            type="primary"
            @click="saveOrganization"
            :loading="loadingModal"
            >Сохранить</el-button
          >
        </el-col>
        <el-col :span="12" :sm="6">
          <el-button
            size="small"
            class="w-100"
            @click="handleCloseModalAddOrganization"
            >Отменить</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import DaDataAutoCompleteComponent from '@/components/DaDataAutoCompleteComponent.vue';
import AuthManager from '@/services/authManager';
import axios from '@/api/axios';

export default {
  components: {
    DaDataAutoCompleteComponent,
  },
  data() {
    return {
      tableData: [],
      tableTotal: 0,
      contractorOrganizationFilter: {},
      matchedFactAddress: true,
      modalAddOrganization: false,
      loading: true,
      loadingModal: false,
      modalTitle: '',
      organizationToSave: {
        id: null,
        fullName: null,
        shortName: null,
        inn: null,
        kpp: null,
        ogrn: null,
        phone: null,
        email: null,
        jurAddress: null,
        factAddress: null,
      },
      addOrganizationShow: false,
      editOrganizationShow: false,
      deleteOrganizationShow: false,
    };
  },
  async mounted() {
    this.contractorOrganizationFilter =
      this.$store.getters.contractorOrganizationFilter;
    await this.loadTableData();
    let currentUser = AuthManager.getUser();
    this.addOrganizationShow = currentUser.roles.find(
      (x) => x == 'add_organization'
    );
    this.editOrganizationShow = currentUser.roles.find(
      (x) => x == 'edit_organization'
    );
    this.deleteOrganizationShow = currentUser.roles.find(
      (x) => x == 'delete_organization'
    );
    await this.$patientHub.start();
    this.$patientHub.client.on('ContractorChange', () => {
      this.loadTableData();
    });
    this.loading = false;
  },
  validations: {
    organizationToSave: {
      fullName: { required },
      shortName: { required },
      inn: { required },
      kpp: { required },
      ogrn: { required },
      phone: { required, minLength: minLength(12) },
      email: { required, email },
      jurAddress: { required },
    },
  },
  methods: {
    //загрузка данных в таблицу по текущему фильтру
    async loadTableData() {
      axios
        .get('contractororganization', {
          params: this.contractorOrganizationFilter,
        })
        .then(async (data) => {
          this.tableData = data.data.value.reverse();
          this.tableTotal = data.data.rowCount;
          await this.$store.commit(
            'SET_CONTRACTORORGANIZATIONSFILTER',
            this.contractorOrganizationFilter
          );
          await this.$store.commit(
            'SET_CONTRACTORORGANIZATIONS',
            this.tableData
          );
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: `Невозможно загрузить список организаций!`,
          });
        });
    },

    //пагинация таблицы
    async handleSizeChange(val) {
      this.contractorOrganizationFilter.pagesize = val;
      await this.loadTableData();
    },
    async handleCurrentChange() {
      await this.loadTableData();
    },

    //сортировка таблицы
    async handleSortChange(val) {
      this.contractorOrganizationFilter.sortColumn = val.prop;
      this.contractorOrganizationFilter.sortOrder =
        val.order == 'descending' ? 1 : 0;
      await this.loadTableData();
    },

    //быстрая фильтрация
    async handleOrganizationRowSearch() {
      await this.loadTableData();
    },

    //удаление организации-контрагента
    handleDelete(row) {
      this.$confirm(
        'Вы действительно хотите удалить эту организацию?',
        'Warning',
        {
          confirmButtonText: 'Подтвердить',
          cancelButtonText: 'Отменить',
          type: 'warning',
        }
      ).then(async () => {
        axios
          .delete(`contractororganization`, { params: { id: row.id } })
          .then(async () => {
            this.$message({
              type: 'success',
              message: 'Удаление прошло успешно',
            });
            await this.$patientHub.client.invoke('BroadcastContractorChange');
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: 'Удаление отменено',
            });
          });
      });
    },

    // работа с модальным окном добавления/редактирования организации
    // открытие модального окна добавления
    openModalAddOrganization() {
      this.modalTitle = 'Добавление организации';
      this.organizationToSave = {
        id: null,
        fullName: '',
        shortName: '',
        inn: '',
        kpp: '',
        ogrn: '',
        phone: '',
        email: '',
        jurAddress: '',
        factAddress: '',
      };
      this.modalAddOrganization = !this.modalAddOrganization;
    },
    //открытие модального окна редактирования
    openModalEditOrganization(row) {
      this.modalTitle = 'Редактирование организации';
      this.organizationToSave = row;
      this.modalAddOrganization = !this.modalAddOrganization;
    },

    //обработка совпадения фактического и юридического адресов
    copyToFactAddress() {
      if (this.matchedFactAddress == true) {
        this.organizationToSave.factAddress =
          this.organizationToSave.jurAddress;
      }
    },
    //сохранение контрагента
    async saveOrganization() {
      this.loadingModal = true;
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.$message({
          type: 'error',
          message: `Заполните обязательные поля!`,
        });
        this.loadingModal = false;
        return;
      } else {
        if (this.organizationToSave.id == null) {
          axios
            .post('contractororganization', this.organizationToSave)
            .then(async () => {
              this.$message({
                type: 'success',
                message: `Добавлена новая организация \n${this.organizationToSave.fullName}`,
              });
              this.modalAddOrganization = false;
              await this.$patientHub.client.invoke('BroadcastContractorChange');
            })
            .catch(() => {
              this.$message({
                type: 'error',
                message: `Произошла ошибка! Организация не добавлена.`,
              });
            });
          this.$v.$reset();
          this.loadingModal = false;
        } else {
          axios
            .put('contractororganization', this.organizationToSave)
            .then(async () => {
              this.$message({
                type: 'success',
                message: 'Данные организации изменены',
              });
              this.$v.$reset();
              this.modalAddOrganization = false;
              this.loadingModal = false;
              await this.$patientHub.client.invoke('BroadcastContractorChange');
            })
            .catch(() => {
              this.modalAddOrganization = true;
              this.loadingModal = false;
              this.$message({
                type: 'error',
                message: `Произошла ошибка! Данные организации не изменены`,
              });
            });
        }
      }
    },

    //закрытие модального окна добавления/редактирования пациента
    handleCloseModalAddOrganization() {
      this.$confirm(
        'Вы действительно хотите закрыть это окно?',
        'Подтверждение действия',
        {
          confirmButtonText: 'Подтвердить',
          cancelButtonText: 'Отменить',
          type: 'warning',
        }
      ).then(async () => {
        this.$v.$reset();
        this.modalAddOrganization = false;
      });
    },
  },
};
</script>
