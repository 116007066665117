<template>
  <div class="settings-page d-flex flex-column directory h-100">
    <el-row :gutter="24">
      <el-col :span="24">
        <h6>Справочник медицинских услуг организации (прайс-лист)</h6>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :lg="8">
        <el-input
          size="small"
          prefix-icon="el-icon-search"
          placeholder="Найти услугу"
          v-model="search"
          @input="handleSearch"
        >
        </el-input>
      </el-col>
      <el-col class="text-right" :offset="8" :lg="8">
        <el-button
          size="mini"
          type="primary"
          @click="openModalAddEditService(null)"
          v-if="addPriceListShow"
        >
          <span class="hidden-md-and-down">Добавить</span>
          <i class="el-icon-plus el-icon-right"></i>
        </el-button>
      </el-col>
    </el-row>
    <el-table
      @sort-change="sortChange"
      :default-sort="{ prop: 'orgName', order: 'ascending' }"
      class="mt price-list-table"
      :data="priceListByPage"
      v-loading="loading"
      style="width: 100%"
      stripe
    >
      <el-table-column
        :show-overflow-tooltip="true"
        sortable="custom"
        width="300"
        prop="orgName"
        label="Наименование в прайс-листе"
      >
      </el-table-column>
      <el-table-column
        :show-overflow-tooltip="true"
        sortable="custom"
        width="270"
        prop="typeName"
        label="Наименование из справочника МЗ"
      >
      </el-table-column>
      <el-table-column
        sortable="custom"
        width="180"
        prop="positionTypeName"
        label="Специальность"
      >
      </el-table-column>
      <el-table-column
        sortable="custom"
        width="140"
        prop="categoryName"
        label="Категория"
      >
      </el-table-column>
      <el-table-column
        sortable="custom"
        width="140"
        prop="price"
        label="Стоимость, ₽"
        :formatter="formatterPrice"
      >
      </el-table-column>
      <el-table-column
        sortable="custom"
        width="160"
        prop="defaultDuration"
        label="Длительность, мин."
        :formatter="formatterDuration"
      >
      </el-table-column>
      <el-table-column
        sortable="custom"
        width="180"
        prop="dateFrom"
        label="Действует "
        :formatter="formatterDate"
      >
      </el-table-column>
      <el-table-column width="80px" label="">
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link"><i class="el-icon-more"></i> </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="editPriceListShow">
                <el-button
                  class="dropdown-btn"
                  type="text"
                  size="mini"
                  @click="openModalAddEditService(scope.row)"
                >
                  <i class="el-icon-edit el-icon-left"></i>
                  Редактировать</el-button
                ></el-dropdown-item
              >
              <el-dropdown-item v-if="!scope.row.isUsed">
                <el-button
                  class="dropdown-btn danger"
                  type="text"
                  size="mini"
                  @click="deletePriceListItemAsync(scope.row)"
                >
                  <i class="el-icon-edit el-icon-left"></i>
                  Удалить</el-button
                ></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.currentSize"
      layout="sizes, prev, pager, next"
      :total="priceList.length"
      class="mt-20"
    >
    </el-pagination>

    <!-- модальное окно добавления услуги -->
    <ModalAddEditServiceComponent
      :is-modal-add-edit-service-visible.sync="isModalAddEditServiceVisible"
      :price-list-item="priceListItem"
      :is-edit-mode="isEditMode"
      @clearAddEditModalService="clearAddEditModalService()"
    ></ModalAddEditServiceComponent>
  </div>
</template>

<script>
import AuthManager from '@/services/authManager';
import { getDateString } from '@/services/commonService';
import ModalAddEditServiceComponent from '@/components/ModalAddEditServiceComponent.vue';
import dateTimeService from '@/services/dateTimeService';
import Money from '@/services/moneyService';
function sort(order) {
  if (order === 'ascending') {
    return (a, b) => {
      if (a < b) {
        return -1;
      }
      if (b < a) {
        return 1;
      }
      return 0;
    };
  }

  return (a, b) => {
    if (a > b) {
      return -1;
    }
    if (b > a) {
      return 1;
    }
    return 0;
  };
}
export default {
  name: 'PriceListPage',
  components: {
    ModalAddEditServiceComponent,
  },
  data() {
    return {
      loading: true,
      isModalAddEditServiceVisible: false,
      priceListItem: {
        id: null,
        idPositionType: null,
        idCategory: null,
        positionTypeName: null,
        categoryName: null,
        orgName: null,
        typeName: null,
        dateFrom: new Date(),
        dateTo: null,
        price: 0,
        defaultDuration: 5,
        clientOffset: null,
        isUsed: false,
        medicalServiceCurrentOrganization: {
          id: null,
          name: null,
          idMedicalServiceType: null,
          medicalServiceType: {
            id: null,
            code: '',
            name: '',
          },
        },
      },
      isEditMode: false,
      search: null,
      addPriceListShow: false,
      editPriceListShow: false,
    };
  },
  computed: {
    priceList() {
      return this.$store.getters.priceList;
    },
    pagination() {
      return this.$store.getters.pagination;
    },
    priceListByPage() {
      return this.$store.getters.priceListByPage;
    },
  },
  mounted() {
    this.$store.dispatch('getPriceList').then(() => {
      this.loading = false;
    });
    this.$store.dispatch('getPositions');
    this.$store.dispatch('getCategories');
    let currentUser = AuthManager.getUser();
    this.addPriceListShow = currentUser.roles.find(
      (x) => x == 'add_price_list'
    );
    this.editPriceListShow = currentUser.roles.find(
      (x) => x == 'edit_price_list'
    );
  },
  methods: {
    handleSearch() {
      if (this.search) {
        let results = this.priceList.filter(
          (s) =>
            s.orgName.toUpperCase().includes(this.search.toUpperCase()) ||
            s.positionTypeName
              .toUpperCase()
              .includes(this.search.toUpperCase()) ||
            s.medicalServiceCurrentOrganization.medicalServiceType.name
              .toUpperCase()
              .includes(this.search.toUpperCase())
        );
        this.$store.commit('SET_PRICE_LIST', results);
      } else {
        this.$store.dispatch('getPriceList');
      }
    },
    sortChange(value) {
      let s = sort(value.order);
      this.priceList = this.priceList.sort((a, b) =>
        s(a[value.prop], b[value.prop])
      );
    },
    openModalAddEditService(priceListItem) {
      if (priceListItem) {
        let temp = JSON.stringify(priceListItem);
        let priceListItemForEdit = JSON.parse(temp);
        priceListItemForEdit.defaultDuration =
          dateTimeService.getDurationAsMinutes(
            priceListItemForEdit.defaultDuration
          );

        this.priceListItem = priceListItemForEdit;
        this.$store.dispatch('getMedicalServiceFromDictionary', {
          pagenumber: 1,
          pagesize: 10,
          id: priceListItem.medicalServiceCurrentOrganization
            .idMedicalServiceType,
        });
        this.isEditMode = true;
      }

      this.isModalAddEditServiceVisible = !this.isModalAddEditServiceVisible;
    },
    clearAddEditModalService() {
      this.$store.dispatch('getPriceList');
      this.priceListItem = { ...this.$store.getters.defaultPriceListItem };
      this.isEditMode = false;
    },
    formatterDate(row) {
      return `${getDateString(row.dateFrom)} - ${getDateString(row.dateTo)}`;
    },
    formatterDuration(row) {
      return dateTimeService.getDurationAsMinutes(row.defaultDuration) == 0
        ? 'Не указана'
        : dateTimeService.getDurationAsMinutes(row.defaultDuration);
    },

    formatterPrice(row) {
      return Money.getAmountWithCurrency(row.price, 'RUB');
    },
    handleSizeChange(val) {
      this.pagination.currentSize = val;
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
    },
    deletePriceListItemAsync(priceListItem) {
      this.$confirm(
        'Вы действительно хотите удалить записи из прайс-листа?',
        'Warning',
        {
          confirmButtonText: 'Подтвердить',
          cancelButtonText: 'Отменить',
          type: 'warning',
        }
      )
        .then(() => {
          this.$store
            .dispatch('deletePriceListItem', priceListItem.id)
            .then(() => {
              this.$message({
                type: 'success',
                message: 'Удаление прошло успешно',
              });
              this.$store.dispatch('getPriceList');
            })
            .catch(() => {
              this.$message({
                type: 'error',
                message: 'Ошибка! Удаление не выполнено!',
              });
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Удаление отменено',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
