<template>
  <div class="page-wrap bg-grey">
    <div class="hidden-sm-and-up">
      <div class="w-100">
        <div class="page-header">
          <h4>Настройки</h4>
          <el-button size="small" type="plain" @click="drawer = true">
            <i class="el-icon-setting"></i>
          </el-button>
        </div>
        <el-drawer
          class="settings-drawer-mobile"
          :visible.sync="drawer"
          :with-header="false"
          :direction="direction"
          size="70%"
        >
          <div class="settings-aside h-100">
            <AsideSettingsComponent></AsideSettingsComponent>
          </div>
        </el-drawer>
      </div>
    </div>
    <div class="settings-container">
      <div class="settings-aside h-100 hidden-xs-only">
        <div class="settings-header">
          <h4>Настройки</h4>
        </div>
        <AsideSettingsComponent></AsideSettingsComponent>
      </div>

      <div class="settings-content-wrap">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import AsideSettingsComponent from '@/components/AsideSettingsComponent.vue';
export default {
  components: {
    AsideSettingsComponent,
  },
  data() {
    return {
      drawer: false,
      direction: 'ltr',
    };
  },
};
</script>
