<template>
  <div class="settings-page d-flex flex-column directory" v-loading="loading">
    <div class="directory-header">
      <h6>Справочник медицинских услуг</h6>
      <el-input
        class="directory-search"
        size="small"
        prefix-icon="el-icon-search"
        placeholder="Найти услугу"
        v-model="searchMedService"
        @input="handleRowSearch">
      </el-input>
    </div>
    <el-table
    class="directory-table"
      :data="medServiceData"
      style="width: 100%"
      stripe
      @sort-change="sortChange"
      :default-sort="{ prop: 'code', order: 'ascending' }">
      <el-table-column prop="code" label="Код услуги" width="180" sortable>
      </el-table-column>
      <el-table-column prop="name" label="Наименование услуги">
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-sizes="pageSizes"
      :page-size="currentSize"
      layout="sizes, prev, pager, next"
      :total="tableTotal"
      class="mt-20">
    </el-pagination>
    <!-- <span class="settings-description">
      Соответствует справочнику НСИ Минздрава "Номенклатура медицинских услуг"
      от 06.04.2021 г.
    </span> -->
  </div>
</template>

<script>
  import MedicalServiceManager from "@/services/medicalServiceManager.js";
  const itemsPerPage = [10, 20, 30];
  export default {
    loading: false,
    data() {
      return {
        searchMedService: "",
        medServiceData: [],
        currentSize: itemsPerPage[0],
        pageSizes: itemsPerPage,
        currentPage: 1,
      };
    },
    async mounted() {
      this.loading = true;
      let medServices = await MedicalServiceManager.getMedicalServices(this.currentPage, this.currentSize, null);
      this.medServiceData = medServices.value;
      this.tableTotal = medServices.rowCount;
      this.loading = false;
    },
    computed: {

    },
    methods: {
      async handleRowSearch() {
        let medServices = await MedicalServiceManager.getMedicalServices(
          1,
          this.currentSize,
          this.searchMedService
        );
        this.medServiceData = medServices.value;
        this.tableTotal = medServices.rowCount;
      },
      async handleSizeChange(val) {
        this.currentSize = val;
        let medServices = await MedicalServiceManager.getMedicalServices(
          this.currentPage,
          this.currentSize,
          this.searchMedService
        );
        this.medServiceData = medServices.value;
        this.tableTotal = medServices.rowCount;
      },
      async handleCurrentChange(val) {
        this.currentPage = val;
        let medServices = await MedicalServiceManager.getMedicalServices(
          this.currentPage,
          this.currentSize,
          this.searchMedService
        );
        this.medServiceData = medServices.value;
        this.tableTotal = medServices.rowCount;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
