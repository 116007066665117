import axios from '@/api/axios';

const getEmployeePositionList = async () => {
  let response = await axios.get('positiontype');
  return response.data.value;
};

const getEmployeeCategoryList = async () => {
  let response = await axios.get('positioncategory');
  return response.data.value;
};

const getEmployeeById = async (id) => {
  let response = await axios.get('doctor/' + id);
  return response.data.value;
};

const getEmployees = async () => {
  let response = await axios.get('doctor');
  return response.data.value;
};
const createEmployee = async (employee) => {
  return await axios.post('doctor', employee);
};

const updateEmployee = async (employee) => {
  return await axios.put('doctor', employee);
};
const deleteEmployee = async (id) => {
  return await axios.delete('doctor/' + id);
};
export default {
  getEmployeePositionList,
  getEmployeeCategoryList,
  getEmployees,
  getEmployeeById,
  createEmployee,
  updateEmployee,
  deleteEmployee,
};
