import axios from '@/api/axios';

const getPatients = async (params) => {
  return await axios.get('patient', { params: params });
};

const createPatient = async (patient) => {
  return await axios.post('patient', patient);
};

const updatePatient = async (patient) => {
  return await axios.put('patient', patient);
};

const getTagList = async () => {
  return await axios.get('tagtype');
};

const getPassportIssuerSuggestion = async (search) => {
  return await axios.get('passportissuer/', {
    params: {
      substring: search,
    },
  });
};

const getPatientImportTemplate = () => {
  return axios.get('patientimport/');
};

export default {
  getPatientImportTemplate,
  getPatients,
  createPatient,
  updatePatient,
  getTagList,
  getPassportIssuerSuggestion,
};
