 import axios  from '@/api/axios';
 import {store} from "../store";
 import { base64ToArrayBuffer } from '../services/commonService';

class FileUploadManager {
    async dowloadFile(file) {
        let pdfFile = await axios.get('appointmentfile/' + file.id);
        let bytes = base64ToArrayBuffer(pdfFile.data.value.fileBase64);
        let blob = new Blob([bytes], { type: 'application/pdf' });
        let a = document.createElement("a");
        a.style.display = "none";
        a.href = URL.createObjectURL(blob);
        a.download = pdfFile.data.value.fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(a.href);
}
    async removeFile(file) {
        await axios.delete('appointmentfile/' + file.id);
        await store.dispatch('GET_APPOINTMENTFILES');
    }

    async removeSuppoerRequestFile(idFile) {
        await axios.delete('supportrequestfile/' + idFile);
    }
}

export default new FileUploadManager();