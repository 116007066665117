import axios from '@/api/axios';

const getMyOrganization = async () => {
  return await axios.get('clientorganization');
};

const changeMyOrganization = async (myOrganization) => {
  return await axios.put('clientorganization', myOrganization);
};

const getFilials = async () => {
  return await axios.get('filial');
};

export default {
  getMyOrganization,
  changeMyOrganization,
  getFilials,
};
