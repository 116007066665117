<template>
  <iframe
    :src="link"
    frameborder="0"
    width="100%"
    title="Видео-уроки по обучению работе в МИС Documeds"
    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; web-share;"
    allowfullscreen></iframe>
</template>

<script>
  export default {
    name: "VideoPlayerComponent",
    props: {
      idVideo: String,
    },
    data() {
      return {
        youtubeBaseURL: "https://www.youtube.com/embed/",
      };
    },
    computed: {
      link() {
        return this.youtubeBaseURL + this.idVideo;
      },
    },
  };
</script>

<style lang="scss">
  iframe {
    margin: 10px 0 20px 0;
    // border-radius: 8px;
    min-height: 400px;
  }
</style>
