<template>
  <div class="settings-page" v-loading="loading">
    <el-row :gutter="24">
      <el-col :span="24">
        <h6>Кабинеты</h6>
        <span class="settings-description">
          Здесь вы можете посмотреть кабинеты, расположенные в филиалах вашей
          организации и создать новые.
        </span>
      </el-col>
      <el-col :span="24" class="mt">
        <el-tabs v-model="filialActive">
          <el-tab-pane
            v-for="filial in filials"
            :key="filial.id"
            :label="filial.name"
            :name="filial.id.toString()"
          >
            <el-row :gutter="24">
              <el-col
                :xl="6"
                :lg="8"
                :md="12"
                :sm="24"
                class="mt"
                v-for="item in filials.find((x) => x.id == filialActive)
                  .cabinets"
                :key="item.id"
              >
                <div class="settings-card">
                  <el-button
                    size="small"
                    class="settings-contacts-list-btn"
                    type="text"
                    icon="el-icon-delete"
                    circle
                    @click="handleRemoveCabinet(item)"
                    v-if="editFilialShow"
                  ></el-button>
                  <div class="settings-card-title">{{ item.name }}</div>
                  <ul class="settings-card-list">
                    <li class="settings-card-item">
                      <span class="settings-card-field">Описание:</span>
                      <span class="settings-card-value">
                        {{ item.description }}
                      </span>
                    </li>
                    <!-- <li class="settings-card-item">
                      <span class="settings-card-field">Теги:</span>
                      <span class="settings-card-value">
                        <el-tag size="small">кабинет УЗИ</el-tag>
                      </span>
                    </li> -->
                  </ul>
                  <el-button
                    size="small"
                    type="primary"
                    plain
                    @click="openModalEditCabinet(item)"
                    v-if="editFilialShow"
                    >Редактировать</el-button
                  >
                </div>
              </el-col>
              <el-col
                :xl="6"
                :lg="8"
                :md="12"
                :sm="24"
                class="mt mb"
                v-if="editFilialShow"
              >
                <div class="settings-card empty">
                  <el-button
                    size="small"
                    class="settings-card-btn"
                    @click="openModalAddCabinet"
                  >
                    <i class="el-icon-plus el-icon-left"></i>
                    Добавить
                  </el-button>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>

    <!-- модальное окно доабвления кабинета -->
    <el-dialog
      class="modal-add-cabinet"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="modalTitle"
      :visible.sync="modalAddCabinet"
      width="100%"
    >
      <el-row :gutter="24">
        <el-col :span="24" class="mt">
          <label for="" class="settings-modal-label"
            >Наименование <span class="danger">*</span></label
          >
          <el-input
            size="small"
            placeholder="Введите наименование кабинета"
            v-model="cabinet.name"
            :class="{
              invalid: $v.cabinet.name.$dirty && !$v.cabinet.name.required,
            }"
          >
          </el-input>
          <small
            class="helper-text invalid"
            v-if="$v.cabinet.name.$dirty && !$v.cabinet.name.required"
            >Поле является обязательным</small
          >
        </el-col>
        <el-col :span="24" class="mt">
          <label for="" class="settings-modal-label">Описание</label>
          <el-input
            size="small"
            type="textarea"
            resize="none"
            :rows="4"
            placeholder="Введите описание кабинета"
            v-model="cabinet.description"
          >
          </el-input>
        </el-col>
        <el-col :span="24" class="mt">
          <el-row :gutter="24">
            <el-col :span="12">
              <el-button
                class="w-100"
                size="small"
                type="primary"
                @click="handleCabinetSave"
                :loading="loadingModal"
                >Сохранить</el-button
              >
            </el-col>
            <el-col :span="12">
              <el-button
                class="w-100"
                size="small"
                @click="modalAddCabinet = false"
                >Отменить</el-button
              >
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import FilialManager from '@/services/filialManager.js';
import AuthManager from '@/services/authManager';

export default {
  data() {
    return {
      loading: false,
      filials: [],
      filialActive: '',
      modalAddCabinet: false,
      loadingModal: false,
      modalTitle: '',
      cabinet: '',
    };
  },
  async mounted() {
    this.loading = true;
    this.filials = await FilialManager.getFilials();
    this.filialActive = this.filials[0].id.toString();
    let currentUser = AuthManager.getUser();
    this.editFilialShow = currentUser.roles.find((x) => x == 'edit_branche');
    this.loading = false;
  },
  validations: {
    cabinet: {
      name: { required },
    },
  },
  methods: {
    openModalEditCabinet(item) {
      this.cabinet = {
        id: item.id,
        name: item.name,
        description: item.description,
        idFilial: item.idFilial,
      };
      this.modalTitle = 'Редактирование кабинета';
      this.modalAddCabinet = true;
    },
    openModalAddCabinet() {
      this.cabinet = {
        id: null,
        name: '',
        description: '',
        idFilial: this.filialActive,
      };
      this.modalTitle = 'Добавление кабинета';
      this.modalAddCabinet = true;
    },
    async handleCabinetSave() {
      this.loadingModal = true;
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.loadingModal = false;
        return;
      } else {
        if (this.cabinet.id) {
          this.filials = await FilialManager.updateCabinet(this.cabinet);
        } else {
          this.filials = await FilialManager.addCabinet(this.cabinet);
        }
        this.modalAddCabinet = false;
        this.loadingModal = false;
      }
    },
    async handleRemoveCabinet(item) {
      this.$confirm(
        'Вы действительно хотите удалить выбранный кабинет?',
        'Удаление кабинета',
        {
          confirmButtonText: 'Подтвердить',
          cancelButtonText: 'Отменить',
          type: 'warning',
        }
      )
        .then(async () => {
          this.$message({
            type: 'success',
            message: 'Удаление прошло успешно',
          });
          this.filials = await FilialManager.deleteCabinet(item);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Удаление отменено',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
