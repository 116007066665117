<template>
  <div class="page-wrap patients-page">
    <div class="page-header">
      <h4>Пациенты</h4>
      <div>
        <el-button
          class="hidden-xs-only"
          type="text"
          size="small"
          @click="openModalImportPatient"
          >Импорт из файла <i class="el-icon-download"></i
        ></el-button>
        <el-button class="patient-btn" type="primary" size="small">
          <router-link class="hidden-xs-only" :to="{ path: '/patient/' + 0 }">
            Добавить
            <i class="el-icon-plus el-icon--right"></i>
          </router-link>
          <router-link class="hidden-sm-and-up" :to="{ path: '/patient/' + 0 }">
            <i class="el-icon-user-plus"></i>
          </router-link>
        </el-button>
      </div>
    </div>
    <div class="page-subheader">
      <el-row class="w-100 d-flex align-items-center" :gutter="24">
        <el-col :span="22" :sm="8">
          <el-tooltip
            class="item hidden-xs-only"
            effect="dark"
            content="Поиск пациента можно осуществлять по ФИО или номеру телефона"
            placement="right"
            :hide-after="2500"
          >
            <el-input
              size="small"
              placeholder="Найти пациента..."
              prefix-icon="el-icon-search"
              v-model="patientFilter.filterstring"
              @input="handlePatientRowSearch"
            >
            </el-input>
          </el-tooltip>
          <el-input
            size="small"
            class="hidden-sm-and-up"
            placeholder="Найти пациента..."
            prefix-icon="el-icon-search"
            v-model="patientFilter.filterstring"
            @input="handlePatientRowSearch"
          >
          </el-input>
        </el-col>
        <el-col :span="2" class="mob-text-right">
          <el-button type="text" size="small" @click="modalFilter = true">
            <div class="hidden-xs-only">Расширенные фильтры</div>
            <b v-if="patientFilter.counter > 0"
              >&nbsp;(+{{ patientFilter.counter }})</b
            >
            <i class="el-icon-setting el-icon-right"></i></el-button
        ></el-col>
      </el-row>
    </div>
    <el-table
      class="patient-table"
      v-loading="loading"
      stripe
      :data="tableData"
      @sort-change="handleSortChange"
      :default-sort="{ prop: 'dateStart', order: 'descending' }"
      style="width: 100%"
    >
      <el-table-column
        width="140px"
        prop="cardNumber"
        label="Номер карты"
        sortable
      >
      </el-table-column>
      <el-table-column
        width="280px"
        label="ФИО пациента"
        sortable="custom"
        :formatter="formatterName"
      >
        <template slot-scope="scope">
          <router-link :to="{ path: '/patient/' + scope.row.id }">{{
            formatterName(scope.row)
          }}</router-link>
        </template>
      </el-table-column>
      <el-table-column
        width="160px"
        prop="birthDate"
        label="Дата рождения"
        sortable
        :formatter="formatterDate"
      ></el-table-column>
      <el-table-column
        prop="gender"
        label="Пол"
        sortable
        :formatter="formatterGender"
      ></el-table-column>
      <el-table-column
        :show-overflow-tooltip="true"
        min-width="300px"
        prop="residenceAddress"
        label="Адрес проживания"
        sortable
      ></el-table-column>
      <el-table-column
        width="130px"
        prop="phone"
        label="Телефон"
        sortable
      ></el-table-column>
      <el-table-column width="120px" prop="patientTags" label="Тэги">
        <template slot-scope="scope">
          <el-tag
            size="small"
            v-for="(tag, index) in scope.row.patientTags"
            :key="index"
          >
            {{ tag.value }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        width="180px"
        prop="cardNumber"
        label="Карта в регистратуре"
        sortable
      >
        <template slot-scope="scope">
          <i
            class="el-icon-circle-check success"
            v-if="scope.row.physicalPatientCardExists"
          ></i>
          <i class="el-icon-circle-close danger" v-else></i>
        </template>
      </el-table-column>
      <el-table-column width="80px" label="">
        <template slot-scope="scope">
          <el-dropdown size="mini" trigger="click">
            <span class="el-dropdown-link"><i class="el-icon-more"></i> </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item type="button" v-if="editPatientShow">
                <router-link
                  class="staff-dropdown-link"
                  :to="{ path: '/patient/' + scope.row.id }"
                >
                  <i class="el-icon-edit el-icon-left"></i> Редактировать
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  size="small"
                  class="dropdown-btn"
                  type="text"
                  @click="openModalPrintDocument(scope.row.id)"
                >
                  <i class="el-icon-print"></i>
                  Печать документов</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item size="small" v-if="viewMedicalCasesShow">
                <router-link
                  class="dropdown-link"
                  :to="{ path: '/patient/' + scope.row.id + '/medical-cases/' }"
                >
                  <i class="el-icon-medkit"></i>Случаи обращения
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item size="small">
                <router-link
                  class="dropdown-link"
                  :to="{
                    path: '/patient/' + scope.row.id + '/guarantee-letters',
                  }"
                >
                  <i class="el-icon-file"></i>Гарантийные письма
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item v-if="linkToInvoicesShow">
                <router-link
                  class="dropdown-link"
                  @click="handlePatientInvoices(scope.row)"
                  :to="{
                    path: '/invoices/' + scope.row.id,
                  }"
                >
                  <i class="el-icon-clipboard-alt el-icon-left"></i>
                  Счета
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item v-if="deletePatientShow">
                <el-button
                  class="dropdown-btn danger"
                  size="small"
                  type="text"
                  @click="handleDelete(scope.row)"
                >
                  <i class="el-icon-delete"></i>
                  Удалить</el-button
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="patientFilter.pagenumber"
      :page-sizes="[10, 20, 30]"
      :page-size="patientFilter.pagesize"
      layout="sizes, prev, pager, next"
      :total="tableTotal"
      class="mt-20"
    >
    </el-pagination>

    <!-- модальное окно расширенного поиска -->
    <el-dialog
      class="patient-search-modal"
      title="Расширенные фильтры"
      :visible.sync="modalFilter"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="100%"
    >
      <el-input
        size="small"
        placeholder="Фамилия"
        v-model="patientFilter.patientLastName"
      ></el-input>
      <el-input
        size="small"
        class="mt-20"
        placeholder="Имя"
        v-model="patientFilter.patientFirstName"
      ></el-input>
      <el-input
        size="small"
        class="mt-20"
        placeholder="Отчество"
        v-model="patientFilter.patientPatronymic"
      ></el-input>
      <el-checkbox-group
        class="mt-20"
        style="display: flex"
        v-model="selectedGender"
        size="small"
      >
        <el-checkbox-button
          v-for="item in patientGenderOptions"
          :key="item.id"
          :label="item.id"
          @change="handleSelectGender"
          >{{ item.name }}</el-checkbox-button
        ></el-checkbox-group
      >
      <div class="d-flex">
        <el-date-picker
          size="small"
          class="mt-20 mr-20"
          format="dd.MM.yyyy"
          v-model="patientFilter.patientBirthDateFrom"
          type="date"
          :picker-options="{ firstDayOfWeek: 1 }"
          placeholder="Дата рождения с"
        ></el-date-picker>
        <el-date-picker
          size="small"
          class="mt-20"
          format="dd.MM.yyyy"
          v-model="patientFilter.patientBirthDateTo"
          type="date"
          :picker-options="{ firstDayOfWeek: 1 }"
          placeholder="Дата рождения по"
        >
        </el-date-picker>
      </div>
      <el-input
        size="small"
        class="mt-20"
        placeholder="Телефон"
        v-maska
        data-maska="+7##########"
        v-model="patientFilter.patientPhone"
      ></el-input>
      <div class="mt-20">
        <el-row :gutter="24" class="mt">
          <el-col :span="12" class="d-flex">
            <el-button
              size="small"
              class="w-100"
              type="primary"
              @click="handlePatientFilter"
              >Применить</el-button
            >
          </el-col>
          <el-col :span="12" class="d-flex">
            <el-button size="small" class="w-100" @click="handleFilterReset"
              >Сбросить</el-button
            >
          </el-col>
        </el-row>
      </div>
    </el-dialog>

    <!-- модальное окно импорта пациентов -->
    <el-dialog
      class="patient-import-modal"
      title="Импорт пациентов"
      :visible.sync="modalImportPatient"
      width="100%"
    >
      <p style="margin-bottom: 0">
        Для импорта пациентов необходимо
        <el-button type="text" @click="handleDownloadTemplate"
          >скачать шаблон файла<i class="el-icon-download"></i
        ></el-button>
      </p>
      <p style="margin-top: 0">
        Корректно заполнить его и загрузить в систему по кнопке ниже.
      </p>
      <el-upload
        class="upload mb mt"
        :limit="1"
        action=""
        :on-change="onUploadChange"
        :on-exceed="handleExceed"
        :on-remove="handleRemove"
        :auto-upload="false"
        :file-list="fileList"
      >
        <el-button size="small" type="primary"
          >Загрузить файл с пациентами .xls</el-button
        >
        <!-- <div slot="tip" class="el-upload__tip">загрузите требуемый файл</div> -->
      </el-upload>
      <el-row :gutter="24" class="mt">
        <el-col :span="12" class="d-flex">
          <el-button
            size="small"
            class="w-100"
            type="primary"
            @click="handleSendRequest"
            :loading="loadingBtn"
            >Применить
          </el-button>
        </el-col>
        <el-col :span="12" class="d-flex">
          <el-button
            size="small"
            class="w-100"
            @click="modalImportPatient = false"
            >Отменить</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>
    <ModalPrintDocumentComponent
      :idAppointment="null"
      :idPatient="patientId"
      :is-modal-visible.sync="isModalVisible"
      :meddoc-name="null"
      :documents-for-print="documentsForPrint"
    >
    </ModalPrintDocumentComponent>
  </div>
</template>

<script>
import AuthManager from '@/services/authManager';
import ModalPrintDocumentComponent from '@/components/ModalPrintDocumentComponent.vue';
import { getTimeOffset, downloadFile } from '@/services/commonService';
import patientApi from '@/api/patient';
import axios from '@/api/axios';
import { getterTypes } from '@/store/modules/documentsPrintModule';
import { mapGetters } from 'vuex';

export default {
  components: { ModalPrintDocumentComponent },
  data() {
    return {
      tableData: [],
      tableTotal: 0,
      patientFilter: {
        filterstring: null,
      },
      modalFilter: false,
      isModalVisible: false,
      modalImportPatient: false,
      loading: true,
      loadingBtn: false,
      addPatientShow: false,
      editPatientShow: false,
      deletePatientShow: false,
      linkToInvoicesShow: false,
      viewMedicalCasesShow: false,
      fileList: [],
      patientId: '',
      selectedGender: [],
      patientGenderOptions: [
        {
          id: 0,
          name: 'Женский',
        },
        {
          id: 1,
          name: 'Мужской',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      documentsForPrint: getterTypes.documentsForPrintOnOtherPages,
    }),
  },
  async mounted() {
    this.patientFilter = this.$store.getters.patientFilter;
    await this.loadTableData();
    let currentUser = AuthManager.getUser();
    this.addPatientShow = currentUser.roles.find((x) => x == 'add_patient');
    this.editPatientShow = currentUser.roles.find((x) => x == 'edit_patient');
    this.deletePatientShow = currentUser.roles.find(
      (x) => x == 'delete_patient'
    );
    this.linkToInvoicesShow = currentUser.roles.find(
      (x) => x == 'view_invoice'
    );
    this.viewMedicalCasesShow = currentUser.roles.find(
      (x) => x == 'view_medical_case'
    );
    await this.$patientHub.start();
    this.$patientHub.client.on('PatientChange', () => {
      this.loadTableData();
    });
    this.loading = false;
  },
  methods: {
    //загрузка данных в таблицу по текущему фильтру
    async loadTableData() {
      await axios
        .get('patient', { params: this.patientFilter })
        .then(async (data) => {
          this.tableData = data.data.value;
          this.tableTotal = data.data.rowCount;
          await this.$store.dispatch(
            'UPDATE_PATIENTFILTER',
            this.patientFilter
          );
          await this.$store.dispatch('UPDATE_PATIENTS', this.tableData);
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: `Невозможно загрузить список пациентов!`,
          });
        });
    },

    //форматирование значений в таблице
    formatterName(row) {
      return (
        row.lastName +
        ' ' +
        row.firstName +
        ' ' +
        (row.patronymic ? row.patronymic : '')
      );
    },
    formatterDate(row) {
      return row.birthDate
        ? new Intl.DateTimeFormat('ru-RU').format(new Date(row.birthDate))
        : '';
    },
    formatterGender(row) {
      return row.gender == 1 ? 'Мужской' : 'Женский';
    },

    //пагинация таблицы
    async handleSizeChange(val) {
      this.patientFilter.pagesize = val;
      await this.loadTableData();
    },
    async handleCurrentChange() {
      await this.loadTableData();
    },

    //сортировка таблицы
    async handleSortChange(val) {
      this.patientFilter.sortColumn = val.prop;
      this.patientFilter.sortOrder = val.order == 'descending' ? 1 : 0;
      await this.loadTableData();
    },

    //фильтрация, быстрая и расширенная
    async handlePatientRowSearch() {
      await this.loadTableData();
    },
    async handlePatientFilter() {
      this.handleSelectParam();
      await this.loadTableData();
      this.modalFilter = false;
    },
    async handleFilterReset() {
      (this.patientFilter = {
        timeOffset: getTimeOffset(),
        pagenumber: 1,
        pagesize: 10,
        filterstring: null,
        patientLastName: null,
        patientFirstName: null,
        patientPatronymic: null,
        patientGender: null,
        patientBirthDateFrom: null,
        patientBirthDateTo: null,
        patientPhone: null,
        sortColumn: null,
      }),
        await this.loadTableData();
      this.modalFilter = false;
    },
    handleSelectParam() {
      this.patientFilter.counter = 0;
      this.patientFilter.counter =
        this.patientFilter.patientLastName &&
        this.patientFilter.patientLastName.length > 0
          ? this.patientFilter.counter + 1
          : this.patientFilter.counter;
      this.patientFilter.counter =
        this.patientFilter.patientFirstName &&
        this.patientFilter.patientFirstName.length > 0
          ? this.patientFilter.counter + 1
          : this.patientFilter.counter;
      this.patientFilter.counter =
        this.patientFilter.patientPatronymic &&
        this.patientFilter.patientPatronymic.length > 0
          ? this.patientFilter.counter + 1
          : this.patientFilter.counter;
      this.handleSelectGender();
      this.patientFilter.counter =
        this.patientFilter.patientGender != null
          ? this.patientFilter.counter + 1
          : this.patientFilter.counter;
      this.patientFilter.counter =
        this.patientFilter.patientBirthDateFrom ||
        this.patientFilter.patientBirthDateTo
          ? this.patientFilter.counter + 1
          : this.patientFilter.counter;
      this.patientFilter.counter =
        this.patientFilter.patientPhone &&
        this.patientFilter.patientPhone.length > 0
          ? this.patientFilter.counter + 1
          : this.patientFilter.counter;
    },
    handleSelectGender() {
      if (this.selectedGender.length > 1) {
        this.patientFilter.patientGender = null;
        return;
      }
      if (this.selectedGender.indexOf(0) > -1) {
        this.patientFilter.patientGender = 0;
        return;
      }
      if (this.selectedGender.indexOf(1) > -1) {
        this.patientFilter.patientGender = 1;
        return;
      }
    },

    //удаление пациента
    async handleDelete(row) {
      this.$confirm(
        'Вы действительно хотите удалить этого пациента?',
        'Warning',
        {
          confirmButtonText: 'Подтвердить',
          cancelButtonText: 'Отменить',
          type: 'warning',
        }
      ).then(async () => {
        axios
          .delete(`patient`, { params: { id: row.id } })
          .then(async () => {
            this.$message({
              type: 'success',
              message: 'Удаление прошло успешно',
            });
            await this.$patientHub.client.invoke('BroadcastPatientChange');
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: 'Удаление отменено',
            });
          });
      });
    },

    //импорт пациентов (скачивание шаблона и загрузка списка)
    openModalImportPatient() {
      this.modalImportPatient = true;
    },
    async handleDownloadTemplate() {
      patientApi
        .getPatientImportTemplate()
        .then((response) => {
          downloadFile(
            response.data.value.file,
            null,
            response.data.value.fileName
          );
        })
        .catch(() => {
          this.$message.error('Ошибка загрузки шаблона!');
        });
    },
    onUploadChange(file) {
      const isXLS =
        file.raw.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (!isXLS) {
        this.$message.error('Возможна загрузка файлов только в формате xlsx!');
        return false;
      }
      var reader = new FileReader();
      reader.readAsDataURL(file.raw);

      reader.onload = function () {
        file.raw.problemFile = reader.result;
      };
      this.fileList.push(file);
    },
    handleExceed() {
      this.$message.warning(`Невозможно приложить более одного файла!`);
    },
    async handleRemove(file) {
      this.fileList.splice(file);
    },
    async handleSendRequest() {
      this.loadingBtn = true;
      let request = this.fileList.map((x) => {
        return {
          fileName: x.name,
          file: x.raw.problemFile,
          clientOffset: getTimeOffset(),
        };
      });
      await axios
        .post('patientimport', request[0])
        .then(async (data) => {
          this.$alert('Результаты импорта', 'Результаты импорта', {
            message: `${data.data}`,
            type: 'success',
            confirmButtonText: 'OK',
            callback: () => {
              this.$message({
                type: 'success',
                message: `Импорт пациентов успешно завершен`,
              });
            },
          });
          await this.$patientHub.client.invoke('BroadcastPatientChange');
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: `Невозможно загрузить список пациентов!`,
          });
        });
      this.loadingBtn = false;
      this.modalImportPatient = false;
      this.fileList = [];
    },

    //открытие модального окна печати документов
    openModalPrintDocument(id) {
      this.isModalVisible = !this.isModalVisible;
      this.patientId = id;
    },
  },
};
</script>
