import exportApi from '@/api/export';

const exportModule = {
  state: () => ({
    scheduleReportInExcel: null,
  }),
  getters: {
    scheduleReportInExcel: (state) => {
      return state.scheduleReportInExcel;
    },
  },
  mutations: {
    SET_SCHEDULE_REPORT_IN_EXCEL: (state, payload) => {
      state.scheduleReportInExcel = payload;
    },
  },
  actions: {
    getScheduleReportInExcel: async (context, params) => {
      let fileFromStream = await exportApi.getScheduleReportInExcel(params);
      context.commit('SET_SCHEDULE_REPORT_IN_EXCEL', fileFromStream);
    },
  },
};

export default exportModule;
