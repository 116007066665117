var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"settings-page"},[_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":20,"md":10}},[_c('h6',[_vm._v("Шаблоны медицинских документов")]),_c('span',{staticClass:"settings-description"},[_vm._v(" Здесь вы можете настроить шаблоны медицинских документов вашей организации. ")]),_c('el-tooltip',{staticClass:"item mt-16 hidden-xs-only",attrs:{"effect":"dark","size":"small","content":"Поиск шаблона можно производить на названию","placement":"right","hide-after":2500}},[_c('el-input',{attrs:{"size":"small","placeholder":"Найти шаблон...","prefix-icon":"el-icon-search"},on:{"input":_vm.handleSearchTemplate},model:{value:(_vm.searchTemplate),callback:function ($$v) {_vm.searchTemplate=$$v},expression:"searchTemplate"}})],1),_c('el-input',{staticClass:"mt-16 hidden-sm-and-up",attrs:{"size":"small","placeholder":"Найти шаблон...","prefix-icon":"el-icon-search"},on:{"input":_vm.handleSearchTemplate},model:{value:(_vm.searchTemplate),callback:function ($$v) {_vm.searchTemplate=$$v},expression:"searchTemplate"}})],1),_c('el-col',{staticClass:"d-flex flex-end",attrs:{"span":4,"md":14}},[_c('router-link',{staticClass:"staff-btn-link hidden-sm-and-down",attrs:{"to":{
          path: '/settings/meddoc-templates-constructor/' + 0,
        }}},[_vm._v("Добавить "),_c('i',{staticClass:"el-icon-plus el-icon-right"})]),_c('router-link',{staticClass:"staff-btn-link hidden-md-and-up",attrs:{"to":{
          path: '/settings/meddoc-templates-constructor/' + 0,
        }}},[_c('i',{staticClass:"el-icon-plus"})])],1)],1),_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":24}},[_c('el-table',{ref:"multipleTable",staticClass:"mt mb",staticStyle:{"width":"100%"},attrs:{"data":_vm.meddocTemplates,"stripe":"","default-sort":{ prop: 'fio', order: 'descending' }},on:{"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"width":"140","sortable":"","property":"name","label":"Наименование шаблона"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.name))])]}}])}),_c('el-table-column',{attrs:{"min-width":"180","property":"positionType","label":"Специальности врачей"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-select',{attrs:{"size":"small","placeholder":"Выберите специальность","multiple":"","disabled":""},model:{value:(scope.row.meddocTemplatePositions),callback:function ($$v) {_vm.$set(scope.row, "meddocTemplatePositions", $$v)},expression:"scope.row.meddocTemplatePositions"}},_vm._l((_vm.positionTypeOptions),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.value,"value":item.id}})}),1)]}}])}),_c('el-table-column',{attrs:{"width":"80px","label":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-dropdown',{attrs:{"trigger":"click"}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"el-icon-more"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown","size":"small"},slot:"dropdown"},[(scope.row.id)?_c('el-dropdown-item',{attrs:{"type":"button"}},[_c('router-link',{staticClass:"staff-dropdown-link",attrs:{"to":{
                      path:
                        '/settings/meddoc-templates-constructor/' +
                        scope.row.id,
                    }}},[_c('i',{staticClass:"el-icon-edit el-icon-left"}),_c('span',[_vm._v("Редактировать")])])],1):_vm._e(),_c('el-dropdown-item',[_c('el-button',{staticClass:"dropdown-btn danger",attrs:{"disabled":scope.row.inUse,"size":"small","type":"text"},on:{"click":function($event){return _vm.handleDelete(scope.row)}}},[_c('i',{staticClass:"el-icon-delete"}),_vm._v(" Удалить")])],1)],1)],1)]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }