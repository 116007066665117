const medDocuments = [
  {
    id: '1',
    name: 'Печать протокола осмотра',
    logoActive: false,
    textActive: false,
    format: 0,
    orientation: 0,
  },
  {
    id: '2',
    name: 'Печать назначений',
    logoActive: false,
    textActive: false,
    format: 0,
    orientation: 0,
  },
];

const ChartColors = {
  blue400: '#4354ed',
  red400: '#f6b4a5',
  green400: '#a5e8c0',
  yellow400: '#fcf3a9',
  orange400: '#ffd0af',
  dark400: '#a2a3ac',
  blue800: '#0314ae',
  red1000: '#e8431f',
  green1000: '#1ec561',
  yellow1000: '#f7e228',
  orange1000: '#f8822c',
  dark800: '#444659',
};

export { medDocuments, ChartColors };
