<template>
  <div class="settings-page" v-loading="loading">
    <div class="print-template">
      <el-row :gutter="24">
        <el-col :span="24">
          <h6>Настройка шаблонов для печати</h6>
        </el-col>
      </el-row>
      <div class="w-100">
        <el-row :gutter="24" class="mt">
          <el-col :xl="12" :lg="12" :sm="24">
            <h6>Логотип</h6>
            <p>Отобразиться в левом верхнем углу документа</p>
          </el-col>
          <el-col :xl="12" :lg="12" :sm="24" class="d-flex align-items-center">
            <el-upload
              class="logo-uploader"
              :headers="requestHeaders"
              :action="requestUrl"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="logoUrl" width="100%" :src="logoUrl" class="avatar" />
              <i v-else class="el-icon-image-plus avatar-uploader-icon"></i>
            </el-upload>
            <el-button
              size="small"
              @disabled="logoUrl ? true : false"
              class="danger ml"
              type="text"
              @click="handleLogoDelete"
              >Удалить<i class="el-icon-trash"></i
            ></el-button>
          </el-col>
        </el-row>
        <el-row :gutter="24" class="mt">
          <el-col :xl="12" :lg="12" :sm="24">
            <h6>Текст шапки документа</h6>
            <p>Отобразиться в шапке документа под его названием</p>
          </el-col>
          <el-col :xl="12" :lg="12" :sm="24">
            <el-input
              size="small"
              type="textarea"
              resize="none"
              :rows="4"
              v-model="documentInfo.headerText"
              placeholder="Название вашего медицинского центра, юридические реквизиты или слоган компании"
            ></el-input>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <h6>Печать медицинских документов (протоколов осмотра)</h6>
        <el-table :data="medDocuments" class="h-100 w-100" stripe>
          <el-table-column min-width="195" label="Наименование документа">
            <template slot-scope="scope">
              <div class="d-flex align-items-center">
                <div class="meddoc-icon">
                  <i class="el-icon-file-medical-alt"></i>
                </div>
                <span>{{ scope.row.name }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Логотип" width="120">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.logoActive"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="Текст" width="120">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.textActive"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="Формат" width="120">
            <template slot-scope="scope">
              <el-checkbox-button
                class="print-template__checkbox"
                v-model="scope.row.format"
                >{{ scope.row.format ? 'А5' : 'А4' }}</el-checkbox-button
              >
            </template>
          </el-table-column>
          <el-table-column label="Ориентация" width="140">
            <template slot-scope="scope">
              <el-checkbox-button
                v-if="scope.row.format === true"
                class="print-template__checkbox"
                v-model="scope.row.orientation"
                :disabled="(scope.row.orientation = true)"
                >{{
                  scope.row.orientation ? 'Альбомная' : 'Портретная'
                }}</el-checkbox-button
              >
              <el-checkbox-button
                v-else
                class="print-template__checkbox"
                v-model="scope.row.orientation"
                >{{
                  scope.row.orientation ? 'Альбомная' : 'Портретная'
                }}</el-checkbox-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-divider></el-divider>
      <h6>Выберите документы для отображения при печати</h6>
      <el-table :data="documentInfo.documents" class="h-100 w-100 mb" stripe>
        <el-table-column min-width="495" label="Наименование документа">
          <template slot-scope="scope">
            <div class="d-flex align-items-center">
              <el-switch
                :width="30"
                v-model="scope.row.active"
                :active-text="scope.row.name"
              >
              </el-switch>

              <el-button
                v-if="scope.row.name !== 'Товарный чек'"
                size="small"
                class="print-template-preview-btn"
                type="text"
                @click="showPreview(scope.row.idClinicDocumentType)"
                ><i class="el-icon-eye"></i
              ></el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Логотип" width="120">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.headerLogoActive"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="Текст" width="120">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.headerTextActive"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="Формат" width="120">
          <template slot-scope="scope">
            <el-checkbox-button
              class="print-template__checkbox"
              v-model="scope.row.format"
              >{{ scope.row.format ? 'А5' : 'А4' }}</el-checkbox-button
            >
          </template>
        </el-table-column>
        <el-table-column label="Ориентация" width="130">
          <template slot-scope="scope">
            <el-checkbox-button
              v-if="scope.row.format === true"
              class="print-template__checkbox"
              v-model="scope.row.orientation"
              :disabled="(scope.row.orientation = true)"
              >{{
                scope.row.orientation ? 'Альбомная' : 'Портретная'
              }}</el-checkbox-button
            >
            <el-checkbox-button
              v-else
              class="print-template__checkbox"
              v-model="scope.row.orientation"
              >{{
                scope.row.orientation ? 'Альбомная' : 'Портретная'
              }}</el-checkbox-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-button
        size="small"
        :loading="loadingSave"
        class="mb"
        type="primary"
        @click="handleSaveClinicDocuments"
        >Сохранить</el-button
      >
    </div>

    <PdfViewComponent
      :pdf-view-visible.sync="pdfViewVisible"
      :source="link"
    ></PdfViewComponent>
  </div>
</template>

<script>
import axios from '@/api/axios';
import { BaseURL, authHeader } from '@/api/httpSettings';
import { base64ToArrayBuffer, getTimeOffset } from '@/services/commonService';
import PdfViewComponent from '@/components/PdfViewComponent.vue';
import { medDocuments } from '@/constants';
export default {
  components: {
    PdfViewComponent,
  },
  data() {
    return {
      loading: false,
      loadingSave: false,
      medDocuments: medDocuments,
      // meddocFullOptions: [""],
      // meddocShortOptions: "",
      // meddocOptions: ["Логотип", "Текст"],
      isActive: '',
      logoUrl: '',
      soucrePreview: 'https://www.africau.edu/images/default/sample.pdf',
      documentInfo: {
        documents: [],
        headerText: '',
      },
      requestHeaders: { Authorization: '' },
      requestUrl: '',
      pdfViewVisible: false,
      link: '',
    };
  },
  created() {},
  async mounted() {
    await this.loadData();
    this.requestUrl = BaseURL() + 'clinicdocument';
    this.requestHeaders = authHeader();
  },
  methods: {
    changeValue(row, value) {
      console.log(this);
      row.format = !value;
      row.orientation = !value;
    },
    async loadData() {
      let documentTemplateTypes = [];
      let clinicDocuments = [];
      await axios
        .get('clinicdocumenttype')
        .then(async (data) => {
          documentTemplateTypes = data.data.value;
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: `Невозможно загрузить список шаблонов!`,
          });
        });
      await axios
        .get('clinicdocument')
        .then(async (data) => {
          clinicDocuments = data.data.value;
          // console.log(clinicDocuments);
          this.documentInfo.headerText = data.data.header;
          this.medDocuments[0].logoActive = data.data.protocolLogoActive;
          this.medDocuments[0].textActive = data.data.protocolTextActive;
          this.medDocuments[0].format = data.data.protocolFormat;
          this.medDocuments[0].orientation = data.data.protocolOrientation;
          this.medDocuments[1].logoActive = data.data.recomendationLogoActive;
          this.medDocuments[1].textActive = data.data.recomendationTextActive;
          this.medDocuments[1].format = data.data.recomendationFormat;
          this.medDocuments[1].orientation = data.data.recomendationOrientation;
          if (data.data.logo) {
            let bytes = base64ToArrayBuffer(data.data.logo);
            let blob = new Blob([bytes], { type: 'image/jpeg' });
            this.logoUrl = URL.createObjectURL(blob);
          }
          // console.log(this.medDocuments);
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: `Невозможно загрузить список шаблонов организации!`,
          });
        });
      this.documentInfo.documents = documentTemplateTypes.map((x) => {
        return {
          id:
            clinicDocuments.find((c) => c.idClinicDocumentType == x.id)?.id ??
            null,
          name: x.name,
          idClinicDocumentType: x.id,
          active:
            clinicDocuments.find((c) => c.idClinicDocumentType == x.id)
              ?.active ?? false,
          headerLogoActive:
            clinicDocuments.find((c) => c.idClinicDocumentType == x.id)
              ?.headerLogoActive ?? false,
          headerTextActive:
            clinicDocuments.find((c) => c.idClinicDocumentType == x.id)
              ?.headerTextActive ?? false,
          format:
            clinicDocuments.find((c) => c.idClinicDocumentType == x.id)
              ?.format ?? false,
          orientation:
            clinicDocuments.find((c) => c.idClinicDocumentType == x.id)
              ?.orientation ?? false,
        };
      });
    },
    async showPreview(id) {
      //this.isPreview = !this.isPreview;
      await axios
        .get('documentprint', {
          params: {
            ids: id,
            timezoneOffset: getTimeOffset(),
          },
        })
        .then(async (data) => {
          let bytes = base64ToArrayBuffer(data.data);
          let blob = new Blob([bytes], { type: 'application/pdf' });
          this.link = URL.createObjectURL(blob);
          this.pdfViewVisible = true;
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: `Невозможно отобразить предпросмотр документа!`,
          });
        });
    },
    handleAvatarSuccess(res, file) {
      this.logoUrl = URL.createObjectURL(file.raw);
      this.$message({
        type: 'success',
        message: 'Файл успешно загружен!',
      });
    },
    beforeAvatarUpload(file) {
      const format = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!format) {
        this.$message.error(
          'Некорректный формат файла! Требуемый формат - jpeg или png'
        );
      }
      if (!isLt2M) {
        this.$message.error('Превышен максимальный размер файла 2MB!');
      }
      return format && isLt2M;
    },
    async handleLogoDelete() {
      await axios
        .delete('clinicdocument')
        .then(async () => {
          this.$message({
            type: 'success',
            message: `Логотип удален!`,
          });
          this.logoUrl = null;
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: `Невозможно удалить логотип!`,
          });
        });
    },
    async handleSaveClinicDocuments() {
      this.documentInfo.protocolLogoActive = this.medDocuments[0].logoActive;
      this.documentInfo.protocolTextActive = this.medDocuments[0].textActive;
      this.documentInfo.protocolFormat = this.medDocuments[0].format;
      this.documentInfo.protocolOrientation = this.medDocuments[0].orientation;
      this.documentInfo.recomendationLogoActive =
        this.medDocuments[1].logoActive;
      this.documentInfo.recomendationTextActive =
        this.medDocuments[1].textActive;
      this.documentInfo.recomendationFormat = this.medDocuments[1].format;
      this.documentInfo.recomendationOrientation =
        this.medDocuments[1].orientation;
      await axios
        .put('clinicdocument', this.documentInfo)
        .then(async () => {
          this.$message({
            type: 'success',
            message: `Данные сохранены!`,
          });
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: `Невозможно сохранить данные!`,
          });
        });
    },
  },
};
</script>
