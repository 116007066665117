<template>
  <div class="page-wrap invoices">
    <div class="page-header">
      <h4>Счета</h4>
      <el-button
        size="small"
        type="primary"
        @click="openModalAdd"
        v-if="addInvoiceShow && !patientMode"
      >
        <span class="hidden-xs-only">Добавить счет</span>
        <i class="el-icon-plus el-icon-right"></i>
      </el-button>
    </div>
    <div class="page-subheader" v-if="!patientMode">
      <el-row class="w-100" :gutter="24">
        <el-col :span="24" :sm="8">
          <el-tooltip
            effect="dark"
            placement="right-start"
            class="hidden-xs-only"
          >
            <div slot="content">
              <span>Начните вводить ФИО пациента</span>
            </div>
            <el-select
              data-is-search="true"
              size="small"
              class="invoices-patient-select"
              v-model="invoiceFilter.patientId"
              filterable
              placeholder="Выберите пациента"
              :filter-method="handlePatientFilter"
              :loading="loading"
              clearable
              @change="handlePatientSelect"
            >
              <el-option
                v-for="item in patientOptions"
                :key="item.id"
                :label="handlePatientLabel(item)"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-tooltip>
          <el-select
            data-is-search="true"
            size="small"
            class="invoices-patient-select hidden-sm-and-up"
            v-model="invoiceFilter.patientId"
            filterable
            placeholder="Выберите пациента"
            :filter-method="handlePatientFilter"
            :loading="loading"
            clearable
            @change="handlePatientSelect"
          >
            <el-option
              v-for="item in patientOptions"
              :key="item.id"
              :label="handlePatientLabel(item)"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </div>
    <el-table
      v-loading="loading"
      class="invoices-table mt"
      stripe
      :data="tableData"
      @sort-change="handleSortChange"
      style="width: 100%"
    >
      <el-table-column property="numberStr" label="Номер счета" sortable>
      </el-table-column>

      <el-table-column
        min-width="120px"
        property="idPatient"
        label="Пациент"
        :formatter="formatterPatient"
        sortable
      >
      </el-table-column>
      <el-table-column
        min-width="120px"
        property="idAppointment"
        label="Специалист"
        :formatter="formatterDoctor"
        sortable
      >
      </el-table-column>

      <el-table-column
        min-width="120px"
        property="dateCreate"
        label="Дата формирования"
        :formatter="formatterDateCreate"
        sortable
      >
      </el-table-column>
      <el-table-column
        width="120px"
        property="datePayment"
        label="Дата оплаты"
        :formatter="formatterDatePayment"
        sortable
      >
      </el-table-column>
      <el-table-column property="sum" label="Сумма" sortable> </el-table-column>
      <!-- <el-table-column
        property="serviceCount"
        :formatter="formatterServiceCount"
        label="Количество услуг"
        sortable>
      </el-table-column> -->
      <el-table-column
        width="120px"
        property="paymentType"
        label="Тип оплаты"
        :formatter="formatterPaymentType"
        sortable
      >
      </el-table-column>
      <el-table-column property="paymentStatus" label="Статус" sortable>
        <template slot-scope="scope">
          <i
            class="el-icon-circle-check success"
            v-if="scope.row.paymentStatus == 1"
          ></i>
          <i
            class="el-icon-circle-close danger"
            v-if="scope.row.paymentStatus != 1"
          ></i>
        </template>
      </el-table-column>
      <el-table-column width="80px" label="">
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link"><i class="el-icon-more"></i> </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="scope.row.paymentStatus != 1">
                <el-button
                  class="dropdown-btn"
                  size="small"
                  type="text"
                  @click="openModalPay(scope.row)"
                >
                  <i class="el-icon-money-insert"></i>
                  Оплатить
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item v-if="editInvoiceShow">
                <el-button
                  class="dropdown-btn"
                  size="small"
                  type="text"
                  @click="openModalEdit(scope.row)"
                >
                  <i class="el-icon-edit"></i>
                  Редактировать
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  class="dropdown-btn"
                  size="small"
                  type="text"
                  @click="openModalPrint(scope.row)"
                >
                  <i class="el-icon-print"></i>
                  Печать
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item v-if="deleteInvoiceShow">
                <el-button
                  class="dropdown-btn danger"
                  size="small"
                  type="text"
                  @click="handleDelete(scope.row)"
                >
                  <i class="el-icon-delete"></i>
                  Удалить
                </el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="invoiceFilter.pagenumber"
      :page-sizes="[10, 20, 30]"
      :page-size="invoiceFilter.pagesize"
      layout="sizes, prev, pager, next"
      :total="tableTotal"
      class="mt-20"
    >
    </el-pagination>

    <!-- модальное окно оплаты счета -->
    <el-dialog
      class="modal-pay-invoices"
      title="Оплата счета"
      :visible.sync="modalPayment"
      width="100%"
    >
      <el-row :gutter="24">
        <el-col :span="24" class="mt">
          <label for="">Тип оплаты</label>
          <el-select
            data-is-search="true"
            size="small"
            v-model="invoiceToSave.paymentType"
            filterable
            placeholder="Выберите тип оплаты"
            :loading="loading"
          >
            <el-option
              v-for="item in paymentTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="12" class="mt">
          <el-button
            size="small"
            class="w-100"
            :loading="loadingModal"
            type="primary"
            @click="handleSavePayment"
            >Сохранить</el-button
          >
        </el-col>
        <el-col :span="12" class="mt">
          <el-button size="small" class="w-100" @click="handleClosePayment"
            >Отменить</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>

    <!-- модальное окно добавления счета -->
    <el-dialog
      class="modal-add-invoices"
      :before-close="handleCloseModalAddInvoice"
      :title="modalTitle"
      :visible.sync="modalAddInvoice"
      width="100%"
    >
      <el-row :gutter="24" align="center mt-20">
        <el-col :span="12" :sm="6">
          <label for="">Номер счета</label>
          <el-input
            size="small"
            placeholder="Номер счета будет присвоен автоматически"
            v-model="invoiceToSave.numberStr"
            disabled
          ></el-input>
        </el-col>
        <el-col :span="12" :sm="6">
          <label for="">Дата формирования</label>
          <el-date-picker
            size="small"
            v-model="invoiceToSave.dateCreate"
            format="dd.MM.yyyy"
            :picker-options="{ firstDayOfWeek: 1 }"
            placeholder="Выберите дату"
            :disabled="(invoiceToSave?.id ?? 0) > 0"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="12" :sm="6" class="mob-mt-16">
          <label for="">Статус оплаты</label>
          <el-select
            data-is-search="true"
            size="small"
            v-model="invoiceToSave.paymentStatus"
            filterable
            placeholder="Выберите статус оплаты"
            :loading="loading"
          >
            <el-option
              v-for="item in paymentStatusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="12" :sm="6" class="mob-mt-16">
          <label for="">Тип оплаты</label>
          <el-select
            data-is-search="true"
            size="small"
            v-model="invoiceToSave.paymentType"
            filterable
            placeholder="Выберите тип оплаты"
            :loading="loading"
          >
            <el-option
              v-for="item in paymentTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row :gutter="24" align="center mt-20">
        <el-col :span="24">
          <label for="">Пациент</label>
          <template>
            <el-select
              data-is-search="true"
              size="small"
              v-model="invoiceToSave.idPatient"
              filterable
              placeholder="Выберите пациента"
              :filter-method="handlePatientFilter"
              :disabled="(invoiceToSave?.id ?? 0) > 0"
              :loading="loading"
            >
              <el-option
                v-for="item in patientOptions"
                :key="item.id"
                :label="handlePatientLabel(item)"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
        </el-col>
      </el-row>

      <el-row :gutter="24" align="center mt-20">
        <el-col :span="24">
          <label for="">Филиал</label>
          <template>
            <el-select
              size="small"
              v-model="invoiceToSave.idFilial"
              :disabled="(invoiceToSave?.id ?? 0) > 0"
              placeholder="Выберите филиал"
            >
              <el-option
                v-for="item in filialOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </template>
        </el-col>
      </el-row>

      <el-row :gutter="24" align="center mt-20">
        <el-col :span="24">
          <label for="">Услуги</label>
          <el-select
            data-is-search="true"
            size="small"
            v-model="currentService"
            filterable
            clearable
            placeholder="Начните вводить название услуги"
            :filter-method="handleServiceFilter"
            :loading="loading"
            @change="handleServiceSelect"
          >
            <el-option
              v-for="item in priceListOptions"
              :key="item.id"
              :label="handlePriceListLabel(item)"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-table
            class="mt"
            :data="invoiceToSave.services"
            style="width: 100%"
          >
            <el-table-column
              min-width="120px"
              property="name"
              label="Наименование"
            ></el-table-column>
            <el-table-column
              min-width="120px"
              property="code"
              label="Код услуги"
            ></el-table-column>
            <el-table-column
              min-width="150px"
              label="Количество, шт."
              property="count"
            >
              <template slot-scope="scope">
                <el-input-number
                  size="mini"
                  v-model="scope.row.count"
                  :min="1"
                  :max="10"
                  @change="handleChangeService(scope.row.idPriceList)"
                ></el-input-number>
              </template>
            </el-table-column>
            <el-table-column min-width="150px" label="Скидка, %">
              <template slot-scope="scope">
                <el-input-number
                  size="mini"
                  v-model="scope.row.discount"
                  :min="0"
                  :max="100"
                  @change="handleChangeService(scope.row.idPriceList)"
                ></el-input-number>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150px"
              property="invoiceServicePrice"
              label="Стоимость, ₽"
            >
              <template slot-scope="scope">
                <el-input-number
                  size="mini"
                  :controls="false"
                  v-model="scope.row.priceDiscount"
                >
                </el-input-number>
              </template>
            </el-table-column>
            <el-table-column
              width="100px"
              property="invoiceServiceDateStart"
              label="Назначена"
              :formatter="formatterDateStart"
            >
            </el-table-column>
            <el-table-column
              width="100px"
              property="invoiceServiceDateStart"
              label="Исполнена"
              :formatter="formatterDateEnd"
            ></el-table-column>
            <el-table-column width="100px" label="Действия">
              <template slot-scope="scope">
                <el-button
                  size="small"
                  type="text"
                  class="danger"
                  @click="handleDeleteService(scope.row)"
                >
                  <i class="el-icon-delete el-icon-left"></i>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row :gutter="24" class="mt">
        <el-col :span="12">
          <h6>Итого: {{ invoiceToSave.sum }}</h6>
        </el-col>
      </el-row>
      <el-row :gutter="24" align="center" class="mt-20">
        <el-col :span="12" :sm="4">
          <el-button
            class="w-100"
            size="small"
            type="primary"
            :loading="loadingModal"
            @click="handleSaveModalInvoice"
            >Сохранить</el-button
          >
        </el-col>
        <el-col :span="12" :sm="4">
          <el-button
            size="small"
            class="w-100"
            @click="handleCloseModalAddInvoice"
            >Отменить</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>
    <ModalPrintDocumentComponent
      :is-modal-visible.sync="isModalPrintVisible"
      :id-patient="idPatient"
      :id-appointment="idAppointment"
      :documents-for-print="documentsForPrint"
    ></ModalPrintDocumentComponent>
  </div>
</template>

<script>
import PriceListManager from '@/services/priceListManager.js';
import { getTimeOffset } from '@/services/commonService';
import AuthManager from '../services/authManager';
import FilialManager from '../services/filialManager';
import axios from '@/api/axios';
import ModalPrintDocumentComponent from '@/components/ModalPrintDocumentComponent.vue';
import { getterTypes } from '@/store/modules/documentsPrintModule';
import { mapGetters } from 'vuex';

export default {
  components: {
    ModalPrintDocumentComponent,
  },
  data() {
    return {
      tableData: [],
      tableTotal: 0,
      invoiceFilter: {},
      patientOptions: [],
      paymentTypeOptions: [
        {
          label: 'безналичный',
          value: 1,
        },
        {
          label: 'наличный',
          value: 2,
        },
        {
          label: 'бесплатно',
          value: 3,
        },
        {
          label: 'ДМС',
          value: 4,
        },
      ],
      paymentStatusOptions: [
        {
          label: 'оплачен',
          value: 1,
        },
        {
          label: 'не оплачен',
          value: null,
        },
      ],
      priceList: [],
      priceListOptions: [],
      filialOptions: [],
      modalTitle: '',
      modalAddInvoice: false,
      loading: false,
      loadingModal: false,
      invoiceToSave: {
        id: null,
        numberStr: '',
        dateCreate: new Date(),
        paymentStatus: '',
        paymentType: '',
        idAppointment: null,
        services: [],
        sum: 0,
        idPatient: null,
        idFilial: null,
      },
      modalPayment: false,
      isModalPrintVisible: false,
      currentService: '',
      addInvoiceShow: false,
      editInvoiceShow: false,
      deleteInvoiceShow: false,
      patientMode: false,
      idPatient: null,
      idAppointment: null,
    };
  },
  computed: {
    ...mapGetters({
      documentsForPrint: getterTypes.documentsForPrintOnInvoicesPage,
    }),
  },
  async mounted() {
    this.loading = true;

    this.invoiceFilter = this.$store.getters.invoicesFilter;
    if (this.invoiceFilter.patientId) {
      let patientList = await axios.get('patient', {
        params: {
          timeOffset: getTimeOffset(),
          pagenumber: 1,
          pagesize: 10,
          patientId: this.invoiceFilter.patientId,
        },
      });
      this.patientOptions = patientList.data.value;
    }
    this.priceList = await PriceListManager.getCurrentPriceList();
    let currentUser = AuthManager.getUser();
    this.addInvoiceShow = currentUser.roles.find((x) => x == 'add_invoice');
    this.editInvoiceShow = currentUser.roles.find((x) => x == 'edit_invoice');
    this.deleteInvoiceShow = currentUser.roles.find(
      (x) => x == 'delete_invoice'
    );
    let curid = this.$route.params.id;
    this.patientMode = curid ? true : false;
    this.invoiceFilter.patientId = curid ? curid : '';
    this.filialOptions = await FilialManager.getFilials();
    await this.loadTableData();
    await this.handlePatientFilter('');
    this.priceListOptions = this.priceList.filter(
      (x) => this.priceList.indexOf(x) < 10
    );
    //подписываемся на изменение счетов
    await this.$patientHub.start();
    this.$patientHub.client.on('InvoicesChange', () => {
      this.loadTableData();
    });
    this.loading = false;
  },
  methods: {
    //загрузка данных в таблицу по текущему фильтру
    async loadTableData() {
      axios
        .get('invoice', { params: this.invoiceFilter })
        .then(async (data) => {
          this.tableData = data.data.value;
          this.tableTotal = data.data.rowCount;
          await this.$store.commit('SET_INVOICESFILTER', this.invoiceFilter);
          await this.$store.commit('SET_INVOICES', this.tableData);
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: `Невозможно загрузить список счетов!`,
          });
        });
    },

    //форматирование значений в таблице
    formatterDateCreate(row) {
      return new Intl.DateTimeFormat('ru-RU').format(new Date(row.dateCreate));
    },
    formatterDatePayment(row) {
      return row.datePayment
        ? new Intl.DateTimeFormat('ru-RU').format(new Date(row.datePayment))
        : '';
    },
    formatterPatient(row) {
      return row.patient.patronymic
        ? `${row.patient.lastName} ${row.patient.firstName.substring(
            0,
            1
          )}. ${row.patient.patronymic.substring(0, 1)}.`
        : `${row.patient.lastName} ${row.patient.firstName.substring(0, 1)}.`;
    },
    formatterDoctor(row) {
      return row.doctor
        ? row.doctor.patronymic
          ? `${row.doctor.lastName} ${row.doctor.firstName.substring(
              0,
              1
            )}. ${row.doctor.patronymic.substring(0, 1)}.`
          : ''
        : '';
    },
    formatterServiceCount(row) {
      return row.services.length;
    },
    formatterDateStart(row) {
      return new Intl.DateTimeFormat('ru-RU').format(
        new Date(row.dateTimeStart)
      );
    },
    formatterDateEnd(row) {
      return row.dateEnd
        ? new Intl.DateTimeFormat('ru-RU').format(new Date(row.dateTimeEnd))
        : '';
    },
    formatterPaymentType(row) {
      return row.paymentType == 1
        ? 'безналичный'
        : row.paymentType == 2
        ? 'наличный'
        : row.paymentType == 3
        ? 'бесплатно'
        : row.paymentType == 4
        ? 'ДМС'
        : '';
    },
    handlePatientLabel(item) {
      /* let birthDateString = new Intl.DateTimeFormat("ru-RU")
        .format(new Date(item.birthDate))
        .toString();
      let labelString = `${item.lastName} ${item.firstName}`;
      labelString = item.patronymic
        ? labelString + ` ${item.patronymic}`
        : labelString;
      labelString = labelString + ` ${birthDateString}г. р., ${item.phone}`;
      labelString = item.registerAddress
        ? labelString + `, ${item.registerAddress}`
        : labelString; */
      let birthDateString = item.birthDate ? new Intl.DateTimeFormat('ru-RU')
        .format(new Date(item.birthDate))
        .toString() : '';
      let labelString = `${item.lastName} ${item.firstName}`;
      if (window.innerWidth > 1024) {
        labelString = item.patronymic
          ? labelString + ` ${item.patronymic}`
          : labelString;
        labelString = labelString + ` ${birthDateString}г. р., ${item.phone}`;
        labelString = item.registerAddress
          ? labelString + `, ${item.registerAddress}`
          : labelString;
      } else if (window.innerWidth > 768) {
        labelString = item.patronymic
          ? labelString + ` ${item.patronymic}`
          : labelString;
        labelString = labelString + ` ${birthDateString}г. р., ${item.phone}`;
      } else {
        labelString = item.patronymic
          ? labelString + ` ${item.patronymic}`
          : labelString;
      }
      return labelString;
    },
    handlePriceListLabel(item) {
      return `${item.medicalServiceCurrentOrganization.name} (${item.positionTypeName}, ${item.categoryName})`;
    },

    //пагинация таблицы
    async handleSizeChange(val) {
      this.invoiceFilter.pagesize = val;
      await this.loadTableData();
    },
    async handleCurrentChange() {
      await this.loadTableData();
    },

    //сортировка таблицы
    async handleSortChange(val) {
      this.invoiceFilter.sortColumn = val.prop;
      this.invoiceFilter.sortOrder = val.order == 'descending' ? 1 : 0;
      await this.loadTableData();
    },

    //обработка вввода значений в строку поиска пациентов
    async handlePatientFilter(query) {
      let params = {
        timeOffset: getTimeOffset(),
        pagenumber: 1,
        pagesize: 10,
      };
      if (query !== '') {
        params.filterstring = query;
      }
      let patientList = await axios.get('patient', {
        params: params,
      });
      this.patientOptions = patientList.data.value;
    },

    //обработка выбора пациента
    handlePatientSelect(value) {
      this.invoiceFilter.patientId = value;
      this.loadTableData();
    },

    //удаление счета
    async handleDelete(row) {
      this.$confirm('Вы действительно хотите удалить этот счет?', 'Warning', {
        confirmButtonText: 'Подтвердить',
        cancelButtonText: 'Отменить',
        type: 'warning',
      })
        .then(async () => {
          axios
            .delete(`invoice`, { params: { id: row.id } })
            .then(async () => {
              this.$message({
                type: 'success',
                message: 'Удаление прошло успешно',
              });
              await this.$patientHub.client.invoke('BroadcastInvoiceChange');
            })
            .catch(() => {
              this.$message({
                type: 'error',
                message: 'Удаление отменено',
              });
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Удаление отменено',
          });
        });
    },
    //открытие модального окна оплаты счета
    openModalPay(row) {
      this.invoiceToSave = row;
      this.paymentTypeOptions[2].disabled = this.invoiceToSave.sum > 0;
      this.modalPayment = true;
    },

    //сохранение информации об оплате
    async handleSavePayment() {
      this.loadingModal = true;
      this.invoiceToSave.paymentStatus = 1;
      this.invoiceToSave.datePayment = new Date();
      axios
        .put(`invoice`, this.invoiceToSave)
        .then(async () => {
          await this.$patientHub.client.invoke('BroadcastInvoiceChange');
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: `Невозможно сохранить информацию об оплате!`,
          });
        });
      this.modalPayment = false;
      this.loadingModal = false;
    },

    //закрытие модального окна оплаты
    handleClosePayment() {
      this.modalPayment = false;
    },

    //открытие модального окна добавления счета
    openModalAdd() {
      this.invoiceToSave = {
        id: null,
        numberStr: null,
        dateCreate: '',
        sum: 0,
        paymentType: null,
        paymentStatus: null,
        idAppointment: null,
        idPatient: null,
        idFilial: null,
        patient: null,
        doctor: null,
        services: [],
      };
      this.loadingModal = false;
      this.modalTitle = 'Создание нового счета';
      this.modalAddInvoice = true;
    },

    //открытие модального окна редактирования счета
    async openModalEdit(item) {
      this.loadingModal = false;
      this.invoiceToSave = item;
      this.modalTitle = 'Редактирование счета';
      let patientList = await axios.get('patient', {
        params: {
          timeOffset: getTimeOffset(),
          pagenumber: 1,
          pagesize: 10,
          patientId: this.invoiceToSave.idPatient,
        },
      });
      this.patientOptions = patientList.data.value;
      this.paymentTypeOptions[2].disabled = this.invoiceToSave.sum > 0;
      this.modalAddInvoice = true;
    },

    //закрытие модального окна работы со счетом
    handleCloseModalAddInvoice(done) {
      this.$confirm('Вы уверены, что хотите прервать работу со счетом?').then(
        () => {
          this.$message({
            type: 'info',
            message: 'Создание счета прервано. Данные не сохранены',
          });
          this.modalAddInvoice = false;
          done();
        }
      );
    },

    //загрузка выпадающего списка услуг
    handleServiceFilter(query) {
      if (query !== '') {
        let queryArr = query.split(' ');
        this.priceListOptions = this.priceList;
        for (let i = 0; i < queryArr.length; i++) {
          this.priceListOptions = this.priceListOptions.filter((item) => {
            return (
              item.medicalServiceCurrentOrganization.name
                .toLowerCase()
                .indexOf(queryArr[i].toLowerCase()) > -1
            );
          });
        }
      } else {
        this.priceListOptions = [];
      }
    },

    //выбор услуги
    handleServiceSelect() {
      let currentPriceListItem = this.priceList.find(
        (x) => x.id == this.currentService
      );
      let currentAppointmentservice = {
        id: null,
        idAppointment: this.invoiceToSave.idAppointment,
        idPriceList: this.currentService,
        dateTimeStart: new Date(),
        dateTimeEnd: new Date(),
        count: 1,
        discount: 0,
        price: currentPriceListItem.price,
        priceDiscount: currentPriceListItem.price,
        name: currentPriceListItem.medicalServiceCurrentOrganization.name,
        code: currentPriceListItem.medicalServiceCurrentOrganization
          .medicalServiceType.code,
      };
      this.invoiceToSave.services.push(currentAppointmentservice);
      this.invoiceToSave.sum =
        this.invoiceToSave.sum + currentAppointmentservice.priceDiscount;
      this.paymentTypeOptions[2].disabled = this.invoiceToSave.sum > 0;
    },

    //редактирование услуги
    handleChangeService(idPriceList) {
      let currentRow = this.invoiceToSave.services.find(
        (x) => x.idPriceList == idPriceList
      );
      currentRow.priceDiscount =
        currentRow.count * currentRow.price * (1 - currentRow.discount / 100);
      this.invoiceToSave.sum = this.invoiceToSave.services.reduce(
        (a, x) => a + x.priceDiscount,
        0
      );
      this.paymentTypeOptions[2].disabled = currentRow.priceDiscount > 0;
    },

    //удаление услуги
    handleDeleteService(row) {
      let index = this.invoiceToSave.services.indexOf(row);
      if (index > -1) {
        this.invoiceToSave.sum = this.invoiceToSave.sum - row.priceDiscount;
        this.invoiceToSave.services.splice(index, 1);
      }
    },

    //сохранение счета
    async handleSaveModalInvoice() {
      if (this.invoiceToSave.idPatient == null) {
        this.$message({
          type: 'error',
          message: `Не выбран пациент!`,
        });
        return;
      }
      if (this.invoiceToSave.idFilial == null) {
        this.$message({
          type: 'error',
          message: `Не выбран филиал!`,
        });
        return;
      }
      if (this.invoiceToSave.services.length < 1) {
        this.$message({
          type: 'error',
          message: `Не выбраны услуги!`,
        });
        return;
      }
      if (this.invoiceToSave.id) {
        this.loadingModal = true;
        axios
          .put('invoice', this.invoiceToSave)
          .then(async () => {
            await this.$patientHub.client.invoke('BroadcastInvoiceChange');
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: `Невозможно сохранить изменения!`,
            });
          });
        this.loadingModal = false;
      } else {
        this.loadingModal = true;
        axios
          .post('invoice', this.invoiceToSave)
          .then(async () => {
            await this.$patientHub.client.invoke('BroadcastInvoiceChange');
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: `Невозможно сохранить изменения!`,
            });
          });
        this.loadingModal = false;
      }
      this.modalAddInvoice = false;
    },
    openModalPrint(row) {
      console.log(row);
      this.idPatient = row.idPatient;
      this.idAppointment = row.idAppointment;
      this.isModalPrintVisible = !this.isModalPrintVisible;
    },
  },
};
</script>

<style lang="scss" scoped></style>
