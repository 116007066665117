<template>
  <el-dialog
    class="modal-add-edit-insurance-policy"
    :close-on-click-modal="false"
    :visible="isModalAddEditInsurancePolicyVisible"
    @update:visible="closeModalAddEditInsurancePolicy"
    width="50%"
  >
    <h5 slot="title">
      {{
        isEditMode
          ? 'Редактирование страхового полиса'
          : 'Добавление страхового полиса'
      }}
    </h5>
    <el-row :gutter="24" class="mb-16">
      <el-col :sm="24" :md="12" :lg="8">
        <label>Тип страхования <span class="danger">*</span></label>
        <el-select
          size="small"
          v-model="currentInsurancePolicy.insuranceType"
          placeholder="Страховая организация"
          @change="handleChangeInsuranceType"
        >
          <el-option
            v-for="item in insuranseTypeList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :sm="24" :md="12" :lg="16">
        <label>Страховая организация <span class="danger">*</span></label>
        <el-select
          size="small"
          v-model="currentInsurancePolicy.insuranceCompanyId"
          placeholder="Страховая организация"
          :class="{
            invalid: $v.currentInsurancePolicy.insuranceCompanyId.$error,
          }"
        >
          <el-option
            v-for="item in insuranceOrganizationList"
            :key="item.id"
            :label="item.shortName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <small
          class="helper-text invalid"
          v-if="$v.currentInsurancePolicy.insuranceCompanyId.$error"
          >Поле является обязательным</small
        >
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mb-16">
      <el-col :sm="24" :md="12" :lg="8">
        <label>ЕНП <span class="danger">*</span></label>
        <el-input
          :disabled="currentInsurancePolicy.insuranceType === 1"
          size="small"
          placeholder="ЕНП"
          v-model="currentInsurancePolicy.singlePolicyNumber"
          :class="{
            invalid: $v.currentInsurancePolicy.singlePolicyNumber.$error,
          }"
        ></el-input>
        <small
          class="helper-text invalid"
          v-if="$v.currentInsurancePolicy.singlePolicyNumber.$error"
          >Поле является обязательным</small
        >
      </el-col>
      <el-col :sm="24" :md="12" :lg="8">
        <label>Серия <span class="danger">*</span></label>
        <el-input
          size="small"
          placeholder="Серия"
          v-model="currentInsurancePolicy.series"
          :class="{
            invalid: $v.currentInsurancePolicy.series.$error,
          }"
        ></el-input>
        <small
          class="helper-text invalid"
          v-if="$v.currentInsurancePolicy.series.$error"
          >Поле является обязательным</small
        >
      </el-col>
      <el-col :sm="24" :md="12" :lg="8">
        <label>Номер <span class="danger">*</span></label>
        <el-input
          size="small"
          placeholder="Номер"
          v-model="currentInsurancePolicy.number"
          :class="{
            invalid: $v.currentInsurancePolicy.number.$error,
          }"
        ></el-input>
        <small
          class="helper-text invalid"
          v-if="$v.currentInsurancePolicy.number.$error"
          >Поле является обязательным</small
        >
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mb-16">
      <el-col :sm="24" :md="12" :lg="8">
        <label>Дата начала <span class="danger">*</span></label>
        <el-date-picker
          size="small"
          format="dd.MM.yyyy"
          value-format="yyyy-MM-dd"
          v-model="currentInsurancePolicy.validFrom"
          type="date"
          placeholder="Введите дату"
          :class="{
            invalid: $v.currentInsurancePolicy.validFrom.$error,
          }"
        ></el-date-picker>
        <small
          class="helper-text invalid"
          v-if="$v.currentInsurancePolicy.validFrom.$error"
          >Поле является обязательным</small
        >
      </el-col>
      <el-col :sm="24" :md="12" :lg="8">
        <label
          >Дата окончания
          <span
            v-show="currentInsurancePolicy.insuranceType === 1"
            class="danger"
            >*</span
          ></label
        >
        <el-date-picker
          size="small"
          format="dd.MM.yyyy"
          value-format="yyyy-MM-dd"
          v-model="currentInsurancePolicy.validUntil"
          type="date"
          placeholder="Введите дату"
          :class="{
            invalid: $v.currentInsurancePolicy.validUntil.$error,
          }"
        ></el-date-picker>
        <small
          class="helper-text invalid"
          v-if="$v.currentInsurancePolicy.validUntil.$error"
          >Поле является обязательным</small
        >
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt">
      <el-col :span="24">
        <el-button size="small" type="primary" @click="saveInsurancePolicy"
          >Сохранить</el-button
        >
        <el-button size="small" @click="closeModalAddEditInsurancePolicy"
          >Отменить</el-button
        >
      </el-col>
      <el-col :span="12"> </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'ModalAddEditInsurancePolicyComponent',
  props: {
    isModalAddEditInsurancePolicyVisible: {
      type: Boolean,
      default: false,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    insurancePolicy: {
      type: Object,
    },
    insuranceOrganizationList: {
      type: Array,
    },
  },
  data() {
    return {
      insuranseTypeList: [
        { id: 0, value: 'ОМС' },
        { id: 1, value: 'ДМС' },
      ],
      isValid: false,
    };
  },
  computed: {
    currentInsurancePolicy: {
      get() {
        return this.insurancePolicy;
      },
      set(value) {
        this.$emit('update:insurancePolicy', value);
      },
    },
  },
  validations() {
    if (this.currentInsurancePolicy.insuranceType === 0) {
      return {
        currentInsurancePolicy: {
          insuranceCompanyId: { required },
          insuranceType: { required },
          number: { required },
          series: { required },
          singlePolicyNumber: { required },
          validFrom: { required },
          validUntil: {},
        },
      };
    }
    if (this.currentInsurancePolicy.insuranceType === 1) {
      return {
        currentInsurancePolicy: {
          insuranceCompanyId: { required },
          insuranceType: { required },
          number: { required },
          series: { required },
          singlePolicyNumber: {},
          validFrom: { required },
          validUntil: { required },
        },
      };
    }
  },
  methods: {
    clearAddEditModalInsurancePolicy() {
      this.$emit('clearAddEditModalInsurancePolicy');
    },
    closeModalAddEditInsurancePolicy() {
      this.clearAddEditModalInsurancePolicy();
      this.$emit('update:isModalAddEditInsurancePolicyVisible');
      this.$v.$reset();
    },
    handleChangeInsuranceType(value) {
      if (value == 1) {
        this.currentInsurancePolicy.singlePolicyNumber = null;
      }
    },
    saveInsurancePolicy() {
      console.log('Add method insurance policy was called!');
      if (!this.$v.$invalid) {
        if (!this.isEditMode) {
          this.$emit('addInsurancePolicy', {
            ...this.insurancePolicy,
            isNew: true,
          });

          this.closeModalAddEditInsurancePolicy();
        } else {
          console.log('Send request to update policy by ID');
          this.$emit('updateInsurancePolicy', this.insurancePolicy);
          this.closeModalAddEditInsurancePolicy();
        }
      } else {
        this.$v.$touch();
      }
    },
  },
};
</script>
