<template>
  <el-row class="h-100vh">
    <el-col :lg="12" class="h-100 login-right">
      <el-col :lg="12" :md="12" :span="12" class="login-form-wrap">
        <div class="login-welcome">
          <h6>Войдите в систему</h6>
          <span
            >Добро пожаловать в медицинскую электронную систему
            <a href="https://www.documeds.ru">Documeds</a></span
          >
          <span>Для входа введите имя пользователя и пароль</span>
        </div>
        <el-form
          class="login-form"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          :hide-required-asterisk="true">
          <el-form-item prop="userLogin">
            <el-input
              class="login-input"
              size="small"
              placeholder="Введите имя пользователя"
              prefix-icon="el-icon-user"
              v-model="ruleForm.userLogin">
            </el-input>
          </el-form-item>
          <el-form-item prop="userPassword">
            <el-input
              class="login-input"
              size="small"
              prefix-icon="el-icon-lock"
              placeholder="Введите пароль"
              v-model="ruleForm.userPassword"
              show-password>
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-button
              size="small"
              :loading="loadingBtn"
              class="login-btn"
              type="primary"
              :autofocus="true"
              @click="submitForm('ruleForm')"
              >Войти</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
      <div class="login-help">
        <span
          >Проблемы со входом? Обратитесь в службу
          <a href="#">технической поддержки</a></span
        >
      </div></el-col
    >
    <el-col :lg="12" class="h-100 login-left">
      <div class="login-logo">
        <span>Медицинская информационная система</span>
        <img src="../assets/logo.svg" alt="Documeds" />
      </div>
      <span class="login-brand"
        >© Все права защищены. ООО «ЦИТ-Рязань», 2023 г.
      </span>
    </el-col>
  </el-row>
</template>

<script>
  import AuthManager from "./../services/authManager.js";

  export default {
    data() {
      return {
        loadingBtn: false,
        ruleForm: {
          userLogin: "",
          userPassword: "",
        },
        rules: {
          userLogin: [
            {
              required: true,
              message: "Имя пользователя обязательное",
              trigger: "validate",
            },
          ],
          userPassword: [
            {
              required: true,
              message: "Пароль обязательный",
              trigger: "validate",
            },
          ],
        },
      };
    },
    created() {
      // обработка нажатия Enter
      document.addEventListener("keyup", (event) => {
        if (event.code === "Enter") this.submitForm("ruleForm");
      });
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            this.loadingBtn = true;
            try {
              await AuthManager.singIn(
                this.ruleForm.userLogin,
                this.ruleForm.userPassword
              );
              if (AuthManager.isAuthenticated) {
                this.loadingBtn = false;
                this.$router.push("/");
              } else {
                console.log(
                  "Неверный токен пользователя или истек срок его действия!"
                );
              }
            } catch (error) {
              this.loadingBtn = false;
              // console.log(error.response.status);
              // if (error.response.status == 500) {
              this.$message({
                type: "error",
                message:
                  "Неверное имя пользователя или пароль! Проверьте введенные данные и попробуйте еще раз.",
              });
              // }
            }
          }
        });
      },
    },
  };
</script>
