<template>
  <div class="education-main">
    <el-row :gutter="24">
      <el-col :lg="18" class="d-flex flex-between flex-center mb-16 flex-wrap">
        <h4 class="mt-16">Создание графика работ и распределение врачей по кабинетам</h4>
        <el-button class="mt-16" size="small" @click="openModalVideo" type="primary"
          >Смотреть видео<i class="el-icon-play"></i
        ></el-button>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt-16">
      <el-col :lg="18">
        <h6>Создание графика работ</h6>
        <p>
          После того, как Вы добавили в организацию филиалы, кабинеты, назначили
          сотрудников на должности, требуется определить приемные часы врачей в
          Вашем медицинском центре.
        </p>
        <p>
          Для этого необходимо перейти в пункт меню «Настройки»/«Графики работ».
          Раздел представляет собой календарь с отображением времени работы
          определенного врача в течение выбранного месяца.
        </p>
        <el-divider></el-divider>
      </el-col>
      <el-col :lg="18">
        <el-image fit="cover" :src="src.url1" :preview-src-list="srcList">
        </el-image>
      </el-col>
      <el-col :lg="18">
        <el-divider></el-divider>
        <p>
          В левой верхней части Вы можете выбрать врача для отображения его
          графика работ, а в правой - переключать месяца для отображения.
        </p>
        <p>
          Для того, чтобы добавить рабочий день сотруднику, необходимо нажать
          левой кнопкой мыши по пустому дню. Пустые дни выделены серым цветом.
        </p>
        <el-image
          class="mt-16 mb-16"
          fit="cover"
          :src="src.url2"
          :preview-src-list="srcList">
        </el-image>
      </el-col>
      <el-col :lg="18">
        <p>
          Далее необходжимо выбрать даты рабочего времени сотрудника. Компонент
          ввода дат поддерживает множественный выбор, как показано ниже.
        </p>
        <el-image
          class="mt-16 mb-16"
          fit="cover"
          :src="src.url3"
          :preview-src-list="srcList">
        </el-image>
        <p>
          Далее необходимо указать рабочее время сотрудника и нажать кнопку
          «Сохранить».
        </p>
        <p>
          При добавлении записей в график рабочие дни сотрудника будут
          подсвечены зеленым цветом с отображением часов работы. Приемные часы
          работы из графика автоматически становятся доступны для записи в
          разделе «Расписание»
        </p>
        <p>
          Для внесения изменений в график работ необходимо нажать левой кнопкой
          мыши на требуемую ячейку с датой и произвести необходимые изменения в
          открывшемся окне.
        </p>
        <el-col :lg="18" class="d-flex flex-between flex-center mb-16 flex-wrap">
          <el-image
            class="mt-16 mb-16"
            fit="cover"
            :src="src.url6"
            :preview-src-list="srcList">
          </el-image>
        </el-col>
      </el-col>
      <el-col :lg="18">
        <p>
          Для редактирования доступны должность врача (исполнение должности, для
          которого создана запись расписания) и часы работы (рабочее время). При
          нажатии на кнопку «Удалить» будет удалена запись в графике работ врача
          на выбранный день.
        </p>
      </el-col>
      <el-col :lg="18">
        <el-divider></el-divider>
        <h6>Распределение врачей по кабинетам</h6>
        <p>
          Для того, чтобы обозначить, где врач будет вести прием, необходимо
          определить кабинет для работы. В компоненте «Загруженность кабинетов»
          можно увидеть диаграмму рассадки врачей по кабинетам в опреденные
          промежутки времени.
        </p>
        <el-image
          class="mt-16 mb-16"
          fit="cover"
          :src="src.url4"
          :preview-src-list="srcList">
        </el-image>
        <p>
          Для того, чтобы определить врачу кабинет, необходимо нажать левой
          кнопкой мыши по промежутку, отмеченному зеленым цветом. Далее выбрать
          кабинет, в который необходимо определить врача. По умолчанию временным
          промежутком для рассадки является весь рабочий день врача. Для того,
          чтобы разбить данный промежуток на несколько отрезков, необходимо
          убрать чек-бокс «Заполнить» и выбрать нужный период времени для
          каждого кабинета.
        </p>
        <p>
          Для применения изменений нажмите кнопку «Сохранить». Назначенный
          кабинет отобразится на диаграмме кабинетов промежутком, отмеченным
          белым цветом, с указанием наименования.
        </p>
        <p>
          Для удаления присвоения кабинета необходимо навести курсор мыши на
          требуемую запись и во всплывающем окне нажать на кнопку «Отменить».
        </p>
      </el-col>
      <el-col :lg="18">
        <el-image
          class="mt-16 mb-16"
          fit="cover"
          :src="src.url5"
          :preview-src-list="srcList">
        </el-image>
        <p>
          Данный раздел позволит администратору-регистратору клиники
          распределить врачей по кабинетам и выстроить правильный поток
          обслуживания пациентов.
        </p>
      </el-col>
    </el-row>
    <!-- модальное окно просмотра видео-урока -->
    <el-dialog
      title="Видео-урок"
      class="modal-play-video"
      :visible.sync="modalVideo"
      width="100%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true">
      <div v-if="!idVideo">
        <h6>К сожалению видео-урок не доступен</h6>
        <p>
          В скором времени мы добавим данный видео-урок по обучению работе в
          Documeds
        </p>
      </div>
      <div v-if="idVideo">
        <VideoPlayerComponent :idVideo="idVideo"></VideoPlayerComponent>
      </div>
      <el-button
        class="education-modal-btn"
        type="primary"
        @click="openModalVideo"
        >Продолжить обучение в текстовом формате</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
  import VideoPlayerComponent from "@/components/VideoPlayerComponent.vue";
  export default {
    components: { VideoPlayerComponent },
    data() {
      return {
        modalVideo: false,
        idVideo: "sjBhxXTW34g",
        src: {
          url1: require("@/assets/img/education/add-timetable-1.png"),
          url2: require("@/assets/img/education/add-timetable-2.png"),
          url3: require("@/assets/img/education/add-timetable-3.png"),
          url4: require("@/assets/img/education/add-timetable-4.png"),
          url5: require("@/assets/img/education/add-timetable-5.png"),
          url6: require("@/assets/img/education/add-timetable-6.png"),
        },
        srcList: [
          require("@/assets/img/education/add-timetable-1.png"),
          require("@/assets/img/education/add-timetable-2.png"),
          require("@/assets/img/education/add-timetable-3.png"),
          require("@/assets/img/education/add-timetable-4.png"),
          require("@/assets/img/education/add-timetable-5.png"),
          require("@/assets/img/education/add-timetable-6.png"),
        ],
      };
    },
    methods: {
      openModalVideo() {
        this.modalVideo = !this.modalVideo;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
