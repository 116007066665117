//получение возраста из даты рождения
export function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

//получение массива байт из base64
export function base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes;
}

//склонение слова "год" для отображения возраста
export function getAgeString(year) {
  if (!year) {
    if (year === 0) {
      return '0 лет';
    }
    return 'Дата рождения не указана';
  }
  let yearStr = year.toString();
  if (
    yearStr == '11' ||
    yearStr == '12' ||
    yearStr == '13' ||
    yearStr == '14' ||
    yearStr.endsWith('0') ||
    yearStr.endsWith('5') ||
    yearStr.endsWith('6') ||
    yearStr.endsWith('7') ||
    yearStr.endsWith('8') ||
    yearStr.endsWith('9')
  ) {
    return yearStr + ' лет';
  }
  if (yearStr.endsWith('1')) {
    return yearStr + ' год';
  } else {
    return yearStr + ' года';
  }
}

// вывод даты рождения в правильном формате
export function getDateString(birthDate) {
  if (birthDate) {
    return new Intl.DateTimeFormat('ru-RU').format(new Date(birthDate));
  } else {
    return '';
  }
}
//скачивание файла
export function downloadFile(file, type, filename) {
  let bytes = base64ToArrayBuffer(file);
  let blob = new Blob([bytes], { type: type ? 'application/pdf' : type });
  let a = document.createElement('a');
  a.style.display = 'none';
  a.href = URL.createObjectURL(blob);
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(a.href);
}

export async function downloadFileFromStream(file, fileName) {
  const blob = new Blob([await file]);
  const url = URL.createObjectURL(blob);
  const anchorElement = document.createElement('a');
  anchorElement.href = url;
  anchorElement.download = fileName + '.xlsx';
  anchorElement.click();
  anchorElement.remove();
  URL.revokeObjectURL(url);
}

//определение часового пояса
export function getTimeOffset() {
  return new Date().getTimezoneOffset() / 60;
}
// упрощенные методы для работы с LocalStorage
export const getItem = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (e) {
    console.log('Error getting data from localStorage', e);
    return null;
  }
};

export const setItem = (key, data) => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (e) {
    console.log('Error saving data in localStorage', e);
  }
};

export const deleteItem = (key) => {
  try {
    return localStorage.deleteItem(key);
  } catch (e) {
    console.log('Error getting data from localStorage', e);
    return null;
  }
};

// метод приводящий строку ввода к строке начиннающийся с большой буквы

export const setFirstLetterToUpperCase = (string) => {
  if (typeof string === 'string' && string) {
    return string[0].toUpperCase() + string.slice(1);
  } else {
    return string;
  }
};

export const copyObject = (object) => {
  try {
    if (object != null && typeof object === 'object') {
      return JSON.parse(JSON.stringify(object));
    } else {
      throw new Error('Expected type "Object');
    }
  } catch (e) {
    console.log('Error while copying object', e);
  }
};
