<template>
  <div class="education-main">
    <el-row :gutter="24">
      <el-col :lg="18" class="d-flex flex-between flex-center mb-16 flex-wrap">
        <h4 class="mt-16">Раздел «Мой профиль»</h4>
        <el-button class="mt-16" size="small" @click="openModalVideo" type="primary"
          >Смотреть видео<i class="el-icon-play"></i
        ></el-button>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt-16">
      <el-col :lg="18">
        <p>
          Раздел «Мой профиль» создан для ознакомления с данными текущего
          аккаунта. Для того, чтобы перейти в раздел, необходимо нажать на
          значок пользователя в верхнем правом углу экрана и в выпадающем меню
          нажать кнопку «Мой профиль».
        </p>
        <p>Информация представлена в виде вкладок:</p>
        <ul>
          <li>
            Вкладка «Мои данные» информирует о персональных данных сотрудника
            без возможности редактирования
            <el-image
              class="mt-16 mb-16"
              fit="contain"
              :src="src.url1"
              :preview-src-list="srcList">
            </el-image>
          </li>
          <li>
            Вкладка «Мои пациенты» информирует о пациентах, которые когда-либо
            были на приеме у текущего пользователя. Данный раздел позволяет
            осуществить быстрый поиск недавно проконсультированных пациентов. Из
            данного раздела можно перейти в разделы «Случаи обращения» и «Счета»
            при наличии соответствующих прав. Переход осуществляется при нажатии
            на кнопку «...» в строке с требуемым пациентом. Из раздела «Случаи
            обращения» можно перейти в раздел «Приемы» при нажатии на кнопку
            «Перейти» в строке с требуемым приемом
            <el-image
              class="mt-16 mb-16"
              fit="contain"
              :src="src.url2"
              :preview-src-list="srcList">
            </el-image>
          </li>
          <li>
            «Мой график работы» представляет собой календарь с отображением
            времени работы текущего пользователя в течение выбранного месяца.
            Данная вкладка аналогична разделу
            <router-link to="/education/add-timetable-lesson"
              >«Создание графика работ»</router-link
            >, отфильтрованному по текущему пользователю и доступному только для
            просмотра
            <el-image
              class="mt-16 mb-16"
              fit="contain"
              :src="src.url3"
              :preview-src-list="srcList">
            </el-image>
          </li>
          <li>
            «Доступ в систему» отображает текущее имя пользователя, а также
            позволяет изменить пароль на удобный Вам. В целях безопасности
            рекомендуется смена пароля каждые 3 месяца
            <el-image
              class="mt-16 mb-16"
              fit="contain"
              :src="src.url4"
              :preview-src-list="srcList">
            </el-image>
          </li>
        </ul>
      </el-col>
    </el-row>
    <!-- модальное окно просмотра видео-урока -->
    <el-dialog
      title="Видео-урок"
      class="modal-play-video"
      :visible.sync="modalVideo"
      width="100%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true">
      <div v-if="!idVideo">
        <h6>К сожалению видео-урок не доступен</h6>
        <p>
          В скором времени мы добавим данный видео-урок по обучению работе в
          Documeds
        </p>
      </div>
      <div v-if="idVideo">
        <VideoPlayerComponent :idVideo="idVideo"></VideoPlayerComponent>
      </div>
      <el-button
        class="education-modal-btn"
        type="primary"
        @click="openModalVideo"
        >Продолжить обучение в текстовом формате</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
  import VideoPlayerComponent from "@/components/VideoPlayerComponent.vue";
  export default {
    components: { VideoPlayerComponent },
    data() {
      return {
        modalVideo: false,
        idVideo: "vv6MA7EuU_Y",
        src: {
          url1: require("@/assets/img/education/profile-1.png"),
          url2: require("@/assets/img/education/profile-2.png"),
          url3: require("@/assets/img/education/profile-3.png"),
          url4: require("@/assets/img/education/profile-4.png"),
        },
        srcList: [
          require("@/assets/img/education/profile-1.png"),
          require("@/assets/img/education/profile-2.png"),
          require("@/assets/img/education/profile-3.png"),
          require("@/assets/img/education/profile-4.png"),
        ],
      };
    },
    methods: {
      openModalVideo() {
        this.modalVideo = !this.modalVideo;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
