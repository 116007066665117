<template>
  <div class="education-main">
    <el-row :gutter="24">
      <el-col :lg="18" class="d-flex flex-between flex-center mb-16 flex-wrap">
        <h4 class="mt-16">Раздел «Настройки шаблонов для печати»</h4>
        <el-button class="mt-16" size="small" @click="openModalVideo" type="primary"
          >Смотреть видео<i class="el-icon-play"></i
        ></el-button>
      </el-col>
    </el-row>
    <el-row :gutter="24" class="mt-16">
      <el-col :lg="18">
        <p>
          Данный раздел позволяет произвести настройку шаблонов документов для
          печати.
        </p>
        <p>
          Для того, чтобы настроить логотип организации, необходимо нажать левой
          кнопкой мыши на символ добавления изображения в блоке «Логотип». Во
          всплывающем окне «Открытие» нужно выбрать требуемое изображение и
          нажать на кнопку «Открыть». Логотип будет отображен в верхнем левом
          углу документа на первой странице. Логотип может быть изменен или
          удален. Для удаления необходимо нажать на кнопку «Удалить».
        </p>
        <p>
          Для того, чтобы настроить текст шапки документа, необходимо внести в
          поле для ввода блока «Текст шапки документа» требуемую информацию об
          организации.
        </p>
        <el-image
          class="mt-16 mb-16"
          fit="cover"
          :src="src.url1"
          :preview-src-list="srcList">
        </el-image>
        <p>
          В данном разделе в блоке «Выберите документы для отображения при
          печати» можно настроить перечень документов, доступных для печати в
          окне «Печать документов пациента». Напомним, что данное окно может
          быть вызвано тремя способами: из раздела
          <router-link to="/education/add-patient-lesson"
            >«Пациенты» </router-link
          >, из раздела
          <router-link to="/education/add-appointment-lesson"
            >«Расписание»
          </router-link>
          и при нажатии на кнопку «Печать» в
          <router-link to="/education/add-visit-lesson"
            >текущем приеме пациента </router-link
          >. Включить документ в перечень доступных для печати документов в
          текущей организации можно путем активации переключателя слева от
          названия документа. Справа от названия документа расположен символ «<i
            class="el-icon-eye"></i
          >», нажатие на который открывает окно предварительного просмотра
          настроенного документа.
        </p>
        <p>
          Флаги, установленные в столбцах «Логотип» и «Текст» напротив
          требуемого документа, выводят в шапку печатной формы документа
          заданные логотип и текст соответсвенно.
        </p>
        <p>
          Для применения внесенных изменений необходимо нажать на кнопку
          «Сохранить».
        </p>
      </el-col>
    </el-row>
    <!-- модальное окно просмотра видео-урока -->
    <el-dialog
      title="Видео-урок"
      class="modal-play-video"
      :visible.sync="modalVideo"
      width="100%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true">
      <div v-if="!idVideo">
        <h6>К сожалению видео-урок не доступен</h6>
        <p>
          В скором времени мы добавим данный видео-урок по обучению работе в
          Documeds
        </p>
      </div>
      <div v-if="idVideo">
        <VideoPlayerComponent :idVideo="idVideo"></VideoPlayerComponent>
      </div>
      <el-button
        class="education-modal-btn"
        type="primary"
        @click="openModalVideo"
        >Продолжить обучение в текстовом формате</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
  import VideoPlayerComponent from "@/components/VideoPlayerComponent.vue";
  export default {
    components: { VideoPlayerComponent },
    data() {
      return {
        modalVideo: false,
        idVideo: "yLXCdsvyyBs",
        src: {
          url1: require("@/assets/img/education/print-templates-1.png"),
        },
        srcList: [require("@/assets/img/education/print-templates-1.png")],
      };
    },
    methods: {
      openModalVideo() {
        this.modalVideo = !this.modalVideo;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
