const invoicesModule = {
    state: () => ({
        invoices: [],
        invoicesFilter: {
          pagenumber: 1,
          pagesize: 10,
        },
    }),
    getters: {
        invoices: (state) => {
            return state.invoices;
        },
        invoicesFilter: (state) => {
          return state.invoicesFilter;
        },
    },
    mutations: {
        SET_INVOICES: (state, payload) => {
            state.invoices = payload;
        },
        SET_INVOICESFILTER: (state, payload) => {
          state.invoicesFilter = payload;
        },
    },
}

export default invoicesModule