import axios from '@/api/axios';

class SupportRequestManager{
    async sendSupportRequest(request) {
        try {
            let {data} = await axios.post('supportrequest/', request);
            return { data: data, error: null};
        }
        catch (error) {
            return { data: null, error: 1};
        }
    }
}
export default new SupportRequestManager();