import axios  from '@/api/axios';
import { base64ToArrayBuffer } from '../services/commonService';

class MeddocManager {
    async getMeddoc(id) {
        let {data} = await axios.get('medicaldocument/' + id);
        return data.value;
    }

    async addMeddoc(meddoc) {
        await axios.post('medicaldocument', meddoc);
        let {data} = await axios.get('appointment' , { params: { id: meddoc.idAppointment}});
        let appointmenmt = data;
        appointmenmt.isVisit = 1;
        return appointmenmt;
    }

    async updateMeddoc(meddoc) {
        await axios.put('medicaldocument', meddoc);
        let {data} = await axios.get('appointment', { params: { id: meddoc.idAppointment}});
        let appointmenmt = data;
        appointmenmt.isVisit = 1;
        return appointmenmt;
    }
    async printMeddoc(meddoc) {
        let pdfFile = await axios.post('medicaldocumentprint', meddoc);
        let bytes = base64ToArrayBuffer(pdfFile.data);
        let blob = new Blob([bytes], { type: "application/pdf" });
        let link = URL.createObjectURL(blob);
        return link;
        // let bytes = base64ToArrayBuffer(pdfFile.data);
        // let blob = new Blob([bytes], { type: 'application/pdf' });
        // let a = document.createElement("a");
        // a.style.display = "none";
        // a.href = URL.createObjectURL(blob);
        // if(meddoc.printMode == 1){
        //     a.download = "Протокол осмотра.pdf";
        // }
        // else{
        //     a.download = "Назначения.pdf";
        // }
        // document.body.appendChild(a);
        // a.click();
        // window.URL.revokeObjectURL(a.href);
    }

}

export default new MeddocManager();