<template>
  <div class="dashboard bg-grey">
    <div class="dashboard-header">
      <div class="dashboard-left">
        <el-avatar icon="el-icon-user" :size="60"> </el-avatar>
        <div class="dashboard-user">
          <span class="dashboard-user-welcome">Добро пожаловать,</span>
          <span class="dashboard-user-info"> {{ userFullName }} </span>
        </div>
      </div>
      <div class="dashboard-right">
        <span class="dashboard-date">{{
          new Date(Date.now()).toLocaleString('ru-RU', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
        }}</span>
        <el-dropdown
          size="mini"
          class="dashboard-header-dropdown"
          trigger="click"
          @command="handleCommand"
        >
          <span class="el-dropdown-link"
            >{{ selectedFilial.name }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="filial in filials"
              :key="filial.id"
              :command="filial.id"
              >{{ filial.name }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-divider></el-divider>
    <div class="dashboard-content">
      <el-container>
        <el-row :gutter="24" class="w-100">
          <el-col
            :span="24"
            :lg="12"
            :md="24"
            :xs="24"
            class="dashboard-content-left"
          >
            <div class="dashboard-card" v-loading="loading">
              <h5 class="dashboard-card-title">Сегодня ведут приём</h5>
              <div class="dashboard-card-inner d-flex flex-column">
                <el-tabs v-model="departmentSelect">
                  <el-tab-pane
                    v-for="department in departments"
                    :key="department.id"
                    :label="department.name"
                    :name="department.name"
                  >
                    <el-row :gutter="24">
                      <el-col
                        :span="24"
                        :sm="12"
                        v-for="item in departments
                          .find((x) => x.name == departmentSelect)
                          ?.schedules.slice(0, 6)"
                        :key="item.idDoctor"
                      >
                        <div class="dashboard-card-doctor">
                          <el-avatar
                            icon="el-icon-user"
                            size="large"
                          ></el-avatar>
                          <div class="dashboard-card-doctor-fio">
                            <span class="dashboard-card-doctor-name"
                              >{{ item.doctorFullName }}
                            </span>
                            <span class="dashboard-card-doctor-patient"
                              >пациентов: <b>{{ item.appointmentsCount }}</b>
                            </span>
                          </div>
                          <el-tooltip
                            class="item"
                            effect="dark"
                            content="Перейти к расписанию"
                            placement="top-start"
                          >
                            <el-button
                              size="small"
                              type="primary"
                              plain
                              class="dashboard-card-invoice-btn ml-auto"
                            >
                              <router-link
                                to="/schedule"
                                class="dashboard-card-doctor-link"
                              >
                                <i class="el-icon-arrow-right"></i>
                              </router-link>
                            </el-button>
                          </el-tooltip>
                        </div>
                      </el-col>
                    </el-row>
                  </el-tab-pane>
                </el-tabs>
              </div>
              <el-button
                size="small"
                class="dashboard-card-btn"
                type="primary"
                plain
              >
                <router-link to="/schedule"> Показать всех врачей </router-link>
              </el-button>
            </div>
            <div class="dashboard-card" v-loading="loading">
              <h5 class="dashboard-card-title">Статистика приемов</h5>
              <div class="dashboard-card-inner d-flex flex-between">
                <div class="dashboard-card-stat">
                  <div class="dashboard-card-stat-icon">
                    <i class="el-icon-users-alt"></i>
                  </div>
                  <div class="d-flex flex-column">
                    <span class="dashboard-card-stat-text">Всего</span>
                    <h4 class="dashboard-card-stat-value">{{ stats.all }}</h4>
                  </div>
                </div>
                <div class="dashboard-card-stat success">
                  <div class="dashboard-card-stat-icon">
                    <i class="el-icon-user-check"></i>
                  </div>
                  <div class="d-flex flex-column">
                    <span class="dashboard-card-stat-text">Принято</span>
                    <h4 class="dashboard-card-stat-value">
                      {{ stats.complite }}
                    </h4>
                  </div>
                </div>
                <div class="dashboard-card-stat warning">
                  <div class="dashboard-card-stat-icon">
                    <i class="el-icon-user-plus"></i>
                  </div>
                  <div class="d-flex flex-column">
                    <span class="dashboard-card-stat-text">Ожидают</span>
                    <h4 class="dashboard-card-stat-value">
                      {{ stats.expect }}
                    </h4>
                  </div>
                </div>
                <div class="dashboard-card-stat danger">
                  <div class="dashboard-card-stat-icon">
                    <i class="el-icon-user-minus"></i>
                  </div>
                  <div class="d-flex flex-column">
                    <span class="dashboard-card-stat-text">Отменено</span>
                    <h4 class="dashboard-card-stat-value">
                      {{ stats.cancel }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col
            :span="24"
            :lg="12"
            :md="24"
            :xs="24"
            class="dashboard-content-right"
            v-if="scheduleShow"
          >
            <div class="dashboard-card" v-loading="loading">
              <h5 class="dashboard-card-title">Моё расписание сегодня</h5>
              <div class="dashboard-card-inner d-flex flex-column">
                <div v-if="schedule.length == 0">Сегодня нет приема</div>
                <el-table
                  class="dashboard-card-schedule"
                  :data="
                    schedule[0].appointments
                      .filter(
                        (x) =>
                          x.idAppointmentStatus != 8 &&
                          x.idAppointmentStatus != 7 &&
                          x.idAppointmentStatus != 6
                      )
                      .slice(0, 8)
                  "
                  style="width: 100%"
                  v-if="schedule.length > 0"
                >
                  <el-table-column
                    show-overflow-tooltip
                    prop="patient.lastName"
                    label="Пациент"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="idAppointmentType"
                    label="Приём"
                    :formatter="formatAppointmentType"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="dateTimeTo"
                    label="Время"
                    :formatter="formatStartTime"
                  >
                  </el-table-column>

                  <el-table-column>
                    <template slot-scope="scope">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="Начать приём"
                        placement="top-start"
                      >
                        <el-button
                          size="small"
                          type="primary"
                          plain
                          class="dashboard-card-invoice-btn"
                        >
                          <router-link
                            :to="{
                              path: '/visit/' + scope.row.id,
                            }"
                          >
                            <i class="el-icon-play"></i>
                          </router-link>
                        </el-button>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <el-button
                size="small"
                class="dashboard-card-btn mt-auto"
                type="primary"
                plain
              >
                <router-link to="/schedule">
                  Показать всех пациентов
                </router-link>
              </el-button>
            </div>
          </el-col>

          <!-- блок со счетами -->
          <el-col
            :span="24"
            :lg="12"
            :md="24"
            :xs="24"
            class="dashboard-content-right"
            v-if="invoicesShow"
          >
            <div class="dashboard-card" v-loading="loading">
              <h5 class="dashboard-card-title">Счета клиники сегодня</h5>
              <div class="dashboard-card-inner d-flex flex-column">
                <div v-if="invoices.length == 0">Сегодня счетов нет</div>
                <el-table
                  class="dashboard-card-schedule"
                  :data="
                    invoices.filter((x) => x.paymentStatus != 1).slice(0, 10)
                  "
                  style="width: 100%"
                  v-if="invoices.length > 0"
                >
                  <el-table-column
                    prop="numberStr"
                    label="Номер счета"
                    width="200px"
                  >
                  </el-table-column>
                  <el-table-column
                    show-overflow-tooltip
                    prop="doctor.lastName"
                    label="Врач"
                  >
                  </el-table-column>
                  <el-table-column
                    show-overflow-tooltip
                    prop="patient.lastName"
                    label="Пациент"
                  >
                  </el-table-column>
                  <el-table-column prop="sum" label="Сумма, ₽">
                  </el-table-column>
                  <el-table-column prop="paymentStatus" label="Статус">
                    <template slot-scope="scope">
                      <i
                        class="el-icon-circle-check success"
                        v-if="scope.row.paymentStatus == 1"
                      ></i>
                      <i
                        class="el-icon-circle-close danger"
                        v-if="scope.row.paymentStatus != 1"
                      ></i>
                    </template>
                  </el-table-column>
                  <el-table-column width="80px" label="">
                    <template slot-scope="scope">
                      <el-dropdown trigger="click">
                        <span class="el-dropdown-link"
                          ><i class="el-icon-more"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item v-if="scope.row.paymentStatus != 1">
                            <el-button
                              class="dropdown-btn"
                              size="small"
                              type="text"
                              @click="openInvoicePayment(scope.row)"
                            >
                              <i class="el-icon-money-insert"></i>
                              Оплатить
                            </el-button>
                          </el-dropdown-item>
                          <el-dropdown-item>
                            <el-button
                              class="dropdown-btn"
                              size="small"
                              type="text"
                              @click="openInvoiceEdit(scope.row)"
                            >
                              <i class="el-icon-edit"></i>
                              Редактировать
                            </el-button>
                          </el-dropdown-item>
                          <el-dropdown-item>
                            <el-button
                              class="dropdown-btn"
                              size="small"
                              type="text"
                              @click="openModalPrint(scope.row)"
                            >
                              <i class="el-icon-print"></i>
                              Печать
                            </el-button>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column width="160px">
                    <template slot-scope="scope">
                      <el-tooltip
                        class="item hidden-xs-only"
                        effect="dark"
                        content="Оплатить счет"
                        placement="top-start"
                      >
                        <el-button
                          size="small"
                          type="primary"
                          plain
                          class="dashboard-card-invoice-btn"
                          @click="openInvoicePayment(scope.row)"
                        >
                          <i class="el-icon-credit-card"></i>
                        </el-button>
                      </el-tooltip>
                      <el-button
                        size="small"
                        type="primary"
                        plain
                        class="dashboard-card-invoice-btn hidden-sm-and-up"
                        @click="openInvoicePayment(scope.row)"
                      >
                        <i class="el-icon-credit-card"></i>
                      </el-button>
                      <el-tooltip
                        class="item hidden-xs-only"
                        effect="dark"
                        content="Редактировать счет"
                        placement="top-start"
                      >
                        <el-button
                          size="small"
                          type="primary"
                          plain
                          class="dashboard-card-invoice-btn"
                          @click="openInvoiceEdit(scope.row)"
                        >
                          <i class="el-icon-edit"></i>
                        </el-button>
                      </el-tooltip>
                      <el-button
                        size="small"
                        type="primary"
                        plain
                        class="dashboard-card-invoice-btn hidden-sm-and-up"
                        @click="openInvoiceEdit(scope.row)"
                      >
                        <i class="el-icon-edit"></i>
                      </el-button>
                    </template>
                  </el-table-column> -->
                </el-table>
              </div>
              <el-button
                size="small"
                class="dashboard-card-btn mt-auto"
                type="primary"
                plain
              >
                <router-link to="/invoices"> Показать все счета </router-link>
              </el-button>
            </div>
          </el-col>
        </el-row>
      </el-container>
    </div>
    <div class="dashboard-newsline">
      <marquee behavior="scroll" direction="left" scrollamount="5">
        <ul class="dashboard-newsline-list">
          <li class="dashboard-newsline-item">
            <i class="el-icon-favorite"></i>
            <span class="dashboard-newsline-text"
              >Сегодня запланировано приемов: {{ news.appointmentCount }}
            </span>
            <!-- <el-link type="primary" href="#">Читать полностью</el-link> -->
          </li>
          <li class="dashboard-newsline-item">
            <i class="el-icon-favorite"></i>
            <span class="dashboard-newsline-text"
              >Сегодня врачей ведут прием: {{ news.scheduleCount }}
            </span>
            <!-- <el-link type="primary" href="#">Читать полностью</el-link> -->
          </li>
          <li class="dashboard-newsline-item" v-if="invoicesShow">
            <i class="el-icon-favorite"></i>
            <span class="dashboard-newsline-text"
              >Оплачено счетов на сумму: {{ news.invoicesSum }} ₽
            </span>
            <!-- <el-link type="primary" href="#">Читать полностью</el-link> -->
          </li>
        </ul>
      </marquee>
    </div>

    <!-- модальное окно оплаты счета -->
    <el-dialog
      class="modal-pay-invocie"
      title="Оплата счета"
      :visible.sync="modalPayment"
      width="100%"
    >
      <el-row :gutter="24">
        <el-col :span="24" class="mt">
          <label for="">Тип оплаты</label>
          <el-select
            data-is-search="true"
            size="small"
            v-model="invoiceToSave.paymentType"
            filterable
            placeholder="Выберите тип оплаты"
            :loading="loading"
          >
            <el-option
              v-for="item in paymentTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="12" class="mt">
          <el-button
            class="w-100"
            size="small"
            type="primary"
            @click="handleSavePayment()"
            >Сохранить</el-button
          >
        </el-col>
        <el-col :span="12" class="mt">
          <el-button class="w-100" size="small" @click="handleClosePayment"
            >Отменить</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>

    <!-- модальное окно добавления счета -->
    <el-dialog
      class="modal-add-invoices"
      :before-close="handleCloseModalAddInvoice"
      :title="modalTitle"
      :visible.sync="modalAddInvoice"
      width="100%"
    >
      <el-row :gutter="24" align="center mt-20">
        <el-col :span="12" :sm="6">
          <label for="">Номер счета</label>
          <el-input
            size="small"
            placeholder="Номер счета будет присвоен автоматически"
            v-model="invoiceToSave.numberStr"
            disabled
          ></el-input>
        </el-col>
        <el-col :span="12" :sm="6">
          <label for="">Дата формирования</label>
          <el-date-picker
            size="small"
            v-model="invoiceToSave.dateCreate"
            format="dd.MM.yyyy"
            :picker-options="{ firstDayOfWeek: 1 }"
            placeholder="Выберите дату"
            :disabled="(invoiceToSave?.id ?? 0) > 0"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="12" :sm="6" class="mob-mt-16">
          <label for="">Статус оплаты</label>
          <el-select
            data-is-search="true"
            size="small"
            v-model="invoiceToSave.paymentStatus"
            filterable
            placeholder="Выберите статус оплаты"
            :loading="loading"
          >
            <el-option
              v-for="item in paymentStatusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="12" :sm="6" class="mob-mt-16">
          <label for="">Тип оплаты</label>
          <el-select
            data-is-search="true"
            size="small"
            v-model="invoiceToSave.paymentType"
            filterable
            placeholder="Выберите тип оплаты"
            :loading="loading"
          >
            <el-option
              v-for="item in paymentTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row :gutter="24" align="center mt-20">
        <el-col :span="24">
          <label for="">Пациент</label>
          <template>
            <el-select
              data-is-search="true"
              size="small"
              v-model="invoiceToSave.idPatient"
              filterable
              placeholder="Выберите пациента"
              :disabled="(invoiceToSave?.id ?? 0) > 0"
              :loading="loading"
            >
              <el-option
                v-for="item in patientOptions"
                :key="item.id"
                :label="handlePatientLabel(item)"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
        </el-col>
      </el-row>

      <el-row :gutter="24" align="center mt-20">
        <el-col :span="24">
          <label for="">Филиал</label>
          <template>
            <el-select
              size="small"
              v-model="invoiceToSave.idFilial"
              :disabled="(invoiceToSave?.id ?? 0) > 0"
              placeholder="Выберите филиал"
            >
              <el-option
                v-for="item in filialOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </template>
        </el-col>
      </el-row>

      <el-row :gutter="24" align="center mt-20">
        <el-col :span="24">
          <label for="">Услуги</label>
          <el-select
            data-is-search="true"
            size="small"
            v-model="currentService"
            filterable
            clearable
            placeholder="Начните вводить название услуги"
            :filter-method="handleServiceFilter"
            :loading="loading"
            @change="handleServiceSelect"
          >
            <el-option
              v-for="item in priceListOptions"
              :key="item.id"
              :label="handlePriceListLabel(item)"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-table
            class="mt"
            :data="invoiceToSave.services"
            style="width: 100%"
          >
            <el-table-column
              min-width="120px"
              property="name"
              label="Наименование"
            ></el-table-column>
            <el-table-column
              min-width="120px"
              property="code"
              label="Код услуги"
            ></el-table-column>
            <el-table-column
              min-width="150px"
              label="Количество, шт."
              property="count"
            >
              <template slot-scope="scope">
                <el-input-number
                  size="mini"
                  v-model="scope.row.count"
                  :min="1"
                  :max="10"
                  @change="handleChangeService(scope.row.idPriceList)"
                ></el-input-number>
              </template>
            </el-table-column>
            <el-table-column min-width="150px" label="Скидка, %">
              <template slot-scope="scope">
                <el-input-number
                  size="mini"
                  v-model="scope.row.discount"
                  :min="0"
                  :max="100"
                  @change="handleChangeService(scope.row.idPriceList)"
                ></el-input-number>
              </template>
            </el-table-column>
            <el-table-column
              min-width="150px"
              property="invoiceServicePrice"
              label="Стоимость, ₽"
            >
              <template slot-scope="scope">
                <el-input-number
                  size="mini"
                  :controls="false"
                  v-model="scope.row.priceDiscount"
                >
                </el-input-number>
              </template>
            </el-table-column>
            <el-table-column
              width="100px"
              property="invoiceServiceDateStart"
              label="Назначена"
              :formatter="formatterDateStart"
            >
            </el-table-column>
            <el-table-column
              width="100px"
              property="invoiceServiceDateStart"
              label="Исполнена"
              :formatter="formatterDateEnd"
            ></el-table-column>
            <el-table-column width="100px" label="Действия">
              <template slot-scope="scope">
                <el-button
                  size="small"
                  type="text"
                  class="danger"
                  @click="handleDeleteService(scope.row)"
                >
                  <i class="el-icon-delete el-icon-left"></i>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row :gutter="24" class="mt">
        <el-col :span="12">
          <h6>Итого: {{ invoiceToSave.sum }}</h6>
        </el-col>
      </el-row>
      <el-row :gutter="24" align="center" class="mt-20">
        <el-col :span="12" :sm="4">
          <el-button
            class="w-100"
            size="small"
            type="primary"
            :loading="loadingModal"
            @click="handleSaveModalInvoice"
            >Сохранить</el-button
          >
        </el-col>
        <el-col :span="12" :sm="4">
          <el-button
            size="small"
            class="w-100"
            @click="handleCloseModalAddInvoice"
            >Отменить</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>
    <ModalPrintDocumentComponent
      :is-modal-visible.sync="isModalPrintVisible"
      :id-patient="idPatient"
      :id-appointment="idAppointment"
      :documents-for-print="documentsForPrint"
    ></ModalPrintDocumentComponent>
  </div>
</template>

<script>
import ModalPrintDocumentComponent from '@/components/ModalPrintDocumentComponent.vue';
import AuthManager from '@/services/authManager';
import ScheduleManager from '@/services/scheduleManager.js';
import AppointmentManager from '@/services/appointmentManager.js';
import FilialManager from '@/services/filialManager.js';
import PriceListManager from '@/services/priceListManager.js';
import axios from 'axios';
import { getterTypes } from '@/store/modules/documentsPrintModule';
import { mapGetters } from 'vuex';

export default {
  name: 'DashboardPage',
  components: {
    ModalPrintDocumentComponent,
  },
  data() {
    return {
      modalPayment: false,
      loading: false,
      departments: [
        {
          id: 0,
          name: '',
          schedules: [
            {
              idDoctor: 0,
            },
          ],
        },
      ],
      departmentSelect: '0',
      schedule: [],
      invoices: [],
      appointmnetTypes: [],
      filials: [
        {
          id: 0,
          name: '',
        },
      ],
      selectedFilial: {
        id: 0,
        name: '',
      },
      invoicesShow: false,
      scheduleShow: false,
      patientOptions: [],
      filialOptions: [],
      priceListOptions: [],
      currentService: '',
      paymentTypeOptions: [
        {
          label: 'безналичный',
          value: '1',
        },
        {
          label: 'наличный',
          value: '2',
        },
        {
          label: 'бесплатно',
          value: '3',
        },
        {
          label: 'ДМС',
          value: '4',
        },
      ],
      paymentStatusOptions: [
        {
          label: 'оплачен',
          value: 1,
        },
        {
          label: 'не оплачен',
          value: null,
        },
      ],
      userFullName: '',
      news: {
        appointmentCount: 0,
        scheduleCount: 0,
        invoicesSum: 0,
      },
      stats: {
        all: 0,
        complite: 0,
        expect: 0,
        cancel: 0,
      },
      loadingModal: false,
      invoiceToSave: {
        paymentType: null,
        id: null,
        numberStr: '',
        dateCreate: new Date(),
        paymentStatus: '',
        idAppointment: null,
        services: [],
        sum: 0,
        idPatient: null,
        idFilial: null,
      },
      modalTitle: '',
      modalAddInvoice: false,
      isModalPrintVisible: false,
      idPatient: null,
      idAppointment: null,
    };
  },
  computed: {
    ...mapGetters({
      documentsForPrint: getterTypes.documentsForPrintOnInvoicesPage,
    }),
  },
  async mounted() {
    this.loading = true;
    let currentUser = AuthManager.getUser();
    this.userFullName = currentUser.fullname;
    this.filials = await FilialManager.getFilials();
    this.$store.dispatch('getFilials');
    this.appointmnetTypes = await AppointmentManager.getAppointmentTypes();
    this.priceList = await PriceListManager.getCurrentPriceList();
    this.selectedFilial = this.filials[0];
    this.scheduleShow = currentUser.roles.find((x) => x == 'personal_schedule');
    this.invoicesShow = currentUser.roles.find((x) => x == 'view_invoice');
    this.$patientHub
      .start()
      .then(() => {})
      .catch(() => {});
    this.$patientHub.client.on('ScheduleChange', async () => {
      await this.loadData();
    });
    this.$patientHub.client.on('InvoicesChange', async () => {
      await this.loadData();
    });
    await this.loadData();

    this.loading = false;
  },
  methods: {
    //загрузка расписания
    async loadData() {
      this.$store
        .dispatch('getScheduleList')
        .then(async () => {
          this.departments = await ScheduleManager.getDashboardSchedule(
            this.selectedFilial.id
          );
          this.departmentSelect =
            this.departments.length > 0 ? this.departments[0].name : '';
          if (this.scheduleShow) {
            this.schedule = await ScheduleManager.getUserDashboardSchedule();
          }
          this.news.appointmentCount =
            this.departments.length > 0
              ? this.departments
                  .flatMap((x) => x.schedules)
                  .reduce((a, x) => a + x.appointmentsCount, 0)
              : 0;
          this.news.scheduleCount =
            this.departments.length > 0
              ? this.departments
                  .flatMap((x) => x.schedules)
                  .reduce((a) => a + 1, 0)
              : 0;
          if (this.invoicesShow) {
            let invoiceData = await axios.get('Invoice', {
              params: { pagenumber: 1, pagesize: 1000, todayOnly: 1 },
            });
            this.invoices = invoiceData.data.value;
            this.news.invoicesSum =
              Math.ceil(
                this.invoices
                  .filter((x) => x.paymentStatus == 1)
                  .reduce((a, x) => a + x.sum, 0) * 100
              ) / 100;
          }
          this.stats.all =
            this.departments.length > 0
              ? this.departments
                  .flatMap((x) => x.schedules)
                  .reduce((a, x) => a + x.appointmentsAllCount, 0)
              : 0;
          this.stats.complite =
            this.departments.length > 0
              ? this.departments
                  .flatMap((x) => x.schedules)
                  .reduce((a, x) => a + x.appointmentsCompliteCount, 0)
              : 0;
          this.stats.expect =
            this.departments.length > 0
              ? this.departments
                  .flatMap((x) => x.schedules)
                  .reduce((a, x) => a + x.appointmentsExpectCount, 0)
              : 0;
          this.stats.cancel =
            this.departments.length > 0
              ? this.departments
                  .flatMap((x) => x.schedules)
                  .reduce((a, x) => a + x.appointmentsCancelCount, 0)
              : 0;
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: `Невозможно загрузить расписание!`,
          });
        });
    },

    formatStartTime(row) {
      return new Intl.DateTimeFormat('ru-RU', {
        hour: 'numeric',
        minute: 'numeric',
      }).format(new Date(row.dateTimeFrom));
    },
    formatAppointmentType(row) {
      return this.appointmnetTypes.find((x) => x.id == row.idAppointmentType)
        .value;
    },
    formatterDateStart(row) {
      return new Intl.DateTimeFormat('ru-RU').format(
        new Date(row.dateTimeStart)
      );
    },
    formatterDateEnd(row) {
      return row.dateEnd
        ? new Intl.DateTimeFormat('ru-RU').format(new Date(row.dateTimeEnd))
        : '';
    },
    handlePatientLabel(item) {
      let birthDateString = new Intl.DateTimeFormat('ru-RU')
        .format(new Date(item.birthDate))
        .toString();
      let labelString = `${item.lastName} ${item.firstName}`;
      if (window.innerWidth > 1024) {
        labelString = item.patronymic
          ? labelString + ` ${item.patronymic}`
          : labelString;
        labelString = labelString + ` ${birthDateString}г. р., ${item.phone}`;
        labelString = item.registerAddress
          ? labelString + `, ${item.registerAddress}`
          : labelString;
      } else if (window.innerWidth > 768) {
        labelString = item.patronymic
          ? labelString + ` ${item.patronymic}`
          : labelString;
        labelString = labelString + ` ${birthDateString}г. р., ${item.phone}`;
      } else {
        labelString = item.patronymic
          ? labelString + ` ${item.patronymic}`
          : labelString;
      }
      return labelString;
    },
    handlePriceListLabel(item) {
      return `${item.medicalServiceCurrentOrganization.name} (${item.positionTypeName}, ${item.categoryName})`;
    },
    handleClosePayment() {
      this.$confirm(
        'Вы действительно хотите отменить оплату счета?',
        'Подтверждение',
        {
          confirmButtonText: 'Подтвердить',
          cancelButtonText: 'Отменить',
          type: 'warning',
        }
      ).then(async () => {
        this.$message({
          type: 'info',
          message: 'Оплата счета отменена',
        });
        this.modalPayment = false;
      });
    },
    //закрытие модального окна работы со счетом
    handleCloseModalAddInvoice(done) {
      this.$confirm('Вы уверены, что хотите прервать работу со счетом?').then(
        () => {
          this.$message({
            type: 'info',
            message: 'Создание счета прервано. Данные не сохранены',
          });
          this.modalAddInvoice = false;
          done();
        }
      );
    },
    //загрузка выпадающего списка услуг
    handleServiceFilter(query) {
      if (query !== '') {
        let queryArr = query.split(' ');
        this.priceListOptions = this.priceList;
        for (let i = 0; i < queryArr.length; i++) {
          this.priceListOptions = this.priceListOptions.filter((item) => {
            return (
              item.medicalServiceCurrentOrganization.name
                .toLowerCase()
                .indexOf(queryArr[i].toLowerCase()) > -1
            );
          });
        }
      } else {
        this.priceListOptions = [];
      }
    },

    //выбор услуги
    handleServiceSelect() {
      let currentPriceListItem = this.priceList.find(
        (x) => x.id == this.currentService
      );
      let currentAppointmentservice = {
        id: null,
        idAppointment: this.invoiceToSave.idAppointment,
        idPriceList: this.currentService,
        dateTimeStart: new Date(),
        dateTimeEnd: new Date(),
        count: 1,
        discount: 0,
        price: currentPriceListItem.price,
        priceDiscount: currentPriceListItem.price,
        name: currentPriceListItem.medicalServiceCurrentOrganization.name,
        code: currentPriceListItem.medicalServiceCurrentOrganization
          .medicalServiceType.code,
      };
      this.invoiceToSave.services.push(currentAppointmentservice);
      this.invoiceToSave.sum =
        this.invoiceToSave.sum + currentAppointmentservice.priceDiscount;
      this.paymentTypeOptions[2].disabled = this.invoiceToSave.sum > 0;
    },
    async handleCommand(command) {
      this.selectedFilial = this.filials.find((x) => x.id == command);
      await this.loadData();
      this.departmentSelect = this.departments[0]?.name ?? '';
    },
    openInvoicePayment(row) {
      this.invoiceToSave = row;
      this.invoiceToSave.paymentType = '';
      this.paymentTypeOptions[2].disabled = this.invoiceToSave.sum > 0;
      this.modalPayment = true;
    },
    async openInvoiceEdit(item) {
      this.loadingModal = false;
      this.invoiceToSave = item;
      this.modalTitle = 'Редактирование счета';
      let patientList = await axios.get('patient', {
        params: {
          pagenumber: 1,
          pagesize: 1,
          patientId: this.invoiceToSave.idPatient,
        },
      });
      this.patientOptions = patientList.data.value;
      this.paymentTypeOptions[2].disabled = this.invoiceToSave.sum > 0;
      this.modalAddInvoice = true;
    },
    //редактирование услуги
    handleChangeService(idPriceList) {
      let currentRow = this.invoiceToSave.services.find(
        (x) => x.idPriceList == idPriceList
      );
      currentRow.priceDiscount =
        currentRow.count * currentRow.price * (1 - currentRow.discount / 100);
      this.invoiceToSave.sum = this.invoiceToSave.services.reduce(
        (a, x) => a + x.priceDiscount,
        0
      );
      this.paymentTypeOptions[2].disabled = currentRow.priceDiscount > 0;
    },
    //удаление услуги
    handleDeleteService(row) {
      let index = this.invoiceToSave.services.indexOf(row);
      if (index > -1) {
        this.invoiceToSave.sum = this.invoiceToSave.sum - row.priceDiscount;
        this.invoiceToSave.services.splice(index, 1);
      }
    },
    //сохранение счета
    async handleSaveModalInvoice() {
      if (this.invoiceToSave.idPatient == null) {
        this.$message({
          type: 'error',
          message: `Не выбран пациент!`,
        });
        return;
      }
      if (this.invoiceToSave.idFilial == null) {
        this.$message({
          type: 'error',
          message: `Не выбран филиал!`,
        });
        return;
      }
      if (this.invoiceToSave.services.length < 1) {
        this.$message({
          type: 'error',
          message: `Не выбраны услуги!`,
        });
        return;
      }
      if (this.invoiceToSave.id) {
        this.loadingModal = true;
        axios
          .put('invoice', this.invoiceToSave)
          .then(async () => {
            await this.$patientHub.client.invoke('BroadcastInvoiceChange');
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: `Невозможно сохранить изменения!`,
            });
          });
        this.loadingModal = false;
      } else {
        this.loadingModal = true;
        axios
          .post('invoice', this.invoiceToSave)
          .then(async () => {
            await this.$patientHub.client.invoke('BroadcastInvoiceChange');
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: `Невозможно сохранить изменения!`,
            });
          });
        this.loadingModal = false;
      }
      this.modalAddInvoice = false;
    },
    async handleSavePayment() {
      this.loadingModal = true;
      this.invoiceToSave.paymentStatus = 1;
      this.invoiceToSave.datePayment = new Date();
      axios
        .put('invoice', this.invoiceToSave)
        .then(async () => {
          await this.$patientHub.client.invoke('BroadcastInvoiceChange');
        })
        .catch(() => {
          this.$message({
            type: 'error',
            message: `Невозможно сохранить информацию об оплате!`,
          });
        });
      this.modalPayment = false;
      this.loadingModal = false;
    },
    openModalPrint(row) {
      console.log(row);
      this.idPatient = row.idPatient;
      this.idAppointment = row.idAppointment;
      this.isModalPrintVisible = !this.isModalPrintVisible;
    },
  },
};
</script>
