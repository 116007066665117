<template>
  <div class="page-wrap" v-loading="loading">
    <BreadcrumbComponent :patient="patient"></BreadcrumbComponent>
    <div class="page-header mb-12">
      <h4 class="mb-0">Случаи обращения</h4>
    </div>
    <div class="page-subheader">
      <el-row class="w-100" :gutter="24">
        <el-col :span="24" :sm="12" :md="8">
          <el-tooltip
            class="item hidden-xs-only"
            effect="dark"
            content="Поиск случая обращения можно производить по ФИО врача, его специальности или диагнозу"
            placement="right"
            :hide-after="2500"
          >
            <el-input
              size="small"
              placeholder="Найти случай обращения..."
              prefix-icon="el-icon-search"
              v-model="medicalCaseSearch"
            >
            </el-input>
          </el-tooltip>
          <el-input
            class="hidden-sm-and-up"
            size="small"
            placeholder="Найти случай обращения..."
            prefix-icon="el-icon-search"
            v-model="medicalCaseSearch"
          >
          </el-input>
        </el-col>
      </el-row>
    </div>
    <el-table
      ref="multipleTable"
      :data="medicalCases(medicalCaseSearch)"
      empty-text="Не найдено случаев обращения у даного пациента"
      style="width: 100%"
      class="mt-16"
    >
      <el-table-column
        property="dateTimeStart"
        label="Дата начала"
        sortable
        :formatter="formatterDateStart"
      >
      </el-table-column>
      <el-table-column
        property="dateTimeEnd"
        label="Дата окончания"
        sortable
        :formatter="formatterDateEnd"
      >
      </el-table-column>
      <el-table-column property="doctorName" label="Врач" sortable>
      </el-table-column>
      <el-table-column
        min-width="140"
        property="doctorPositionName"
        label="Специальность"
        sortable
      >
      </el-table-column>
      <el-table-column
        min-width="160"
        property="mainDiagnosisName"
        label="Основной диагноз"
        sortable
      >
      </el-table-column>
      <el-table-column
        property="amountVisits"
        label="Кол-во приемов"
        sortable
        :formatter="formatAppointmentsCount"
      >
      </el-table-column>
      <el-table-column width="100" label="">
        <template slot-scope="scope">
          <router-link
            v-show="scope.row.appointments.length"
            class="table-link"
            :to="{
              path:
                '/patient/' +
                patientId +
                '/medical-cases/' +
                scope.row.id +
                '/visits-list/',
            }"
          >
            Приемы
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BreadcrumbComponent from '@/components/BreadcrumbComponent.vue';
export default {
  name: 'MedicalCasesPage',
  components: {
    BreadcrumbComponent,
  },
  data() {
    return {
      medicalCaseSearch: '',
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['medicalCases', 'patient']),
    patientId() {
      return Number(this.patient.id);
    },
  },
  mounted() {
    this.loading = true;
    this.loadData()
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.$message({
          type: 'error',
          message: 'Произошла ошибка при загрузке страницы!',
        });
        this.$router.go(-1);
      });
  },

  methods: {
    async loadData() {
      await this.$store.dispatch('GET_MEDICALCASES', {
        id: this.$route.params.id,
      });
      await this.$store.dispatch('getPatient', {
        patientId: this.$route.params.id,
        pagenumber: 1,
        pagesize: 1,
      });
    },
    formatterDateStart(row) {
      return new Intl.DateTimeFormat('ru-RU').format(
        new Date(row.dateTimeStart)
      );
    },
    formatterDateEnd(row) {
      return row.dateTimeEnd
        ? new Intl.DateTimeFormat('ru-RU').format(new Date(row.dateTimeEnd))
        : '';
    },
    formatAppointmentsCount(row) {
      return row.appointments.length;
    },
  },
};
</script>
