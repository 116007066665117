import Axios  from "axios";

class ClientOrganizationManager {

  async getClientOrganization() {
    let { data } = await Axios.get("clientorganization");
    return data.value;
  }

  async updateClientOrganization(clientOrganization) {
    await Axios.put("clientorganization", clientOrganization);
  }
}

export default new ClientOrganizationManager();